import React from 'react';

interface Props {

}

// eslint-disable-next-line no-empty-pattern
export default function PrivacyPolicy({

}: Props) {

  return (
    <article className="grid-container">
      <h4>Privacy Policy</h4>

      <p>Fadila uses user identifiable data from google to read users YouTube data.</p>
      <p>This is done to allow the application to play music video's from the users private playlists.</p>

      <p>Fadila also uses user identifiable data from google to read data from the users Google Drive if applicable.</p>
      <p>This is done to allow the application to play music from the users Google Drive.</p>

      <p>Fadila is a serverless application, therefore no data is stored on servers outside of those noted above.</p>
      <p>Furthermore, Fadila does not share or send any data to third parties.</p>
    </article>
  );
}
