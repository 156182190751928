import React from 'react';
import { Link } from 'react-router-dom';
import s from './header.module.css';

interface Props {

}

// eslint-disable-next-line no-empty-pattern
export default function ContentPageHeader({

}: Props) {

  return (
    <header>
      <nav className="navigation">
        <div>
          <h3>
            <Link to="/">
              <img
                className={s.vinylImg}
                alt="Vinyl Record"
                src="/icons/vinyl.png"
              />
              Fadila
            </Link>
          </h3>
        </div>
        <ul className="menu align-center">
          <li className={s.navListItem}>
            <Link to={"/app"}>
              App
            </Link>
          </li>
          <li className={s.navListItem}>
            <Link to={"/privacy"}>
              Privacy
            </Link>
          </li>
          <li className={s.navListItem}>
            <Link to={"/help"}>
              Help
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
