import classNames from 'classnames';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { DragType } from '../../../models/dragTypes';
import { addMultipleItemsAsync } from '../../../models/playlist/playlist';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import { SpotifyTrackAlbum } from '../../../models/spotify/spotifyAlbum';
import PlaylistColumns, { ColumnKey, ColumnScope, TrackProperty } from '../../../services/columnSettings/playlistColumns';
import { Context } from '../../../services/_globalContext/context';
import AddToPlaylistSubMenu from '../../common/addToPlaylist/addToPlaylistSubMenu';
import useColumnSettings from '../../common/hooks/useColumnSettings';
import s from '../../common/itemRow/itemRow.module.scss';
import ItemRowWrapper from '../../common/itemRow/ItemRowWrapper';
import sS from '../../common/streamingServiceCommon/streamSvcSearchResultRow.module.scss';
import spotifyStyles from '../spotify.module.scss';
import getImageBySize from '../spotifyCommon';

export interface SpotifyAlbumRowProps {
  /** Item array index */
  index: number;
  item: SpotifyTrackAlbum;
  addAlbumToPlaylist(addItems: (i: PlaylistItem[]) => void):
    (albumId: string) => Promise<void>;
  openAlbum(album: SpotifyTrackAlbum): void;

  isSelected: boolean;
  selectedItemsRef: React.MutableRefObject<Iterable<SpotifyTrackAlbum>>;

  handleToggleItem(plItm: SpotifyTrackAlbum): void;
  changeItemSelectionState(
    plItm: SpotifyTrackAlbum,
    selectState: boolean,
    removePrevious?: boolean): void;
  handleShiftSelect(plItm: SpotifyTrackAlbum): void;
  clearSelected(): void;

  portalTarget?: HTMLElement;
}

export default function AlbumSearchResult({
  index,
  item,
  addAlbumToPlaylist,
  openAlbum,

  portalTarget,
  isSelected,
  selectedItemsRef,
  changeItemSelectionState,
  handleShiftSelect,
  handleToggleItem,
  clearSelected,

}: SpotifyAlbumRowProps) {

  const ctx = useContext(Context);

  const titleElRef = useRef<HTMLTableDataCellElement>(null);

  const [isDragging, setIsDragging] = useState(false);

  const { columns, columnSettings } = useColumnSettings(ColumnScope.SpotifyAlbumSearch);

  const onDragStart = (
    ev: React.DragEvent,
  ) => {
    console.debug('dragstart on album item');

    setIsDragging(true);

    // We set loads of data here since you can't call getData in onDragOver
    ev.dataTransfer.setData('dragType', DragType.SpotifyAlbum);
    // types.includes checks for matching drag type in onDragOver
    ev.dataTransfer.setData(DragType.SpotifyAlbum, DragType.SpotifyAlbum);
    ev.dataTransfer.setData('text/plain', item.id);
    ev.dataTransfer.setData('name', item.name);

    ev.dataTransfer.effectAllowed = 'copy';

    if (titleElRef.current) {
      ev.dataTransfer.setDragImage(titleElRef.current, 0, 0);
    }
    if (!isSelected) {
      clearSelected();
      changeItemSelectionState(item, true, true);
    }

    ctx.dndData[item.id] = selectedItemsRef;
  };
  function onDragEnd(_ev: React.DragEvent) {
    setIsDragging(false);

    delete ctx.dndData[item.id];
  }

  /** Add items in all selected playlists to target library playlist */
  const handleAddAlbumsToPlaylist = useCallback(function handleAddAlbumsToPlaylist(
    addItems: (i: PlaylistItem[]) => void,
  ) {
    const tasks = Array<Promise<any>>();
    const items = Array<PlaylistItem>();
    const addItemsToArray = (x: PlaylistItem[]) => items.push(...x);

    for (const album of selectedItemsRef.current) {
      tasks.push(
        addAlbumToPlaylist(addItemsToArray)(album.id)
      );
    }

    addMultipleItemsAsync({ tasks, items, addItems });

  }, [addAlbumToPlaylist, selectedItemsRef]);

  return (
    <ItemRowWrapper<SpotifyTrackAlbum>
      index={index}
      item={item}
      itemId={item.id}
      className={sS.row}

      onDragStart={onDragStart}
      onDragEnd={onDragEnd}

      isSelected={isSelected}
      isDragging={isDragging}

      handleShiftSelect={() => handleShiftSelect(item)}
      handleToggleItem={() => handleToggleItem(item)}
      selectOnlyItem={() => changeItemSelectionState(item, true, true)}

      handlePlay={() => openAlbum(item)}

      menuItems={
        <AddToPlaylistSubMenu
          newPlaylistName={item.name}
          playlistItemOrItems={addItems => handleAddAlbumsToPlaylist(addItems)}
        />
      }
      portalTarget={portalTarget}
    >
      {columnSettings &&
        <>
          <div className={classNames(s.rowCell, s.playBtn)}>
            <button
              className="play-button"
              onClick={() => openAlbum(item)}
            >
              <span role="img" aria-label="Link">🔗</span>
            </button>
          </div>
          {columns.map(col => {
            const colKey = col as ColumnKey;

            switch (colKey) {
              case TrackProperty.title:
                return (
                  <div
                    key={colKey}
                    ref={titleElRef}
                    className={classNames(s.rowCell, {
                      [s.rightAlignCell]: PlaylistColumns[colKey].alignRight
                    })}
                    style={{
                      width: columnSettings[colKey]?.width
                    }}
                    title={item.name}
                  >
                    {item.name}
                  </div>
                );

              case TrackProperty.artist:
                const artists = item.artists.map(a => a.name).join(', ');

                return (
                  <div
                    key={colKey}
                    className={classNames(s.rowCell, {
                      [s.rightAlignCell]: PlaylistColumns[colKey].alignRight
                    })}
                    style={{
                      width: columnSettings[colKey]?.width
                    }}
                    title={artists}
                  >
                    {artists}
                  </div>
                );

              case 'image':
                return (
                  <div
                    key={colKey}
                    className={classNames(s.rowCell, {
                      [s.rightAlignCell]: PlaylistColumns[colKey].alignRight
                    })}
                    style={{
                      width: columnSettings[colKey]?.width
                    }}
                    title={item.name}
                  >
                    <img
                      className={spotifyStyles.playlistImgLarge}
                      src={getImageBySize(item.images, true).url}
                      alt={item.name}
                    />
                  </div>
                );

              default: throw new Error('Unexpected column mismatch!');
            }
          })}
        </>
      }
    </ItemRowWrapper>
  );
};
