import { traverse } from "fp-ts/es6/Array";
import { either } from "fp-ts/es6/Either";
import { pipe } from "fp-ts/es6/function";
import PlaylistItem from "../../models/playlistItem/playlistItem";
import Track from "../../models/track";
import { GlobalState } from "../_globalContext/context";
import { ILibraryContext } from "./libraryService";

export function createAddTracksToLibrary(
  ctx: GlobalState,
  library: ILibraryContext,
) {
  return function addTracksToLibrary(_addItems?: any) {
    return function (...items: Array<Track>) {
      return pipe(
        (x: Array<Track>) => traverse(either)(x, library.addToLibrary),
        x => x.mapLeft(ctx.callSnackbar),
      )(items);
    };
  }
}
export function createAddItemsToPlaylistThenLibrary<T1>(
  ctx: GlobalState,
  libCtx: ILibraryContext,
  trackConstructor: (itm: T1) => Track,
) {
  return function addItemsThenToLibrary(addItems: (i: PlaylistItem[]) => void) {
    return function (...items: T1[]) {
      return pipe(
        (x: Array<T1>) => x.map(trackConstructor),
        x => libCtx.addToLibrary(...x),
        x => x.map(y => y.map(PlaylistItem.from)),
        x => x.map(addItems),
        x => x.mapLeft(ctx.callSnackbar),
      )(items);
    };
  };
}

export default function createAddTracksToPlaylistThenLibrary(
  ctx: GlobalState,
  libCtx: ILibraryContext,
) {
  return function addTracksToPlaylistThenLibrary(addItems: (i: PlaylistItem[]) => void) {
    return function (...items: Array<Track>) {
      return pipe(
        (x: Array<Track>) => libCtx.addToLibrary(...x),
        x => x.map(y => y.map(PlaylistItem.from)),
        x => x.map(addItems),
        x => x.mapLeft(ctx.callSnackbar),
      )(items);
    };
  }
}
