import classNames from 'classnames';
import React from 'react';
import s from './spinner.module.css';

interface Props {
  mini?: boolean;
  noMaxHeight?: boolean;
}

export default function Spinner({
  mini,
  noMaxHeight,
}: Props) {

  return (
    <article className={classNames({
      [s.container]: true,
      [s.noMaxHeight]: noMaxHeight,
      [s.miniSpinner]: mini,
    })}>
      <div className={s.ldsFacebook}>
        <div></div><div></div><div></div>
      </div>
    </article>
  );
}
