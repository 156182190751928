import Track from '.';
import Source from '../source';

export default class GoogleDriveTrack extends Track {
  static from(driveItem: gapi.client.drive.File) {
    return new GoogleDriveTrack(driveItem);
  }
  constructor(
    driveItem: gapi.client.drive.File,
  ) {

    super(
      driveItem.id!, // we throw on null later in the constructor
      Source.GoogleDrive,
      // Remove filename extensions
      driveItem.name!.replace(/\.(?:.(?!\.))+$/, ''),
    );

    if (!driveItem.id) throw new Error('Missing DriveItem id for GoogleDriveTrack');
  }
}
