import classNames from 'classnames';
import React, { useCallback, useContext, useMemo } from 'react';
import uuidv4 from '../../../gUtilities/uuidv4';
import Source, { Sources } from '../../../models/source';
import Track from '../../../models/track';
import { ActivePlaylistContext } from '../../../services/activePlaylist/activePlaylist';
import PlaylistColumns, { ColumnScope, ColumnSettings, TrackProperty } from '../../../services/columnSettings/playlistColumns';
import { preventDefaultStopPropogation } from '../../../utilities/preventDefault';
import useColumnSettings from '../hooks/useColumnSettings';
import s from './itemRow.module.scss';
import ItemRowMoveTarget from './itemRowMoveTarget';
import ItemRowWrapper from './ItemRowWrapper';

interface Props {
  /** Item array index */
  index: number;
  track: Track;
  innerRowRef: React.RefObject<HTMLElement>;
  titleElRef: React.RefObject<HTMLTableDataCellElement>;

  onDragStart(ev: React.DragEvent): void;
  onDragEnd(ev: React.DragEvent): void;

  isHoverTop?: boolean;
  isHoverBottom?: boolean;
  moveableHandlers?: any;

  isSelected: boolean;
  isDragging: boolean;

  /**
   * Removes all previous selections
   */
  selectOnlyItem(): void;
  handleToggleItem(): void;
  handleShiftSelect(): void;

  handlePlay(): void;
  /** Unused */
  handleDelete?(): void;

  menuItems: React.ReactNode;
  onShowContextMenu?: Function;
  onHideContextMenu?: Function;

  /**
   * Provide data for non-default columns.
   */
  extraColumns?: React.ReactNode;
  columnScope: ColumnScope;
  portalTarget?: HTMLElement;
}

export default React.memo(function ItemRowInner(props: Props) {

  const {
    index,
    track,
    innerRowRef,
    titleElRef,
  
    isSelected,
    isDragging,
  
    onDragStart,
    onDragEnd,
  
    selectOnlyItem,
  
    handlePlay,
    handleShiftSelect,
    handleToggleItem,
  
    menuItems,
    onShowContextMenu,
    onHideContextMenu,
  
    extraColumns,
    columnScope,
  
    isHoverTop,
    isHoverBottom,
    moveableHandlers,
  
    portalTarget,
  } = props;

  const { columnSettings } = useColumnSettings(columnScope);

  const apCtx = useContext(ActivePlaylistContext);
  const itmUUID = useMemo(() => uuidv4(), []);
  const pos = innerRowRef.current?.getBoundingClientRect();

  const trackColumns = useMemo(() => columnSettings === undefined ? {} :
    Object.keys(columnSettings).reduce((res, col) => {

      const trackPropCol = col as TrackProperty;

      // Filter out columns not applicable to Source
      // F.x. YT tracks in library add artist and album width to title
      if (Sources[track.type].skipColumns) {
        if (Sources[track.type].skipColumns?.includes(trackPropCol) === false) {
          res[trackPropCol] = columnSettings[trackPropCol];
        }
      }
      else {
        res[trackPropCol] = columnSettings[trackPropCol];
      }

      return res;

    }, {} as Partial<ColumnSettings>

    ), [columnSettings, track]);

  /** 
   * Merges adjacent columns that are not applicable to the given source
   * 
   * F.x. in YT case, album/artist/genre do not apply 
   * so we add their width to the previous column
  */
  const calculateWidth = useCallback(function (trackProp: TrackProperty) {

    if (columnSettings) {
      let colWidth = columnSettings[trackProp]?.width;

      if (colWidth) {

        const colKeys = Object.keys(columnSettings);
        let missingWidth = 0;

        for (let x = colKeys.indexOf(trackProp) + 1; x < colKeys.length; x++) {

          const colKey = colKeys[x] as TrackProperty;

          // If the column was not applicable for the given source
          // it will be missing from track columns
          if (trackColumns[colKey] === undefined) {
            missingWidth += columnSettings[colKey]!.width;
          }
          // If the column existed in trackColumns, 
          // there are no more missing adjacent columns to merge with the observed column
          else {
            break;
          }
        }

        return colWidth + missingWidth;
      }
    }
  }, [trackColumns, columnSettings]);

  return (
    <article
      ref={innerRowRef}

      className={classNames('playlistItemRow', s.tbody)}
    >
      <ItemRowWrapper
        index={index}
        item={track}
        itemId={itmUUID}

        onDragStart={onDragStart}
        onDragEnd={onDragEnd}

        moveableHandlers={moveableHandlers}

        isSelected={isSelected}
        isDragging={isDragging}

        handleShiftSelect={handleShiftSelect}
        handleToggleItem={handleToggleItem}
        selectOnlyItem={selectOnlyItem}

        handlePlay={handlePlay}
        // handleDelete={handleDelete(removeItems)}

        onShowContextMenu={onShowContextMenu}
        onHideContextMenu={onHideContextMenu}

        menuItems={menuItems}
        portalChildren={moveableHandlers &&
          <ItemRowMoveTarget
            hide={!(isHoverTop || isHoverBottom)}
            posY={isHoverTop ? pos?.top : pos?.bottom}
            width={pos?.width}
          />
        }
        portalTarget={portalTarget}
      >
        <div className={classNames(s.rowCell, s.playBtn)}>
          {track.missing
            ? '!'
            : track.type !== Source.Null && apCtx.currentTrack.id === track.id
              ?
              '♫'
              :
              <button
                className="play-button invisible-control"
                onClick={preventDefaultStopPropogation(handlePlay)}
              >
                ►
              </button>
          }
        </div>
        {(Object.keys(trackColumns) as TrackProperty[])
          // We resort af useColumnSettings since our reduce and object keys
          // ignore previous sorting
          .sort((a, b) => trackColumns[a]!.sortOrder - trackColumns[b]!.sortOrder)
          .map(key => {

            const value = Track.format(track[key], key);

            return (
              <div
                key={key}
                className={classNames(s.rowCell, {
                  [s.rightAlignCell]: PlaylistColumns[key].alignRight
                })}
                ref={key === TrackProperty.title ? titleElRef : undefined}
                style={{
                  width: calculateWidth(key)
                }}
                title={value}
              >
                {value}
              </div>
            );
          })}
        {extraColumns}
      </ItemRowWrapper>
    </article>
  );
});
