import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, ContextMenuTriggerProps } from 'react-contextmenu';
import { Link } from 'react-router-dom';
import AccessibleElement from '../../../gUtilities/components/accessibleElement';
import Portal from '../../../gUtilities/portal';
import { DragType } from '../../../models/dragTypes';
import Playlist, { addItems } from '../../../models/playlist/playlist';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import { DropboxContext } from '../../../services/dropbox/dropboxService';
import { GoogleApiContext } from '../../../services/gapi/gapiService';
import createAddTracksToPlaylistThenLibrary from '../../../services/library/addTracksToPlaylistThenLibrary';
import { LibraryContext } from '../../../services/library/libraryService';
import { OneDriveContext } from '../../../services/oneDrive/oneDriveService';
import { SmallDeviceContext, SmallDeviceShow } from '../../../services/smallDevices/smallDeviceService';
import { SpotifyContext } from '../../../services/spotify/spotifyService';
import { Context } from '../../../services/_globalContext/context';
import Constants, { Keys, messages } from '../../../settings';
import { preventDefaultStopPropogation } from '../../../utilities/preventDefault';
import FadilaMenuItem from '../../common/contextMenu/fadilaMenuItem';
import s from './playlistRow.module.scss';
import { baseSupportedDragTypes, handleDropCommon } from './playlistRowCommon';
import PlaylistMoveTarget from './playlistRowMoveTarget';

interface Props {
  draggable: boolean;
  playlist: Playlist;
  selected: boolean;
  setCurPlaylist?(val: string): void;
  portalTarget?: HTMLElement;
}

export default React.memo(function PlaylistRow({
  draggable,
  playlist,
  selected,
  setCurPlaylist,
  portalTarget,
}: Props) {

  const inLibraryComponent = !!setCurPlaylist;

  const [supportedDropTypes] = useState<string[]>([
    DragType.Playlist,
    DragType.PlaylistItems,
  ].concat(baseSupportedDragTypes));

  const ctx = useContext(Context);
  const smallDevSvc = useContext(SmallDeviceContext);
  const libCtx = useContext(LibraryContext);
  const libCtxRef = useRef(libCtx);
  libCtxRef.current = libCtx;
  const spotifyCtx = useContext(SpotifyContext);
  const gapiCtx = useContext(GoogleApiContext);
  const oneDriveCtx = useContext(OneDriveContext);
  const dbxCtx = useContext(DropboxContext);

  const el = useRef<HTMLElement>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isHoverTop, setIsHoverTop] = useState(false);
  const [isHoverBottom, setIsHoverBottom] = useState(false);
  const [isHoverDrop, setIsHoverDrop] = useState(false);

  const pos = el.current?.getBoundingClientRect();

  const addTracksToPlaylistThenLibrary = createAddTracksToPlaylistThenLibrary(ctx, libCtx);

  function handleDrop(ev: React.DragEvent<HTMLLIElement>) {

    const dataTransfer: DataTransfer = ev.dataTransfer;
    const add = addItems(playlist)(libCtx.updatePlaylist);

    const dragType = dataTransfer.getData('dragType');

    switch (dragType) {

      case DragType.Playlist:

        const id = dataTransfer.getData('text/plain');
        const pl = libCtx.playlists.find(pl => pl.id === id);

        if (pl) {
          if (el.current) {
            var pos = el.current.getBoundingClientRect();

            if (ev.clientY < pos.top + (pos.height / 3)) {
              libCtx.movePlaylist(pl, playlist);
            }
            else if (ev.clientY > pos.top + (pos.height / 3) * 2) {
              libCtx.movePlaylist(pl, playlist, true);
            }
            else {
              add(pl.tracks.map(PlaylistItem.from));
            }
          }

        } else {
          ctx.callSnackbar(messages(Keys.playlistNotFound));
        }

        return;

      case DragType.PlaylistItems:

        const key = dataTransfer.getData('text/plain');
        const items = ctx.dndData[key] as { current: Iterable<PlaylistItem> };
        if (items) {
          add([...items.current].map(PlaylistItem.from));
        }

        return;
    }

    handleDropCommon(
      ctx,
      libCtx,
      gapiCtx,
      spotifyCtx,
      oneDriveCtx,
      dbxCtx,
      add,
      addTracksToPlaylistThenLibrary(add),
      ev,
    );
  }

  const onDragStart = (
    ev: React.DragEvent<HTMLLIElement>,
  ) => {
    console.debug('dragstart on playlist: ', playlist.id);

    // We set loads of data here since you can't call getData in onDragOver
    ev.dataTransfer.setData('dragType', DragType.Playlist);
    // types.includes checks for matching drag type in onDragOver
    ev.dataTransfer.setData(DragType.Playlist, DragType.Playlist);
    // onDragOver tests if we're hovering over the playlist that itself is being dragged
    // in that case we don't allow drop on yourself
    ev.dataTransfer.setData(playlist.id, playlist.id);
    ev.dataTransfer.setData('text/plain', playlist.id);

    ev.dataTransfer.effectAllowed = 'copyMove';

    setIsDragging(true);
  };
  const onDragOver = (ev: React.DragEvent<HTMLLIElement>) => {

    if (ev.dataTransfer.types.some(x => supportedDropTypes.includes(x))) {

      if (!ev.dataTransfer.types.includes(playlist.id)) {

        ev.preventDefault();

        if (el.current) {
          var pos = el.current.getBoundingClientRect();

          if (ev.dataTransfer.types.includes(DragType.Playlist)) {
            if (ev.clientY < pos.top + (pos.height / 3)) {
              setIsHoverTop(true);
              setIsHoverBottom(false);
              setIsHoverDrop(false);
              ev.dataTransfer.dropEffect = 'move';
            }
            else if (ev.clientY > pos.top + (pos.height / 3) * 2) {
              setIsHoverTop(false);
              setIsHoverBottom(true);
              setIsHoverDrop(false);
              ev.dataTransfer.dropEffect = 'move';
            }
            else {
              setIsHoverTop(false);
              setIsHoverBottom(false);
              setIsHoverDrop(true);
              ev.dataTransfer.dropEffect = 'copy';
            }
          } else {
            setIsHoverDrop(true);
            ev.dataTransfer.dropEffect = 'copy';
          }
        }
      } else {
        ev.dataTransfer.dropEffect = 'none';
      }
    }
  };
  const onDragLeave = (_ev: React.DragEvent<HTMLLIElement>) => {
    console.debug('onDragLeave');
    setIsHoverTop(false);
    setIsHoverBottom(false);
    setIsHoverDrop(false);
  };
  const onDrop = (
    ev: React.DragEvent<HTMLLIElement>,
  ) => {
    console.debug('onDrop');

    // It's possible to drop invalid types when there's a nested html input element
    if (ev.dataTransfer.types.some(x => supportedDropTypes.includes(x))) {

      if (!ev.dataTransfer.types.includes(playlist.id)) {
        ev.preventDefault();

        handleDrop(ev);

        setIsHoverTop(false);
        setIsHoverBottom(false);
        setIsHoverDrop(false);
      }
    }
  };

  function onDragEnd(_ev: React.DragEvent<HTMLLIElement>) {
    setIsDragging(false);
    // delete ctx.dndData[key];
  }

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    const form = ev.currentTarget;
    const inputEl: HTMLInputElement = form['playlist-name'];

    updatePlaylistName(inputEl);
  }

  function handleBlur(ev: React.FocusEvent<HTMLInputElement>) {
    updatePlaylistName(ev.currentTarget);
  }

  function updatePlaylistName(inputEl: HTMLInputElement) {

    if (inputEl) {
      libCtx.updatePlaylist({ ...playlist, name: inputEl.value });
    }

    setIsRenaming(false);
  }

  function triggerPlaylistSync() {
    for (let syncSource of playlist.syncSources) {
      syncSource.lastUpdated = -1;
    }

    libCtx.updatePlaylist({ ...playlist });
  }

  const inputEl = useRef<HTMLInputElement>(null);
  useEffect(function focusInput() {
    if (isRenaming) {
      inputEl.current?.focus();
    }
  }, [isRenaming]);

  const onActivate = useCallback(function () {
    if (inLibraryComponent) {
      setCurPlaylist!(playlist.id);
      smallDevSvc.setState(SmallDeviceShow.Main);
    }
  }, [inLibraryComponent, setCurPlaylist, smallDevSvc, playlist]);

  const contextTrigger = useRef<React.Component<ContextMenuTriggerProps, any, any>>(null);
  const toggleMenu = useCallback((e: React.MouseEvent) => {
    if (contextTrigger.current) {
      (contextTrigger.current as any).handleContextClick(e);
    }
  }, []);

  return (
    <AccessibleElement
      ref={el}
      onActivate={onActivate}

      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnter={(ev: React.DragEvent) => ev.preventDefault()}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onDragEnd={onDragEnd}

      className={classNames(s.row, {
        [s.isDragging]: isDragging,
        [s.selected]: selected,
        [s.hoverDrop]: isHoverDrop,
        [s.spacedFlexContainer]: inLibraryComponent,
      })}
    >
      {(() => {
        const rowBody = (<>
          <ContextMenuTrigger
            ref={contextTrigger}
            id={playlist.id}
            holdToDisplay={-1}
            disableIfShiftIsPressed={true}
            attributes={{
              title: playlist.name,
              className: s.rowBody
            }}
          >
            {playlist.id === Constants.LibraryId ?
              playlist.name
              :
              isRenaming
                ?
                <form onSubmit={handleSubmit}>
                  <input
                    ref={inputEl}
                    id={`playlist-name-${playlist.id}`}
                    name='playlist-name'
                    type='text'
                    defaultValue={playlist.name}
                    className={s.playlistNameInput}
                    onBlur={handleBlur}
                  />
                </form>
                :
                <>
                  {playlist.name}
                </>
            }
          </ContextMenuTrigger>
          <button
            className={classNames(s.menuBtn, 'hidden-control')}
            onClick={toggleMenu}
          >
            ☰
          </button>
        </>);

        return inLibraryComponent
          ? rowBody
          : (
            <Link
              to={`/app/library/${playlist.id}`}
              className={classNames('no-styles-link', s.spacedFlexContainer)}
            >
              {rowBody}
            </Link>
          )
      })()}

      <Portal portalTarget={portalTarget}>
        <PlaylistMoveTarget
          hide={!(isHoverTop || isHoverBottom)}
          posY={isHoverTop ? pos?.top : pos?.bottom}
          width={pos?.width}
        />
        <ContextMenu id={playlist.id}>
          {playlist.id !== Constants.LibraryId &&
            <>
              <FadilaMenuItem onClick={preventDefaultStopPropogation(() => setIsRenaming(!isRenaming))}>
                {isRenaming && 'Cancel '}Rename
              </FadilaMenuItem>
              {playlist.syncSources.length > 0 &&
                <FadilaMenuItem onClick={preventDefaultStopPropogation(triggerPlaylistSync)}>
                  Synchronize
                </FadilaMenuItem>
              }
              <FadilaMenuItem
                onClick={preventDefaultStopPropogation(() => libCtx.removePlaylist(playlist.id))}
                attributes={{
                  className: s.deleteMenuItem,
                }}
              >
                Delete
              </FadilaMenuItem>
            </>
          }
        </ContextMenu>
      </Portal>
    </AccessibleElement>
  );
});
