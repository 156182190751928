import classNames from 'classnames';
import React, { useContext, useRef, useState } from 'react';
import formatDuration from '../../../gUtilities/formatDuration';
import { DragType } from '../../../models/dragTypes';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import { SpotifyApiTrack } from '../../../models/spotify/spotifyApiTrack';
import Track from '../../../models/track';
import SpotifyTrack from '../../../models/track/spotifyTrack';
import { ActivePlaylistContext } from '../../../services/activePlaylist/activePlaylist';
import PlaylistColumns, { ColumnKey, ColumnScope, TrackProperty } from '../../../services/columnSettings/playlistColumns';
import { Context } from '../../../services/_globalContext/context';
import { preventDefaultStopPropogation } from '../../../utilities/preventDefault';
import AddToPlaylistSubMenu from '../../common/addToPlaylist/addToPlaylistSubMenu';
import FadilaMenuItem from '../../common/contextMenu/fadilaMenuItem';
import useColumnSettings from '../../common/hooks/useColumnSettings';
import useSelectedItemsRef from '../../common/hooks/useSelectedItemsRef';
import s from '../../common/itemRow/itemRow.module.scss';
import ItemRowWrapper from '../../common/itemRow/ItemRowWrapper';

export interface TrackSearchResultRowProps {
  /** Item array index */
  index: number;
  item: SpotifyApiTrack;
  handlePlayTrack(
    item: SpotifyApiTrack,
  ): void;
  addItemsThenToLibrary(addItems: (i: PlaylistItem[]) => void):
    (...items: SpotifyApiTrack[]) => void;

  isSelected: boolean;
  selectedItemsRef: React.MutableRefObject<Iterable<SpotifyApiTrack>>;

  handleToggleItem(plItm: SpotifyApiTrack): void;
  changeItemSelectionState(
    plItm: SpotifyApiTrack,
    selectState: boolean,
    removePrevious?: boolean): void;
  handleShiftSelect(plItm: SpotifyApiTrack): void;
  clearSelected(): void;

  portalTarget?: HTMLElement;
}

export default function TrackSearchResult({
  index,
  item,
  handlePlayTrack,
  addItemsThenToLibrary,

  portalTarget,
  isSelected,
  selectedItemsRef,
  changeItemSelectionState,
  handleShiftSelect,
  handleToggleItem,
  clearSelected,

}: TrackSearchResultRowProps) {

  const titleElRef = useRef<HTMLTableDataCellElement>(null);
  const selectedItemsRefWithMapping = useSelectedItemsRef<SpotifyApiTrack, SpotifyTrack>(
    SpotifyTrack.from,
    selectedItemsRef,
  );
  const ctx = useContext(Context);
  const apCtx = useContext(ActivePlaylistContext);

  const [isDragging, setIsDragging] = useState(false);

  const { columns, columnSettings } = useColumnSettings(ColumnScope.SpotifyTrackSearch);

  const onDragStart = (
    ev: React.DragEvent,
  ) => {
    console.debug('dragstart on track item');

    setIsDragging(true);

    // We set loads of data here since you can't call getData in onDragOver
    ev.dataTransfer.setData('dragType', DragType.TrackItems);
    // types.includes checks for matching drag type in onDragOver
    ev.dataTransfer.setData(DragType.TrackItems, DragType.TrackItems);
    ev.dataTransfer.setData('text/plain', item.id);
    ev.dataTransfer.setData('name', item.name);

    ev.dataTransfer.effectAllowed = 'copy';

    if (titleElRef.current) {
      ev.dataTransfer.setDragImage(titleElRef.current, 0, 0);
    }
    if (!isSelected) {
      clearSelected();
      changeItemSelectionState(item, true, true);
    }

    ctx.dndData[item.id] = selectedItemsRefWithMapping;
  };
  function onDragEnd(_ev: React.DragEvent) {
    setIsDragging(false);

    delete ctx.dndData[item.id];
  }

  return (
    <ItemRowWrapper<SpotifyApiTrack>
      index={index}
      item={item}
      itemId={item.id}

      onDragStart={onDragStart}
      onDragEnd={onDragEnd}

      isSelected={isSelected}
      isDragging={isDragging}

      handleShiftSelect={() => handleShiftSelect(item)}
      handleToggleItem={() => handleToggleItem(item)}
      selectOnlyItem={() => changeItemSelectionState(item, true, true)}

      handlePlay={() => handlePlayTrack(item)}

      menuItems={<>
        <FadilaMenuItem
          onClick={preventDefaultStopPropogation(() => handlePlayTrack(item))}
          attributes={{
            className: 'secondary',
          }}
        >
          Play
        </FadilaMenuItem>
        <AddToPlaylistSubMenu
          newPlaylistName={item.name}
          playlistItemOrItems={
            addItems => addItemsThenToLibrary(addItems)(...selectedItemsRef.current)}
        />
      </>}
      portalTarget={portalTarget}
    >
      {columnSettings &&
        <>
          <div className={classNames(s.rowCell, s.playBtn)}>
            {apCtx.currentTrack.id === item.id
              ?
              '♫'
              :
              <button
                className="play-button invisible-control"
                onClick={preventDefaultStopPropogation(() => handlePlayTrack(item))}
              >
                ►
              </button>
            }
          </div>
          {columns.map(col => {
            const colKey = col as ColumnKey;

            let value;

            switch (colKey) {

              case TrackProperty.length:
                value = formatDuration(item.duration_ms);
                break;
                
              case TrackProperty.title:
                value = item.name;
                break;

              case TrackProperty.artist:
                value = item.artists.map(a => a.name).join(', ');
                break;

              case TrackProperty.album:
                value = item.album?.name;
                break;

              case TrackProperty.releaseDate:
                value = item.album && Track.format(item.album.release_date, TrackProperty.album);
                break;

              default: throw new Error('Unexpected column mismatch!');
            }

            return (
              <div
                key={colKey}
                className={classNames(s.rowCell, {
                  [s.rightAlignCell]: PlaylistColumns[colKey].alignRight
                })}
                ref={colKey === TrackProperty.title ? titleElRef : undefined}
                style={{
                  width: columnSettings[colKey]?.width
                }}
                title={value}
              >
                {value}
              </div>
            );
          })}
        </>
      }
    </ItemRowWrapper>
  );
};
