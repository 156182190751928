import Source from "../source";
import Track from "../track";

export default class PlaylistItem {
  /** 
   * We use an explicit track id instead of a Track object reference
   * to save on object serialization
   */
  id: string;
  trackType: Source;

  constructor(track: Track);
  constructor(id: string, trackType: Source);
  constructor(obj: any, trackType?: Source) {

    if (trackType !== undefined) {

      this.id = obj;

      this.trackType = trackType;
    } else {
      const track = obj as Track;

      this.id = track.id;

      this.trackType = track.type;
    }
  }

  static from(playlistItem: PlaylistItem): PlaylistItem;
  static from(track: Track): PlaylistItem;
  static from(track: any) {
    const plItm = track as PlaylistItem;
    if (plItm.trackType !== undefined) {
      return new PlaylistItem(plItm.id, plItm.trackType);
    }
    return new PlaylistItem(track);
  }
}
