import { none } from 'fp-ts/es6/Option';
import React, { useCallback, useContext, useState } from 'react';
import { useRouteMatch } from 'react-router';
import noop from '../../gUtilities/noop';
import Playlist, { removeItems } from '../../models/playlist/playlist';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import { ActivePlaylistContext } from '../../services/activePlaylist/activePlaylist';
import { LibraryContext } from '../../services/library/libraryService';
import { Context } from '../../services/_globalContext/context';
import Constants from '../../settings';
import ErrorBoundary from '../common/errorBoundary/errorBoundary';
import PlaylistComponent from '../playlist/playlistComponent';
import StartupMessage from '../settingsAndStartup/startupMessage/startupMessage';
import LibraryPlaylists from './libraryPlaylists';

interface PlaylistPath {
  playlist: string;
}

export default function Library() {

  const match = useRouteMatch<PlaylistPath>();

  const ctx = useContext(Context);
  const libCtx = useContext(LibraryContext);
  const activePlaylist = useContext(ActivePlaylistContext);

  const [curPlaylist, setCurPlaylist] = useState<string>(match.params.playlist || Constants.LibraryId);

  function setCurrentPlaylist(val: string) {

    // This way we skip re-rendering the whole tree
    // Re-rendering the context components is not expensive but for some reason this cuts a
    // previously 250ms render to 100ms
    window.history.pushState(undefined, 'Fadila', `/app/library/${val}`);
    // history.push(`/app/library/${val}`);
    setCurPlaylist(val);
  }

  /**
   * The following lines aim to reduce re-rendering for playlist components
   * Previously these methods were being recreated every render causing needless 
   * PlaylistComponent updates
   */
  const libPl = libCtx.playlists.find(x => x.id === curPlaylist);

  const libraryPlaylistRemoveItems = useCallback(libPl
    ? removeItems(libPl, libCtx.updatePlaylist)
    : () => none,
    [libPl, libCtx.updatePlaylist]);

  const libraryPlaylistClear = useCallback(libPl !== undefined
    ? () => libCtx.clearPlaylist(libPl)
    : noop,
    [libPl]
  );

  return (
    <ErrorBoundary>
      <StartupMessage />
      <article className="grid-container full fluid">
        <div className="grid-x">
          <div className="cell shrink">
            <div className={`mainViewport ${ctx.playerSize}`}>
              <LibraryPlaylists
                curPlaylist={curPlaylist}
                setCurPlaylist={setCurrentPlaylist}
              />
            </div>
          </div>
          <div className="cell auto">
            {curPlaylist && (() => {

              let playlist: Playlist | undefined;

              if (curPlaylist === Constants.LibraryId) {
                var moveable = false;
                // We check for undefined before allowing user to select library
                playlist = libCtx.libraryPlaylist!;
                var remItem = (...itemsToRemove: PlaylistItem[]) => libCtx.removeFromLibrary(itemsToRemove);
                var clrPlaylist = libCtx.resetLibrary;
                var updatePlaylist = (_pl: Playlist) => { }; // unused
              }
              else if (curPlaylist === Constants.ActivePlaylistId) {
                /** 
                 * We don't allow active playlist ordering since it's likely to be confusing
                 * Ordering an ap is only a visual action. That is it does not change the play order of tracks
                 */
                var notOrderable = true
                var moveable = true;
                playlist = activePlaylist.value;
                var remItem = activePlaylist.removeItems;
                var clrPlaylist = activePlaylist.clearPlaylist;
                var updatePlaylist = (pl: Playlist) => activePlaylist.updateActivePlaylist(pl.tracks);
              }
              else {
                var moveable = true;
                playlist = libCtx.playlists.find(x => x.id === curPlaylist);
                var remItem = libraryPlaylistRemoveItems;
                var clrPlaylist = libraryPlaylistClear;
                var updatePlaylist = libCtx.updatePlaylist;
              }

              if (playlist) {
                return (
                  <PlaylistComponent
                    moveable={moveable}
                    notOrderable={notOrderable!}
                    playlist={playlist}
                    updatePlaylist={updatePlaylist}
                    removeItem={remItem}
                    clearPlaylist={clrPlaylist}
                  />
                );
              }
            })()}
          </div>
        </div>
      </article>
    </ErrorBoundary>
  );
}
