import { useState } from "react";

export default function useSelectedItemsRef<T, TrackT>(
  trackConstructor: (item: T) => TrackT,
  selectedItems: React.MutableRefObject<Iterable<T>>,
) {
  // Used in drop handlers through ctx.DnDdata
  const [selItmsRef] = useState<React.MutableRefObject<Set<TrackT>> & { selItms: Iterable<T> }>({
    /**
     * This is needed to ensure the getter doesn't keep old selectedItems
     * This was okey previously since we were mutating the selectedItems.current set itself
     * This would happen inside changeSelectState f.x. before setSelectedItems was called.
     * 
     * Even though setSelectedItems would be called, which in turn would re-render dbxcomponent
     * which in turn used to re-assign the selItmsRef object.
     * ctx.DnDdata would still hold the previous selItmsRef which was referencing the old
     * selectedItems state which itself referenced the still current and mutated Set object.
     * 
     * This could break at any time of course so we now do it like this!
     */
    selItms: selectedItems.current,
    get current(): Set<TrackT> {
      return new Set<TrackT>(
        [...selItmsRef.selItms].map(trackConstructor)
      );
    }
  });
  selItmsRef.selItms = selectedItems.current;

  return selItmsRef;
}
