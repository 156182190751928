import classNames from 'classnames';
import { pipe } from 'fp-ts/es6/function';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';
import { DragType } from '../../models/dragTypes';
import Playlist from '../../models/playlist/playlist';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import QueryIterator from '../../models/queryIterator';
import Source from '../../models/source';
import Track from '../../models/track';
import YouTubeTrack from '../../models/track/youTubeTrack';
import YouTubeKind from '../../models/youTube/kind';
import { ActivePlaylistContext } from '../../services/activePlaylist/activePlaylist';
import { ColumnKey, ColumnScope, TrackProperty } from '../../services/columnSettings/playlistColumns';
import { GoogleApiContext, GoogleScope } from '../../services/gapi/gapiService';
import handleGapiError from '../../services/gapi/handleGapiError';
import { createAddItemsToPlaylistThenLibrary } from '../../services/library/addTracksToPlaylistThenLibrary';
import { LibraryContext } from '../../services/library/libraryService';
import { SmallDeviceContext, SmallDeviceShow } from '../../services/smallDevices/smallDeviceService';
import { Context } from '../../services/_globalContext/context';
import Constants from '../../settings';
import createAddPlaylistToPlaylist from '../../utilities/helperFactories/addPlaylistToPlaylist';
import totalItemCountStringDefault from '../../utilities/totalItemCountStringDefault';
import ErrorBoundary from '../common/errorBoundary/errorBoundary';
import Spinner from '../common/spinner/spinner';
import StreamingServicePlaylistRowRenderer, { StreamingServicePlaylistRowData } from '../common/streamingServiceCommon/streamSvcPlaylistPlaylistRowRenderer';
import StreamingServiceSearchResults from '../common/streamingServiceCommon/streamSvcSearchResults';
import useLoadMore from '../common/virtualized/useLoadMore';
import LibraryPlaylists from '../library/libraryPlaylists';
import libPlsStyles from '../library/libraryPlaylists.module.scss';
import TrackPlaylistComponent from '../playlist/trackPlaylistComponent';
import s from './youTubeComponent.module.scss';
import YouTubeSearchResultRow, { YouTubeSearchResultProps } from './youTubeSearchResultRow';

interface Props {
}

// eslint-disable-next-line no-empty-pattern
export default React.memo(function YouTubeComponent({
}: Props) {

  const ctx = useContext(Context);
  const smallDevSvc = useContext(SmallDeviceContext);
  const libCtx = useContext(LibraryContext);
  const activePlaylist = useContext(ActivePlaylistContext);
  const gapiCtx = useContext(GoogleApiContext);

  const errorHandler = handleGapiError(ctx.callSnackbar, gapiCtx.logout);
  const isYouTubeAuthorized = gapiCtx.authorizedScopes.indexOf(GoogleScope.YouTube) !== -1;

  const [resultsPageNumber, setResultsPageNumber] = useState(1);
  const [searchLoading, setSearchLoading] = useState(false);
  const [results, setResults]
    = useState<gapi.client.youtube.SearchResult[]>([]);
  const [searchIterator, setSearchIterator]
    = useState<QueryIterator<gapi.client.youtube.SearchResult>>();
  const [searchLoadingMore, searchLoadMore]
    = useLoadMore({

      items: results,
      setItems: setResults,
      queryIterator: searchIterator,
      setIterator: setSearchIterator,
      errorHandler,
    });

  const [userPlaylistLoading, setUserPlaylistLoading] = useState(false);
  const [userPlaylistIterator, setUserPlaylistIterator]
    = useState<QueryIterator<gapi.client.youtube.Playlist>>();
  const [userPlaylists, setUserPlaylists]
    = useState<gapi.client.youtube.Playlist[]>([]);
  const [curUserPl, setCurUserPl] = useState<gapi.client.youtube.Playlist>();

  const [ytPlaylistItemsLoading, setYTPlaylistItemsLoading] = useState(false);
  const [ytPlaylistItemsIterator, setYTPlaylistItemsIterator]
    = useState<QueryIterator<gapi.client.youtube.PlaylistItem>>();
  const [ytPlaylistItems, setYTPlaylistItems] = useState<Track[]>([]);
  const ytPlaylistPlaylistItems = useMemo(
    () => ytPlaylistItems.map(PlaylistItem.from),
    [ytPlaylistItems]
  );
  const [ytPlaylistItemsLoadingMore, ytPlaylistItemsLoadMore]
    = useLoadMore<YouTubeTrack, gapi.client.youtube.PlaylistItem>({

      items: ytPlaylistItems,
      setItems: setYTPlaylistItems,
      queryIterator: ytPlaylistItemsIterator,
      setIterator: setYTPlaylistItemsIterator,
      itemMapper: YouTubeTrack.from,
      errorHandler,
    });

  const playlist = useMemo(
    () => curUserPl
      ? new Playlist(
        curUserPl.snippet!.title!,
        ytPlaylistPlaylistItems,
        curUserPl.id,
      )
      : new Playlist(
        'Null',
        [],
        'Null',
      ),
    // TrackPlaylistComponents are loaded but neither displayed nor rendered 
    // while their tracks are being loaded
    // If we watch for curUserPl we create a new playlist with tracks from previous pl
    // We can however safely assume that each time the user changes playlist
    // a new tracks array will be created.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ytPlaylistPlaylistItems]
  );
  const [playlistsElement, setPlaylistsElement] = useState<HTMLElement | null>(null);

  useEffect(function getUserPlaylistsOnLoad() {
    if (isYouTubeAuthorized) {
      getUserPlaylists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isYouTubeAuthorized]);

  const searchYouTube = useCallback(async function (ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    const form = ev.currentTarget;
    const inputEl = form.elements.namedItem('query') as HTMLInputElement;

    setCurUserPl(undefined);
    setYTPlaylistItems([]);
    setYTPlaylistItemsIterator(undefined);
    setSearchLoading(true);

    try {
      const searchIter = await gapiCtx.searchPaged(inputEl.value, Constants.ResultCount);

      if (searchIter.totalResults) {
        setResultsPageNumber(1);
        setResults(searchIter.results!);
        setSearchIterator(searchIter);
      }
    } catch (err) {
      errorHandler(err, 'Search failed! Please try again.');
    } finally {
      setSearchLoading(false);
    }
  }, [gapiCtx, errorHandler]);

  const getUserPlaylists = useCallback(async function () {
    if (isYouTubeAuthorized) {
      setUserPlaylistLoading(true);

      try {
        const playlists: gapi.client.youtube.Playlist[] = [];
        let userPlaylistIterator = await gapiCtx.getUserPlaylistsPaged(
          // Math.min(ctx.resultsPerPage, 50)
        );

        playlists.push(...userPlaylistIterator.results);

        if (userPlaylistIterator.totalResults) {

          while (userPlaylistIterator.next) {
            userPlaylistIterator = await userPlaylistIterator.next();

            playlists.push(...userPlaylistIterator.results);
          }

          setUserPlaylists(playlists || []);
          setUserPlaylistIterator(userPlaylistIterator);
        }
      } catch (err) {
        errorHandler(err, 'Failed to retrieve user data');
      } finally {
        setUserPlaylistLoading(false);
      }
    } else {
      ctx.callSnackbar('Please login with google first');
    }
  }, [gapiCtx, ctx, errorHandler, isYouTubeAuthorized]);

  const getPlaylistItems = useCallback(async function (playlist: gapi.client.youtube.Playlist) {
    setCurUserPl(playlist);
    setYTPlaylistItemsLoading(true);
    setSearchIterator(undefined);
    setResults([]);

    try {
      const res = await gapiCtx.getPlaylistItemsPaged(playlist.id!, Constants.ResultCount);

      if (res.results) {

        // addToLibraryThenPlaylist(res.results);
        const ytTracks = res.results.map(YouTubeTrack.from);
        setYTPlaylistItems(ytTracks);

        setYTPlaylistItemsIterator(res);
      }
    } catch (err) {
      errorHandler(err, 'Error loading playlist!');
    } finally {
      setYTPlaylistItemsLoading(false);
    }
  }, [gapiCtx, errorHandler]);

  /**
   * Automatically adds videos from user playlists straight to library before adding to playlist
   * @param items 
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function addToLibraryThenPlaylist(items: gapi.client.youtube.PlaylistItem[], replace = true) {
    pipe(
      (x: gapi.client.youtube.PlaylistItem[]) => x.map(YouTubeTrack.from),
      x => libCtx.addToLibrary(...x),
      x => x.map(x => replace
        ? setYTPlaylistItems(x)
        : setYTPlaylistItems(ytPlaylistItems.concat(x))),
      x => x.mapLeft(ctx.callSnackbar),
    )(items);
  }

  /** 
   * For playing single videos from search results
   * In those cases we do not add to library
   */
  const playSearchResult = useCallback(function (
    item: gapi.client.youtube.SearchResult,
  ) {
    const plItm = new PlaylistItem(item.id!.videoId!, Source.YouTube);

    const doPlay = true;
    activePlaylist.setActivePlaylist(
      [plItm],
      0,
      doPlay,
    );
  }, [activePlaylist]);

  const addItemsThenToLibrary
    = useMemo(() => createAddItemsToPlaylistThenLibrary<gapi.client.youtube.SearchResult | gapi.client.youtube.PlaylistItem>(
      ctx,
      libCtx,
      YouTubeTrack.from,
    ), [ctx, libCtx]);
  const addPlaylistToPlaylist = useMemo(() =>
    (addItems: (i: PlaylistItem[]) => void) =>
      createAddPlaylistToPlaylist(
        ctx,
        gapiCtx.getPlaylistItemsPaged,
        addItemsThenToLibrary,
        addItems,
      ), [
    ctx,
    gapiCtx,
    addItemsThenToLibrary
  ]);

  const addAnyToPlaylist = useCallback(function addAnyToPlaylist(
    addItems: (i: PlaylistItem[]) => void,
  ) {
    return async function doAddAnyToPlaylist(...searchResult: gapi.client.youtube.SearchResult[]) {
      const videos = searchResult.filter(x => x.id?.kind === YouTubeKind.YouTubeVideos);
      const playlists = searchResult.filter(x => x.id?.kind === YouTubeKind.YouTubePlaylist);
      const channels = searchResult.filter(x => x.id?.kind === YouTubeKind.YouTubeChannel);

      if (channels.length) {
        ctx.callSnackbar('Skipping selected channels. YouTube channel adding is currently not supported.')
      }

      addItemsThenToLibrary(addItems)(...videos);
      for (const pl of playlists) {
        await addPlaylistToPlaylist(addItems)(pl.id!.playlistId!);
      }
    }
  }, [ctx, addItemsThenToLibrary, addPlaylistToPlaylist]);

  /**
   * This triggers on component re-render, f.x. on new search
   */
  useEffect(function resetPageNumber() {
    if (resultsPageNumber !== 1) {
      setResultsPageNumber(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const titleAccessor = useCallback((pl: gapi.client.youtube.Playlist) => pl.snippet?.title!, []);

  const playlistRowData: StreamingServicePlaylistRowData<gapi.client.youtube.Playlist> = useMemo(() => ({
    curUserPl,
    playlists: userPlaylists,
    dragType: DragType.YouTubePlaylist,
    getPlaylistItems,
    addPlaylistToPlaylist,
    titleAccessor,
    portalTarget: playlistsElement || undefined,
  }), [
    getPlaylistItems,
    titleAccessor,
    addPlaylistToPlaylist,
    curUserPl,
    userPlaylists,
    playlistsElement
  ]);

  const columnCustomName = useCallback(
    (colKey: ColumnKey) => colKey === TrackProperty.album
      ? 'Channel Title'
      : colKey === 'image'
        ? 'Preview'
        : undefined
    , []);
  const searchResultsProps = useMemo(() => ({
    handlePlay: playSearchResult,
    handleNavigation: () => ctx.callSnackbar('Currently unsupported'),
    addAnyToPlaylist,
  }), [
    ctx,
    playSearchResult,
    addAnyToPlaylist
  ]);

  return (
    <ErrorBoundary>
      {// Show as loading while the libraries and gapi client are initialized
        !gapiCtx.gapiClientLoaded ?
          <Spinner />
          : !isYouTubeAuthorized ?
            <>
              <p className={s.signInReminder}>
                Sign in to access your playlists
              </p>
              <button
                className="googleSignin"
                onClick={() => gapiCtx.authorizeAdditionalScope(GoogleScope.YouTube)}
              >
              </button>
            </>
            :
            <article className="grid-container full fluid">
              <div className="grid-x">
                <div className="cell shrink">
                  <article className={`mainViewport ${ctx.playerSize}`}>
                    <LibraryPlaylists />
                  </article>
                </div>
                <div className="cell auto">
                  <div className={`mainViewport ${ctx.playerSize}`}>
                    <section className={`grid-x ${s.searchBar}`}>
                      <form className={s.searchForm} onSubmit={searchYouTube}>
                        <div className="grid-x grid-margin-x">
                          <input
                            className="cell auto"
                            type="text"
                            name="query"
                            id="query"
                            placeholder="Search YouTube"
                          />
                          <button className="cell shrink button">
                            Search
                          </button>
                        </div>
                      </form>
                      <button
                        className="button"
                        onClick={gapiCtx.disconnect}
                      >
                        Re-select YouTube channel
                      </button>
                    </section>
                    {searchIterator === undefined &&
                      <div className={`items-list ${s.mainViewContainer}`}>
                        {(ytPlaylistItemsLoading || ytPlaylistItemsIterator !== undefined) &&
                          <TrackPlaylistComponent
                            moveable={false}
                            dragType={DragType.TrackItems}

                            playlist={playlist!}
                            trackItems={ytPlaylistItems}
                            loading={ytPlaylistItemsLoading}

                            display={ytPlaylistItemsIterator !== undefined}
                            isComplete={() => !ytPlaylistItemsIterator?.next}
                            totalItemCount={() => ytPlaylistItemsIterator?.totalResults!}
                            totalItemCountString={
                              totalItemCountStringDefault(ytPlaylistItemsIterator?.totalResults || 0)
                              + (ytPlaylistItemsIterator?.totalResults !== ytPlaylistItems.length
                                ? ' (including deleted)'
                                : '')
                            }
                            filteredItemCountString="loaded"

                            columnScope={ColumnScope.YouTube}

                            loadMore={ytPlaylistItemsLoadMore}
                            isNextPageLoading={ytPlaylistItemsLoadingMore}
                            hasMore={ytPlaylistItemsIterator?.next !== undefined}
                          />
                        }
                      </div>
                    }
                    {ytPlaylistItemsIterator === undefined &&
                      <div className={`items-list ${s.mainViewContainer}`}>
                        {(searchLoading || searchIterator !== undefined) &&
                          <StreamingServiceSearchResults<gapi.client.youtube.SearchResult, YouTubeSearchResultProps>
                            columnScope={ColumnScope.YouTubeSearch}
                            loading={searchLoading}
                            results={results}
                            itemComponent={YouTubeSearchResultRow}
                            display={searchIterator !== undefined}

                            props={searchResultsProps}

                            isComplete={() => !searchIterator?.next}
                            totalItemCount={() => searchIterator?.totalResults!}
                            totalItemCountString={
                              searchIterator?.totalResults &&
                                searchIterator?.totalResults > 10000
                                ? false
                                : "results"
                            }
                            columnCustomName={columnCustomName}

                            isNextPageLoading={searchLoadingMore}
                            loadMore={searchLoadMore}
                            hasMore={searchIterator?.next !== undefined}
                          />
                        }
                      </div>
                    }
                  </div>
                </div>
                <div className="cell shrink">
                  {userPlaylistLoading ?
                    <Spinner />
                    :
                    userPlaylistIterator !== undefined && isYouTubeAuthorized
                    &&
                    <div className={`mainViewport ${ctx.playerSize}`}>
                      <article
                        ref={el => setPlaylistsElement(el)}
                        className={classNames(
                          libPlsStyles.playlistList,
                          s.youTubePlaylists, {
                          [libPlsStyles.open]: smallDevSvc.state === SmallDeviceShow.StreamingPlaylists,
                        })}
                      >
                        {playlistsElement !== null &&
                          <AutoSizer>
                            {({ height, width }) => (
                              <List
                                height={height}
                                width={width}
                                itemCount={userPlaylists.length}
                                itemSize={29}
                                itemData={playlistRowData}
                              >
                                {StreamingServicePlaylistRowRenderer}
                              </List>
                            )}
                          </AutoSizer>
                        }
                      </article>
                    </div>
                  }
                </div>
              </div>
            </article>
      }
    </ErrorBoundary>
  );
});
