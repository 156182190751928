import React, { useEffect, useState } from 'react';
import { getLsItem, setLsItem } from '../../../services/storage/localStorageService';
import Tips from '../../common/tips/tips';
import s from './startupMessage.module.scss';

interface Props {

}

export default function StartupMessage({

}: Props) {

  const [showMessage, setShowMessage] = useState(false);

  useEffect(function testPreviouslyShown() {

    if (getLsItem('hasShownStartupMessage').run().isNone()) {
      setShowMessage(true)
      setLsItem('hasShownStartupMessage')(true);
    }
  }, []);

  return (
    <>
      {showMessage &&
        <div className={s.container}>
          <article className={s.article}>
            <Tips />
            <div className={s.btnContainer}>
              <button
                className={`button ${s.button}`}

                onClick={() => setShowMessage(false)}
              >
                Close
              </button>
            </div>
          </article>
        </div>
      }
    </>
  );
}
