import { DriveItem } from '@microsoft/microsoft-graph-types';
import Track from '.';
import Source from '../source';

export default class OneDriveTrack extends Track {
  static from(driveItem: DriveItem) {
    return new OneDriveTrack(driveItem);
  }

  constructor(
    driveItem: DriveItem,
  ) {
    super(
      driveItem.id!, // we throw on null later in the constructor
      Source.OneDrive,
      driveItem.description || 
        (driveItem.name && 
        // Remove filename extensions
        driveItem.name.replace(/\.(?:.(?!\.))+$/, '')) || undefined,
      driveItem.audio?.artist || undefined,
      driveItem.audio?.album || undefined,
      driveItem.audio?.duration || undefined,
      driveItem.audio?.genre || undefined,
      driveItem.audio?.year ? new Date(driveItem.audio!.year, 0).valueOf() : undefined,
    );

    if (!driveItem.id) throw new Error('Missing DriveItem id for OneDriveTrack');
  }
}
