import { Client, GraphError, LargeFileUploadTask } from "@microsoft/microsoft-graph-client";
import { curry } from 'fp-ts/es6/function';
import Constants, { Keys, messages } from '../../settings';
import compress, { decompress } from "../../utilities/flateCompressDecompress";
import { Flate } from "../_globalContext/context";
import { msgraphGet } from './oneDriveApi';

export const prepareAppData = (function (
) {
  let currentInit: Promise<void>;

  return async function (
    graphClient: Client,
  ) {
    if (currentInit === undefined) {
      currentInit = graphClient.api('/me/drive/special/approot/children').post({
        name: "AppDataInit",
        folder: {},
        // eslint-disable-next-line no-useless-computed-key
        ['@microsoft.graph.conflictBehavior']: "replace",
      });
    }

    await currentInit;
  }
})();

/**
 * Saves/replaces a Fadila data file to users drive
 */
export const putFile = curry(async function doPutFile<T>(
  graphClient: Client,
  errorHandler: ((message: any) => void) | undefined,
  flate: Flate | undefined,
  fileName: string,
  fileData: T,
) {
  if (!flate) {
    throw new Error('Ensure wasm-flate is loaded before completing startup');
  }

  try {
    const requestUrl = `/me/drive/special/approot:/${fileName}:/createUploadSession`;
    const payload = {
      item: {
        "@microsoft.graph.conflictBehavior": "replace",
        name: fileName,
      },
    };

    const body = compress(fileData, flate);

    const uploadSession
      = await LargeFileUploadTask.createUploadSession(
        graphClient,
        requestUrl,
        payload,
      );

    return await fetch(uploadSession.url, {
      method: 'PUT',
      body,
      headers: new Headers({
        "Content-Length": `${body.byteLength}`,
        "Content-Range": `bytes ${0}-${body.byteLength - 1}/${body.byteLength}`
      }),
    });

    // const fileObject = {
    //   size: file.byteLength,
    //   content: file,
    //   name: fileName,
    // };

    // const uploadTask 
    //   = await new LargeFileUploadTask(
    //     graphClient, 
    //     fileObject, 
    //     uploadSession,
    //   );

    // return await uploadTask.upload();
  }
  catch (err) {
    if (errorHandler) {
      errorHandler(err);
    } else {
      throw err;
    }
  }
});

export const getDataFolder = curry(function (
  graphClient: Client,
  errorHandler: ((message: any) => void) | undefined,
) {
  return msgraphGet(graphClient, errorHandler)
    (`/me/drive/special/approot`);
});

export const getDataFile = curry(async function (
  graphClient: Client,
  errorHandler: ((message: any) => void) | undefined,
  flate: Flate | undefined,
  fileName: string,
) {
  if (!flate) {
    throw new Error('Ensure wasm-flate is loaded before completing startup');
  }

  const driveItem = await msgraphGet(graphClient, errorHandler)
    (`/me/drive/special/approot:/${fileName}`);
  const res = await fetch(driveItem[Constants.MsGraphDlUrlProp]);

  if (res.ok) {
    const bytes = await res.arrayBuffer();
  
    return decompress(bytes, flate);
  }
  else {
    throw res;
  }
});

export const getOrCreateDataFile = curry(async function <T>(
  graphClient: Client,
  errorHandler: ((message: any) => void) | undefined,
  flate: Flate | undefined,
  fileName: string,
  defaultData: T,
) {
  if (!flate) {
    throw new Error('Ensure wasm-flate is loaded before completing startup');
  }

  try {
    return await getDataFile(graphClient)(errorHandler)(flate)(fileName) as T;
  } catch (err) {

    try {
      if (err instanceof GraphError && err.statusCode === 404) {
        // Assume first launch, init approot folder
        await prepareAppData(graphClient);

        return putFile(graphClient)(errorHandler)(flate)(fileName)(defaultData)
          .then(() => defaultData, errorHandler);
      } else {
        if (errorHandler) {
          errorHandler(err);
          return Promise.reject();
        } else {
          return Promise.reject(messages(Keys.libraryLoadError));
        }
      }
    }
    catch (err) {
      if (errorHandler) {
        errorHandler(err);
      }

      return Promise.reject();
    }
  }
});

/** Unused */
export const createDataFolder = curry(function (
  graphClient: Client,
  _errorHandler: ((message: any) => void) | undefined,
) {
  const driveItem = {
    name: Constants.DataFolder,
    folder: {},
    '@microsoft.graph.conflictBehavior': "fail"
  };

  return graphClient.api('/me/drive/root/children').post({ driveItem, });
});

/**
 * Unused since put creates folder if it does not exist
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ensureDataFolderExists = curry(async function (
  graphClient: Client,
  errorHandler: ((message: any) => void) | undefined,
) {

  try { // Assume folder already exists
    return await getDataFolder(graphClient)(errorHandler);
  }
  catch (err) {
    await createDataFolder(graphClient)(errorHandler);

    try { // Should definately exist now
      return await getDataFolder(graphClient)(errorHandler);
    }
    catch (err) {
      if (errorHandler) {
        errorHandler(err);
      } else {
        throw err;
      }
    }
  }
});
