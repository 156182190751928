import React from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

interface Props<T> {
  itemData: T;
  itemRenderer: React.ComponentClass<ListChildComponentProps, any>;

  itemSize: number;
  itemCount: number;

  isItemLoaded: (index: number) => boolean;
  loadMoreItems: (startIndex: number, stopIndex: number) => Promise<any> | null;
}

export default function InfiniteList<T>({
  itemData,
  itemSize,
  itemCount,
  isItemLoaded,
  loadMoreItems,
  itemRenderer,
}: Props<T>) {

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              itemSize={itemSize}
              onItemsRendered={onItemsRendered}
              ref={ref}
              itemCount={itemCount}
              itemData={itemData}
            >
              {itemRenderer}
            </List>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
}
