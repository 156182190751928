import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PlayerSize } from '../../components/player/player';
import StartupErrorComponent from '../../components/settingsAndStartup/startupErrorComponent';
import getScrollbarWidth from '../../gUtilities/getScrollbarWidth';
import noop from '../../gUtilities/noop';
import storageAvailable from '../../gUtilities/storageAvailable';
import SyncType from '../../models/playlist/syncType';
import RepeatState from '../../models/repeatState';
import { StorageProviderKey, StorageProviderMap } from '../../models/storageProviderKeys';
import SyncProvider from '../../models/synchronization/syncProvider';
import SyncProviders from '../../models/synchronization/syncProviders';
import { reqQueueCreator } from '../queues/reqQueue';
import * as serviceWorker from '../serviceWorker/serviceWorker';
import IStorageProvider from '../storage/istorageProvider';
import { Context, DeviceCapabilities, Flate, SettingsState } from './context';
import createCallSnackbar from './snackbar';

interface Props {
  children: React.ReactNode;
}
export default function ContextService({
  children,
}: Props) {

  console.debug('Rendering ctx');

  const [dndData] = useState({});

  const [deviceCapabilities, setDeviceCapabilities]
    = useState<DeviceCapabilities>(new DeviceCapabilities());

  // type 'cast' is okey as map is typed to return provider | undefined
  const [storageProviderMap] = useState<StorageProviderMap>({} as StorageProviderMap);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const settingsLoading = useRef(false);
  const [settings, setSettings] = useState(() => new SettingsState());

  const [playerSize, setPlayerSize] = useState(PlayerSize.Hide);

  const [snackbarEl, setSnackbarEl] = useState<HTMLDivElement | null>(null);
  const callSnackbar = useMemo(() => createCallSnackbar(snackbarEl), [snackbarEl]);

  const [flate, setFlate] = useState<Flate>();
  /**
   * The reason for the ref like state is because the sp's themselves are continuously updated
   * This happens in their respective service by assigning to a stable sp ref obj
   * 
   * If these updates would setState it would at worst cause an infinite loop and at best alot of re-renders
   * This way we allow frequent updates but ensure that we always have the latest version in context.
   * 
   * Other implementations are likely to end up with stale ctx sp here and/or on the context value
   */
  const [storageProvider, setStorageProvider] = useState({
    current: undefined as IStorageProvider | undefined,
  });
  const [syncProviders, setSyncProviders] = useState<SyncProviders>({});
  // We use a set of currently processing items, this ensures that if there are multiple concurrent tasks
  // One task completing will not remove the processing state for all the others
  const [runningTasks, setRunningTask] = useState<React.MutableRefObject<Set<string>>>({
    current: new Set<string>()
  });
  const setRunningTaskF = useCallback((task: string) => setRunningTask(prev => ({
    current: prev.current.add(task)
  })), []);
  const removeRunningTaskF = useCallback((task: string) =>
    setRunningTask(prev => {
      prev.current.delete(task);
      return {
        current: prev.current
      };
    }), []);
  // Signifies that the queue is currently actively processing a request
  const setActive = useRef(setRunningTaskF);
  setActive.current = setRunningTaskF;
  const disableActive = useRef(removeRunningTaskF);
  disableActive.current = removeRunningTaskF;

  const spLogoutHandling = useRef<() => Promise<void>>();

  /** The base request queue that all others build on */
  const [unifiedReqQueue] = useState(() => reqQueueCreator(
    () => setActive.current('reqQueue'),
    () => disableActive.current('reqQueue'),
  ));
  useEffect(function beforeUnloadWarning() {

    function beforeUnloadCallback(ev: BeforeUnloadEvent) {

      if (runningTasks.current.size > 0 &&
        !(runningTasks.current.has('resetAppDataFolder') && runningTasks.current.size === 1)) {

        const prompt = 'There are currently running tasks, are you sure you would like to exit?';

        ev.preventDefault();
        ev.returnValue = prompt;
        return prompt;
      }
    }

    if (runningTasks.current.size) {
      window.addEventListener('beforeunload', beforeUnloadCallback);
    }

    return function () {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    };
  }, [runningTasks]);

  useEffect(function ensureCapableDevice() {
    async function doRegisterSW() {
      try {
        await serviceWorker.register();

        // Assume user hard reloaded
        // https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#shift-reload
        if (navigator.serviceWorker.controller === null) {
          console.warn('User requested hard reload, this disables service workers. Reloading to enable.');
          window.location.reload();
        }
      }
      catch (err) {
        setDeviceCapabilities({
          ...deviceCapabilities,
          hasServiceWorkers: false,
        });

        throw err;
      }
    }

    const deviceCapabilities = new DeviceCapabilities();
    deviceCapabilities.hasFetch = 'fetch' in window;
    deviceCapabilities.hasPromise = 'Promise' in window;
    deviceCapabilities.hasTextEncoder = 'TextEncoder' in window;
    deviceCapabilities.hasServiceWorkers = 'serviceWorker' in navigator;
    deviceCapabilities.hasIndexedDB = 'indexedDB' in window;
    deviceCapabilities.hasLocalStorage = storageAvailable('localStorage');

    deviceCapabilities.detectionComplete = true;

    if (deviceCapabilities.hasServiceWorkers && deviceCapabilities.hasIndexedDB) {
      doRegisterSW()
        .then(() => setDeviceCapabilities(deviceCapabilities))
        .catch(noop);
    }
    else {
      setDeviceCapabilities(deviceCapabilities);
    }
  }, []);

  useEffect(function loadWasm() {
    async function loadFlate() {
      try {
        const flate = await import('wasm-flate');

        setFlate(flate);

      } catch (err) {
        callSnackbar(`Unexpected error loading compression library. ${err.message}`);
      }
    }

    if (snackbarEl) {
      loadFlate();
    }
  }, [snackbarEl, callSnackbar]);

  useEffect(function doLoadState() {
    if (snackbarEl
      && flate
      && storageProvider?.current
      && !settingsLoaded
      && !settingsLoading.current) {

      settingsLoading.current = true;

      storageProvider.current.load('settings.js.gz', {})
        .then(
          s => {
            setSettings(new SettingsState(s));
            setSettingsLoaded(true);
          },
          callSnackbar,
        )
        .finally(() => settingsLoading.current = false);
    }
  }, [storageProvider, settingsLoaded, snackbarEl, callSnackbar, flate]);

  const setPersistState = useCallback((settings: SettingsState) => {
    if (storageProvider?.current && flate) {
      storageProvider.current.saveAppData(
        'settings.js.gz',
        settings);
    }
    setSettings(settings);
  }, [storageProvider, flate]);

  const configureStorageProvider = useCallback(function (key?: StorageProviderKey) {
    if (key) {
      const provider = storageProviderMap[key];

      if (provider && provider.current) {

        try {
          localStorage.setItem('storageProvider', provider.current.name);
        }
        catch (err) {
          callSnackbar(err);
        }

        if (storageProvider.current) {
          // Let's not try to be too clever, it's best to start fresh in the extremely uncommon case
          // of a user switching their sp
          window.location.reload();
        } else {
          setStorageProvider(provider);
        }
      } else {
        callSnackbar('Provider hasn\'t registered or unknown provider specified.');
      }
    }
    else {
      try {
        localStorage.removeItem('storageProvider');
      } catch { }
      setStorageProvider({ current: undefined });
    }
  }, [storageProvider, storageProviderMap, callSnackbar]);

  const autoConfigureStorageProvider = useCallback(function () {
    if (!storageProvider.current) {
      try {
        const sp = localStorage.getItem('storageProvider');
        if (sp) {
          configureStorageProvider(sp as StorageProviderKey);
        }
      }
      catch (err) {
        callSnackbar(err);
      }
    }
  }, [storageProvider, configureStorageProvider, callSnackbar]);

  const toggleRepeat = useCallback(function () {
    switch (settings.repeatState) {
      case RepeatState.All:
        setPersistState(
          Object.assign({}, settings, { repeatState: RepeatState.None })
        );
        break;
      case RepeatState.One:
        setPersistState(
          Object.assign({}, settings, { repeatState: RepeatState.All })
        );
        break;
      case RepeatState.None:
      default:
        setPersistState(
          Object.assign({}, settings, { repeatState: RepeatState.One })
        );
        break;
    }
  }, [setPersistState, settings]);

  const addSyncProvider = useCallback(function (syncType: SyncType, syncProvider: SyncProvider) {
    setSyncProviders({
      ...syncProviders,
      [syncType]: syncProvider,
    });
  }, [syncProviders]);

  const contextSpLogoutHandling = useCallback(
    async function () {
      setSettingsLoaded(false);

      if (spLogoutHandling.current) {
        await spLogoutHandling.current();
      }
    }, []);

  const setSpLogoutHandling = useCallback(
    (f: () => Promise<void>) => spLogoutHandling.current = f,
    [],
  );

  const scrollbarWidth = useRef<number>();
  useEffect(function calculateScrollbarWidth() {
    scrollbarWidth.current = getScrollbarWidth();
  }, []);

  const setLibraryPath = useCallback((path: string) => setPersistState(
    Object.assign({}, settings, { libraryPath: path })
  ), [settings, setPersistState]);

  const toggleShowPlaylistStatusBar = useCallback(() => setPersistState({
    ...settings,
    showPlaylistStatusBar: !settings.showPlaylistStatusBar
  }), [
    settings,
    setPersistState,
  ]);

  const toggleConfirmDelete = useCallback(() => setPersistState({
    ...settings,
    confirmDelete: !settings.confirmDelete,
  }), [settings, setPersistState]);

  const toggleGoogleDriveIdNavigation = useCallback(
    () => setPersistState({
      ...settings,
      enableGoogleDriveIdNavigation: !settings.enableGoogleDriveIdNavigation
    }),
    [settings, setPersistState]);
  const toggleLockPlayerSizeMedium = useCallback(function toggleLockPlayerSizeMedium() {
    setPersistState({
      ...settings,
      lockPlayerSizeMedium: !settings.lockPlayerSizeMedium
    });
    setPlayerSize(PlayerSize.Medium);
  }, [settings, setPersistState]);

  const ctxValue = useMemo(() => ({

    deviceCapabilities,

    settingsLoaded,

    playerSize,
    setPlayerSize,

    toggleRepeat,

    setLibraryPath,

    callSnackbar,

    syncProviders,
    addSyncProvider,

    flate,
    storageProvider,
    configureStorageProvider,
    storageProviderMap,
    autoConfigureStorageProvider,

    isProcessing: runningTasks.current.size > 0,
    setRunningTask: setRunningTaskF,
    removeRunningTask: removeRunningTaskF,

    unifiedReqQueue,

    dndData,

    spLogoutHandling: contextSpLogoutHandling,
    setLibrarySpLogoutHandling: setSpLogoutHandling,

    scrollbarWidth: scrollbarWidth.current,

    toggleShowPlaylistStatusBar,

    toggleConfirmDelete,
    toggleGoogleDriveIdNavigation,
    toggleLockPlayerSizeMedium,

    settings,
  }), [
    deviceCapabilities,
    settingsLoaded,
    playerSize,
    settings,
    toggleRepeat,
    callSnackbar,
    syncProviders,
    addSyncProvider,
    flate,
    storageProvider,
    configureStorageProvider,
    storageProviderMap,
    autoConfigureStorageProvider,
    runningTasks,
    setRunningTaskF,
    removeRunningTaskF,
    contextSpLogoutHandling,
    setSpLogoutHandling,
    dndData,
    unifiedReqQueue,
    setLibraryPath,
    toggleShowPlaylistStatusBar,
    toggleConfirmDelete,
    toggleGoogleDriveIdNavigation,
    toggleLockPlayerSizeMedium,
  ]);

  const isCapableDevice = useMemo(
    () => Object.keys(deviceCapabilities)
      // @ts-ignore - all properties are boolean
      .reduce((prev, cur) => prev && deviceCapabilities[cur], true)
    , [deviceCapabilities]);

  return (
    <>
      {snackbarEl &&
        <Context.Provider value={ctxValue}>
          {isCapableDevice
            ?
            children
            : deviceCapabilities.detectionComplete &&
            <StartupErrorComponent />
          }
        </Context.Provider>
      }
      <div ref={c => setSnackbarEl(c)} className="mdc-snackbar">
        <button
          type="button"
          className="mdc-button mdc-snackbar__action"
        >
          <div className="mdc-snackbar__surface">
            <div className="mdc-snackbar__label"
              role="status"
              aria-live="polite">
            </div>
            <div className="mdc-snackbar__actions">
            </div>
          </div>
        </button>
      </div>
    </>
  );
}
