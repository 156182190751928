enum RepeatState {
  None,
  All,
  /**
   * Repeat the current track
   */
  One,
}

export default RepeatState;
