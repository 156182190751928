import React, { ComponentType } from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

interface Props {
  children: ComponentType<ListChildComponentProps>;
  itemCount: number;
  itemData?: any;
}

/**
 * Inline function child, re-mount on every re-render.
 * https://stackoverflow.com/questions/45895831/react-virtualized-autosizer-masonry-doesnt-render-until-resized
 */
export default function ListWithAutosizer({
  children,
  itemCount,
  itemData,
}: Props) {

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          width={width}
          itemCount={itemCount}
          itemSize={26}
          itemData={itemData}
        >
          {children}
        </List>
      )}
    </AutoSizer>
  );
}
