import { fromOption } from 'fp-ts/es6/Either';
import { fromPredicate } from 'fp-ts/es6/Option';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ScreenDetectionContext, ScreenSize } from '../screenDetection/screenDetection';
import { getLsItem, setLsItem } from '../storage/localStorageService';
import calcWidthFromCharCount from './calcWidthFromCharCount';
import PlaylistColumns, { ColumnScope, ColumnSetting, ColumnSettings, ColumnSettingsStore, TrackProperty } from './playlistColumns';
import playlistColumnSubset from './playlistColumnsSubset';

export interface IColumnSettingsContext {
  columnSettingsStore: ColumnSettingsStore;
  setColumnWidth(
    columnName: TrackProperty,
    width: number,
    scope: ColumnScope,
  ): void;
  // /**
  //  * Allows the user to choose per-playlist which columnthe list is sorted by
  //  *
  //  * We already allow for permanent sorting by column so this isn't stricly needed.
  //  * Maybe a nice to have, if the current implementation works it sets a global default.
  //  * Needs to be per-playlist
  //  * 
  //  * @param columnName 
  //  * @param scope 
  //  */
  // setColumnDefault(
  //   columnName: TrackProperty | 'index',
  //   scope: ColumnScope,
  // ): void;
  /**
  * Grows or shrinks the target column in an attempt
  * to match the viewport width
  */
  columnAutoResize(
    columName: TrackProperty,
    viewportWidth: number,
    scope: ColumnScope,
    contentMinimumWidth: number,
  ): void;
  /**
  * Creates a scope using default columns with default width.
  * 
  * @param viewportWidth 
  */
  initializeScope(
    viewportWidth: number,
    scope: ColumnScope,
  ): Partial<ColumnSettings>;
}


export const ColumnSettingsContext = React.createContext({} as IColumnSettingsContext);

interface Props {
  children: React.ReactNode;
}

export default function ColumnSettingsService({
  children,
}: Props) {

  console.debug('Column settings rendering');

  const screenSize = useContext(ScreenDetectionContext);

  const [columnSettingsStore, setColumnSettingsStore] = useState<ColumnSettingsStore>(
    () => new ColumnSettingsStore()
  );

  useEffect(function loadLocalState() {
    const version = getLsItem('columnSettingsVersion').run();
    const isLatest = fromPredicate((x: string) => +x === 1);

    fromOption(new ColumnSettingsStore())(version.chain(isLatest))
      .map(() =>
        getLsItem('columnSettings').run()
          .map(JSON.parse)
          // We currently do not persist defaults when used and unchanged, 
          // not really any point is there
          .map(x => new ColumnSettingsStore(x))
          .map(setColumnSettingsStore)
      )
      .mapLeft(store => {
        setLsItem('columnSettings')(store);
        setColumnSettingsStore(store);
      })
      .mapLeft(() => setLsItem('columnSettingsVersion')(1));
  }, []);

  const setColumnWidth = useCallback((
    columnName: TrackProperty,
    width: number,
    scope: ColumnScope,
  ) => {
    let prevColumnSetting: ColumnSetting | undefined;

    if (columnSettingsStore[scope][screenSize]) {
      prevColumnSetting = columnSettingsStore[scope][screenSize]![columnName];
    }
    else {
      prevColumnSetting = {} as ColumnSetting;
    }

    const updatedState = {
      ...columnSettingsStore,
      [scope]: {
        ...columnSettingsStore[scope],
        [screenSize]: {
          ...columnSettingsStore[scope][screenSize],
          [columnName]: {
            ...prevColumnSetting,
            width,
          },
        },
      },
    };
    setLsItem('columnSettings')(updatedState);
    setColumnSettingsStore(updatedState);

  }, [columnSettingsStore, screenSize]);

  // const setColumnDefault = useCallback(function setColumnDefault(
  //   columnName: TrackProperty | 'index',
  //   scope: ColumnScope,
  // ) {
  //   const columnSettings = columnSettingsStore[scope][screenSize];

  //   if (columnSettings) {
  //     for (let col in columnSettings) {
  //       const trackProp = col as TrackProperty;
  //       if (trackProp === columnName) {
  //         columnSettings[trackProp]!.default = true;
  //       }
  //       else {
  //         columnSettings[trackProp]!.default = undefined;
  //       }
  //     }
  //   }

  //   const updatedState = {
  //     ...columnSettingsStore,
  //     [scope]: {
  //       ...columnSettingsStore[scope],
  //       [screenSize]: {
  //         ...columnSettingsStore[scope][screenSize],
  //       },
  //     },
  //   };
  //   setLsItem('columnSettings')(updatedState);
  //   setColumnSettingsStore(updatedState);

  // }, [columnSettingsStore, screenSize]);

  const columnAutoResize = useCallback((
    columnName: TrackProperty,
    viewportWidth: number,
    scope: ColumnScope,
    contentMinimumWidth: number,
  ) => {

    // Forced non undefined since currentWidth !== undefined takes care of checking.
    const columnSettings = columnSettingsStore[scope][screenSize]!;
    const currentWidth = columnSettings && columnSettings[columnName]?.width;

    if (currentWidth !== undefined) {
      const sum = Object.keys(columnSettings)
        .reduce((prev, cur) => prev + columnSettings[cur as TrackProperty]!.width, 0);

      if (sum > viewportWidth) {
        var targetWidth = Math.max(
          contentMinimumWidth,
          currentWidth - (sum - viewportWidth)
        );
      }
      else if (sum < viewportWidth) {
        var targetWidth = currentWidth + (viewportWidth - sum);
      }
      else {

        // Unhiding a column sets to minimum width when width is full
        if (columnSettings[columnName]?.width === 0) {
          var targetWidth = contentMinimumWidth;
        }
        else {
          return;
        }
      }

      setColumnWidth(
        columnName,
        targetWidth,
        scope,
      );
    }
  }, [columnSettingsStore, setColumnWidth, screenSize]);

  const initializeScope = useCallback(function (
    viewportWidth: number,
    scope: ColumnScope,
  ) {
    const displayedColumnKeys = Object.keys(PlaylistColumns)
      .filter(key => PlaylistColumns[key as TrackProperty].defaultWidthPerc[scope]);

    // smaller screen sizes
    if (screenSize === ScreenSize.Small ||
      screenSize === ScreenSize.Medium) {
      var columnSettings = playlistColumnSubset(viewportWidth, scope, screenSize, displayedColumnKeys);
    }
    else /*(screenSize === ScreenSize.Large / XLarge)*/ {

      var columnSettings = displayedColumnKeys
        .filter(colKey => PlaylistColumns[colKey as TrackProperty].calcFromCharCount)
        .reduce((prev, cur) => {
          const curKey = cur as TrackProperty;
          const charCount = PlaylistColumns[curKey].defaultWidthPerc[scope];

          const colWidth = calcWidthFromCharCount(charCount);
          prev[curKey] = {
            width: colWidth,
            sortOrder: PlaylistColumns[curKey].sortOrder,
          };

          viewportWidth -= colWidth;
          return prev;

        }, {} as Partial<ColumnSettings>);

      displayedColumnKeys
        .filter(colKey => PlaylistColumns[colKey as TrackProperty].calcFromCharCount !== true)
        .reduce((prev, cur) => {
          const curKey = cur as TrackProperty;

          prev[curKey] = {
            width: viewportWidth
              * PlaylistColumns[curKey].defaultWidthPerc[scope]
              / 100,
            sortOrder: PlaylistColumns[curKey].sortOrder,
          };

          return prev;

        }, columnSettings);
    }

    const updatedState = {
      ...columnSettingsStore,
      [scope]: {
        ...columnSettingsStore[scope],
        [screenSize]: columnSettings,
      },
    };
    setLsItem('columnSettings')(updatedState);
    setColumnSettingsStore(updatedState);

    return columnSettings;

  }, [columnSettingsStore, screenSize]);

  const ctxValue = useMemo(() => ({
    columnSettingsStore,
    setColumnWidth,
    columnAutoResize,
    initializeScope,

  }), [
    columnSettingsStore,
    columnAutoResize,
    initializeScope,
    setColumnWidth,
  ]);

  return (
    <ColumnSettingsContext.Provider value={ctxValue}>
      {children}
    </ColumnSettingsContext.Provider>
  );
}
