enum Constants {
  /** Number of results to download per retrieval, used in various places */
  ResultCount = 50,
  HeaderHideBreakpoint = 90,
  // OneDriveClientId: '5257fb08-186b-4f0b-b538-8498d8cf4d32',
  MsGraphDlUrlProp = '@microsoft.graph.downloadUrl',
  DataFolder = 'Fadila',
  ActivePlaylistName = '♫ Now Playing ♫',
  ActivePlaylistId = 'activeplaylist',
  /** LocalStorage key */
  ActivePlaylistKey = 'activePlaylist',
  LibraryName = '📚 Library',
  LibraryId = 'library',
  PodcastFolderName = 'Podcasts',
  NewPlaylistName = '» New Playlist «',
}
export default Constants;

export enum Tasks {
  addFolderToPlaylist = 'addFolderToPlaylist',
  resetAppDataFolder = 'resetAppDataFolder',
  downloadAppDataFolder = 'downloadAppDataFolder',
}
export enum Keys {
  saved,
  storageProviderNotLoggedIn,
  dropboxNotLoggedIn,
  spotifyNotLoggedIn,
  googleAppDataDuplicates,
  libraryPathNotConfigured,
  libraryLoadError,
  ensurePathStringConformity,
  notPodcastItem,
  savedPodcastItem,
  playlistNotFound,
  errorAddingPlaylistToLibrary,
  errorAddingFolderToLibrary,
  invalidTrackType,
}
const strings: {
  [P in Keys]: string;
} = {
  [Keys.dropboxNotLoggedIn]: 'Please log in to Dropbox and try again',
  [Keys.spotifyNotLoggedIn]: 'Please log in to Spotify and try again',
  [Keys.storageProviderNotLoggedIn]: 'You have not authenticated with your currently configured storage provider. Please log in.',
  [Keys.libraryPathNotConfigured]: 'Please configure library path in settings',
  [Keys.ensurePathStringConformity]: 'Path string should start with /',
  [Keys.saved]: 'Saved!',
  [Keys.notPodcastItem]: 'Incorrect track type, podcast item needed',
  [Keys.savedPodcastItem]: 'Successfully saved podcast to library, updated all playlists with saved item',
  [Keys.googleAppDataDuplicates]: 'Duplicate app data files found! Reset GoogleDrive AppData in settings may fix. Please notify fadila@starcraft.is.',
  [Keys.playlistNotFound]: 'Unable to find playlist, please try again.',
  [Keys.errorAddingPlaylistToLibrary]: 'Error adding playlist to library',
  [Keys.errorAddingFolderToLibrary]: 'Error adding folder to library',
  [Keys.libraryLoadError]: 'Unable to load library, please reload to try again',
  [Keys.invalidTrackType]: 'Invalid track type, can\'t add to library',
};

export function messages(key: Keys) {
  
  return strings[key];
}

/**
 * Tests for a mac desktop computer.
 * This is used to make assumptions on intended use of ctrlKey and metakey.
 * We switch shortcuts on mac computers.
 */
export const isMac = /mac/i.test(navigator.platform);

export const isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);

export const backendHost = () => process.env.NODE_ENV === 'production'
  ? 'https://fadilabackend.azurewebsites.net/api'
  : 'https://fadilabackenddev.azurewebsites.net/api'
  ;

  /** 
   * Default character to use for width calculations
   * F.x. when calculating default width of columns with calcFromCharCount
   * render an element filled with x * characterForWidthCalc
   */
export const characterForWidthCalc = '0';
