import GoogleApiError from "../../models/gapi/gapiError";

export default function handleGapiError(
  callSnackbar: (message: any) => void,
  logout: () => void,
) {
  return function (
    err: any,
    defaultMessage?: string,
  ) {
    if (err.result && err.result.error) {
      const gapiErr = err.result as GoogleApiError;

      if (gapiErr.error.code === 401) {
        callSnackbar('User authorization expired, please login again');
        logout();
      } else if (gapiErr.error.code === 403
        && gapiErr.error.errors.some(
          x => x.reason === 'appNotAuthorizedToFile'
          || x.reason === 'insufficientScopes')
      ) {
        callSnackbar('You have not granted the app enough access. Please modify the apps permissions.');
      } else if (gapiErr.error.code === 403
        && gapiErr.error.errors.some(x => x.reason === 'domainPolicy')) {
          callSnackbar('The domain administrators have disabled Drive apps.');
      } else if (gapiErr.error.code === 403
        && gapiErr.error.errors.some(x => x.reason === 'insufficientFilePermissions')) {
          callSnackbar('You do not have sufficient permissions for this file.');
      } else if (gapiErr.error.code === 404) {
        callSnackbar('Unable to find requested resource!');
      } else if (gapiErr.error.code === 403 || gapiErr.error.code === 429) {
        callSnackbar('GoogleDrive requests rate limited!');
      } else if (gapiErr.error.code >= 500) {
        callSnackbar('GoogleDrive server error! Please try again later.');
      } else {
        callSnackbar(defaultMessage || gapiErr.error.message);
      }
    // gapi.auth2 error
    } else if (err.error && err.details) {
      callSnackbar(err.details);
    // Catch all
    } else {
      callSnackbar(defaultMessage || err);
    }
  }
}
