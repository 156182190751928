import React from 'react';
import formatDuration from '../../gUtilities/formatDuration';

interface Props {
  name?: string;
  className?: string;

  paused: boolean;
  currentTime: number;
  duration: number;

  handlePlayPause(): void;
  handleSeek(target_ms: number): void;
}

export default function PlayerControls({
  name,
  paused,
  duration,
  currentTime,
  handlePlayPause,
  handleSeek,
  className,
}: Props) {

  const rangePos = Math.min(
    Math.round(currentTime / duration * 100),
    100);

  function doSeek(ev: React.ChangeEvent<HTMLInputElement>) {
    const val = +ev.currentTarget.value;
    const percentageVal = val / 100;
    handleSeek(duration * percentageVal);
  }

  return (
    <article className={className}>
      {name &&
        <p>{name}</p>
      }
      <button onClick={handlePlayPause} className="button secondary">
        {paused ? 'Play' : 'Pause'}
      </button>
      <input
        type="range"
        onChange={doSeek}
        value={rangePos}
      />
      <span className="track-time">
        {`${formatDuration(currentTime, true)} / ${formatDuration(duration, true)}`}
      </span>
    </article>
  );
}
