import 'firebase/analytics';
import * as firebase from 'firebase/app';

export default function firebaseInit() {
  // Initialize Firebase
  var config = {
    apiKey: "AIzaSyDnoL_A9pvT5RGeQ1xNHMLCUNgjh1U18zU",
    authDomain: "audio-237812.firebaseapp.com",
    databaseURL: "https://audio-237812.firebaseio.com",
    projectId: "audio-237812",
    storageBucket: "audio-237812.appspot.com",
    messagingSenderId: "755034602295",
    appId: '1:755034602295:web:f2ce65c1ca1f576566f8b2',
    measurementId: 'G-4EW2WK9P1M',
  };
  
  firebase.initializeApp(config);
  firebase.analytics();
}
