import React, { useCallback, useContext, useRef } from 'react';
import { ColumnSettingsContext } from '../../../services/columnSettings/columnSettings';
import { ColumnScope, TrackProperty } from '../../../services/columnSettings/playlistColumns';

export default function useColumnResizing(
  columnScope: ColumnScope,
  mainViewport: HTMLElement | undefined,
): [
  (ev: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => void,
] {

  const resizeData = useRef<{
    columnName: TrackProperty;
    pageX: number;
    parentWidth: number;
    contentMinimumWidth: number;
  }>({} as any);

  const colCtx = useContext(ColumnSettingsContext);
  const colCtxRef = useRef(colCtx);
  colCtxRef.current = colCtx;

  const onResizeMoving = useCallback(function (ev: MouseEvent | TouchEvent) {

    ev.preventDefault();
    
    if (ev.type === 'touchmove') {
      var pageX = (ev as TouchEvent).changedTouches[0].pageX;
    } else { //if (ev.type === 'mousemove') {
      var pageX = (ev as MouseEvent).pageX;
    }

    // Never shrink below the minimum
    const newWidth = Math.max(
      resizeData.current.parentWidth + pageX - resizeData.current.pageX,
      resizeData.current.contentMinimumWidth
    );

    colCtxRef.current.setColumnWidth(
      resizeData.current.columnName,
      newWidth,
      columnScope,
    );

  }, [columnScope]);

  const onResizeEnd = useCallback(function (ev: MouseEvent | TouchEvent) {
    const isTouch = ev.type === 'touchend' || ev.type === 'touchcancel';

    if (isTouch) {
      document.removeEventListener('touchmove', onResizeMoving)
      document.removeEventListener('touchcancel', onResizeEnd)
      document.removeEventListener('touchend', onResizeEnd)
    }

    // If its a touch event clear the mouse one's as well because sometimes
    // the mouseDown event gets called as well, but the mouseUp event doesn't
    document.removeEventListener('mousemove', onResizeMoving)
    document.removeEventListener('mouseup', onResizeEnd)
    document.removeEventListener('mouseleave', onResizeEnd)

    // The touch events don't propagate up to the sorting's onMouseDown event so
    // no need to prevent it from happening or else the first click after a touch
    // event resize will not sort the column.
    if (!isTouch) {
      resizeData.current = {} as any;
    }

  }, [onResizeMoving]);
  const onResizeStart = useCallback(function (ev: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) {
    ev.stopPropagation();

    const isTouch = (ev as any).changedTouches ? true : false;

    const parentEl = ev.currentTarget.parentElement!;
    const columnName = parentEl.attributes.getNamedItem('data-column-name')?.value as TrackProperty;
    const parentWidth = parentEl.getBoundingClientRect().width;

    const columnContentEl = parentEl.getElementsByClassName('header-column-content')[0];
    const contentMinimumWidth = columnContentEl.getBoundingClientRect().width;

    if ((ev as any).changedTouches) {
      const touchEvent = ev as React.TouchEvent;
      var pageX = touchEvent.changedTouches[0].pageX;
    } else {
      const mouseEvent = ev as React.MouseEvent;
      var pageX = mouseEvent.pageX;
    }

    resizeData.current = {
      columnName,
      pageX,
      parentWidth,
      contentMinimumWidth,
    };

    if (isTouch) {
      document.addEventListener('touchmove', onResizeMoving);
      document.addEventListener('touchcancel', onResizeEnd);
      document.addEventListener('touchend', onResizeEnd);
    } else {
      document.addEventListener('mousemove', onResizeMoving);
      document.addEventListener('mouseup', onResizeEnd);
      document.addEventListener('mouseleave', onResizeEnd);
    }
  }, [onResizeEnd, onResizeMoving]);

  return [onResizeStart];
}
