import React, { useContext } from 'react';
import { ColumnSettingsContext } from '../../../services/columnSettings/columnSettings';
import PlaylistColumns, { ColumnScope, TrackProperty } from '../../../services/columnSettings/playlistColumns';
import { ScreenDetectionContext } from '../../../services/screenDetection/screenDetection';
import FadilaMenuItem from '../contextMenu/fadilaMenuItem';
import FadilaSubMenu from '../contextMenu/fadilaSubMenu';
import s from './hideColumnsMenu.module.scss';

interface Props {
  scope: ColumnScope;
  hideColumn(columnName: TrackProperty): void;
  unhideColumn(columnName: TrackProperty): void;
}

export default function HideColumnsMenu({
  scope,
  hideColumn,
  unhideColumn,
}: Props) {

  const colCtx = useContext(ColumnSettingsContext);
  const screenSize = useContext(ScreenDetectionContext);

  const columnSettings = colCtx.columnSettingsStore[scope][screenSize];

  return (
    <FadilaSubMenu
      title="Columns"
      preventCloseOnClick
      onClick={ev => ev.stopPropagation()}
    >
      {columnSettings && Object.keys(columnSettings).map(col => {

        const trackProp = col as TrackProperty;
        const isHidden = !(columnSettings![trackProp]!.width > 0);

        const onActivate = isHidden
          ? () => unhideColumn(trackProp)
          : () => hideColumn(trackProp)
          ;

        return (
          <FadilaMenuItem
            key={col}
            preventClose
            onClick={onActivate}
            className={s.column}
          >
            {PlaylistColumns[trackProp].name}
            {' '}
            <input
              onChange={onActivate}
              type="checkbox"
              checked={!isHidden}
            />
          </FadilaMenuItem>
        );
      })}
    </FadilaSubMenu>
  );
}
