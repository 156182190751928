import { debounce } from "debounce";
import { useCallback, useContext, useEffect, useState } from "react";
import calculateTextWidth from '../../../gUtilities/calculateTextWidth';
import { ColumnSettingsContext } from "../../../services/columnSettings/columnSettings";
import PlaylistColumns, { ColumnScope, TrackProperty } from "../../../services/columnSettings/playlistColumns";
import { ScreenDetectionContext } from "../../../services/screenDetection/screenDetection";
import { useDeductions } from "../../playlist/playlistCommon";

/**
 * Calculates total width of content from the mainViewportOffsetWidth and provided column widths
 * 
 * Percentages reflect a fraction of mainViewportOffsetWidth after deducting 
 * play button, context menu button and scrollbar.
 * 
 * @param ctx 
 * @param columns 
 */
export default function useDeductedContentWidth(
  scope: ColumnScope,
) {

  const [mainViewport, setMainViewport] = useState<HTMLElement>();
  const colCtx = useContext(ColumnSettingsContext);
  const screenSize = useContext(ScreenDetectionContext);

  const deductions = useDeductions();

  const resetColumnWidths = useCallback(() => mainViewport &&
    colCtx.initializeScope(
      mainViewport.getBoundingClientRect().width - deductions,
      scope,

    ), [colCtx, scope, deductions, mainViewport]);

  /**
   * Returns total width of content according to 
   * user requested column widths or defaults.
   * 
   * If the user has not configure column widths
   * we create them from defaults.
   * 
   * Defaults are created using 
   * mainViewportOffsetWidth - deductions
   */
  const calculateContentWidth = useCallback(() => {

    let columnSettings = colCtx.columnSettingsStore[scope][screenSize];
    let totalDeductedContentWidth = 0;

    if (mainViewport) {

      if (columnSettings === undefined) {
        console.debug('resetting column widths');
        columnSettings = resetColumnWidths()!;
      }

      for (let col in columnSettings) {
        totalDeductedContentWidth += columnSettings[col as TrackProperty]!.width;
      }

      // console.debug(
      //   'calculating deducted content width, offsetWidth, totalDeductedContentWidth, columnSettings',
      //   mainViewport?.offsetWidth,
      //   totalDeductedContentWidth,
      //   columnSettings);
    }

    return totalDeductedContentWidth;

  }, [colCtx, scope, screenSize, resetColumnWidths, mainViewport]);

  // We only recalculate content width when mainViewport width changes
  const [contentWidth, setContentWidth] = useState(calculateContentWidth);

  useEffect(function watchForResize() {
    const onResize = debounce(function (_ev: UIEvent) {
      setContentWidth(calculateContentWidth());
    }, 100);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [calculateContentWidth]);

  const mainViewportOffsetWidth = mainViewport?.offsetWidth;
  /**
   * Comments below explained the shortcomings of using a React.RefObject for the mainViewport element.
   * The following comments are no longer applicable.
   * 
   * It is not possible to watch for changes in mainViewport?.offsetWidth
   * by straightforward means.
   * 
   * If we track the value directly we run into cases where we evaluate the value
   * before the final render which changes the width.
   * 
   * Instead we useEffect which always runs after renders, tracking if the value changed
   * and recompute if necessary
   */
  useEffect(function triggerContentWidthCalculation() {
    setContentWidth(calculateContentWidth());

  }, [mainViewportOffsetWidth, colCtx.columnSettingsStore, calculateContentWidth]);

  const columnAutoResize = useCallback(function columnAutoResize(ev: React.MouseEvent<HTMLDivElement>) {

    if (mainViewport) {

      const parentEl = ev.currentTarget.parentElement!;
      const columnName = parentEl.attributes.getNamedItem('data-column-name')?.value as TrackProperty;

      const contentMinimumWidth = calculateTextWidth(PlaylistColumns[columnName].name);

      colCtx.columnAutoResize(
        columnName,
        mainViewport.getBoundingClientRect().width - deductions,
        scope,
        contentMinimumWidth,
      );
    }
  }, [mainViewport, colCtx, scope, deductions]);

  const hideColumn = useCallback(function (columnName: TrackProperty) {
    colCtx.setColumnWidth(
      columnName,
      0,
      scope,
    );
  }, [colCtx, scope]);

  const unhideColumn = useCallback(function (columnName: TrackProperty) {
    if (mainViewport) {

      const contentMinimumWidth = calculateTextWidth(PlaylistColumns[columnName].name);

      colCtx.columnAutoResize(
        columnName,
        mainViewport.getBoundingClientRect().width - deductions,
        scope,
        contentMinimumWidth,
      );
    }
  }, [colCtx, mainViewport, scope, deductions]);

  /**
   * contentWidth + deductions.
   */
  const totalContentWidth = contentWidth + deductions;

  return {
    totalContentWidth,
    mainViewport,
    setMainViewport,
    resetColumnWidths,
    columnAutoResize,
    hideColumn,
    unhideColumn,
  };
}
