import classNames from 'classnames';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, ContextMenuTriggerProps } from 'react-contextmenu';
import { promiseNoop } from '../../../gUtilities/noop';
import { ColumnKey, ColumnScope, TrackProperty } from '../../../services/columnSettings/playlistColumns';
import { Context } from '../../../services/_globalContext/context';
import { preventDefaultStopPropogation } from '../../../utilities/preventDefault';
import s from '../../playlist/playlist.module.scss';
import FadilaMenuItem from '../contextMenu/fadilaMenuItem';
import HideColumnsMenu from '../hideColumnsMenu/hideColumnsMenu';
import useColumnResizing from '../hooks/useColumnResizing';
import useColumnSettings from '../hooks/useColumnSettings';
import useDeductedContentWidth from '../hooks/useDeductedContentWidth';
import useSharedPlaylistMethods from '../hooks/useSharedPlaylistMethods';
import ItemListColumn from '../itemListColumn/itemListColumn';
import PlaylistControls from '../playlistControls/playlistControls';
import Spinner from '../spinner/spinner';
import InfiniteList from '../virtualized/infiniteList';
import StreamingServiceSearchResultRenderer, { StreamingServiceSearchResulData as StreamingServiceSearchResultData } from './streamSvcSearchRenderer';

interface Props<T1, T2> {
  columnScope: ColumnScope;
  loading: boolean;
  results: T1[];
  itemComponent: React.FunctionComponent<T2>;

  display: boolean;
  isComplete(): boolean;
  totalItemCount(): number;
  totalItemCountString?: string | false;
  filteredItemCountString?: string | false;
  columnCustomName?: (colKey: ColumnKey) => string | undefined;

  isNextPageLoading: boolean;
  loadMore(startIndex: number, stopIndex: number): Promise<any>;
  /**
   * Is there more data left to be loaded?
   */
  hasMore?: boolean;

  props: Partial<T2>;
  /**
   * Virtualized row height
   */
  itemSize?: number;
}

function StreamingServiceSearchResults<T1, T2>({
  columnScope,
  loading,
  results,
  itemComponent,
  display,
  isComplete,
  totalItemCount: totalItemCountF,
  totalItemCountString,
  filteredItemCountString,
  columnCustomName,
  isNextPageLoading,
  loadMore,
  hasMore,
  props,
  itemSize,
}: Props<T1, T2>) {

  const ctx = useContext(Context);
  const { columns, columnSettings, } = useColumnSettings(columnScope);

  const {
    totalContentWidth,
    mainViewport,
    setMainViewport,
    resetColumnWidths,
    columnAutoResize,
    hideColumn,
    unhideColumn,
  } = useDeductedContentWidth(columnScope);
  const [onResizeStart] = useColumnResizing(columnScope, mainViewport);

  const {
    selectedItems,
    selectedItemsRef,

    handleToggleItem,
    shiftSelectItem,
    changeItemSelectionState,
    clearSelected,

  } = useSharedPlaylistMethods({
    tracks: results,
    playlistElement: mainViewport,
  });

  const [contextTriggers] = useState<{
    [P in TrackProperty]?: React.Component<ContextMenuTriggerProps, any, any>;
  } & {
    size?: React.Component<ContextMenuTriggerProps, any, any>;
    image?: React.Component<ContextMenuTriggerProps, any, any>;
    contextCol?: React.Component<ContextMenuTriggerProps, any, any>;
  }>({});

  const toggleMenu = useCallback((e: React.MouseEvent) => {
    const parent = e.currentTarget.parentElement;

    if (parent) {
      const columnName = parent.attributes.getNamedItem('data-column-name')?.value as TrackProperty;

      const ctxTrigger = contextTriggers[columnName];
      if (ctxTrigger) {
        (ctxTrigger as any).handleContextClick(e);
      }
    }
  }, [contextTriggers]);

  // Adds for the conditional loading element that follows if we do not have a total item count
  const totalVirtualItemCount = hasMore ? 1 : 0; // Loading element at end

  const isItemLoaded = useCallback(
    (index: number) =>
      // Don't include loading element padding
      index < results.length
    , [results]);

  const itemRendererProps: StreamingServiceSearchResultData<T1, T2>
    = useMemo(() => ({
      results,

      itemComponent,

      selectedItems,
      portalTarget: mainViewport || undefined,

      props: {
        selectedItemsRef,
        handleToggleItem: (itm: T1) => handleToggleItem(itm, selectedItemsRef),
        handleShiftSelect: (itm: T1) => shiftSelectItem(itm, selectedItemsRef, results),
        changeItemSelectionState: (
          itm: T1,
          selectState: boolean,
          remPrev: boolean | undefined
        ) =>
          changeItemSelectionState(itm, selectedItemsRef, selectState, remPrev),

        clearSelected,

        ...props
      },
    }), [
      selectedItems,
      selectedItemsRef,
      mainViewport,
      handleToggleItem,
      shiftSelectItem,
      changeItemSelectionState,
      clearSelected,
      results,
      props,
      itemComponent,
    ]);

  return (
    <article
      ref={c => setMainViewport(c || undefined)}
      className={`items-list ${s.playlist}`}
    >
      {loading || columnSettings === undefined || mainViewport === undefined ?
        <Spinner />
        :
        <PlaylistControls
          name="Search results"
          display={display}
          isComplete={isComplete}

          filteredItemCount={results.length}
          totalItemCount={totalItemCountF}

          displayGetRemaining={false}

          displayBottom={ctx.settings.showPlaylistStatusBar}
          totalItemCountString={totalItemCountString}
          filteredItemCountString={filteredItemCountString}
        >
          <div className={classNames(s.contentWrapper, s.parentContentWrapper)}>
            <div className={s.thead} style={{ width: totalContentWidth }}>
              <div
                className={classNames(
                  s.headerColumn,
                  s.underscoreColumn)}>
              </div>
              {columns.map(colKey =>
                <ItemListColumn
                  key={colKey}
                  customName={columnCustomName && columnCustomName(colKey)}
                  columnKey={colKey}
                  columnSettings={columnSettings}
                  columnAutoResize={columnAutoResize}
                  contextMenuTriggerRef={c => { contextTriggers[colKey] = c || undefined; }}
                  onResizeStart={onResizeStart}
                  toggleMenu={toggleMenu}
                />
              )}
              <div
                data-column-name="contextCol"
                className={classNames(
                  s.headerColumn,
                  s.hideOnHover,
                  s.contextMenuBtnColumn,
                )}
                style={{ width: (ctx.scrollbarWidth || 0) + 16 }}
              >
                <ContextMenuTrigger
                  ref={c => contextTriggers['contextCol'] = c || undefined}
                  id="cloudprovider-context-menu"
                  holdToDisplay={-1}
                  disableIfShiftIsPressed={true}
                >
                  <></>
                </ContextMenuTrigger>
                <button
                  className={classNames(s.menuBtn, 'invisible-control')}
                  onClick={toggleMenu}
                >
                  ☰
                </button>
              </div>
              <ContextMenu id="cloudprovider-context-menu">
                <HideColumnsMenu
                  scope={columnScope}
                  hideColumn={hideColumn}
                  unhideColumn={unhideColumn}
                />
                <FadilaMenuItem
                  onClick={preventDefaultStopPropogation(resetColumnWidths)}
                >
                  Reset columns
                </FadilaMenuItem>
              </ContextMenu>
            </div>
            <div
              className={classNames(s.contentWrapper, {
                [s.oddRows]: results.length % 2 !== 0
              })}
              style={{ width: totalContentWidth }}
            >
              <InfiniteList
                isItemLoaded={isItemLoaded}
                itemCount={totalVirtualItemCount + results.length}
                loadMoreItems={isNextPageLoading
                  ? promiseNoop
                  : loadMore}

                itemSize={itemSize || 110}
                itemData={itemRendererProps}
                itemRenderer={StreamingServiceSearchResultRenderer}
              />
            </div>
          </div>
        </PlaylistControls>
      }
    </article>
  );
}

export default React.memo(StreamingServiceSearchResults) as typeof StreamingServiceSearchResults;
