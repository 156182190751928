import React from 'react';
import Tips from '../../components/common/tips/tips';

interface Props {

}

export default function Help({

}: Props) {

  return (
    <article className="grid-container">
      <Tips />
      <hr />
      <h3>Troubleshooting</h3>
      <h5>Fixing startup issues</h5>
      <p>If the app won't open you can try resetting your library.</p>
      <p>You will lose all your songs and playlists but this may fix some startup issues from broken files.</p>
      <p>By default your data will be stored in google drive, to reset google drive app data find the Reset GooleDrive AppData button under advanced during startup or under settings.</p>
      <p>If you are using a different storage provider you should be able to find Fadila AppData in the folder Fadila in your cloud storage root.</p>
    </article>
  );
}
