import classNames from 'classnames';
import { pipe } from 'fp-ts/es6/function';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';
import { DragType } from '../../models/dragTypes';
import Playlist from '../../models/playlist/playlist';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import QueryIterator from '../../models/queryIterator';
import Source from '../../models/source';
import { SpotifyAlbumBase, SpotifyAlbumSimplified, SpotifyTrackAlbum } from '../../models/spotify/spotifyAlbum';
import SpotifyApiPlaylistTrack, { SpotifyApiTrack } from '../../models/spotify/spotifyApiTrack';
import { SpotifyPlaylistSimplified } from '../../models/spotify/spotifyPlaylist';
import Track from '../../models/track';
import SpotifyTrack from '../../models/track/spotifyTrack';
import { ActivePlaylistContext } from '../../services/activePlaylist/activePlaylist';
import { ColumnScope } from '../../services/columnSettings/playlistColumns';
import { createAddItemsToPlaylistThenLibrary } from '../../services/library/addTracksToPlaylistThenLibrary';
import { LibraryContext } from '../../services/library/libraryService';
import { SmallDeviceContext, SmallDeviceShow } from '../../services/smallDevices/smallDeviceService';
import { SpotifyContext } from '../../services/spotify/spotifyService';
import { Context } from '../../services/_globalContext/context';
import Constants from '../../settings';
import createAddPlaylistToPlaylist from '../../utilities/helperFactories/addPlaylistToPlaylist';
import ErrorBoundary from '../common/errorBoundary/errorBoundary';
import Spinner from '../common/spinner/spinner';
import StreamingServicePlaylistRowRenderer, { StreamingServicePlaylistRowData } from '../common/streamingServiceCommon/streamSvcPlaylistPlaylistRowRenderer';
import StreamingServiceSearchResults from '../common/streamingServiceCommon/streamSvcSearchResults';
import useLoadMore from '../common/virtualized/useLoadMore';
import LibraryPlaylists from '../library/libraryPlaylists';
import libPlsStyles from '../library/libraryPlaylists.module.scss';
import TrackPlaylistComponent from '../playlist/trackPlaylistComponent';
import AlbumSearchResult, { SpotifyAlbumRowProps } from './searchResults/albumSearchResult';
import PlaylistSearchResult, { SpotifyPlaylistRowProps } from './searchResults/playlistSearchResult';
import TrackSearchResult, { TrackSearchResultRowProps as SpotifyTrackRowProps } from './searchResults/trackSearchResult';
import s from './spotify.module.scss';
import SpotifyPlaylistRowContent from './spotifyPlaylistRowContent';

enum SpotifySearchType {
  Track = 'track',
  Playlist = 'playlist',
  Album = 'album',
  Artist = 'artist',
}
interface Props {
}

export default React.memo(function SpotifyComponent({
}: Props) {

  const ctx = useContext(Context);
  const smallDevSvc = useContext(SmallDeviceContext);
  const activePlaylist = useContext(ActivePlaylistContext);
  const libCtx = useContext(LibraryContext);
  const spotifyCtx = useContext(SpotifyContext);

  // Search State
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchType, setSearchType] = useState<SpotifySearchType>()

  const [searchAlbumsIterator, setSearchAlbumsIterator]
    = useState<QueryIterator<SpotifyTrackAlbum>>();
  const [searchAlbumsResults, setSearchAlbumsResults] = useState<SpotifyTrackAlbum[]>([]);
  const [searchAlbumsLoadingMore, searchAlbumsLoadMore]
    = useLoadMore({
      items: searchAlbumsResults,
      setItems: setSearchAlbumsResults,
      queryIterator: searchAlbumsIterator,
      setIterator: setSearchAlbumsIterator,
    });

  const [searchPlaylistsIterator, setSearchPlaylistsIterator]
    = useState<QueryIterator<SpotifyPlaylistSimplified>>();
  const [searchPlaylistsResults, setSearchPlaylistsResults] = useState<SpotifyPlaylistSimplified[]>([]);
  const [searchPlaylistsLoadingMore, searchPlaylistsLoadMore]
    = useLoadMore({
      items: searchPlaylistsResults,
      setItems: setSearchPlaylistsResults,
      queryIterator: searchPlaylistsIterator,
      setIterator: setSearchPlaylistsIterator,
    });

  const [searchTracksIterator, setSearchTracksIterator]
    = useState<QueryIterator<SpotifyApiTrack>>();
  const [searchTracksResults, setSearchTracksResults] = useState<SpotifyApiTrack[]>([]);
  const [searchTracksLoadingMore, searchTracksLoadMore]
    = useLoadMore({
      items: searchTracksResults,
      setItems: setSearchTracksResults,
      queryIterator: searchTracksIterator,
      setIterator: setSearchTracksIterator,
    });

  // const [searchArtistsPageNumber, setSearchArtistsPageNumber] = useState(1);
  // const [searchArtistsIterator, setSearchArtistsIterator]
  //   = useState<QueryIterator<SpotifyArtist>>();
  // const [searchArtistsResults, setSearchArtistsResults] = useState<SpotifyArtist[]>([]);

  const searchIteratorsUndefined = (
    searchAlbumsIterator === undefined &&
    searchPlaylistsIterator === undefined &&
    searchTracksIterator === undefined
  );

  // Search State End

  // Playlists State
  const [playlistsPageNumber, setPlaylistsPageNumber] = useState(1);
  const [playlistsIterator, setPlaylistsIterator]
    = useState<QueryIterator<SpotifyPlaylistSimplified>>();
  const [playlistsLoading, setPlaylistsLoading] = useState(false);
  const [playlists, setPlaylists] = useState<SpotifyPlaylistSimplified[]>([]);

  const [currentPlaylistOrAlbum, setCurrentPlaylistOrAlbum] = useState<SpotifyPlaylistSimplified | SpotifyAlbumBase>();
  // Playlists State End

  // Playlist Items State
  const [spotifyPlaylistItemsIterator, setSpotifyPlaylistItemsIterator]
    = useState<QueryIterator<SpotifyApiPlaylistTrack | SpotifyApiTrack>>();
  const [spotifyPlaylistItemsLoading, setSpotifyPlaylistItemsLoading] = useState(false);
  const [spotifyPlaylistItemsLoadingMore, setSpotifyPlaylistItemsLoadingMore] = useState(false);
  const [spotifyPlaylistItems, setSpotifyPlaylistItems] = useState<Track[]>([]);
  // Playlist Items State End

  const spotifyPlaylistPlaylistItems = useMemo(
    () => spotifyPlaylistItems.map(PlaylistItem.from),
    [spotifyPlaylistItems]
  );
  const playlist = useMemo(
    () => currentPlaylistOrAlbum
      ? new Playlist(
        currentPlaylistOrAlbum.name,
        spotifyPlaylistPlaylistItems,
        currentPlaylistOrAlbum.id,
      )
      : new Playlist(
        'Null',
        [],
        'Null',
      ),
    // TrackPlaylistComponents are loaded but neither displayed nor rendered 
    // while their tracks are being loaded
    // If we watch for curUserPl we create a new playlist with tracks from previous pl
    // We can however safely assume that each time the user changes playlist
    // a new tracks array will be created.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [spotifyPlaylistPlaylistItems]
  );
  const [playlistsElement, setPlaylistsElement] = useState<HTMLElement | null>(null);

  const getUserPlaylists = useCallback(async function () {
    if (spotifyCtx.spotifyApi) {
      setPlaylistsLoading(true);

      try {
        const playlists: SpotifyPlaylistSimplified[] = [];
        let playlistsIterator = await spotifyCtx.spotifyApi.getUserPlaylists(
          // Math.min(Constants.ResultCount, 50)
        );

        playlists.push(...playlistsIterator.results);

        if (playlistsIterator.totalResults) {

          while (playlistsIterator.next) {
            playlistsIterator = await playlistsIterator.next();

            playlists.push(...playlistsIterator.results);
          }
        }

        if (playlistsPageNumber !== 1) {
          setPlaylistsPageNumber(1);
        }
        setPlaylists(playlists);
        setPlaylistsIterator(playlistsIterator);
      } finally {
        setPlaylistsLoading(false);
      }
    }
  }, [spotifyCtx.spotifyApi, playlistsPageNumber]);
  useEffect(function loadUser() {

    getUserPlaylists();
    // Should not reset on page change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotifyCtx.accessToken]);

  /**
  * Automatically adds videos from user playlists straight to library before adding to playlist
  * @param items 
  */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addToLibraryThenPlaylist = useCallback(function (items: SpotifyApiPlaylistTrack[], replace = true) {
    pipe(
      (x: SpotifyApiPlaylistTrack[]) => x.map(SpotifyTrack.from),
      x => libCtx.addToLibrary(...x),
      x => x.map(x => replace
        ? setSpotifyPlaylistItems(x)
        : setSpotifyPlaylistItems(spotifyPlaylistItems.concat(x))),
      x => x.mapLeft(ctx.callSnackbar),
    )(items);
  },
    [spotifyPlaylistItems, ctx.callSnackbar, libCtx]);

  const handleSearch = useCallback(async function (ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    if (spotifyCtx.spotifyApi) {

      setCurrentPlaylistOrAlbum(undefined);
      setSpotifyPlaylistItems([]);
      setSpotifyPlaylistItemsIterator(undefined);

      setSearchAlbumsIterator(undefined);
      setSearchTracksIterator(undefined);
      setSearchPlaylistsIterator(undefined);
      setSearchLoading(true);

      const form = ev.currentTarget;
      const queryEl = form.elements.namedItem('query') as HTMLInputElement;

      // let types = '';
      // for (let type in SpotifyType) {
      //   const inputEl = form.elements.namedItem(type.toLowerCase()) as HTMLInputElement;

      //   if (inputEl && inputEl.checked) {
      //     types += `${type.toLowerCase()},`;
      //   }
      // }

      const type = form.elements.namedItem('type') as HTMLInputElement;
      setSearchType(type.value as SpotifySearchType);

      try {
        const res = await spotifyCtx.spotifyApi.search(
          queryEl.value,
          Constants.ResultCount,
          // types ? types.substring(0, types.length - 1) : undefined,
          type.value,
        );

        if (res.albums) {
          setSearchAlbumsResults(res.albums.results);
          setSearchAlbumsIterator(res.albums);
        }
        else if (res.playlists) {
          setSearchPlaylistsResults(res.playlists.results);
          setSearchPlaylistsIterator(res.playlists);
        }
        else if (res.tracks) {
          setSearchTracksResults(res.tracks.results);
          setSearchTracksIterator(res.tracks);
        }

        // if (res.artists) {
        //   setSearchArtistsResults(res.artists.results);
        //   setSearchArtistsIterator(res.artists);
        //    setSearchArtistsPageNumber(1);
        // }
      }
      catch (err) {
        throw err;
      }
      finally {
        setSearchLoading(false);
      }
    }
  }, [spotifyCtx.spotifyApi]);

  const getPlaylistItems = useCallback(async function (playlist: SpotifyPlaylistSimplified) {
    if (spotifyCtx.spotifyApi) {
      setSearchType(undefined);
      setSearchAlbumsIterator(undefined);
      setSearchTracksIterator(undefined);
      setSearchPlaylistsIterator(undefined);
      setSearchTracksResults([]);

      setSpotifyPlaylistItemsIterator(undefined);
      setSpotifyPlaylistItemsLoading(true);

      try {
        const playlistIter = await spotifyCtx.spotifyApi.getPlaylist(playlist.id);

        const plItms = playlistIter.results.map(SpotifyTrack.from);
        setCurrentPlaylistOrAlbum(playlist);
        setSpotifyPlaylistItems(plItms);
        setSpotifyPlaylistItemsIterator(playlistIter);
      }
      catch (err) {
        throw err;
      }
      finally {
        setSpotifyPlaylistItemsLoading(false);
      }
    }
  }, [spotifyCtx.spotifyApi]);
  const getAlbumItems = useCallback(async function (album: SpotifyAlbumSimplified) {
    if (spotifyCtx.spotifyApi) {
      setSearchType(undefined);
      setSearchAlbumsIterator(undefined);
      setSearchTracksIterator(undefined);
      setSearchPlaylistsIterator(undefined);
      setSpotifyPlaylistItemsIterator(undefined);
      setSpotifyPlaylistItemsLoading(true);

      try {
        const playlistIter = await spotifyCtx.spotifyApi.getAlbum(album.id);

        const plItms = playlistIter.results.map(SpotifyTrack.from);
        setSpotifyPlaylistItems(plItms);
        setSpotifyPlaylistItemsIterator(playlistIter);
        setCurrentPlaylistOrAlbum(album);
      }
      catch (err) {
        throw err;
      }
      finally {
        setSpotifyPlaylistItemsLoading(false);
      }
    }
  }, [spotifyCtx.spotifyApi]);

  // Async state
  /** 
   * We can't use a state based variable in case multiple requests attempt to fire 
   * in quick succession. 
   * This happens when we have total item count and user scrolls to end f.x.
   * In that case all loadMore methods called would have the old state variable.
   * We also need the ref since updating the ref will not trigger a re-render
   * so the TrackPlaylist can 
   */
  const isLoadingMore = useRef(false);
  const stopIdxRef = useRef<number>();

  const loadMore = useCallback(async function loadMore(startIdx: number, stopIndex: number) {

    console.info('LoadMore', startIdx, stopIndex);

    // All executions update stop index
    // JS is not multi-threaded, so we can assume that a second loadMore won't get called
    // until iterate below gets called
    // This allows us to update let stopIndex at end of while loop
    // allowing further data loading if warranted
    stopIdxRef.current = stopIndex;

    if (isLoadingMore.current) {
      return;
    }

    isLoadingMore.current = true;

    if (spotifyPlaylistItemsIterator?.next) {

      setSpotifyPlaylistItemsLoadingMore(true);
      let iterator = spotifyPlaylistItemsIterator;
      let playlistItems = spotifyPlaylistItems;

      try {
        while (stopIndex >= playlistItems.length && iterator.next !== undefined) {

          iterator = await iterator.next();

          if (iterator.results) {
            const spotifyTracks = iterator.results.map(SpotifyTrack.from);
            playlistItems = playlistItems.concat(spotifyTracks);

            setSpotifyPlaylistItems(playlistItems);
          }

          // Update after async method returns
          stopIndex = stopIdxRef.current;
        }
      }
      catch (err) {
        throw err;
      }
      finally {
        isLoadingMore.current = false;
        setSpotifyPlaylistItemsIterator(iterator);
        setSpotifyPlaylistItemsLoadingMore(false);
      }
    }
    else {
      isLoadingMore.current = false;
    }
  }, [spotifyPlaylistItems, spotifyPlaylistItemsIterator]);

  const addItemsToPlaylistThenLibrary = useMemo(() => createAddItemsToPlaylistThenLibrary<SpotifyApiTrack | SpotifyApiPlaylistTrack>(
    ctx,
    libCtx,
    SpotifyTrack.from,
  ), [ctx, libCtx]);

  /** 
   * For playing single videos from search results
   * In those cases we do not add to library
   */
  const playSearchResult = useCallback(function (item: SpotifyApiTrack) {
    const plItm = new PlaylistItem(item.id, Source.Spotify);

    const doPlay = true;
    activePlaylist.setActivePlaylist(
      [plItm],
      0,
      doPlay,
    );
  }, [activePlaylist]);

  const addPlaylistToPlaylist = useMemo(() => (addItems: (i: PlaylistItem[]) => void) =>
    createAddPlaylistToPlaylist<SpotifyApiPlaylistTrack>(
      ctx,
      spotifyCtx.spotifyApi?.getPlaylist!,
      addItemsToPlaylistThenLibrary,
      addItems,
    ), [ctx, spotifyCtx.spotifyApi, addItemsToPlaylistThenLibrary]);

  const addAlbumToPlaylist = useMemo(() => (addItems: (i: PlaylistItem[]) => void) =>
    createAddPlaylistToPlaylist<SpotifyApiTrack>(
      ctx,
      spotifyCtx.spotifyApi?.getAlbum!,
      addItemsToPlaylistThenLibrary,
      addItems,
    ), [ctx, spotifyCtx.spotifyApi, addItemsToPlaylistThenLibrary]);

  const titleAccessor = useCallback((pl: SpotifyPlaylistSimplified) => pl.name, []);
  const itemData: StreamingServicePlaylistRowData<SpotifyPlaylistSimplified> = useMemo(() => ({
    curUserPl: currentPlaylistOrAlbum as any,
    playlists,
    dragType: DragType.SpotifyPlaylist,
    getPlaylistItems,
    addPlaylistToPlaylist,
    titleAccessor,
    additionalContent: SpotifyPlaylistRowContent,
    portalTarget: playlistsElement || undefined,

  }), [getPlaylistItems, titleAccessor, addPlaylistToPlaylist, currentPlaylistOrAlbum, playlists, playlistsElement]);

  const trackSearchProps: Partial<SpotifyTrackRowProps> = useMemo(() => ({
    handlePlayTrack: playSearchResult,
    addItemsThenToLibrary: addItemsToPlaylistThenLibrary,

  }), [playSearchResult, addItemsToPlaylistThenLibrary]);

  const playlistSearchProps: Partial<SpotifyPlaylistRowProps> = useMemo(() => ({
    addPlaylistToPlaylist:
      (addItems: any) => createAddPlaylistToPlaylist<SpotifyApiPlaylistTrack>(
        ctx,
        spotifyCtx.spotifyApi?.getPlaylist!,
        addItemsToPlaylistThenLibrary,
        addItems,
      ),
    openPlaylist: getPlaylistItems,
  }), [
    ctx,
    spotifyCtx,
    getPlaylistItems,
    addItemsToPlaylistThenLibrary,
  ]);

  const albumSearchProps: Partial<SpotifyAlbumRowProps> = useMemo(() => ({
    addAlbumToPlaylist,
    openAlbum: getAlbumItems,
  }), [
    getAlbumItems,
    addAlbumToPlaylist,
  ]);

  return (
    <ErrorBoundary>
      {spotifyCtx.isAttemptingLogin ?
        <Spinner />
        : !spotifyCtx.accessToken
          ?
          <div>
            <button className="button" onClick={spotifyCtx.authorize}>
              Spotify Login
            </button>
          </div>
          :
          <article className="grid-container full fluid">
            <div className="grid-x">
              <div className="cell shrink">
                <article className={`mainViewport ${ctx.playerSize}`}>
                  <LibraryPlaylists />
                </article>
              </div>

              <div className="cell auto">
                <div className={`mainViewport ${ctx.playerSize}`}>
                  <section>
                    <form onSubmit={handleSearch}>
                      <div className={`grid-x ${s.searchBar}`}>
                        <label className={s.searchLabel} htmlFor="query">
                          <div className="grid-x grid-margin-x">
                            <input
                              className="cell auto"
                              type="text"
                              name="query"
                              id="query"
                              placeholder="Search Spotify"
                            />
                            <div className={classNames('cell', 'shrink', s.searchTypeControls)}>
                              <label htmlFor={SpotifySearchType.Album}>
                                Albums
                                <input
                                  type="radio"
                                  id={SpotifySearchType.Album}
                                  name="type"
                                  value={SpotifySearchType.Album}
                                />
                              </label>
                              <label htmlFor={SpotifySearchType.Artist}>
                                Artists
                                <input
                                  disabled
                                  type="radio"
                                  id={SpotifySearchType.Artist}
                                  name="type"
                                  value={`${SpotifySearchType.Artist} - unsupported`}
                                />
                              </label>
                              <label htmlFor={SpotifySearchType.Playlist}>
                                Playlists
                                <input
                                  type="radio"
                                  id={SpotifySearchType.Playlist}
                                  name="type"
                                  value={SpotifySearchType.Playlist}
                                />
                              </label>
                              <label htmlFor={SpotifySearchType.Track}>
                                Tracks
                                <input
                                  type="radio"
                                  id={SpotifySearchType.Track}
                                  name="type"
                                  value={SpotifySearchType.Track}
                                  defaultChecked
                                />
                              </label>
                            </div>
                            <button className="cell shrink button">
                              Search
                            </button>
                          </div>
                        </label>
                      </div>
                    </form>
                  </section>

                  {searchIteratorsUndefined &&
                    <div className={`items-list ${s.mainViewContainer}`}>
                      {(spotifyPlaylistItemsLoading || spotifyPlaylistItemsIterator !== undefined) && currentPlaylistOrAlbum &&
                        <TrackPlaylistComponent
                          moveable={false}
                          dragType={DragType.TrackItems}

                          playlist={playlist}
                          trackItems={spotifyPlaylistItems}
                          loading={spotifyPlaylistItemsLoading}

                          display={spotifyPlaylistItemsIterator !== undefined}
                          isComplete={() => !spotifyPlaylistItemsIterator?.next}
                          totalItemCount={() => spotifyPlaylistItemsIterator?.totalResults!}

                          columnScope={ColumnScope.Spotify}

                          loadMore={loadMore}
                          hasMore={spotifyPlaylistItemsIterator?.next !== undefined}
                          isNextPageLoading={spotifyPlaylistItemsLoadingMore}
                        />
                      }
                    </div>
                  }

                  {(() => {
                    switch (searchType) {
                      case SpotifySearchType.Track:
                        return (
                          <div className={`items-list ${s.mainViewContainer}`}>
                            {(searchLoading || searchTracksIterator !== undefined) &&
                              <StreamingServiceSearchResults<SpotifyApiTrack, SpotifyTrackRowProps>

                                columnScope={ColumnScope.SpotifyTrackSearch}
                                itemComponent={TrackSearchResult}
                                results={searchTracksResults}
                                loading={searchLoading}
                                display={searchTracksIterator !== undefined}
                                isComplete={() => !searchTracksIterator?.next}

                                totalItemCount={() => searchTracksIterator!.totalResults!}
                                totalItemCountString={
                                  searchTracksIterator?.totalResults &&
                                    searchTracksIterator?.totalResults > 10000
                                    ? false
                                    : "results"
                                }

                                isNextPageLoading={searchTracksLoadingMore}
                                loadMore={searchTracksLoadMore}
                                hasMore={searchTracksIterator?.next !== undefined}

                                itemSize={26}
                                props={trackSearchProps}
                              />
                            }
                          </div>
                        );

                      case SpotifySearchType.Playlist:
                        return (
                          <div className={`items-list ${s.mainViewContainer}`}>
                            {(searchLoading || searchPlaylistsIterator !== undefined) &&
                              <StreamingServiceSearchResults<SpotifyPlaylistSimplified, SpotifyPlaylistRowProps>

                                columnScope={ColumnScope.SpotifyPlaylistSearch}
                                itemComponent={PlaylistSearchResult}
                                results={searchPlaylistsResults}
                                loading={searchLoading}
                                display={searchPlaylistsIterator !== undefined}
                                isComplete={() => !searchPlaylistsIterator?.next}

                                totalItemCount={() => searchPlaylistsIterator!.totalResults!}
                                totalItemCountString={
                                  searchPlaylistsIterator?.totalResults &&
                                    searchPlaylistsIterator?.totalResults > 10000
                                    ? false
                                    : "results"
                                }

                                isNextPageLoading={searchPlaylistsLoadingMore}
                                loadMore={searchPlaylistsLoadMore}
                                hasMore={searchPlaylistsIterator?.next !== undefined}

                                props={playlistSearchProps}
                              />
                            }
                          </div>
                        );

                      case SpotifySearchType.Album:
                        return (
                          <div className={`items-list ${s.mainViewContainer}`}>
                            {(searchLoading || searchAlbumsIterator !== undefined) &&
                              <StreamingServiceSearchResults<SpotifyTrackAlbum, SpotifyAlbumRowProps>

                                columnScope={ColumnScope.SpotifyAlbumSearch}
                                itemComponent={AlbumSearchResult}
                                results={searchAlbumsResults}
                                loading={searchLoading}
                                display={searchAlbumsIterator !== undefined}
                                isComplete={() => !searchAlbumsIterator?.next}

                                totalItemCount={() => searchAlbumsIterator!.totalResults!}
                                totalItemCountString={
                                  searchAlbumsIterator?.totalResults &&
                                    searchAlbumsIterator?.totalResults > 10000
                                    ? false
                                    : "results"
                                }

                                isNextPageLoading={searchAlbumsLoadingMore}
                                loadMore={searchAlbumsLoadMore}
                                hasMore={searchAlbumsIterator?.next !== undefined}

                                props={albumSearchProps}
                              />
                            }
                          </div>
                        );
                    }
                  })()}
                </div>
              </div>

              <div className="cell shrink">
                {playlistsLoading
                  ? <Spinner />
                  : playlistsIterator !== undefined
                  &&
                  <div className={`mainViewport ${ctx.playerSize}`}>
                    <article
                      ref={el => setPlaylistsElement(el)}
                      className={classNames(
                        libPlsStyles.playlistList, s.spotifyPlaylists, {
                        [libPlsStyles.open]: smallDevSvc.state === SmallDeviceShow.StreamingPlaylists,
                      })}
                    >
                      {playlistsElement !== null &&
                        <AutoSizer>
                          {({ height, width }) => (
                            <List
                              height={height}
                              width={width}
                              itemCount={playlists.length}
                              itemSize={29}
                              itemData={itemData}
                            >
                              {StreamingServicePlaylistRowRenderer}
                            </List>
                          )}
                        </AutoSizer>
                      }
                    </article>
                  </div>
                }
              </div>
            </div>
          </article>
      }
    </ErrorBoundary>
  );
});
