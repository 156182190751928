import React from 'react';
import { ListChildComponentProps } from 'react-window';
import InfiniteLoadingRow from '../virtualized/infiniteLoadingRow';

export interface StreamingServiceSearchResulData<T1, T2> {
  results: T1[];
  selectedItems: React.MutableRefObject<Set<T1>>;
  portalTarget?: HTMLElement;
  itemComponent: React.FunctionComponent<T2>;
  props: Partial<T2>;
}
interface Props<T1, T2> extends ListChildComponentProps {
  data: StreamingServiceSearchResulData<T1, T2>;
}

function StreamingServiceSearchResultRenderer({
  index,
  style,
  data,
}: Props<any, any>) {

  const {
    results,
    itemComponent: ItemComponent,
    selectedItems,
    portalTarget,
    props,
  } = data;

  const item = results[index];

  if (item === undefined) {
    return <InfiniteLoadingRow style={style} />;
  }
  else {
    return (
      <div style={style}>
        <ItemComponent
          index={index}
          item={item}
          portalTarget={portalTarget}
          isSelected={selectedItems.current.has(item)}

          {...props}
        />
      </div>
    );
  }
}

export default StreamingServiceSearchResultRenderer as unknown as React.ComponentClass<Props<any, any>, void>;
