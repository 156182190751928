import decodeHtml from '../../utilities/decodeHtml';
import Source from '../source';
import { SpotifyTrackAlbum } from '../spotify/spotifyAlbum';
import SpotifyApiPlaylistTrack, { SpotifyApiTrack, SpotifyTrackSimplified } from '../spotify/spotifyApiTrack';
import NullTrack from './nullTrack';
import Track from './track';

export default class SpotifyTrack extends Track {
  static from(track: SpotifyTrackSimplified, album: SpotifyTrackAlbum): SpotifyTrack;
  static from(track: SpotifyApiTrack): SpotifyTrack;
  static from(track: SpotifyApiPlaylistTrack): SpotifyTrack;
  static from(track: SpotifyApiPlaylistTrack | SpotifyApiTrack): SpotifyTrack;
  static from(track: any) {
    if (track.is_local) {
      return new NullTrack(track.track.name, 'Unsupported local spotify track');
    } else {
      return new SpotifyTrack(track);
    }
  }

  constructor(track: SpotifyTrackSimplified, album: SpotifyTrackAlbum);
  constructor(track: SpotifyApiTrack);
  constructor(track: SpotifyApiPlaylistTrack | SpotifyApiTrack);
  constructor(track: SpotifyApiPlaylistTrack);
  constructor(
    track: any,
    album?: SpotifyTrackAlbum
  ) {
    const baseItem: SpotifyTrackSimplified = track.track ? track.track : track;

    if ((baseItem as SpotifyApiTrack).album) {
      var item = baseItem as SpotifyApiTrack;
      super(
        // we throw on null later in the constructor
        item.id, 
        Source.Spotify,
        decodeHtml(item.name),
        item.artists?.length ? item.artists[0].name : '',
        item.album ? item.album.name : '',
        item.duration_ms,
        '',
        item.album 
          ? item.album.release_date.valueOf() 
          : track.added_at.valueOf(),
      );
    } else {
      
      super(
        // we throw on null later in the constructor
        baseItem.id, 
        Source.Spotify,
        decodeHtml(baseItem.name),
        baseItem.artists?.length ? baseItem.artists[0].name : '',
        album ? album.name : '',
        baseItem.duration_ms,
        '',
        album 
          ? album.release_date.valueOf() 
          : track.added_at.valueOf(),
      );
    }
  }
}
