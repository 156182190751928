import classNames from 'classnames';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, ContextMenuTriggerProps } from 'react-contextmenu';
import { Link } from 'react-router-dom';
import AccessibleElement from '../../../gUtilities/components/accessibleElement';
import Portal from '../../../gUtilities/portal';
import Playlist from '../../../models/playlist/playlist';
import { DropboxContext } from '../../../services/dropbox/dropboxService';
import { GoogleApiContext } from '../../../services/gapi/gapiService';
import { createAddTracksToLibrary } from '../../../services/library/addTracksToPlaylistThenLibrary';
import { LibraryContext } from '../../../services/library/libraryService';
import { OneDriveContext } from '../../../services/oneDrive/oneDriveService';
import { SmallDeviceContext, SmallDeviceShow } from '../../../services/smallDevices/smallDeviceService';
import { SpotifyContext } from '../../../services/spotify/spotifyService';
import { Context } from '../../../services/_globalContext/context';
import Constants from '../../../settings';
import { preventDefaultStopPropogation } from '../../../utilities/preventDefault';
import FadilaMenuItem from '../../common/contextMenu/fadilaMenuItem';
import s from './playlistRow.module.scss';
import { baseSupportedDragTypes, handleDropCommon } from './playlistRowCommon';

interface Props {
  playlist: Playlist;
  selected: boolean;
  setCurrentPlaylist?(val: string): void;
  portalTarget?: HTMLElement;
}

export default React.memo(function LibraryPlaylistRow({
  playlist,
  selected,
  setCurrentPlaylist,
  portalTarget,
}: Props) {

  const inLibraryComponent = !!setCurrentPlaylist;

  const supportedDropTypes: string[] = baseSupportedDragTypes;

  const ctx = useContext(Context);
  const smallDevSvc = useContext(SmallDeviceContext);
  const libCtx = useContext(LibraryContext);
  const gapiCtx = useContext(GoogleApiContext);
  const spotifyCtx = useContext(SpotifyContext);
  const oneDriveCtx = useContext(OneDriveContext);
  const dbxCtx = useContext(DropboxContext);

  const listElRef = useRef<HTMLLIElement>(null);

  const [isHoverDrop, setIsHoverDrop] = useState(false);

  const addTracksToLibrary = createAddTracksToLibrary(ctx, libCtx);

  const onDragOver = (ev: React.DragEvent<HTMLLIElement>) => {

    if (ev.dataTransfer.types.some(x => supportedDropTypes.includes(x))) {

      ev.preventDefault();

      if (listElRef.current) {

        setIsHoverDrop(true);
        ev.dataTransfer.dropEffect = 'copy';
      }
    }
  };
  const onDragLeave = (_ev: React.DragEvent<HTMLLIElement>) => {
    console.debug('onDragLeave');
    setIsHoverDrop(false);
  };
  const onDrop = (
    ev: React.DragEvent<HTMLLIElement>,
  ) => {
    console.debug('onDrop');

    if (ev.dataTransfer.types.some(x => supportedDropTypes.includes(x))) {
      ev.preventDefault();

      handleDropCommon(
        ctx,
        libCtx,
        gapiCtx,
        spotifyCtx,
        oneDriveCtx,
        dbxCtx,
        () => null,
        addTracksToLibrary(),
        ev,
      );

      setIsHoverDrop(false);
    }
  };

  const onActivate = useCallback(function () {
    if (inLibraryComponent) {
      setCurrentPlaylist!(playlist.id)
      smallDevSvc.setState(SmallDeviceShow.Main);
    }
  }, [smallDevSvc, inLibraryComponent, setCurrentPlaylist, playlist]);

  const contextTrigger = useRef<React.Component<ContextMenuTriggerProps, any, any>>(null);
  const toggleMenu = useCallback((e: React.MouseEvent) => {
    if (contextTrigger.current) {
      (contextTrigger.current as any).handleContextClick(e);
    }
  }, []);

  return (
    <AccessibleElement
      ref={listElRef}
      onActivate={onActivate}

      onDragEnter={(ev: React.DragEvent) => ev.preventDefault()}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}

      className={classNames({
        [s.row]: true,
        [s.library]: true,
        [s.selected]: selected,
        [s.hoverDrop]: isHoverDrop,
        [s.spacedFlexContainer]: inLibraryComponent,
      })}
    >
      {(() => {
        const rowBody = (<>
          <ContextMenuTrigger
            ref={contextTrigger}
            id={playlist.id}
            holdToDisplay={-1}
            disableIfShiftIsPressed={true}
            attributes={{
              className: s.rowBody
            }}
          >
            {playlist.name}
          </ContextMenuTrigger>
          <button
            className={classNames(s.menuBtn, 'hidden-control')}
            onClick={toggleMenu}
          >
            ☰
          </button>
        </>);
        
        return inLibraryComponent
          ? rowBody
          :
          <Link
            to={`/app/library/${Constants.LibraryId}`}
            className={classNames('no-styles-link', s.spacedFlexContainer)}
          >
            {rowBody}
          </Link>
      })()}

      <Portal portalTarget={portalTarget}>
        <ContextMenu id={playlist.id}>
          <FadilaMenuItem
            onClick={preventDefaultStopPropogation(() => libCtx.resetLibrary())}
            attributes={{
              className: s.deleteMenuItem,
            }}
          >
            Reset Library
          </FadilaMenuItem>
        </ContextMenu>
      </Portal>
    </AccessibleElement>
  );
});
