import React from 'react';
import Track from '../../../models/track';
import { ItemRendererData } from '../../common/itemRow/itemRowPropsAndTypes';
import InfiniteLoadingRow from '../../common/virtualized/infiniteLoadingRow';

const WindowedTrackItemRenderer = React.memo(function ({
  index,
  style,
  data,
}: ItemRendererData<Track>) {

  const {
    filteredAndSortedItems,

    ItemRow,

  } = data;

  const {
    dragType,

    columnScope,
    portalTarget,
    moveable,
    moveDescending,

    handlePlayTrack,
    removeItems,

    selectedItemsRef,
    handleToggleItem,
    handleShiftSelect,
    changeItemSelectionState,

    clearSelected,

  } = data;

  const item = filteredAndSortedItems[index];

  return moveable && index === filteredAndSortedItems.length
    ?
    <></>
    : item === undefined
      ?
      <InfiniteLoadingRow style={style} />
      :
      <div style={style}>
        <ItemRow
          index={index}
          track={item}
          dragType={dragType}

          isSelected={selectedItemsRef.current.has(item)}
          columnScope={columnScope}
          portalTarget={portalTarget}

          moveable={moveable}
          moveDescending={moveDescending}

          handlePlayTrack={handlePlayTrack}
          removeItems={removeItems}

          selectedItemsRef={selectedItemsRef}
          handleToggleItem={handleToggleItem}
          changeItemSelectionState={changeItemSelectionState}
          handleShiftSelect={handleShiftSelect}
          clearSelected={clearSelected}
        />
      </div>
});

export default WindowedTrackItemRenderer;
