export default {
  has: (str?: string) => str?.startsWith('audio') || str?.startsWith('video') || false,
};
// export default new Set([
//   'video/webm',
//   'video/mp4',
//   'audio/webm',
//   'audio/ogg',
//   'audio/vorbis',
//   'video/ogg',
//   'application/ogg',
//   'audio/mpeg',
//   'audio/wave',
//   'audio/wav',
//   'audio/x-wav',
//   'audio/x-pn-wav',
//   'audio/flac',
//   'audio/x-flac',
//   'audio/mp3',
//   'audio/m4a',
//   'audio/x-m4a',
//   'audio/mp4',
//   'audio/basic',
//   'audio/L24',
//   'audio/mid',
//   'audio/x-aiff',
//   'audio/x-mpegurl',
//   'audio/vnd.rn-realaudio',
//   'audio/vnd.wav',
// ]);
