import { DriveItem } from '@microsoft/microsoft-graph-types';
import classNames from 'classnames';
import fileSize from 'filesize';
import React from 'react';
import { DragType } from '../../models/dragTypes';
import { isAudioFile } from '../../models/fileExtensions';
import OneDriveTrack from '../../models/track/oneDriveTrack';
import PlaylistColumns from '../../services/columnSettings/playlistColumns';
import linkEncodeUri, { oneDriveEncodeUri } from '../../utilities/preEncodeUri';
import CloudProviderFileFolderRow from '../common/cloudProviderCommon/cloudProviderFileFolderRow';
import useColumnSettings from '../common/hooks/useColumnSettings';
import s from '../common/itemRow/itemRow.module.scss';
import { CloudItemRendererData } from '../common/itemRow/itemRowPropsAndTypes';
import TrackItemRow from '../playlist/playlistItems/trackItemRow';

export default React.memo(function OneDriveItemRenderer({
  index,
  style,
  data,
}: CloudItemRendererData<DriveItem, DriveItem, OneDriveTrack>) {

  const {
    items,
    parentFolderPath,
  } = data;

  const {
    columnScope,

    dragType,

    handlePlayTrack,

    selectedItems,
    selectedItemsRef,
    handleToggleItem,
    changeItemSelectionState,
    handleShiftSelect,
    clearSelected,

    addFolderToPlaylist,

    portalTarget,
  } = data;

  const { columnSettings, } = useColumnSettings(columnScope);

  const indexBumpAmount = 1 + (parentFolderPath ? 1 : 0);

  const driveItem = items[index - indexBumpAmount]!; // We check undefined in base

  if (driveItem.audio || isAudioFile(driveItem.name || '')) {
    const track = OneDriveTrack.from(driveItem);

    return (
      <div style={style}>
        <TrackItemRow
          key={driveItem.id}
          index={index}

          columnScope={columnScope}
          extraColumns={
            <>
              {columnSettings && columnSettings['size'] &&
                <div
                  style={{ width: columnSettings['size'].width }}
                  className={classNames(s.rowCell, {
                    [s.rightAlignCell]: PlaylistColumns.size.alignRight
                  })}
                >
                  {driveItem.size}
                </div>
              }
            </>
          }
          isSelected={selectedItems.current.has(driveItem)}

          track={track}

          handlePlayTrack={() => handlePlayTrack(driveItem)}

          dragType={dragType}

          selectedItemsRef={selectedItemsRef}
          handleToggleItem={() => handleToggleItem(driveItem)}
          changeItemSelectionState={
            (_, selectState, removePrevious) => changeItemSelectionState(driveItem, selectState, removePrevious)}
          handleShiftSelect={() => handleShiftSelect(driveItem)}
          clearSelected={clearSelected}

          portalTarget={portalTarget}
        />
      </div>
    );
  } else {
    return (
      <div style={style}>
        <CloudProviderFileFolderRow
          key={driveItem.id}
          index={index}
          isFolder={!!driveItem.folder}

          name={driveItem.name!}
          itemId={driveItem.id!}
          size={driveItem.size
            ? fileSize(driveItem.size!)
            : undefined}
          path={driveItem.folder && driveItem.name
            ? `${oneDriveEncodeUri(window.location.pathname)}/${linkEncodeUri(driveItem.name)}`
            : undefined}

          dragType={DragType.OneDriveFolder}
          columnScope={columnScope}

          addFolderToPlaylist={addItems => _ => addFolderToPlaylist(driveItem)(addItems)}
          portalTarget={portalTarget}
        />
      </div>
    );
  }
});
