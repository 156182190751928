/**
 * Erronous track lengths
 */
enum TrackLength {
  /**
   * F.x. a youtube video length that has not been retrieved
   */
  NOT_READY = -2,
  /**
   * Erronous
   */
  UNAVAILABLE,
  /**
   * No length, unfinished media file?
   */
  NONE,
}

export default TrackLength;
