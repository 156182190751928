import { parse, toSeconds } from 'iso8601-duration';
import decodeHtml from '../../utilities/decodeHtml';
import { DragType } from '../dragTypes';
import YouTubePlaylistItemWithDuration from '../gapi/gapiPlaylistItemExtensions';
import Source from '../source';
import Track from './track';

export default class YouTubeTrack extends Track {
  static from(playlistItem: YouTubePlaylistItemWithDuration | gapi.client.youtube.SearchResult) {
    return new YouTubeTrack(playlistItem);
  }
  constructor(
    playlistItem: YouTubePlaylistItemWithDuration | gapi.client.youtube.SearchResult,
  ) {
    super(
      // we throw on null later in the constructor
      (playlistItem as gapi.client.youtube.PlaylistItem).contentDetails
        ? (playlistItem as gapi.client.youtube.PlaylistItem).contentDetails!.videoId!
        : (playlistItem as gapi.client.youtube.SearchResult).id!.videoId!, 
      Source.YouTube,
      // Our youTube 'part' parameter ensures we receive the title
      decodeHtml(playlistItem.snippet!.title!),
      '',
      '',
      -2,
      '',
      playlistItem.snippet!.publishedAt 
        ? Date.parse(playlistItem.snippet!.publishedAt) 
        : undefined,
    );

    const searchResult = playlistItem as gapi.client.youtube.SearchResult;
    if (searchResult?.id?.kind === DragType.YouTubeChannel || 
      searchResult?.id?.kind === DragType.YouTubePlaylist) {
        throw new Error('Unable to create Track from Playlist/Channel');
    }

    if ((playlistItem as YouTubePlaylistItemWithDuration).duration) {
      var durationS = toSeconds(
        parse(
          (playlistItem as YouTubePlaylistItemWithDuration).duration!
        )
      );
      this.length = durationS * 1000;
    }
  }

  /**
   * Cleanup video length fetches
   */
  dispose = () => {

  }
}
