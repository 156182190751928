import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

export enum SmallDeviceShow {
  Main,
  Navigation,
  Playlists,
  /** Users playlists in YouTube/Spotify/SoundCloud */
  StreamingPlaylists,
  Player,
}

export interface ISmallDeviceContext {
  state: SmallDeviceShow;
  setState(val: SmallDeviceShow): void;
}
export const SmallDeviceContext = React.createContext({} as ISmallDeviceContext);

interface Props {
  children: React.ReactNode;
}

export default function SmallDeviceService({
  children
}: Props) {

  const location = useLocation();
  const [state, setState] = useState(SmallDeviceShow.Main);

  useEffect(function resetStateOnNavigation() {
    setState(SmallDeviceShow.Main);
  }, [location.pathname])
  const ctxValue = useMemo(() => ({
    state,
    setState,
  }), [
    state,
    setState,
  ]);

  return (
    <SmallDeviceContext.Provider value={ctxValue}>
      {children}
    </SmallDeviceContext.Provider>
  );
}
