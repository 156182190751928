import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as Collapse } from '../../assets/icomoon/circle-down.svg';
import { ReactComponent as Open } from '../../assets/icomoon/circle-up.svg';
import { ReactComponent as RepeatAll } from '../../assets/icomoon/loop2.svg';
import { ReactComponent as Repeat } from '../../assets/icomoon/spinner11.svg';
import RepeatState from '../../models/repeatState';
import Source from '../../models/source';
import { TrackLength } from '../../models/track';
import { ActivePlaylistContext } from '../../services/activePlaylist/activePlaylist';
import { DropboxContext } from '../../services/dropbox/dropboxService';
import { GoogleApiContext } from '../../services/gapi/gapiService';
import { LibraryContext } from '../../services/library/libraryService';
import * as OneDriveApi from '../../services/oneDrive/oneDriveApi';
import { OneDriveContext } from '../../services/oneDrive/oneDriveService';
import { ScreenDetectionContext, ScreenSize } from '../../services/screenDetection/screenDetection';
import { SmallDeviceContext, SmallDeviceShow } from '../../services/smallDevices/smallDeviceService';
import { SpotifyContext } from '../../services/spotify/spotifyService';
import { Context } from '../../services/_globalContext/context';
import Constants from '../../settings';
import ErrorBoundary from '../common/errorBoundary/errorBoundary';
import SpotifyPlayerWrapper from '../spotify/spotifyPlayerWrapper';
import s from './player.module.scss';

interface Props {
}

export enum PlayerSize {
  Hide = 'hidePlayer',
  Small = 'small',
  Medium = 'medium',
  // Large = 'large',
}

// eslint-disable-next-line no-empty-pattern
export default function Player({
}: Props) {

  const ctx = useContext(Context);
  const screenSize = useContext(ScreenDetectionContext);
  const smallDevSvc = useContext(SmallDeviceContext);
  const libCtx = useContext(LibraryContext);
  const {
    currentTrack,
    next,
    prev,
    setActivePlaylist,
    currentPlaylistIndex,
    value: activePlaylistValue,
  } = useContext(ActivePlaylistContext);
  const gapiCtx = useContext(GoogleApiContext);
  const oneDriveCtx = useContext(OneDriveContext);
  const dbxCtx = useContext(DropboxContext);
  const spotifyCtx = useContext(SpotifyContext);

  /**
   * Take care when using the track object.
   * For the spotify and youtube components, it is possible to play tracks that are not
   * in the users library.
   * 
   * In these cases getLibraryTrack returns NullTrack.
   * The Player handles this gracefully
   * and instead uses currentTrack when possible and still plays the track.
   */
  var track = libCtx.getLibraryTrack(currentTrack);
  /**
   * Currently we only mark tracks missing when get track url from cloud drive fails
   */
  const markTrackMissingOnError = useCallback(function markTrackMissingOnError() {
    if (track) {
      libCtx.setTrackMissing(track);
    }
    next();
  }, [libCtx, track, next]);
  const resetTrackMissing = useCallback(function resetTrackMissing() {
    if (track) {
      libCtx.setTrackMissing(track, false);
    }
  }, [libCtx, track]);

  const audioEl = useRef<HTMLAudioElement>(null);

  const [ytIFrameSrcUrl, setYtIFrameSrcUrl] = useState<string | undefined>(undefined);

  useEffect(function setAudioElEventListener() {

    const curAudioEl = audioEl.current;
    if (curAudioEl) {
      curAudioEl.addEventListener('ended', next)
    }

    return function cleanup() {
      if (curAudioEl) {
        curAudioEl.removeEventListener('ended', next);
      }
    };
  }, [audioEl, next]);

  useEffect(function listenForAudioElementErrors() {
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/MediaError
     * @param ev 
     */
    function audioElErrorListener(ev: ErrorEvent) {
      if (audioEl.current!.error) {
        switch (audioEl.current!.error.code) {
          case audioEl.current!.error.MEDIA_ERR_ABORTED: // 1
            console.debug('MEDIA_ERR_ABORTED', audioEl.current!.error, ev);
            break;
          case audioEl.current!.error.MEDIA_ERR_NETWORK: // 2
            console.debug('MEDIA_ERR_NETWORK - likely an expired OneDrive temporary link. Is handled elsewhere.', audioEl.current!.error, ev);
            break;
          case audioEl.current!.error.MEDIA_ERR_DECODE: // 3
            markTrackMissingOnError();
            console.debug('MEDIA_ERR_DECODE - unsupported track', audioEl.current!.error, ev);
            break;
          case audioEl.current!.error.MEDIA_ERR_SRC_NOT_SUPPORTED: // 4
            markTrackMissingOnError();
            console.debug(
              'MEDIA_ERR_SRC_NOT_SUPPORTED - possible 404, skipping track',
              audioEl.current!.error,
              ev,
            );
            break;
        }
      }
    }

    const curAudioEl = audioEl.current;
    if (curAudioEl) {
      curAudioEl.addEventListener('error', audioElErrorListener);
    }

    return function cleanup() {
      if (curAudioEl) {
        curAudioEl.removeEventListener('error', audioElErrorListener);
      }
    };
  }, [next, markTrackMissingOnError]);

  useEffect(function play() {

    const curAudioEl = audioEl.current;
    let halt = false;
    let playPromise: Promise<void>;

    /**
     * Handles errors thrown by the Audio element play() promise
     * DOMException's are usually when user navigates too quickly
     * @param err 
     */
    function catchAudioElementPlayErrors(err: any) {
      if (!(err instanceof DOMException)) {
        ctx.callSnackbar(err);
      } else {
        console.debug(err, 'Playback likely aborted due to track change');
      }
    }

    async function handleOneDriveExpired() {

      if (curAudioEl && curAudioEl.error
        && curAudioEl.error.code === curAudioEl.error.MEDIA_ERR_NETWORK) {

        const trackWithFreshUrl = await OneDriveApi.getItem
          (oneDriveCtx.graphClient)
          (ctx.callSnackbar)
          (currentTrack.id);

        if (curAudioEl && halt === false) {
          // FIX: If i start working with MediaStreams
          // I should be able to get a batch of data first
          // and then switch sources when ready
          const curTime = curAudioEl.currentTime;
          curAudioEl.src = (trackWithFreshUrl as any)['@microsoft.graph.downloadUrl'];
          curAudioEl.currentTime = curTime;

          playPromise = curAudioEl.play();
          if (playPromise) {
            playPromise.catch(catchAudioElementPlayErrors);
          }
        }
      }
    }
    async function handleGapiExpired() {

      if (curAudioEl && curAudioEl.error
        && curAudioEl.error.code === curAudioEl.error.MEDIA_ERR_NETWORK) {

        const fileUrl = await gapiCtx.getDriveFileUrl(currentTrack.id, undefined, true);

        if (curAudioEl && halt === false) {
          const curTime = curAudioEl.currentTime;
          curAudioEl.src = fileUrl;
          curAudioEl.currentTime = curTime;

          playPromise = curAudioEl.play();
          if (playPromise) {
            playPromise.catch(catchAudioElementPlayErrors);
          }
        }
      }
    }

    function handleSpotifyStateChange(state: Spotify.PlaybackState) {
      if (currentTrack.trackType === Source.Spotify && state
        && state.duration - state.position < 1000 && state.paused) {

        next();
      }
    }

    function doSetPlayerSizeSmall() {
      if (ctx.playerSize !== PlayerSize.Small 
        && screenSize !== ScreenSize.Small 
        && !ctx.settings.lockPlayerSizeMedium) {

        ctx.setPlayerSize(PlayerSize.Small);
      }
    }

    document.title
      = `Fadila${(track.type !== Source.Null && track.title
        ? ` - ${track.title}`
        : '')
      }`;

    if (currentTrack) {

      switch (currentTrack.trackType) {

        case Source.YouTube:

          setYtIFrameSrcUrl(`https://www.youtube.com/embed/${currentTrack.id}?enablejsapi=1`);

          if (screenSize === ScreenSize.Small) {
            smallDevSvc.setState(SmallDeviceShow.Player);
          }
          else if (ctx.playerSize !== PlayerSize.Medium) {
            ctx.setPlayerSize(PlayerSize.Medium);
          }

          // The YouTube play effect then picks up the changed src url
          // This way the YT.Player is only created after the react re-render with updated src
          break;

        case Source.OneDrive:

          OneDriveApi.getItem
            (oneDriveCtx.graphClient)
            (ctx.callSnackbar)
            (currentTrack.id).then(driveItem => {

              resetTrackMissing();

              // If api call was successful and context state is not authenticated
              // user must have been prompted to authenticate in relation to this call.
              // Authorization in this way outside the normal flow needs to be acknowledged
              // by the oneDrive context.
              if (!oneDriveCtx.isAuthenticated) {
                oneDriveCtx.setIsAuthenticated();
              }

              if (curAudioEl && halt === false) {
                curAudioEl.src = (driveItem as any)[Constants.MsGraphDlUrlProp];
                playPromise = curAudioEl.play();
                if (playPromise) {
                  playPromise.catch(catchAudioElementPlayErrors);
                }

                doSetPlayerSizeSmall();

                curAudioEl.addEventListener('error', handleOneDriveExpired);

                // Reset the graph downloadUrl
                // This was needed since the msgraph dl url has a maximum lifetime of circa 1h

                // We might wanna listen after an error and only act on that instead of this
                // -- update, we now do

                // I could try setting source to same broken url and testing event listeners
                // ..targetting that
                // Or not, we can listen to onerror on audioEl in the above case
                // however the event has no useful information about the error
                // this event listener would therefore apply to media of all types
                // and is probably not the best solution for this app
                // interval.current = setInterval(async () => {
                //   const isPaused = curAudioEl.paused;

                //   OneDriveApi.getItem
                //     (oneDriveCtx.graphClient)
                //     (ctx.callSnackbar)
                //     (track.id!).then(trackWithFreshUrl => {

                //       if (halt === false) {
                //         // FIX: When i start working with MediaStreams
                //         // I should be able to get a batch of data first
                //         // and then switch sources when ready
                //         const curTime = curAudioEl.currentTime;
                //         curAudioEl.src = (trackWithFreshUrl as any)['@microsoft.graph.downloadUrl'];
                //         curAudioEl.currentTime = curTime;

                //         if (isPaused === false) {
                //           curAudioEl.play();
                //         }
                //       }
                //     });
                // }, OneDriveApi.OneDriveDownloadUrlTimeoutMs);
              }
            }, markTrackMissingOnError);
          break;

        case Source.GoogleDrive:

          function playGDriveTrack() {

            gapiCtx.getDriveFileUrl(currentTrack.id, undefined, true)
              .then(fileUrl => {

                resetTrackMissing();

                if (curAudioEl && halt === false) {

                  curAudioEl.src = fileUrl;
                  playPromise = curAudioEl.play();
                  if (playPromise) {
                    playPromise.then(console.log, catchAudioElementPlayErrors);
                  }

                  doSetPlayerSizeSmall();

                  curAudioEl.addEventListener('error', handleGapiExpired);
                }
              }, markTrackMissingOnError);
          }

          if (!gapiCtx.gapiAuthorized) {
            gapiCtx.authorize().then(playGDriveTrack);
          } else {
            playGDriveTrack();
          }
          break;

        case Source.Dropbox:

          function playDbxTrack() {
            dbxCtx.dbx.filesGetTemporaryLink({ path: currentTrack.id })
              .then(res => {

                resetTrackMissing();

                if (curAudioEl && halt === false) {

                  curAudioEl.src = res.link;
                  playPromise = curAudioEl.play();
                  if (playPromise) {
                    playPromise.catch(catchAudioElementPlayErrors);
                  }

                  doSetPlayerSizeSmall();
                }
              }, markTrackMissingOnError);
          }

          if (!dbxCtx.account) {
            dbxCtx.authorize().then(playDbxTrack);
          }
          else {
            playDbxTrack();
          }

          break;

        case Source.Spotify:
          async function playSpotifyTrack() {
            if (spotifyCtx.player) {
              spotifyCtx.player.addListener('player_state_changed', handleSpotifyStateChange)
            }
            doSetPlayerSizeSmall();

            try {
              // We already checked for access token before calling playSpotifyTrack
              const result = await spotifyCtx.spotifyApi!.playTrack(
                spotifyCtx.playerId!,
                currentTrack.id,
              );
              
              resetTrackMissing();

              return result;
            }
            catch (err) {
              markTrackMissingOnError();
            }
          }

          if (!spotifyCtx.playerId) {
            ctx.callSnackbar('Spotify Player not ready! Please try again later.');
            return;
          }
          if (!spotifyCtx.accessToken) {
            spotifyCtx.authorize().then(playSpotifyTrack);
          } else {
            playSpotifyTrack();
          }

          break;

        case Source.Podcast:
          if (curAudioEl) {

            curAudioEl.src = track.id;
            playPromise = curAudioEl.play();
            // Browser workaround
            if (playPromise) {
              playPromise.catch(catchAudioElementPlayErrors);
            }

            doSetPlayerSizeSmall();
          }

          break;

        case Source.Null:
          // NullTrack with id Null is used internally to signify absence of track to play
          // other id values signify an erronous track that should be skipped
          if (currentTrack.id !== 'Null') {
            next();
          }
          break;

        default:
          ctx.callSnackbar('Unable to play, unknown track type!');
      }
    }

    return function cleanup() {

      if (gapiCtx.ytPlayer.current) {
        try {
          gapiCtx.ytPlayer.current.pauseVideo();
        } catch (err) {
          console.error(err);
        }

        setYtIFrameSrcUrl(undefined);
      }

      // This is intended to pause Audio element playback 
      // when switching to a youtube track
      if (playPromise) {

        if (curAudioEl) {
          curAudioEl.pause();
        }
      }
      if (curAudioEl) {
        curAudioEl.removeEventListener('error', handleOneDriveExpired);
        curAudioEl.removeEventListener('error', handleGapiExpired);
      }

      if (spotifyCtx.player) {
        spotifyCtx.player.removeListener('player_state_changed', handleSpotifyStateChange);
        spotifyCtx.player.pause().catch(console.error);
      }

      halt = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrack]);

  /** Create YouTube player after iframe src url updates */
  useEffect(function youtubePlay() {
    /** Update YT track length */
    function updateTrackLength() {
      // Track is null when not playing a library track
      if (track.type !== Source.Null && track.length === TrackLength.NOT_READY
        && gapiCtx.ytPlayer.current) {
        track.length = gapiCtx.ytPlayer.current.getDuration() * 1000;

        // Trigger re-render to display new length
        setActivePlaylist(
          activePlaylistValue.tracks,
          currentPlaylistIndex,
        );
      }
    }

    function handleYtTrackEnd(ev: YT.OnStateChangeEvent) {
      if (ev.data === YT.PlayerState.ENDED) {
        // call playlist next to handle finding the next track in line
        // and changing to that track

        next();
      }
    }

    /**
     * https://developers.google.com/youtube/iframe_api_reference#Events
     * onError
     *  This event fires if an error occurs in the player. The API will pass an event object to the event listener function. That object's data property will specify an integer that identifies the type of error that occurred. Possible values are:
     *  2 – The request contains an invalid parameter value. For example, this error occurs if you specify a video ID that does not have 11 characters, or if the video ID contains invalid characters, such as exclamation points or asterisks.
     *  5 – The requested content cannot be played in an HTML5 player or another error related to the HTML5 player has occurred.
     *  100 – The video requested was not found. This error occurs when a video has been removed (for any reason) or has been marked as private.
     *  101 – The owner of the requested video does not allow it to be played in embedded players.
     *  150 – This error is the same as 101. It's just a 101 error in disguise!
     * @param ev 
     */
    function handleYTVideoError(ev: YT.OnErrorEvent) {
      switch (ev.data) {
        case 2:
          ctx.callSnackbar(`YouTube responded 'The request contained an invalid parameter value'`);
          break;
        case 5:
          ctx.callSnackbar(`The requested content cannot be played in an HTML5 player.`);
          break;
        case 100:
          ctx.callSnackbar(`The video requested was not found.`);
          break;
        case 101:
          ctx.callSnackbar(`The owner of the requested video does not allow it to be played in embedded players.`);
          break;
        case 150:
          ctx.callSnackbar('Video blocked in country');
          break;
        default:
          ctx.callSnackbar(`YouTube API error ${ev.data}`);
          break;
      }

      next();
    }

    if (ytIFrameSrcUrl) {
      gapiCtx.playVideo(
        updateTrackLength,
        handleYtTrackEnd,
        handleYTVideoError,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ytIFrameSrcUrl]);


  // function togglePlayerSize(curSize: PlayerSize) {
  //   if (curSize === PlayerSize.Small) {
  //     ctx.setPlayerSize(PlayerSize.Medium);
  //   } else if (curSize === PlayerSize.Medium) {
  //     ctx.setPlayerSize(PlayerSize.Small);
  //   }
  // }

  /**
   * Small device note: Will navigate from full screen now playing view to Main
   */
  function hidePlayer() {
    smallDevSvc.setState(SmallDeviceShow.Main);
    ctx.setPlayerSize(PlayerSize.Hide);
  }
  function showPlayer() {
    if (ctx.settings.lockPlayerSizeMedium) {
      ctx.setPlayerSize(PlayerSize.Medium);
      return;
    }
    if (currentTrack) {
      switch (currentTrack.trackType) {
        case Source.YouTube:
          ctx.setPlayerSize(PlayerSize.Medium);
          break;

        default:
          ctx.setPlayerSize(PlayerSize.Small);
          break;
      }
    }
    else {
      ctx.setPlayerSize(PlayerSize.Small);
    }
  }

  return (
    <ErrorBoundary>
      {ctx.playerSize === PlayerSize.Hide && screenSize !== ScreenSize.Small &&
        <div className={s.hiddenPlayerControls}>
          <button
            onClick={showPlayer}
            className={s.toggleSize}
          >
            <Open />
          </button>
        </div>
      }
      <figure className={classNames(s.player, s[ctx.playerSize], {
        [s.smallDeviceShow]: smallDevSvc.state === SmallDeviceShow.Player,
      })}>
        <section className={s.controls}>
          {/* {track.type === Source.Null &&
            <AddToPlaylist
              playlistItemOrItems={currentTrack}
            />
          } */}

          <button
            className={classNames({
              "button": true,
              "hollow": ctx.settings.repeatState === RepeatState.None,
              // "": ctx.repeatState === RepeatState.All,
            })}
            onClick={ctx.toggleRepeat}
          >
            {ctx.settings.repeatState === RepeatState.All
              ? <RepeatAll title="Repeat All" />
              : ctx.settings.repeatState === RepeatState.None
                ? <Repeat title="Repeat None" />
                : /*ctx.repeatState === RepeatState.One && */ <Repeat title="Repeat" />}
          </button>

          <button
            // onClick={() => togglePlayerSize(ctx.playerSize)}
            onClick={hidePlayer}
            className={s.toggleSize}
          >
            {/* {ctx.playerSize === PlayerSize.Small ? '˄' : '˅'} */}
            <Collapse />
          </button>
        </section>

        {track.type !== Source.YouTube &&
          <h4>{
            track.type === Source.Null
              ? ''
              : track.title + (track.artist ? ` - ${track.artist}` : '')
          }</h4>
        }

        <div className={s.playbackControlsContainer}>
          <button onClick={prev} className={s.trackNavigation}>
            &#60;
        </button>

          <iframe
            ref={gapiCtx.playerEl}
            title="youtube-embed"
            className={
              currentTrack.trackType !== Source.YouTube ||
                ctx.playerSize === PlayerSize.Small
                ? 'hide'
                : ''
            }
            width="640" height="360"
            frameBorder="0"
            allow="autoplay"
            src={ytIFrameSrcUrl}
            allowFullScreen
          />

          <audio
            ref={audioEl}
            // if track is not a youtubetrack show the audio element
            className={classNames({
              [s.audio]: true,
              'hide': currentTrack.trackType === Source.YouTube
                || currentTrack.trackType === Source.Spotify
            })}
            controls
            crossOrigin='anonymous'
          />

          <SpotifyPlayerWrapper
            className={classNames({
              [s.spotifyPlayer]: true,
              'hide': currentTrack.trackType !== Source.Spotify,
            })}
            playlistItem={currentTrack}
          />

          <button onClick={next} className={s.trackNavigation}>
            &#62;
          </button>

        </div>
      </figure>
    </ErrorBoundary >
  );
}
