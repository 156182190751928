import { Reader } from 'jsmediatags';
import XhrFileReader from 'jsmediatags/build2/XhrFileReader';
import { CallbackType, LoadCallbackType } from 'jsmediatags/types';

export default class XhrFileReaderWithLength extends XhrFileReader {

  static urlToSizeMap: { 
    [index: string]: number 
  } = { };

  static read(url: string, callbacks: CallbackType) {
    new Reader(url).setFileReader(XhrFileReaderWithLength)
      .setTagsToRead([
        // some id3v2 and mp4 tags
        "TIT2", "TT2", '©nam', // title
        "TPE1", "TP1", '@ART', 'aART', '©wrt', // artist
        "TALB", "TAL", '©alb', // album
        "TYER", "TYE", '©day', // year
        "COMM", "COM", '©cmt', // comment
        "TRCK", "TRK", 'trkn', // track number
        "TCON", "TCO", '©gen', 'gnre', // genre
        "USLT", "ULT", '©lyr', // lyrics
        'TPA', // album
        'CNT', // play counter
        'cpil', // compilation
        'disk', 
        //  "TBP": "BPM (Beats Per Minute)",
        //  "TCM": "Composer",
        //  "TDA": "Date",
        //  "TEN": "Encoded by",
      ])
      .read(callbacks);
  }

  constructor(url: string) {
    super(url);

    if (XhrFileReaderWithLength.urlToSizeMap[this._url]) {
      this._size = XhrFileReaderWithLength.urlToSizeMap[this._url];
    }
  }

  _init(callbacks: LoadCallbackType) {
    if (this._size) {
      this._isInitialized = true;
      callbacks.onSuccess();
    } else {
      if (callbacks.onError)
        callbacks.onError({
          "type": "XhrFileReaderWithLength",
          "info": "Missing size, did you map the provided url to size? Set XhrFileReaderWithLength.urlToSizeMap before usage.",
        });
    }
  }
}
