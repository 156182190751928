import { debounce } from 'debounce';
import React, { MutableRefObject, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Playlist from '../../../models/playlist/playlist';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import Track from '../../../models/track/track';
import { ActivePlaylistContext } from '../../../services/activePlaylist/activePlaylist';
import { ColumnScope, TrackProperty } from '../../../services/columnSettings/playlistColumns';
import { LibraryContext } from '../../../services/library/libraryService';
import { isMac } from '../../../settings';
import { createFilterBy, createSortByProp, createSortByPropDescending, ISorter, sortByIndex, sortByIndexDescending } from '../../playlist/playlistSorters';
import useColumnSettings from './useColumnSettings';

interface Params<T> {
  lastSelectedItem: MutableRefObject<T | undefined>;
  selectedItemsRef: MutableRefObject<Set<T>>;
  setSelectedItems: React.Dispatch<SetStateAction<MutableRefObject<Set<T>>>>;
  playlist: Playlist;
  items: T[];
  moveableProp: boolean | undefined;
  columnScope: ColumnScope;
}
export default function useMoreSharedPlaylistMethods<T extends PlaylistItem | Track>({
  playlist,
  items,
  lastSelectedItem,
  selectedItemsRef,
  setSelectedItems,
  moveableProp,
  columnScope,
}: Params<T>) {

  const activePlaylist = useContext(ActivePlaylistContext);
  const { columnSettings } = useColumnSettings(columnScope);

  const libCtx = useContext(LibraryContext);

  if (columnSettings) {
    var defaultColumn = (Object.keys(columnSettings) as TrackProperty[])
      .find(col => columnSettings[col]?.default)
  }

  const updateFilter = useCallback(
    debounce<(val: string) => void>(
      function updateFilter(val: string) {

        setFilter(val);
      },
      150
    ),
    []);

  const sortByProp = useMemo(() => createSortByProp(libCtx), [libCtx]);
  const sortByPropDescending
    = useMemo(() => createSortByPropDescending(libCtx), [libCtx]);
  const [sortBy, setStateSortBy] = useState<ISorter<PlaylistItem | Track>>(defaultColumn 
    ? sortByProp(defaultColumn)
    : sortByIndex);
  const setSortBy = useCallback(function setSortBy(
    column: TrackProperty | 'index',
    descending?: true,
  ) {
    if (column === 'index') {
      if (descending) {
        setStateSortBy(sortByIndexDescending);
      }
      else {
        setStateSortBy(sortByIndex);
      }
    }
    else {
      if (descending) {
        setStateSortBy(sortByPropDescending(column))
      }
      else {
        setStateSortBy(sortByProp(column));
      }
    }

    // colCtx.setColumnDefault(column, columnScope);

  }, [sortByProp, sortByPropDescending]);

  const [filter, setFilter] = useState<string>('');
  const filterBy = useMemo(() => createFilterBy<T>(libCtx), [libCtx]);

  const filteredAndSortedTracks = useMemo(
    () => items.filter(filterBy(filter)).sort(sortBy),
    [items, filter, filterBy, sortBy]);

  const handlePlayTrack = useCallback(function (
    plItem: T,
  ) {
    const doPlay = true;

    if ((plItem as PlaylistItem).trackType) {
      activePlaylist.setActivePlaylist(
        filteredAndSortedTracks as PlaylistItem[],
        filteredAndSortedTracks.indexOf(plItem),
        doPlay,
      );
    }
    else {
      const filtered = (filteredAndSortedTracks as Track[]).map(PlaylistItem.from);

      // TrackPlaylists add all tracks to active playlist
      // regardless of filtering
      activePlaylist.setActivePlaylist(
        filtered,
        filtered.findIndex(x => x.id === plItem.id),
        doPlay,
      );
    }
  }, [activePlaylist, filteredAndSortedTracks])

  const prevPlaylistRef = useRef<Playlist>();
  /**
   * This triggers on component re-render, f.x. on playlist change
   */
  useEffect(function resetOnChangePlaylist() {
    if (prevPlaylistRef.current &&
      (prevPlaylistRef.current.id !== playlist.id)) {
      setFilter('');
      setSortBy('index');
      lastSelectedItem.current = undefined;

      selectedItemsRef.current.clear();
    }
  }, [playlist, selectedItemsRef, lastSelectedItem, setSortBy]);
  /**
   * Helps in cases of selected tracks deleted from library.
   */
  useEffect(function handleChangedItems() {
    if (prevPlaylistRef.current && prevPlaylistRef.current.id === playlist.id) {
      console.debug('Handling changed item');

      let didChange = false;

      selectedItemsRef.current.forEach(
        item => {
          if (!playlist.tracks.some(x => x.id === item.id) && selectedItemsRef.current.delete(item)) {
            didChange = true
          }
        });

      if (didChange) {
        setSelectedItems({
          current: selectedItemsRef.current,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlist.tracks]);
  useEffect(() => {
    prevPlaylistRef.current = playlist;
  });

  useEffect(function configureKeyboardShortcuts() {
    function listener(ev: KeyboardEvent) {
      if (document.activeElement?.tagName !== 'INPUT'
        && ev.key === 'a'
        && ((isMac && ev.metaKey) || (!isMac && ev.ctrlKey))) {

        setSelectedItems({
          current: new Set(filteredAndSortedTracks),
        });

        ev.stopPropagation();
        ev.preventDefault();
      }
    }

    document.addEventListener('keydown', listener);

    return () => document.removeEventListener('keydown', listener);

  }, [filteredAndSortedTracks, setSelectedItems]);

  const sortOnClickCreator = useCallback(function (propName: TrackProperty) {
    return function (ev: React.MouseEvent) {
      ev.stopPropagation();
      ev.preventDefault();

      if (sortBy.propName === propName) {
        return setSortBy(propName, true);
      } else {
        return setSortBy(propName);
      }
    };
  }, [sortBy, setSortBy]);

  const moveable = moveableProp &&
    // It's weird to reorder items when the playlist is statically sorted
    (sortBy.propName === 'index' || sortBy.propName === 'index-descending');

  return {
    sortBy,
    sortByProp,
    setSortBy,
    sortOnClickCreator,
    sortByPropDescending,

    filter,
    updateFilter,

    filteredAndSortedTracks,
    handlePlayTrack,

    moveable,
  };
}
