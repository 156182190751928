import React from 'react';

interface Props {
  style: React.CSSProperties;
}

export default function InfiniteLoadingRow({
  style,
}: Props) {

  return (
    <div style={style}>
      Loading...
      {/* <Spinner mini /> */}
    </div>
  );
}
