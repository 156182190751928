import React from 'react';

interface Props {

}

// eslint-disable-next-line no-empty-pattern
export default function Frontpage({

}: Props) {

  return (
    <article className="grid-container">
      <h3>Fadila music manager.</h3>
      <p>Fadila music manager intends to consolidate music collections spanning diverse service providers and cloud storage.</p>
      <p>
        Users today find their favorite tracks on various online services and 
        often have no way of creating a unified playlist containing these disparate tracks.
      </p>
      <p>Fadila solves this problem, creating one unified view of your entire online music collection.</p>
      <p>
        Despite being a fully featured media library solution, Fadila requires no server backend for most use cases.
      </p>
    </article>
  );
}
