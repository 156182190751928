import { array } from 'fp-ts/es6';
import { curry } from 'fp-ts/es6/function';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Playlist, { addItem, addItems, insertTrack, moveItem, nextItemIndex, prevItemIndex, removeItems } from '../../models/playlist/playlist';
import PlaylistItem from "../../models/playlistItem/playlistItem";
import Source from '../../models/source';
import NullTrack from '../../models/track/nullTrack';
import Constants from '../../settings';
import { loadPlaylist, setLsItem } from '../storage/localStorageService';
import { Context } from '../_globalContext/context';

const loadActivePlaylist = loadPlaylist(Constants.ActivePlaylistKey);
const persistActivePlaylistWrapper
  = curry(function wrapper(
    fn: (pl: Playlist) => void,
    pl: Playlist,
  ) {
    fn(pl);
    setLsItem(Constants.ActivePlaylistId)(pl);
  });

/** The real play method */
const createActivePlaylist
  = function (
    setPlaylist: React.Dispatch<React.SetStateAction<Playlist>>,
    setCurPlaylistItem: React.Dispatch<React.SetStateAction<number>>,
    setCurTrack: React.Dispatch<React.SetStateAction<PlaylistItem>>,
  ) {
    return function setActivePlaylist(
      tracks: PlaylistItem[],
      curPlItm: number = 0,
      play = false,
    ) {
      const playlist = new Playlist(
        Constants.ActivePlaylistName,
        tracks,
        Constants.ActivePlaylistId,
      );
      persistActivePlaylistWrapper(setPlaylist)(playlist);

      setCurPlaylistItem(curPlItm);

      if (play && playlist.tracks[curPlItm]) {
        setCurTrack(playlist.tracks[curPlItm]);
      }
    };
  };

export interface IActivePlaylistContext {
  value: Playlist;
  setActivePlaylist: ReturnType<typeof createActivePlaylist>;
  /**
   * Allows the current AP to be modified 'in place', keeping current track.
   * @param tracks 
   */
  updateActivePlaylist(tracks: PlaylistItem[]): void;
  /** 
   * The idea is that we can have a queued PlaylistItem not currently playing 
   * Maybe we do away with currentTrack, stop player autoplay and queue up current playlistItem
   * then call play on the player if applicable?
   */
  currentPlaylistIndex: number;
  setCurrentPlaylistItem: React.Dispatch<React.SetStateAction<number>>;

  addItem(playlistItem: PlaylistItem): void;
  addItems(playlistItems: PlaylistItem[]): void;
  insertTrack: ReturnType<typeof insertTrack>;
  removeItems: ReturnType<typeof removeItems>;
  clearPlaylist(): void;
  moveItem: ReturnType<typeof moveItem>;

  prev(): unknown;
  play(): unknown;
  next(): unknown;

  currentTrack: PlaylistItem;
  /**
   * Only used by youtube component
   */
  setCurrentTrack: React.Dispatch<React.SetStateAction<PlaylistItem>>;
}

export const ActivePlaylistContext = React.createContext({} as IActivePlaylistContext);

interface Props {
  children: React.ReactNode;
}
export default function ActivePlaylist({
  children,
}: Props) {

  console.debug('Rendering ActivePlaylist');
  const ctx = useContext(Context);
  const ctxRef = useRef(ctx);
  ctxRef.current = ctx;

  const [playlist, setPlaylist]
    = useState<Playlist>(() => Playlist.activeFrom([]));
  const [currentPlaylistItem, setCurrentPlaylistItem] = useState(-1);
  const [currentTrack, setCurrentTrack] = useState<PlaylistItem>(
    PlaylistItem.from(
      new NullTrack()
    )
  );

  useEffect(function localStorageLoad() {
    loadActivePlaylist()
      .map(setPlaylist);
  }, []);

  const updateActivePlaylist = useCallback(function (
    tracks: PlaylistItem[],
  ) {
    const playlist = new Playlist(
      Constants.ActivePlaylistName,
      tracks,
      Constants.ActivePlaylistId,
    );
    persistActivePlaylistWrapper(setPlaylist)(playlist);

    const curPlItm = currentTrack as PlaylistItem;
    if (curPlItm.trackType !== undefined
      && curPlItm.trackType !== Source.Null) {
      setCurrentPlaylistItem(tracks.findIndex(x => x === curPlItm));
    }
  }, [currentTrack]);

  const setActivePlaylist = createActivePlaylist(
    setPlaylist,
    setCurrentPlaylistItem,
    setCurrentTrack,
  );
  const ctxValue = useMemo(() => ({
    value: playlist,
    currentTrack,
    setCurrentTrack,
    currentPlaylistIndex: currentPlaylistItem,
    setCurrentPlaylistItem,

    setActivePlaylist,
    updateActivePlaylist,
    addItem: addItem(playlist)(persistActivePlaylistWrapper(setPlaylist)),
    addItems: addItems(playlist)(persistActivePlaylistWrapper(setPlaylist)),
    insertTrack: insertTrack(playlist, persistActivePlaylistWrapper(setPlaylist)),
    removeItems: removeItems(playlist, persistActivePlaylistWrapper(setPlaylist)),
    clearPlaylist: () => setPlaylist(Playlist.activeFrom([])),
    moveItem: moveItem(playlist, persistActivePlaylistWrapper(setPlaylist)),

    prev: () => prevItemIndex(playlist, currentPlaylistItem)
      .chain(idx => {
        const itemOption = array.lookup(idx, playlist.tracks);
        if (itemOption.isSome()) {
          setCurrentPlaylistItem(idx);
        }

        return itemOption;
      })
      .map(setCurrentTrack)
    ,
    play: () => array.lookup(currentPlaylistItem, playlist.tracks)
      .map(setCurrentTrack)
    ,
    next: () => nextItemIndex(playlist, currentPlaylistItem, ctxRef.current.settings.repeatState)
      .chain(idx => {
        const itemOption = array.lookup(idx, playlist.tracks);
        if (itemOption.isSome()) {
          setCurrentPlaylistItem(idx);
        }

        return itemOption;
      })
      .map(nextTrack => {
        // If repeating the same track we have to refresh the react state 
        // so a change is detected
        if (nextTrack === currentTrack) {
          setCurrentTrack(PlaylistItem.from(
            new NullTrack()
          ));
        }
        setCurrentTrack(nextTrack);
        return undefined;
      })
    ,
  }), [
    playlist,
    currentTrack,
    currentPlaylistItem,
    setActivePlaylist,
    updateActivePlaylist,
  ]);

  return (
    <ActivePlaylistContext.Provider value={ctxValue}>
      {children}
    </ActivePlaylistContext.Provider>
  );
}
