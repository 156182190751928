import { Either, left, right } from 'fp-ts/es6/Either';
import { none, Option, some } from 'fp-ts/es6/Option';
import { parse, toSeconds } from 'iso8601-duration';
import jsmediatags from 'jsmediatags';
import { TagType } from 'jsmediatags/types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Playlist, { playlistUpgrader, removeTrackNoPersist } from '../../models/playlist/playlist';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import Source from '../../models/source';
import Track, { TrackLength } from '../../models/track';
import NullTrack from '../../models/track/nullTrack';
import { GoogleApiContext } from '../../services/gapi/gapiService';
import Constants, { Keys, messages } from '../../settings';
import { genMediaDuration } from '../../utilities/genMediaDuration';
import { DropboxContext } from '../dropbox/dropboxService';
import XhrFileReaderWithLength from '../jsmediatags/XhrFileReaderWithLength';
import * as OneDriveApi from '../oneDrive/oneDriveApi';
import { OneDriveContext } from '../oneDrive/oneDriveService';
import { fileQueueCreator } from '../queues/appDataQueue';
import { tagReqQueueCreator } from '../queues/tagReqQueue';
import { Context } from '../_globalContext/context';
import MusicLibrary from './musicLibrary';

const fiveMinutes = 5 * 60 * 1000;

export interface ILibraryContext {
  library?: MusicLibrary;
  libraryLoaded: boolean;
  getLibraryTrack(plItm: PlaylistItem): Track;
  getTrackTags(track: Track): void;
  setTrackMissing(track: Track, missing?: boolean): void;
  addToLibrary(...tracks: Track[]): Either<string, Track[]>;
  removeFromLibrary(plItm: Iterable<PlaylistItem>, noConfirm?: boolean): Option<void>;
  resetLibrary(): void;
  saveLibrary(): Promise<void>;

  libraryPlaylist: Playlist | undefined;
  playlists: Playlist[];
  setPersistPlaylists(playlists: Playlist[]): void;
  playlistsLoaded: boolean;
  createPlaylist(name: string): Either<string, Playlist>;
  updatePlaylist(pl: Playlist): void;
  clearPlaylist(playlist: Playlist): void;
  removePlaylist(id: string): void;
  movePlaylist(pl: Playlist, target: Playlist | undefined, after?: boolean): void;

  handleSpLogout(): Promise<void>;
}
export const LibraryContext = React.createContext({} as ILibraryContext);

interface Props {
  children: React.ReactNode;
}
export default function LibraryService(props: Props) {

  const {
    children,
  } = props;

  console.debug('Rendering Library');

  const ctx = useContext(Context);
  const {
    storageProvider,
  } = ctx;
  const ctxRef = useRef(ctx);
  ctxRef.current = ctx;

  const gapiCtx = useContext(GoogleApiContext);
  const gapiCtxRef = useRef(gapiCtx);
  gapiCtxRef.current = gapiCtx;
  const oneDriveCtx = useContext(OneDriveContext);
  const oneDriveCtxRef = useRef(oneDriveCtx);
  oneDriveCtxRef.current = oneDriveCtx;
  const dbxCtx = useContext(DropboxContext);
  const dbxCtxRef = useRef(dbxCtx);
  dbxCtxRef.current = dbxCtx;

  // We would love to keep library access simple, 
  // defer access until loaded somehow
  const [library, setLibrary] = useState<MusicLibrary>();
  /**
   * Ensures addToLibrary and friends always work with the newest library.
   * We would otherwise likely run into problems with the async nature of getTrackTags
   */
  const libRef = useRef(library);
  libRef.current = library;
  const [libraryPlaylist, setLibraryPlaylist] = useState<Playlist>();
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  /**
   * For loadLibrary then callbacks, will otherwise concat old playlists
   * 
   * Memo: useRef() params are only set once, if it is an expression
   * it is however evaluated everytime, but still not set to current.
   * 
   * ref's needing updates per render therefore are set like so.
   * Reason for providing also as param to useRef is for typings
   */
  const plsRef = useRef(playlists);
  plsRef.current = playlists;
  const [libraryLoaded, setLibraryLoaded] = useState(false);
  const libLoadingRef = useRef(false);
  const [playlistsLoaded, setPlaylistsLoaded] = useState(false);
  const plsLoadingRef = useRef(false);

  // Signifies that the queue is currently actively processing a request
  const setActive = useRef(ctx.setRunningTask);
  setActive.current = ctx.setRunningTask;
  const disableActive = useRef(ctx.removeRunningTask);
  disableActive.current = ctx.removeRunningTask;

  /**
   * Controls ID3 tag retrieval, queues network requests.
   */
  const [tagQueue] = useState(() => tagReqQueueCreator(
    setActive,
    disableActive,
    ctxRef.current.unifiedReqQueue,
  ));
  // Retrieves temporary links to cloud files, 
  // which are then used to get raw data.
  const [tempLinkReqQueue] = useState(ctx.unifiedReqQueue);

  const [mediaDurationQueue] = useState(ctx.unifiedReqQueue);

  const [plStorageQueue] = useState(() => fileQueueCreator(
    'playlists.js.gz',
    setActive,
    disableActive,
    15 * 1000,
  ));
  const [libStorageQueue] = useState(() => fileQueueCreator(
    'library.js.gz',
    setActive,
    disableActive,
    fiveMinutes, // initially paused
  ));

  const libraryMigrations = useCallback(function (lib: MusicLibrary) {

    // Use refs throughout, is used in async callback
    // if (ctxRef.current.storageProvider.current && libStorageQueue.current) {

    //   libStorageQueue.current.queue(
    //     libRef.current,
    //     ctxRef.current.storageProvider.current,
    //   );
    // }
  }, []);

  useEffect(function loadLibrary() {
    if (storageProvider.current
      && ctx.flate
      && !libraryLoaded
      && !libLoadingRef.current) {

      libLoadingRef.current = true;

      storageProvider.current.load('library.js.gz', new MusicLibrary())
        .then(MusicLibrary.fromJson)
        .then(lib => {

          libraryMigrations(lib);

          setLibrary(lib);
          setLibraryLoaded(true);

          return lib.createPlaylist();
        })
        .then(
          setLibraryPlaylist,
          ctxRef.current.callSnackbar,
        ).finally(() => libLoadingRef.current = false);
    }
  }, [storageProvider, libraryLoaded, libraryMigrations, ctx.flate]);

  useEffect(function doLoadPlaylists() {
    if (storageProvider.current
      && ctx.flate
      && !playlistsLoaded
      && !plsLoadingRef.current) {

      plsLoadingRef.current = true;

      storageProvider.current.load('playlists.js.gz', [])
        .then(
          (pl: Playlist[]) => {
            pl = pl.map(playlistUpgrader);
            // // We filter out playlists on concat in case of changed storage provider 
            // const pls = plsRef.current.filter(x => x.id === Constants.LibraryId)
            //   .concat(pl);
            setPlaylists(pl);
            setPlaylistsLoaded(true);
          },
          ctxRef.current.callSnackbar,
        ).finally(() => plsLoadingRef.current = false);
    }
  }, [storageProvider, playlistsLoaded, ctx.flate]);

  /**
   * 
   * @param fn 
   * @param pl 
   * @param isLibraryPlaylist 
   * When callers are updating the library playlist, 
   * there is no need to update the playlists data file since library is stored seperately.
   * This flag thus allows us to consolidate all playlist updating while handling the library
   * special case.
   */
  const setPersistPlaylists
    = useCallback((fn: (playlist: Playlist[]) => void, pl: Playlist[], isLibraryPlaylist?: boolean) => {
      fn(pl);
      if (ctxRef.current.storageProvider.current && !isLibraryPlaylist) {
        plStorageQueue.queue(
          pl.filter(x => x.id !== Constants.LibraryId),
          ctxRef.current.storageProvider.current,
        );
      }
    }, [plStorageQueue]);

  const createPlaylist = useCallback(function (name: string): Either<string, Playlist> {
    if (name !== Constants.LibraryName && plsRef.current.some(pl => pl.name === name) === false) {

      const newPl = new Playlist(name);
      setPersistPlaylists(setPlaylists, plsRef.current.concat(newPl));
      return right(newPl);
    }
    else {
      return left('Duplicate playlist found!');
    }
  }, [setPersistPlaylists]);

  /**
   * Update playlist expects a new playlist object with updated data
   * vs its counterpart with matching id
   * 
   * If the playlist existed previously we keep previous playlist ordering
   * @param playlist 
   */
  const updatePlaylist = useCallback(function (playlist: Playlist) {

    // The ref de-reffing and assigning here ensures that calls that overwrite 
    // multiple times (f.x. addToLibraryThenPlaylist) don't conflict.
    //
    // Example: User calls addToLibraryThenPlaylist, it saves a reference to playlists
    // updateLibrary calls updatePlaylist then setPlaylists with updated library playlist
    //
    // Later on addItems returns an updated user playlist for updatePlaylist
    // this second updatePlaylist call overwrites previous library playlist
    //
    // We both have to use a ref for the playlists and ensure we update that ref here,
    // only updating state will not trigger a re-render (which causes a ref update)
    // in time.
    const pls = plsRef.current;
    const idx = plsRef.current.findIndex(pl => pl.id === playlist.id);

    if (idx === -1) {
      plsRef.current = pls.concat(playlist);
    } else {
      plsRef.current = pls.slice(0, idx)
        .concat(playlist)
        .concat(pls.slice(idx + 1, pls.length));
    }

    setPersistPlaylists(setPlaylists, plsRef.current, playlist.id === Constants.LibraryId);

  }, [setPersistPlaylists]);
  const clearPlaylist = useCallback(function (playlist: Playlist) {
    if (window.confirm('Are you sure you want to clear this playlist?')) {
      updatePlaylist({ ...playlist, tracks: [], });
    }
  }, [updatePlaylist]);
  const removePlaylist = useCallback(function (id: string) {
    setPersistPlaylists(
      setPlaylists,
      plsRef.current.filter(pl => pl.id !== id), // New array with playlist removed
    );
  }, [setPersistPlaylists]);

  const getLibraryTrack = useCallback(function (plItm: PlaylistItem) {
    if (libRef.current) {
      return libRef.current[plItm.trackType][plItm.id];
    } else {
      return new NullTrack();
    }
  }, []);

  /**
   * Supposedly causes the react hierarchy to re-render based on new data in MusicLibrary
   * Also ensures that the next time library is persisted to cloud storage, 
   * it will contain these changes.
   */
  const updateLibrary = useCallback(function () {
    // Use refs throughout, is used in async callback
    if (ctxRef.current.storageProvider.current && libStorageQueue) {

      libStorageQueue.queue(
        libRef.current,
        ctxRef.current.storageProvider.current,
      );
    }

    setLibrary(libRef.current && libRef.current.clone());

  }, [libStorageQueue]);

  /**
   * Regarding promises
   * 
   * Instead of recording all queued requests into an array,
   * we could also execute tag requests and media gen durations in sequence 
   * immediately after getting the file url, skipping the queue.
   * The current method was chosen in case we might want to do some more complicated work
   * inside the queues at a later date.
   * 
   * Regarding the previous blocking promise bug
   * 
   * Take care not to return a queued up promise from inside a queued up promise.
   * If done, the attached finally handler for the original promise will never fire.
   * This happens when you return the inner promise, 
   * causing the outer initial promise not to resolve until the inner queued one does.
   * But the inner queued one is never dequeued since that hinges on the finally handler firing
   * and dequeueing it.
   * 
   * @param track 
   * @param initialAdd 
   * The idea is to make some assumptions and shortcuts on the initial add to library
   * The user can then refetch tags if that data is not sufficient
   */
  const getTrackTags = useCallback(function getTrackTags(track: Track, initialAdd?: boolean) {
    const callbacks = {
      onSuccess: function (tag: TagType) {

        if (track.missing) {
          track.missing = false;
        }
        
        console.debug(tag);

        track.title = tag.tags.title || track.title;
        track.artist = tag.tags.artist || track.artist;
        track.album = tag.tags.album || track.album;

        if (tag.tags["TDRL"]) {
          track.releaseDate = new Date(Date.parse(tag.tags["TDRL"].data)).valueOf();
        } else if (tag.tags.year
          && tag.tags.year.length === 4
          && !isNaN(+tag.tags.year)) {
          track.releaseDate = new Date(+tag.tags.year, 0).valueOf();
        }
        track.genre = tag.tags.genre || track.genre;

        // Remove picture from tag
        // Update: Not needed since we skip tags in XhrFileReaderWithLength

        // delete tag.tags['PIC'];
        // delete tag.tags['pic'];
        // delete tag.tags['apic'];
        // delete tag.tags['APIC'];
        // delete tag.tags['picture'];

        // track.tags = tag.tags;

        if (initialAdd !== true) {
          updateLibrary();
        }
      },
      onError: function (_error: any) {
        ctxRef.current.callSnackbar('Error retrieving media tag for track ' + track.title);
      }
    };

    function youTubeDurationCallback(ytResponse: gapi.client.Response<gapi.client.youtube.VideoListResponse>) {
      if (ytResponse.result.items![0]
        && ytResponse.result.items![0].contentDetails!.duration) {
        var durationS = toSeconds(parse(ytResponse.result.items![0].contentDetails!.duration));
        track.length = durationS * 1000;

        if (initialAdd !== true) {
          updateLibrary();
        }
      }
    }

    function genMediaDurationCallback(duration: number) {
      track.length = duration * 1000;

      if (initialAdd !== true) {
        updateLibrary();
      }
    }

    const requests = new Array<Promise<any>>();

    if (track.type === Source.OneDrive) {
      // OneDrive API already provides all basic track metadata
      if (!initialAdd
        // OneDrive DriveItems are sometimes missing metadata,
        // presumably this can happen f.x. when saving podcast to drive
        // and OneDrive has yet to generate file metadata
        || track.length === TrackLength.UNAVAILABLE) {

        const tempLinkReq = tempLinkReqQueue.queue(() =>
          OneDriveApi.getItem(oneDriveCtxRef.current.graphClient)
            (ctxRef.current.callSnackbar)
            (track.id).then(driveItem => {
              const url = (driveItem as any)[Constants.MsGraphDlUrlProp];

              XhrFileReaderWithLength.urlToSizeMap[url] = driveItem.size!;

              requests.push(
                tagQueue.queue(
                  XhrFileReaderWithLength.read,
                  url,
                  callbacks,
                )
              );

              requests.push(
                mediaDurationQueue.queue(
                  () => genMediaDuration(url).then(genMediaDurationCallback)
                )
              );
            })
        );

        requests.push(tempLinkReq);
      }
    }
    else if (track.type === Source.GoogleDrive) {
      requests.push(
        tempLinkReqQueue.queue(() =>
          gapiCtxRef.current.getDriveFileUrl(track.id).then(fileUrl => {
            requests.push(
              tagQueue.queue(
                jsmediatags.read,
                fileUrl,
                callbacks,
              )
            );
            requests.push(
              mediaDurationQueue.queue(
                () => genMediaDuration(fileUrl).then(genMediaDurationCallback)
              )
            );
          })
        )
      );
    }
    else if (track.type === Source.Dropbox) {
      requests.push(
        tempLinkReqQueue.queue(() =>
          dbxCtxRef.current.dbx.filesGetTemporaryLink({ path: track.id }).then(res => {
            requests.push(
              tagQueue.queue(
                jsmediatags.read,
                res.link,
                callbacks,
              )
            );
            requests.push(
              mediaDurationQueue.queue(
                () => genMediaDuration(res.link).then(genMediaDurationCallback)
              )
            );
          })
        )
      );
    }
    else if (track.type === Source.YouTube) {

      if (track.length === TrackLength.NOT_READY) {
        requests.push(
          tempLinkReqQueue.queue(() =>
            gapiCtxRef.current.getDuration(track.id)
              .then(youTubeDurationCallback)
          )
        );
      }
    }
    else if (track.type === Source.Podcast) {
      // Let's try depending on podcast xml for these values
      // Web media will likely not support CORS * in most cases

      // tagQueue.current!.queue(
      //   jsmediatags.read,
      //   track.id,
      //   callbacks,
      // );
      // mediaDurationQ.queue(() => genMediaDuration(track.id).then(genMediaDurationCallback));
    }

    return Promise.all(requests);

  }, [mediaDurationQueue, tempLinkReqQueue, tagQueue, updateLibrary]);

  const addToLibrary = useCallback(function (...tracks: Track[]): Either<string, Track[]> {

    if (libRef.current === undefined) {
      return left('Unable to add, music library not ready');
    }

    let requests = new Array<Promise<any>>();

    for (let track of tracks) {
      if (track.type === Source.Null) {
        ctxRef.current.callSnackbar(messages(Keys.invalidTrackType));
      }

      const targetLibrary = libRef.current[track.type];
      // Does track already exist in users music library?
      if (targetLibrary[track.id].type === Source.Null) {

        requests.push(
          getTrackTags(track, true)
        );

        libRef.current[track.type][track.id] = track;
      }
    }

    Promise.all(requests).then(() => {
      updateLibrary();
      if (libRef.current) {
        setLibraryPlaylist(libRef.current.createPlaylist());
      }
    });

    return right(tracks);

  }, [updateLibrary, getTrackTags]);

  const doRemoveFromLibrary = useCallback(function (plItms: Iterable<PlaylistItem>) {
    if (libRef.current) {

      let updatedPlaylists = plsRef.current;

      for (const plItm of plItms) {
        const track = getLibraryTrack(plItm);
        const targetLibrary = libRef.current[track.type];

        if (targetLibrary[track.id]) {

          // Remove item from all custom playlists
          updatedPlaylists = updatedPlaylists.map(removeTrackNoPersist)
            .map(remove => remove(track));

          delete targetLibrary[track.id];
        }
      }

      // setPersistPlaylists(setPlaylists, [libPl].concat(plsUpdated));
      setPersistPlaylists(setPlaylists, updatedPlaylists);

      setLibraryPlaylist(libRef.current.createPlaylist());
      if (ctxRef.current.storageProvider.current) {
        libStorageQueue.queue(
          libRef.current,
          ctxRef.current.storageProvider.current,
        );
      }
      setLibrary(libRef.current);
      return some<void>(undefined);
    }

    return none;

  }, [getLibraryTrack, libStorageQueue, setPersistPlaylists]);

  const removeFromLibrary = useCallback(function (
    library: MusicLibrary | undefined,
  ) {
    return function (plItms: Iterable<PlaylistItem>, noConfirm?: boolean) {

      if (library === undefined) {
        ctxRef.current.callSnackbar('Unable to remove, library not ready');
        return none;
      }

      if (!ctxRef.current.settings.confirmDelete || noConfirm
        || window.confirm('Removing this track from your library will also remove it from all playlists, are you sure?')) {

        // Currently we always prompt before deleting unless user disables this features
        // if (!ctxRef.current.hasConfirmedDelete) {
        //   ctxRef.current.setHasConfirmedDelete();
        // }

        return doRemoveFromLibrary(plItms);
      }

      return none;
    }
  }, [doRemoveFromLibrary]);

  const setTrackMissing = useCallback(function markTrackMissing(track: Track, missing = true) {

    if (missing) {
      if (!track.missing) {
        track.missing = true;
    
        updateLibrary();
      }
    }
    else {
      if (track.missing) {
        track.missing = false;
    
        updateLibrary();
      }
    }
  }, [updateLibrary]);

  const resetLibrary = useCallback(function () {
    if (!ctxRef.current.settings.confirmDelete
      || window.confirm('Are you sure you want to reset your music library?')) {

      if (ctxRef.current.storageProvider.current) {
        libStorageQueue.queue(
          {},
          ctxRef.current.storageProvider.current,
        );
      }
      const newLib = new MusicLibrary();
      setLibrary(newLib);
      setLibraryPlaylist(newLib.createPlaylist());
    }
  }, [libStorageQueue]);

  /** 
   * Persist to cloud library changes already made
   * This is in contrast to actually updating the MusicLibrary object with track data,
   */
  const saveLibrary = useCallback(async function () {
    await libStorageQueue.executeSingle();

    ctxRef.current.callSnackbar(messages(Keys.saved), false);
  }, [libStorageQueue]);

  /**
   * 
   * @param pl Source playlist being moved
   * @param target 
   * We always have a target playlist that the move is in relation to
   * It's too complicated otherwise since all the indexes shuffle around 
   * depending on where in the list the source is in relation to the target.
   * 
   * @param after Should we move the source pl to the index directly after the target?
   */
  const movePlaylist = useCallback(function (pl: Playlist, target: Playlist | undefined, after?: boolean) {

    if (target) {
      const curIdx = plsRef.current.indexOf(pl);

      const playlistsWithoutSource = plsRef.current.filter(x => x !== pl);

      const targetIdx = after
        ? playlistsWithoutSource.indexOf(target) + 1
        : playlistsWithoutSource.indexOf(target);

      if (curIdx !== targetIdx) {

        const result = [
          ...playlistsWithoutSource.slice(0, targetIdx),
          pl,
          ...playlistsWithoutSource.slice(targetIdx),
        ];

        setPersistPlaylists(setPlaylists, result);
      }
    }
    else {
      // skip if playlist already at end
      if (plsRef.current[plsRef.current.length - 1] !== pl) {
        setPersistPlaylists(
          setPlaylists,
          plsRef.current.filter(x => x !== pl).concat(pl),
        );
      }
    }
  }, [setPersistPlaylists]);

  const handleSpLogout = useCallback(async function () {
    if (libStorageQueue.queuedReq || plStorageQueue.queuedReq) {

      const fileName = libStorageQueue.queuedReq
        ? 'Library'
        : 'Playlists'
        ;

      if (window.confirm(`${fileName} currently has unsaved changes, would you like to save first?`)) {
        await libStorageQueue.executeSingle();
        await plStorageQueue.executeSingle();
      }
    }

    setLibraryLoaded(false);
    setPlaylistsLoaded(false);
  }, [libStorageQueue, plStorageQueue]);
  useEffect(function registerSpLogoutHandler() {
    ctxRef.current.setLibrarySpLogoutHandling(handleSpLogout);
  }, [handleSpLogout]);

  const ctxValue = useMemo<ILibraryContext>(() => ({
    library,
    libraryLoaded,
    getLibraryTrack,
    getTrackTags,
    setTrackMissing,
    addToLibrary,
    removeFromLibrary: removeFromLibrary(library),
    resetLibrary,
    saveLibrary,

    libraryPlaylist,
    playlists,
    setPersistPlaylists: (playlists: Playlist[]) => setPersistPlaylists(setPlaylists, playlists),
    playlistsLoaded,
    createPlaylist,
    updatePlaylist,
    clearPlaylist,
    removePlaylist,
    movePlaylist,

    handleSpLogout,
  }), [
    library,
    libraryLoaded,
    getLibraryTrack,
    getTrackTags,
    setTrackMissing,
    addToLibrary,
    removeFromLibrary,
    resetLibrary,
    saveLibrary,
    libraryPlaylist,
    playlists,
    setPersistPlaylists,
    playlistsLoaded,
    createPlaylist,
    updatePlaylist,
    clearPlaylist,
    removePlaylist,
    movePlaylist,
    handleSpLogout,
  ]);

  return (
    <LibraryContext.Provider value={ctxValue}>
      {children}
    </LibraryContext.Provider>
  );
}
