import React, { useContext, useEffect, useState } from 'react';
import { ScreenDetectionContext, ScreenSize } from '../../../services/screenDetection/screenDetection';
import totalItemCountStringDefault from '../../../utilities/totalItemCountStringDefault';
import s from './paging.module.css';

interface Props {
  name?: string;
  display: boolean;
  displayBottom?: boolean;
  children: React.ReactNode;
  /**
   * Optional further check to conditionally display get remaining button
   */
  isComplete(): boolean;
  /**
   * Number of items after filtering
   */
  filteredItemCount: number;

  shufflePlaylist?(): void;

  displayGetRemaining: boolean;
  getRemaining?: (_ev: React.MouseEvent<HTMLButtonElement>) => void;

  additionalControls?: React.ReactNode;
  handleFilter?(val: string): void;
  clearPlaylist?(): void;

  totalItemCount(): number;
  totalItemCountString?: string | boolean;
  filteredItemCountString?: string | false;
}

export default function PlaylistControls({
  children,
  name,
  isComplete,
  display,
  displayBottom,
  filteredItemCount,
  totalItemCount,
  displayGetRemaining,
  getRemaining,
  additionalControls,
  handleFilter: handleFilterChange,
  clearPlaylist,
  shufflePlaylist,
  totalItemCountString,
  filteredItemCountString,
}: Props) {

  const screenSize = useContext(ScreenDetectionContext);

  const [filter, setFilter] = useState('');

  let leftControls: JSX.Element | undefined;
  let rightControls: JSX.Element | undefined;

  useEffect(function resetFilterOnPlaylistChange() {
    setFilter('');
  }, [name]);

  function updateFilter(ev: React.ChangeEvent<HTMLInputElement>) {
    ev.preventDefault();
    const val = ev.currentTarget.value;

    setFilter(val);
    handleFilterChange!(val);
  }


  // This way we don't have to check iterator undefined in isComplete
  if (display) {

    leftControls = (
      <div className={"button-group align-center " + s.buttonGroup}>
        {/* 
          We don't allow filtering until all results have been retrieved
          Otherwise it mangles up paging.

          It is f.x. impossible to know how many pages there are of a filtered view
          when you don't have all results yet. This also makes next page / change page
          weird in filtered views with partial results..
        */}
        {isComplete && handleFilterChange &&
          <input
            className={s.filter}
            // ref={filterEl}
            type="text"
            name="filter"
            id="filter"
            onChange={updateFilter}
            value={filter}
            placeholder="Filter Playlist"
          />
        }
        {displayGetRemaining && !isComplete() &&
          <button className="button" onClick={getRemaining}>
            Get Remaining
          </button>
        }
      </div>
    );
    rightControls = (
      <div className={s.rightControls}>
        {additionalControls}
        {shufflePlaylist &&
          <button className="button secondary" onClick={shufflePlaylist}>
            Shuffle Playlist
          </button>
        }
        {clearPlaylist &&
          <button className="button alert" onClick={clearPlaylist}>
            Clear Playlist
          </button>
        }
      </div>
    );
  }

  return (
    <>
      {/* When displaying longer lists, 
              have navigation accessible at top and bottom */}
      {display &&
        <div className="grid-x">
          <div className={"cell large-4"}>{leftControls}</div>
          <div className="cell small-6 large-4">{name && <h3>{name}</h3>}</div>
          {screenSize === ScreenSize.XLarge &&
            <div className="cell large-2" />
          }
          <div className={`cell small-6 large-${screenSize === ScreenSize.XLarge ? 2 : 4}`}>
            {rightControls}
          </div>
        </div>
      }
      {display && children}
      {displayBottom &&
        <div className={s.bottomControls}>
          {totalItemCountString !== false &&
            <>
              <span>
                {`${totalItemCount()} `} 
                {(totalItemCountString || totalItemCountStringDefault(totalItemCount()))}
              </span>
              {totalItemCount() !== filteredItemCount &&
                <span>
                  {`, ${filteredItemCount} `}
                  {filteredItemCountString || `displayed`}
                </span>
              }
            </>
          }
        </div>
      }
    </>
  );
}
