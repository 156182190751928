import React, { forwardRef, useContext, useMemo } from "react";
import { Context } from "../../services/_globalContext/context";

interface RefSet<T> {
  current: Set<T>;
}

/** Width in px */
export const playBtnWidth = 16;
/** Width in px */
export const contextMenuBtnWidth = 16;

export function createHandleToggleItem<T>(
  setSelectedItems: (set: RefSet<T>) => void,
  lastSelectedItem: React.MutableRefObject<T | undefined>,
) {
  return function handleToggleItem(plItm: T, selectedItems: RefSet<T>) {

    const selItms = selectedItems.current;

    if (selItms.has(plItm)) {
      selItms.delete(plItm);
    } else {
      selItms.add(plItm);
    }

    lastSelectedItem.current = plItm;

    setSelectedItems({
      current: selItms,
    });
  }
}

export function createChangeItemSelectionState<T>(
  setSelectedItems: (set: RefSet<T>) => void,
  lastSelectedItem: React.MutableRefObject<T | undefined>,
) {
  /**
  * 
  * @param plItm 
  * @param selectedItems
  * @param selectState true selects / false deselects
  * @param removePrevious Remove all previous selections
  */
  return function changeItemSelectionState(
    plItm: T,
    selectedItems: RefSet<T>,
    selectState: boolean,
    removePrevious?: boolean,
  ) {
    const selItms = selectedItems.current;

    if (removePrevious) {
      
      selItms.clear();

      if (selectState) {
        selItms.add(plItm);
        lastSelectedItem.current = plItm;
      }
    }
    else {
      if (selectState) {
        selItms.add(plItm);
        lastSelectedItem.current = plItm;
      } else {
        selItms.delete(plItm);
      }
    }

    setSelectedItems({
      current: selItms,
    });
  }
}

export function createShiftSelectItem<T>(
  setSelectedItems: (set: RefSet<T>) => void,
  lastSelectedItem: React.MutableRefObject<T | undefined>,
) {
  return function shiftSelectItem(
    playlistItem: T,
    selectedItems: RefSet<T>,
    playlistItems: (T | undefined)[],
  ) {
    const curSelItms = selectedItems.current;

    if (lastSelectedItem.current) {
      const indexOf = playlistItems.indexOf(lastSelectedItem.current);

      var startIndex = indexOf !== -1 ? indexOf : 0;
    }
    else {
      var startIndex = 0;
    }

    const targetIndex = playlistItems.indexOf(playlistItem);

    if (startIndex < targetIndex) {
      for (let x = startIndex; x <= targetIndex; x++) {
        const item = playlistItems[x];
        if (item) {
          curSelItms.add(item);
        }
      }
    }
    else if (startIndex > targetIndex) {
      for (let x = targetIndex; x <= startIndex; x++) {
        const item = playlistItems[x];
        if (item) {
          curSelItms.add(item);
        }
      }
    }

    setSelectedItems({
      current: curSelItms,
    });
  };
}

/**
 * Creates padding inside react-window list when applicable
 */
export const innerElementType = forwardRef(({ style, ...rest }: any, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height) + 24}px`
    }}
    {...rest}
  />
));

export function useDeductions() {

  const ctx = useContext(Context);

  const deductions = useMemo(() =>
    playBtnWidth
    + contextMenuBtnWidth
    + (ctx.scrollbarWidth || 0)
    // - 1 // 1 less for safety, 
    // // otherwise I see unecessary x scroll in some screen sizes
    ,
    [ctx.scrollbarWidth]);

  return deductions;
}
