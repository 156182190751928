import React, { useContext } from 'react';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import Source from '../../models/source';
import { SpotifyContext } from '../../services/spotify/spotifyService';
import PlayerControls from '../player/playerControls';

interface Props {
  className?: string;
  playlistItem: PlaylistItem
}

export default function SpotifyPlayerWrapper({
  className,
  playlistItem,
}: Props) {

  const spotifyCtx = useContext(SpotifyContext);
  
  return (
    <>
      {spotifyCtx.player && spotifyCtx.playbackState 
      && playlistItem.trackType === Source.Spotify &&
        <PlayerControls
          className={className}
          paused={spotifyCtx.playbackState.paused}
          currentTime={spotifyCtx.playbackState.position}
          duration={spotifyCtx.playbackState.duration}
          handlePlayPause={() => spotifyCtx.player!.togglePlay()}
          handleSeek={(num: number) => spotifyCtx.player!.seek(num)}
        />
      }
    </>
  );
}
