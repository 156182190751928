import { CallbackType, jsmediatagsError, TagType } from "jsmediatags/types";
import { reqQueueCreator } from "./reqQueue";

export const tagReqQueueCreator = function (
  setActive: React.MutableRefObject<(task: string) => void>,
  disableActive: React.MutableRefObject<(task: string) => void>,
  baseReqQ?: ReturnType<typeof reqQueueCreator>,
) {

  baseReqQ = baseReqQ || reqQueueCreator(
    () => setActive.current('tagQueue'),
    () => disableActive.current('tagQueue'),
  );

  function callbackWrapper(
    callbacks: CallbackType,
    resolve: Function,
    reject: Function,
  ) {
    return {
      onSuccess: (data: TagType) => {
        try {
          callbacks.onSuccess(data);
        } finally {
          resolve();
        }
      },
      onError: (error: jsmediatagsError) => {
        try {
          if (callbacks.onError) {
            callbacks.onError(error);
          }
        } finally {
          reject();
        }
      },
    };
  }

  /** Receives and queues an id3 tag request from jsmediatags */
  return {
    queue(
      readFn: (location: any, callbacks: CallbackType) => void,
      url: string,
      callbacks: CallbackType,
    ) {

      return baseReqQ!.queue(() =>
        new Promise(function (resolve, reject) {
          readFn(url, callbackWrapper(callbacks, resolve, reject));
        })
      );
    },
  };
};
