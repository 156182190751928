import React from 'react';
import { Context } from '../../../services/_globalContext/context';

interface Props {

}

class State {
  hasError = false;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  context!: React.ContextType<typeof Context>;
  static contextType = Context;

  constructor(props: Props) {
    super(props);
    this.state = new State();
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    if (this.context.callSnackbar) {
      this.context.callSnackbar(error);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}
