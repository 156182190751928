import SpotifyImage from "../../models/spotify/spotifyImage";

/**
 * 
 * @param images collection of spotify images from spotify api
 * @param pickLargest default is to choose the smallest image available by height
 */
export default function getImageBySize(images: SpotifyImage[], pickLargest = false) {
  images.sort((a, b) =>
    a.height > b.height
      ? 1
      : a.height < b.height
        ? -1
        : 0
  );

  // Get first image with height > x
  for (let img of images) {
    if (img.height >= 30) {
      return img;
    }
  }

  if (pickLargest) {
    return images[0];
  }
  else {
    // default to smallest image
    return images[images.length - 1];
  }
}
