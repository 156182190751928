import { DropboxFile } from "../../models/dropboxFile";

export default function dropboxSorter(a: DropboxFile, b: DropboxFile) {

  if (a[".tag"] === 'folder'
    && b[".tag"] !== 'folder') {
    return -1;
  }
  else if (b[".tag"] === 'folder'
    && a[".tag"] !== 'folder') {
    return 1;
  }

  return a.name.toLowerCase() > b.name.toLowerCase()
    ? 1
    : a.name.toLowerCase() < b.name.toLowerCase()
      ? -1
      : 0
    ;
}
