import IStorageProvider from "../services/storage/istorageProvider";

export enum StorageProviderKey { 
  OneDrive = 'onedrive', 
  Dropbox = 'dropbox', 
  GoogleDrive = 'gdrive', 
}

export const StorageProviderKeyToReadableName: {
  [P in StorageProviderKey]: string;
} = {
  [StorageProviderKey.Dropbox]: 'DropBox',
  [StorageProviderKey.GoogleDrive]: 'GoogleDrive',
  [StorageProviderKey.OneDrive]: 'OneDrive',
};

export type StorageProviderMap = {
  [P in StorageProviderKey]: React.MutableRefObject<IStorageProvider|undefined> | undefined;
}
