import classNames from 'classnames';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, ContextMenuTriggerProps } from 'react-contextmenu';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { ReactComponent as SortAscending } from '../../assets/icomoon/sort-amount-asc.svg';
import { ReactComponent as SortDescending } from '../../assets/icomoon/sort-amount-desc.svg';
import { promiseNoop } from '../../gUtilities/noop';
import { DragType } from '../../models/dragTypes';
import Playlist from '../../models/playlist/playlist';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import Track from '../../models/track/track';
import { ActivePlaylistContext } from '../../services/activePlaylist/activePlaylist';
import PlaylistColumns, { ColumnKey, ColumnScope, TrackProperty } from '../../services/columnSettings/playlistColumns';
import { Context } from '../../services/_globalContext/context';
import Constants from '../../settings';
import { preventDefaultStopPropogation } from '../../utilities/preventDefault';
import shuffle from '../../utilities/shuffle';
import FadilaMenuItem from '../common/contextMenu/fadilaMenuItem';
import HideColumnsMenu from '../common/hideColumnsMenu/hideColumnsMenu';
import useColumnResizing from '../common/hooks/useColumnResizing';
import useColumnSettings from '../common/hooks/useColumnSettings';
import useDeductedContentWidth from '../common/hooks/useDeductedContentWidth';
import useMoreSharedPlaylistMethods from '../common/hooks/useMoreSharedPlaylistMethods';
import useSharedPlaylistMethods from '../common/hooks/useSharedPlaylistMethods';
import { ItemRendererProps } from '../common/itemRow/itemRowPropsAndTypes';
import PlaylistControls from '../common/playlistControls/playlistControls';
import Spinner from '../common/spinner/spinner';
import s from './playlist.module.scss';
import TrackItemRow from './playlistItems/trackItemRow';
import WindowedTrackItemRenderer from './playlistItems/windowedTrackItemRenderer';

interface Props {
  loading?: boolean;
  moveable?: boolean;
  dragType: DragType;

  playlist: Playlist;
  /**
   * Allows for a controlled playlist component with no dependency on the library itself
   */
  trackItems: Track[];

  display: boolean;
  isComplete(): boolean;
  totalItemCount(): number;
  totalItemCountString?: string;
  filteredItemCountString?: string | false;

  columnScope: ColumnScope;

  isNextPageLoading: boolean;
  loadMore(startIndex: number, stopIndex: number): Promise<any>;
  /**
   * Is there more data left to be loaded?
   * 
   * In TrackPlaylist we use hasMore and totalItemCount in tandem.
   * 
   * This is done since YouTube Api does not return the correct total result count.
   * They include deleted videos and then do not return anything to signify where in
   * playlist order said deleted videos where.
   * 
   * This means we have to detect here when we have retrieved all items for the playlist
   * and adjust total item count based on trackItems.length
   */
  hasMore?: boolean;
}

/**
 * This playlist shows items not currently in library,
 * f.x. YT playlist
 * Hides delete and clear buttons.
 */
export default React.memo(function TrackPlaylistComponent({
  moveable: moveableProp,
  dragType,
  loading,

  playlist,
  trackItems,

  display,
  isComplete,
  totalItemCount: totalItemCountF,
  totalItemCountString,
  filteredItemCountString,

  columnScope,

  isNextPageLoading,
  loadMore,
  hasMore,
}: Props) {
  const ctx = useContext(Context);
  const activePlaylist = useContext(ActivePlaylistContext);

  const { columns, columnSettings, } = useColumnSettings(columnScope);

  const {
    totalContentWidth,
    mainViewport,
    setMainViewport,
    resetColumnWidths,
    columnAutoResize,
    hideColumn,
    unhideColumn,
  } = useDeductedContentWidth(columnScope);
  const [onResizeStart] = useColumnResizing(columnScope, mainViewport);

  const {
    lastSelectedItem,
    selectedItems,
    selectedItemsRef,

    handleToggleItem,
    shiftSelectItem,
    changeItemSelectionState,
    clearSelected,
    setSelectedItems,
  } = useSharedPlaylistMethods<Track>({
    playlistElement: mainViewport,
  });
  const {
    sortBy,
    setSortBy,
    sortOnClickCreator,

    updateFilter,

    filteredAndSortedTracks,
    handlePlayTrack,

    moveable,
  } = useMoreSharedPlaylistMethods({
    playlist,
    items: trackItems,
    lastSelectedItem,
    selectedItemsRef,
    setSelectedItems,
    moveableProp,
    columnScope,
  });

  const shufflePlaylist = useCallback(function () {
    const shuffledItems = shuffle(filteredAndSortedTracks.slice());

    if (shuffledItems[0]) {
      const doPlay = true;
      activePlaylist.setActivePlaylist(
        shuffledItems.map(PlaylistItem.from),
        0,
        doPlay,
      );
    }
  }, [activePlaylist, filteredAndSortedTracks]);

  const itemRendererProps: ItemRendererProps<Track> = useMemo(() => ({
    filteredAndSortedItems: filteredAndSortedTracks,

    ItemRow: TrackItemRow,
    ItemRowEnd: undefined,

    dragType,

    columnScope,
    portalTarget: mainViewport || undefined,
    moveable,
    moveDescending: sortBy.propName === 'index-descending',

    handlePlayTrack,

    selectedItems,
    selectedItemsRef,
    handleToggleItem: plItm => handleToggleItem(plItm, selectedItemsRef),
    handleShiftSelect: plItm => shiftSelectItem(plItm, selectedItemsRef, filteredAndSortedTracks),
    changeItemSelectionState: (plItm, selectState, remPrev) =>
      changeItemSelectionState(plItm, selectedItemsRef, selectState, remPrev),

    clearSelected,
  }), [
    selectedItems,
    selectedItemsRef,
    filteredAndSortedTracks,
    dragType,
    mainViewport,
    columnScope,
    moveable,
    sortBy,
    handlePlayTrack,
    handleToggleItem,
    shiftSelectItem,
    changeItemSelectionState,
    clearSelected,
  ]);

  const [contextTriggers] = useState<{
    [P in ColumnKey]?: React.Component<ContextMenuTriggerProps, any, any>;
  } & {
    contextCol?: React.Component<ContextMenuTriggerProps, any, any>;
  }>({});

  const toggleMenu = useCallback((e: React.MouseEvent) => {
    const parent = e.currentTarget.parentElement;

    if (parent) {
      const columnName = parent.attributes.getNamedItem('data-column-name')?.value as TrackProperty;

      const ctxTrigger = contextTriggers[columnName];
      if (ctxTrigger) {
        (ctxTrigger as any).handleContextClick(e);
      }
    }
  }, [contextTriggers]);

  const totalItemCount = totalItemCountF();
  // Adds for the conditional loading element that follows if we do not have a total item count
  const totalVirtualItemCount
    = (totalItemCount === undefined && hasMore ? 1 : 0); // Loading element at end

  const isItemLoaded = useCallback(
    (index: number) => totalItemCount
      ? filteredAndSortedTracks[index] !== undefined
      // Don't include loading element padding
      : index < filteredAndSortedTracks.length
    , [filteredAndSortedTracks, totalItemCount]);

  return (
    <article
      ref={c => setMainViewport(c || undefined)}
      className={`items-list ${s.playlist}`}
    >
      {loading || mainViewport === undefined || columnSettings === undefined ?
        <Spinner />
        :
        <PlaylistControls
          name={playlist.name}
          display={display}
          isComplete={isComplete}

          filteredItemCount={filteredAndSortedTracks.length}
          totalItemCount={totalItemCountF}

          displayGetRemaining={false}
          handleFilter={updateFilter}
          shufflePlaylist={shufflePlaylist}

          displayBottom={ctx.settings.showPlaylistStatusBar}
          totalItemCountString={totalItemCountString}
          filteredItemCountString={filteredItemCountString}
        >
          <div className={classNames(s.contentWrapper, s.parentContentWrapper)}>
            <div className={s.thead} style={{ width: totalContentWidth }}>
              <div
                className={classNames(
                  s.headerColumn,
                  s.underscoreColumn, {
                  [s.activeFilter]: sortBy.propName === 'index',
                  [s.activeFilterDesc]: sortBy.propName === 'index-descending',
                })}
              >
                <div
                  onClick={ev => {
                    ev.stopPropagation();
                    return sortBy.propName === 'index'
                      ? setSortBy('index', true)
                      : setSortBy('index');
                  }}
                >
                  _
                </div>
              </div>
              {/* We never have size and image in playlist components */}
              {(columns as TrackProperty[]).map(key =>
                <div
                  key={key}
                  data-column-name={key}
                  style={{ width: columnSettings[key]?.width }}
                  className={classNames(
                    s.headerColumn, {
                    [s.hideOnHover]: columnSettings[key]!.width < Constants.HeaderHideBreakpoint,
                    [s.activeFilter]: sortBy.propName === key,
                    [s.activeFilterDesc]: sortBy.propName === `${key}-descending`,
                  })}
                >
                  <ContextMenuTrigger
                    ref={c => contextTriggers[key] = c || undefined}
                    id={`context-menu-${key}`}
                    holdToDisplay={-1}
                    disableIfShiftIsPressed={true}
                  >
                    <div
                      onClick={sortOnClickCreator(PlaylistColumns[key].trackProperty)}
                    >
                      <span className="header-column-content">
                        {PlaylistColumns[key].name}
                      </span>
                    </div>
                  </ContextMenuTrigger>
                  <button
                    className={classNames(s.menuBtn, s.hiddenColControls)}
                    onClick={sortOnClickCreator(PlaylistColumns[key].trackProperty)}>
                    {sortBy.propName === key
                      ?
                      <SortDescending fill="white" />
                      :
                      <SortAscending fill="white" />
                    }
                  </button>
                  <button
                    className={classNames(s.menuBtn, 'invisible-control')}
                    onClick={toggleMenu}
                  >
                    ☰
                  </button>
                  <ContextMenu id={`context-menu-${key}`}>
                    <FadilaMenuItem onClick={() => hideColumn(key)}>
                      Hide Column
                    </FadilaMenuItem>
                    <FadilaMenuItem
                      onClick={preventDefaultStopPropogation(resetColumnWidths)}
                    >
                      Reset columns
                    </FadilaMenuItem>
                  </ContextMenu>
                  <div
                    className={s.resizeColumn}
                    onMouseDown={onResizeStart}
                    onDoubleClick={columnAutoResize}
                    onTouchStart={onResizeStart}
                  />
                </div>
              )}
              <div
                data-column-name={'contextCol'}
                className={classNames(
                  s.headerColumn,
                  s.hideOnHover,
                  s.contextMenuBtnColumn,
                )}
                style={{ width: (ctx.scrollbarWidth || 0) + 16 }}
              >
                <ContextMenuTrigger
                  ref={c => contextTriggers['contextCol'] = c || undefined}
                  id="context-menu-contextCol"
                  holdToDisplay={-1}
                  disableIfShiftIsPressed={true}
                >
                  <></>
                </ContextMenuTrigger>
                <button
                  className={classNames(s.menuBtn, 'invisible-control')}
                  onClick={toggleMenu}
                >
                  ☰
                </button>
                <ContextMenu id="context-menu-contextCol">
                  <HideColumnsMenu
                    scope={columnScope}
                    hideColumn={hideColumn}
                    unhideColumn={unhideColumn}
                  />
                  <FadilaMenuItem
                    onClick={preventDefaultStopPropogation(resetColumnWidths)}
                  >
                    Reset columns
                  </FadilaMenuItem>
                </ContextMenu>
              </div>
            </div>
            <div
              className={classNames(s.contentWrapper, {
                [s.oddRows]: filteredAndSortedTracks.length % 2 !== 0
              })}
              style={{ width: totalContentWidth }}
            >
              {/* <InfiniteListWithContext
                itemData={itemRendererProps}
                itemRenderer={WindowedTrackItemRenderer}

                itemSize={26}
                itemCount={totalVirtualItemCount 
                  + ((hasMore && totalItemCount) || trackItems.length) 
                  + (moveable ? 1 : 0)}
                isItemLoaded={isItemLoaded}

                loadMoreItems={totalItemCount
                  ? loadMore
                  : isNextPageLoading
                    ? promiseNoop
                    : loadMore}
              /> */}
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={totalVirtualItemCount
                  + ((hasMore && totalItemCount) || trackItems.length)
                  + (moveable ? 1 : 0)}
                loadMoreItems={totalItemCount
                  ? loadMore
                  : isNextPageLoading
                    ? promiseNoop
                    : loadMore}
              >
                {({ onItemsRendered, ref }) => (
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        height={height}
                        width={width}
                        itemSize={26}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        itemCount={totalVirtualItemCount
                          + ((hasMore && totalItemCount) || trackItems.length)
                          + (moveable ? 1 : 0)}
                        itemData={itemRendererProps}
                      >
                        {WindowedTrackItemRenderer}
                      </List>
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            </div>
          </div>
        </PlaylistControls>
      }
    </article>
  );
});
