import classNames from 'classnames';
import jsmediatags from 'jsmediatags';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import PrivacyPolicy from './components/privacyPolicy/privacyPolicy';
import LoadingComponent from './components/settingsAndStartup/loadingComponent';
import SetupComponent from './components/settingsAndStartup/setupComponent';
import noop from './gUtilities/noop';
import ContentPageHeader from './pages/contentPageHeader/header';
import Frontpage from './pages/frontpage/frontpage';
import Help from './pages/troubleshooting/troubleshooting';
import routes from './routes';
import ActivePlaylist from './services/activePlaylist/activePlaylist';
import ColumnSettingsService from './services/columnSettings/columnSettings';
import DropboxRedirect from './services/dropbox/dropboxRedirect';
import DropboxService from './services/dropbox/dropboxService';
import GoogleApiService, { GoogleApiContext } from './services/gapi/gapiService';
import LibraryService, { LibraryContext } from './services/library/libraryService';
import OneDriveService from './services/oneDrive/oneDriveService';
import PodcastService from './services/podcasts/podcastService';
import ScreenDetection from './services/screenDetection/screenDetection';
import SmallDeviceService from './services/smallDevices/smallDeviceService';
import SpotifyRedirect from './services/spotify/spotifyRedirect';
import SpotifyService from './services/spotify/spotifyService';
import SyncService from './services/synchronization/syncService';
import { Context } from './services/_globalContext/context';
import ContextService from './services/_globalContext/contextService';
import { isTouchDevice } from './settings';
import './styles/App.scss';

export interface IRoute {
  component: any;
  link?: string;
  path: string;
  name: string;
  icon?: string;
  notExact?: boolean;
}

export default function App(props: any) {

  useEffect(function configureJsMediaTags() {
    jsmediatags.Config.setDisallowedXhrHeaders(['if-modified-since']);
    jsmediatags.Config.setXhrTimeoutInSec(900);
  }, []);

  useEffect(function disableLoggingInProduction() {
    if (process.env.NODE_ENV === 'production') {
      console.debug = noop;
      console.info = noop;
      console.log = noop;
    }
  }, []);

  return (
    <Switch>
      <Route
        path="/"
        exact
      >
        <article className="Frontpage">
          <ContentPageHeader />
          <Frontpage />
        </article>
      </Route>
      <Route
        path="/privacy"
        exact
      >
        <article className="Frontpage">
          <ContentPageHeader />
          <PrivacyPolicy />
        </article>
      </Route>
      <Route
        path="/help"
        exact
      >
        <article className="Frontpage">
          <ContentPageHeader />
          <Help />
        </article>
      </Route>
      <Route
        path="/dropbox-redirect"
      >
        <DropboxRedirect />
      </Route>
      <Route
        path="/spotify-redirect"
      >
        <SpotifyRedirect />
      </Route>
      <Route
        path="/app"
      >
        <AppServices />
      </Route>
      <Route>
        <p>Page not found</p>
      </Route>
    </Switch>
  );
}

const AppServices = () =>
  <ScreenDetection>
    <SmallDeviceService>
      {/** Keep early to ensure errors can be displayed with snackbar */}
      <ContextService>
        <ColumnSettingsService>
          <ActivePlaylist>
            <DropboxService>
              <OneDriveService>
                <GoogleApiService>
                  <SpotifyService>
                    <LibraryService>
                      <SyncService>
                        <PodcastService>
                          <AppInner />
                        </PodcastService>
                      </SyncService>
                    </LibraryService>
                  </SpotifyService>
                </GoogleApiService>
              </OneDriveService>
            </DropboxService>
          </ActivePlaylist>
        </ColumnSettingsService>
      </ContextService>
    </SmallDeviceService>
  </ScreenDetection>

const AppInner = () => {

  const ctx = useContext(Context);
  const ctxRef = useRef(ctx);
  ctxRef.current = ctx;
  const gapiCtx = useContext(GoogleApiContext);
  const libCtx = useContext(LibraryContext);

  const [didAutoConfigure, setDidAutoConfigure] = useState(false);

  const externalLoaded = (
    ctx.flate
    && gapiCtx.dataApiLoaded
    && gapiCtx.iframeApiLoaded
    && gapiCtx.gapiClientLoaded
  );
  const appLoaded = (
    ctx.settingsLoaded
    && libCtx.libraryLoaded
    && libCtx.playlistsLoaded
  );

  /**
   * Attempt to autoconfigure storage provider using localStorage setting.
   */
  useEffect(function autoConfigure() {
    if (externalLoaded && !didAutoConfigure) {
      ctxRef.current.autoConfigureStorageProvider();
      setDidAutoConfigure(true);
    }
  }, [externalLoaded, didAutoConfigure]);

  return (
    <article className={classNames('App', 'ViewportHeight', {
      'touch-device': isTouchDevice,
    })}>
      <Header
        routes={routes}
        setup={!ctx.storageProvider.current}
      />
      <main className={ctx.playerSize}>
        {(() => {
          // We do not return an inline function component
          // We execute an inline function, returning stable component types
          if (externalLoaded) {
            if (appLoaded && ctx.storageProvider.current) {
              return (
                <Switch>
                  {routes.map(route =>
                    <Route
                      key={route.path}
                      path={route.path}
                      exact={route.notExact !== true}
                      component={route.component}
                    />
                  )}
                  <Route
                    path="/app"
                  >
                    <Redirect to="/app/library" />
                  </Route>
                  <Route>
                    <p>Page not found</p>
                  </Route>
                </Switch>
              );
            } else if (didAutoConfigure && !ctx.storageProvider.current) {
              return <SetupComponent />;
            } else {
              return <LoadingComponent />;
            }
          } else {
            return <LoadingComponent />;
          }
        })()}
      </main>
      <Footer />
    </article>
  );
}
