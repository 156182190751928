import { Either } from "fp-ts/es6/Either";
import { DragType } from "../../../models/dragTypes";
import { isAudioFile } from '../../../models/fileExtensions';
import mimeTypes from "../../../models/mimeTypes";
import { addMultipleItemsAsync } from "../../../models/playlist/playlist";
import PlaylistItem from "../../../models/playlistItem/playlistItem";
import { SpotifyTrackAlbum } from "../../../models/spotify/spotifyAlbum";
import SpotifyApiPlaylistTrack, { SpotifyApiTrack } from "../../../models/spotify/spotifyApiTrack";
import { SpotifyPlaylistSimplified } from "../../../models/spotify/spotifyPlaylist";
import Track, { YouTubeTrack } from "../../../models/track";
import DropboxTrack from "../../../models/track/dropboxTrack";
import GoogleDriveTrack from "../../../models/track/googleDriveTrack";
import OneDriveTrack from "../../../models/track/oneDriveTrack";
import SpotifyTrack from "../../../models/track/spotifyTrack";
import YouTubeKind from "../../../models/youTube/kind";
import { IDropboxContext } from "../../../services/dropbox/dropboxService";
import { IGoogleApiContext } from '../../../services/gapi/gapiService';
import handleGapiError from "../../../services/gapi/handleGapiError";
import { createAddItemsToPlaylistThenLibrary } from '../../../services/library/addTracksToPlaylistThenLibrary';
import { ILibraryContext } from "../../../services/library/libraryService";
import { IOneDriveContext } from "../../../services/oneDrive/oneDriveService";
import { ISpotifyContext } from '../../../services/spotify/spotifyService';
import { GlobalState } from "../../../services/_globalContext/context";
import { Keys, messages } from "../../../settings";
import createAddFolderToPlaylist from "../../../utilities/helperFactories/addFolderToPlaylist";
import createAddPlaylistToPlaylist from "../../../utilities/helperFactories/addPlaylistToPlaylist";

/**
 * 
 * @param ctx 
 * @param libCtx 
 * @param gapiCtx 
 * @param spotifyCtx 
 * @param oneDriveCtx 
 * @param dbxCtx 
 * @param addItems Use addItemsUsingRef to support YouTubeAddAny
 * @param addTracks 
 * @param ev 
 */
export function handleDropCommon(
  ctx: GlobalState,
  libCtx: ILibraryContext,
  gapiCtx: IGoogleApiContext,
  spotifyCtx: ISpotifyContext,
  oneDriveCtx: IOneDriveContext,
  dbxCtx: IDropboxContext,
  addItems: (plItms: PlaylistItem[]) => void,
  addTracks: (...tracks: Track[]) => Either<any, any>,
  ev: React.DragEvent,
) {
  const dataTransfer: DataTransfer = ev.dataTransfer;

  const dragType = dataTransfer.getData('dragType');

  // For more complex drag types we store all playlist items created from network requests
  // then listen for all requests completing
  // and finally add all the stored items to the playlist in one batch
  const tasks = Array<Promise<any>>();
  const items = Array<PlaylistItem>();
  const addItemsToArray = (x: PlaylistItem[]) => items.push(...x);

  const addSpotifyItemsThenToLibrary
    = createAddItemsToPlaylistThenLibrary<SpotifyApiPlaylistTrack | SpotifyApiTrack>(
      ctx,
      libCtx,
      SpotifyTrack.from,
    );
  const addYouTubeItemsThenToLibrary
    = createAddItemsToPlaylistThenLibrary<gapi.client.youtube.SearchResult | gapi.client.youtube.PlaylistItem>(
      ctx,
      libCtx,
      YouTubeTrack.from,
    );

  // There is no error boundary wrapping onDrop dom event handlers
  try {
    switch (dragType) {

      case DragType.SoundCloudPlaylist:
      case DragType.YouTubeChannel:
        throw new Error('Not implemented!');

      case DragType.TrackItems:

        var key = dataTransfer.getData('text/plain');
        var itemsRef = ctx.dndData[key] as { current: Iterable<Track> };
        if (itemsRef) {
          addTracks(...itemsRef.current);
        }
        break;

      case DragType.SpotifyPlaylist:
      case DragType.SpotifyAlbum:
        if (!spotifyCtx.spotifyApi) {
          ctx.callSnackbar(messages(Keys.spotifyNotLoggedIn));
          return;
        }

        const addSpotifyAlbumToPlaylist
          = createAddPlaylistToPlaylist(
            ctx,
            spotifyCtx.spotifyApi?.getAlbum!,
            addSpotifyItemsThenToLibrary,
            addItemsToArray
          );
        const addSpotifyPlaylistToPlaylist
          = createAddPlaylistToPlaylist(
            ctx,
            spotifyCtx.spotifyApi?.getPlaylist!,
            addSpotifyItemsThenToLibrary,
            addItemsToArray
          );

        var key = dataTransfer.getData('text/plain');
        const spotifyPlaylistsOrAlbumsRef = ctx.dndData[key] as React.MutableRefObject<
          Iterable<SpotifyTrackAlbum | SpotifyPlaylistSimplified>
        >;

        for (const item of spotifyPlaylistsOrAlbumsRef.current) {
          if (dragType === DragType.SpotifyPlaylist) {
            tasks.push(
              addSpotifyPlaylistToPlaylist(item.id)
            );
          }
          else /*if (dragType === DragType.SpotifyAlbum)*/ {
            tasks.push(
              addSpotifyAlbumToPlaylist(item.id)
            );
          }
        }

        addMultipleItemsAsync({ tasks, items, addItems });
        break;

      case DragType.YouTubePlaylist:

        const addYouTubePlaylistToPlaylist
          = createAddPlaylistToPlaylist(
            ctx,
            gapiCtx.getPlaylistItemsPaged,
            addYouTubeItemsThenToLibrary,
            addItems,
          );

        var key = dataTransfer.getData('text/plain');
        addYouTubePlaylistToPlaylist(key);
        break;

      case DragType.DropBoxFolder:

        const addDropboxItemsToPlaylistThenLibrary
          = createAddItemsToPlaylistThenLibrary(
            ctx,
            libCtx,
            DropboxTrack.from,
          );

        const addDropboxFolderToPlaylist
          = createAddFolderToPlaylist(
            ctx,
            dbxCtx.getAllTracksRecursive,
            addDropboxItemsToPlaylistThenLibrary(addItems),
            x => isAudioFile(x.name) && x[".tag"] === 'file',
            dbxCtx.errorHandler,
          );

        var key = dataTransfer.getData('text/plain');
        addDropboxFolderToPlaylist(key);
        break;

      case DragType.OneDriveFolder:

        const addOneDriveItemsToPlaylistThenLibrary
          = createAddItemsToPlaylistThenLibrary(
            ctx,
            libCtx,
            OneDriveTrack.from,
          );
        const addOneDriveFolderToPlaylist
          = createAddFolderToPlaylist(
            ctx,
            oneDriveCtx.configuredApi.msgraphGetByIdRecursive,
            addOneDriveItemsToPlaylistThenLibrary(addItems),
            x => x.audio !== undefined,
            // errorHandler already configured
          );

        var key = dataTransfer.getData('text/plain');
        addOneDriveFolderToPlaylist(key);
        break;

      case DragType.GoogleDriveFolder:

        const addGoogleDriveItemsToPlaylistThenLibrary
          = createAddItemsToPlaylistThenLibrary<gapi.client.drive.File>(
            ctx,
            libCtx,
            GoogleDriveTrack.from,
          );
        const addGoogleDriveFolderToPlaylist
          = createAddFolderToPlaylist(
            ctx,
            gapiCtx.getFilesInFolderRecursive,
            addGoogleDriveItemsToPlaylistThenLibrary(addItems),
            x => mimeTypes.has(x.mimeType!),
            handleGapiError(ctx.callSnackbar, gapiCtx.logout),
          );

        var key = dataTransfer.getData('text/plain');
        addGoogleDriveFolderToPlaylist(key);
        break;

      case DragType.YouTubeAny:

        console.info('playlistRowCommon YouTubeAny');

        const addYouTubePlaylistResultToPlaylist
          = createAddPlaylistToPlaylist(
            ctx,
            gapiCtx.getPlaylistItemsPaged,
            addYouTubeItemsThenToLibrary,
            addItemsToArray,
          );

        var key = dataTransfer.getData('text/plain');
        const selectedItemsRef = ctx.dndData[key] as React.MutableRefObject<
          Iterable<gapi.client.youtube.SearchResult>
        >;

        const searchResults = [...selectedItemsRef.current];
        const videos = searchResults.filter(x => x.id?.kind === YouTubeKind.YouTubeVideos);
        const playlists = searchResults.filter(x => x.id?.kind === YouTubeKind.YouTubePlaylist);
        const channels = searchResults.filter(x => x.id?.kind === YouTubeKind.YouTubeChannel);

        if (channels.length) {
          ctx.callSnackbar(
            'Skipping selected channels. YouTube channel adding is currently not supported.'
          );
        }

        addYouTubeItemsThenToLibrary(addItemsToArray)(...videos);
        for (const pl of playlists) {
          tasks.push(
            addYouTubePlaylistResultToPlaylist(pl.id!.playlistId!)
          );
        }

        addMultipleItemsAsync({
          tasks,
          items,
          addItems,
        });
    }
  }
  catch (err) {
    ctx.callSnackbar(err);

    throw err;
  }
}

export const baseSupportedDragTypes = [
  DragType.TrackItems,
  DragType.SoundCloudPlaylist,
  DragType.YouTubePlaylist,
  DragType.YouTubeAny,
  DragType.SpotifyPlaylist,
  DragType.SpotifyAlbum,
  DragType.DropBoxFolder,
  DragType.GoogleDriveFolder,
  DragType.OneDriveFolder,
];
