import React, { useContext } from 'react';
import { Context } from '../../services/_globalContext/context';

export default function StartupErrorComponent() {

  const ctx = useContext(Context);

  return (
    <article className="App" style={{ textAlign: 'unset' }}>
      <div className="grid-container">
        <ul>
        {!ctx.deviceCapabilities.hasServiceWorkers &&
            <li>
              <div className="callout alert">
                <h4>ServiceWorkers unavailable</h4>
                <p>Unable to complete startup, ServiceWorkers unavailable.</p>
                <p>iOS users please use Safari or install the app to home screen.</p>
                <p>Desktop users ensure browser is not in private mode.</p>
              </div>
            </li>
          }
          {!ctx.deviceCapabilities.hasLocalStorage &&
            <li>
              <div className="callout alert">
                <h4>LocalStorage unavailable</h4>
                <p>Unable to complete startup, LocalStorage unavailable.</p>
                <p>Ensure browser is not in private mode.</p>
              </div>
            </li>
          }
          {(!ctx.deviceCapabilities.hasFetch ||
            !ctx.deviceCapabilities.hasPromise ||
            !ctx.deviceCapabilities.hasTextEncoder || 
            !ctx.deviceCapabilities.hasIndexedDB) &&

            <li>
              <div className="callout alert">
                <h5>Incompatible device!</h5>
                <p>Unable to complete startup. Missing core browser capabilities.</p>
                <p>Fadila requires a modern browser to run, please update your browser and/or device and try again.</p>
              </div>
            </li>
          }
        </ul>
      </div>
    </article>

  );
}
