import { updateGapiToken } from "../serviceWorker/serviceWorker";

/** 
 * We don't want to refresh after the token has expired
 * But we also don't want to refresh before the gapi client automatically refreshes.
 */
const gracePeriod = 2 * 60 * 1000; // 2m
export default class GapiTokenService {

  currAuthentication?: gapi.auth2.AuthResponse = undefined;
  task?: NodeJS.Timeout;
  configureToken(authResponse: gapi.auth2.AuthResponse) {
    console.debug('Configuring token', authResponse);

    if (authResponse.expires_at > (this.currAuthentication?.expires_at || 0)) {
      this.currAuthentication = authResponse;

      // We only ever want one delayed task queued
      if (this.task) {
        clearTimeout(this.task);
      }

      this.task = setTimeout(
        this.refreshToken,
        authResponse.expires_at - Date.now() - gracePeriod
      );
      updateGapiToken(authResponse.access_token);
    }
  }

  refreshToken = async () => {
    console.debug('Refreshing token');

    const authInstance = gapi.auth2.getAuthInstance();
    const currUser = authInstance.currentUser.get();
    let authResponse = currUser.getAuthResponse(true);

    if (authResponse.expires_at > (this.currAuthentication?.expires_at || 0)) {
      this.currAuthentication = authResponse;
      updateGapiToken(authResponse.access_token);
    }
    else {
      console.warn(`Refreshed token but received same or worse expires_at, reloading auth response`, this.currAuthentication, authResponse);
      authResponse = await currUser.reloadAuthResponse();
      console.debug(authResponse);
    }

    // We keep token refreshed after the first gapi request
    // ensuring audio elements relying on service worker header proxying
    // always have a token.
    this.task = setTimeout(
      this.refreshToken,
      // Wait minimum of 300ms between requests
      Math.max(authResponse.expires_at - Date.now() - gracePeriod, 300)
    );
  }
}
