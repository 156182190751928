/**
 * Default Limit 20, Min 1, Max 50
 */

import QueryIterator from '../../models/queryIterator';
import PagingObject from '../../models/spotify/pagingObject';
import Playlists from '../../models/spotify/playlists';
import { SpotifyAlbum, SpotifyTrackAlbum } from '../../models/spotify/spotifyAlbum';
import SpotifyApiPlaylistTrack, { SpotifyApiTrack, SpotifyTrackSimplified } from '../../models/spotify/spotifyApiTrack';
import SpotifyArtist from '../../models/spotify/spotifyArtist';
import SpotifyPlaylist, { SpotifyPlaylistSimplified } from '../../models/spotify/spotifyPlaylist';
import SpotifySearchResponse, { SpotifySearchIterators } from '../../models/spotify/spotifySearchResponse';
import UserProfile from '../../models/spotify/userProfile';

export const scopes = [
  'streaming', // Web Playback
  'user-modify-playback-state', // Web Playback

  'user-read-email', // account info
  'user-read-private', // User market country code

  'playlist-read-private',
  'playlist-modify-private',
  'playlist-modify-public',
  'playlist-read-collaborative',

  'user-library-modify',
];

export const apiUrl = 'https://api.spotify.com/v1';
export const clientID = '44708940782a489380045ba609767166';

function createHeaders(accessToken: string) {
  return new Headers({
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  });
}
async function spotifyRequest(
  url: string,
  accessToken?: string | undefined,
  method = 'GET',
  body: any = undefined,
  skipApiUrl = false,
) {
  if (accessToken === undefined) {
    throw new Error('Missing access token');
  }

  const res = await fetch(skipApiUrl ? url : apiUrl + url, {
    method,
    headers: createHeaders(accessToken),
    body: body && JSON.stringify(body),
  });

  if (res.status === 204) {
    return;
  }

  if (!res.ok) {
    throw await res.json();
  }

  try {
    const json = await res.json();
    console.debug(json);
    return json;
  } catch {
    return;
  }
}

export async function getUserProfile(accessToken: string): Promise<UserProfile> {
  return await spotifyRequest('/me', accessToken);
}
export async function getUserPlaylists(
  accessToken: string,
  limit = 50,
): Promise<QueryIterator<SpotifyPlaylistSimplified>> {

  async function doGetUserPlaylists(): Promise<Playlists> {
    return await spotifyRequest(
      `/me/playlists?limit=${Math.min(50, limit)}&offset=0`,
      accessToken);
  }

  const response = await doGetUserPlaylists();

  function createGetNextPagedPlaylists(nextLink: string) {
    return async function getNextPagedPlaylists() {

      const response: Playlists = await spotifyRequest(
        nextLink, 
        accessToken, 
        undefined, 
        undefined, 
        true,
      );

      return {
        results: response.items,
        next: response.next !== null ? createGetNextPagedPlaylists(response.next) : undefined,
        totalResults: response.total,
      };
    }
  }

  return {
    results: response.items,
    next: response.next !== null ? createGetNextPagedPlaylists(response.next) : undefined,
    totalResults: response.total,
  };
}

export async function getPlaylist(
  accessToken: string,
  playlistId: string,
): Promise<QueryIterator<SpotifyApiPlaylistTrack>> {

  const playlist: SpotifyPlaylist = await spotifyRequest(
    `/playlists/${playlistId}`,
    accessToken,
  );

  let iter = playlist.tracks;

  async function doGet() {
    const pagingObj: PagingObject<SpotifyApiPlaylistTrack> = await spotifyRequest(
      iter.next!, 
      accessToken,
      undefined,
      undefined,
      true,
    );

    return {
      results: pagingObj.items,
      totalResults: pagingObj.total,
      next: pagingObj.next ? doGet : undefined,
    };
  }

  return {
    results: iter.items,
    totalResults: iter.total,
    next: iter.next ? doGet : undefined,
  };
}
export async function getAlbum(
  accessToken: string,
  albumId: string,
): Promise<QueryIterator<SpotifyApiTrack>> {

  const album: SpotifyAlbum = await spotifyRequest(
    `/albums/${albumId}`,
    accessToken
  );
  let {
    tracks: iter,
    ...albumProps
  } = album;

  const trackAlbum: SpotifyTrackAlbum = {
    total_tracks: iter.total,
    ...albumProps,
  };

  async function doGet(): Promise<QueryIterator<SpotifyApiTrack>> {
    const pagingObj: PagingObject<SpotifyTrackSimplified> = await spotifyRequest(
      iter.next!, 
      accessToken,
      undefined,
      undefined,
      true,
    );

    return {
      results: pagingObj.items.map(x => ({
        ...x,
        external_ids: undefined as any,
        album: trackAlbum,
        popularity: album.popularity,
      })),
      totalResults: pagingObj.total,
      next: pagingObj.next ? doGet : undefined,
    };
  }

  return {
    results: iter.items.map(x => ({
      ...x,
      external_ids: undefined as any,
      album: trackAlbum,
      popularity: album.popularity,
    })),
    totalResults: iter.total,
    next: iter.next ? doGet : undefined,
  };
}

/** Plural of SpotifyType */
export type SpotifyTypes = 'artists' | 'albums' | 'playlists' | 'tracks';
export type SearchResult = SpotifyApiTrack | SpotifyTrackAlbum | SpotifyArtist | SpotifyPlaylistSimplified;
/**
 * 
 * @param q 
 * @param accessToken 
 * @param type 
 * @param limit The limit is applied within each type, not on the total response.
 * For example, if the limit value is 3 and the type is artist,album, the response contains 3 artists and 3 albums.
 */
export async function search(
  accessToken: string,
  q: string,
  limit = 50,
  type = 'album,artist,playlist,track',
): Promise<SpotifySearchIterators> {

  // let offset = 0;

  async function doSearch(): Promise<SpotifySearchResponse> {
    return await spotifyRequest(
      `/search?q=${encodeURIComponent(q)}&market=from_token` +
      `&type=${type}&limit=${Math.min(50, limit)}&offset=0`,
      accessToken,
    );
  }

  const response = await doSearch();

  function createNext<T>(nextLink: string, type: SpotifyTypes) {
    return async function (): Promise<QueryIterator<T>> {

      const response: SpotifySearchResponse = await spotifyRequest(
        nextLink, 
        accessToken, 
        undefined, 
        undefined, 
        true,
      );

      if (response[type] !== undefined) {
        const pagingObj: PagingObject<T> = response[type] as any;

        return {
          results: pagingObj.items,
          next: pagingObj.next !== null ? createNext<T>(pagingObj.next, type) : undefined,
          totalResults: pagingObj.total,
        };
      } else { // Should never happen
        return {
          results: [],
          next: undefined,
          totalResults: 0,
        };
      }
    };
  }

  return {
    albums: response.albums && {
      results: response.albums.items,
      next: response.albums.next !== null
        ? createNext(response.albums.next, 'albums')
        : undefined,
      totalResults: response.albums.total,
    },
    artists: response.artists && {
      results: response.artists.items,
      next: response.artists.next !== null
        ? createNext(response.artists.next, 'artists')
        : undefined,
      totalResults: response.artists.total,
    },
    playlists: response.playlists && {
      results: response.playlists.items,
      next: response.playlists.next !== null
        ? createNext(response.playlists.next, 'playlists')
        : undefined,
      totalResults: response.playlists.total,
    },
    tracks: response.tracks && {
      results: response.tracks.items,
      next: response.tracks.next !== null
        ? createNext(response.tracks.next, 'tracks')
        : undefined,
      totalResults: response.tracks.total,
    },
  };
}

export async function playTrack(
  accessToken: string,
  playerId: string,
  trackId: string,
) {
  return await spotifyRequest(
    `/me/player/play?device_id=${playerId}`,
    accessToken,
    'PUT',
    { uris: [`spotify:track:${trackId}`] },
  );
}

/**
 * 
 * @param accessToken Spotify Access Token
 * @param wrapper F.x. exception handling
 */
export default function configureSpotifyApi(
  accessToken: string,
  wrapper = <T>(x: () => Promise<T>) => x(),
) {
  return {
    getUserProfile: () => wrapper(() => getUserProfile(accessToken)),
    getUserPlaylists: (limit?: number) => wrapper(() => getUserPlaylists(accessToken, limit)),
    getPlaylist: (playlistId: string) => wrapper(() =>
      getPlaylist(accessToken, playlistId)),
    getAlbum: (albumId: string) => wrapper(() =>
      getAlbum(accessToken, albumId)),

    playTrack: (playerId: string, spotifyUri: string) =>
      wrapper(() => playTrack(accessToken, playerId, spotifyUri)),

    search: (
      q: string,
      limit?: number,
      type?: string,
    ) => wrapper(() => search(accessToken, q, limit, type)),
  };
}
