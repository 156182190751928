import React from 'react';
import { DragType } from '../../../models/dragTypes';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import StreamingServicePlaylistRow, { AdditionalContentProps, StreamingServicePlaylist } from './streamSvcPlaylistPlaylistRow';

export interface StreamingServicePlaylistRowData<T> {
  curUserPl?: T;
  playlists: T[];
  dragType: DragType;
  getPlaylistItems(pl: T): Promise<void>;
  addPlaylistToPlaylist(addItems: (i: PlaylistItem[]) => void): (playlistId: string) => Promise<void>;
  titleAccessor(pl: T): string;
  additionalContent?: React.FC<AdditionalContentProps<T>>;
  portalTarget?: HTMLElement;

}
interface Props<T> {
  index: number;
  style: React.CSSProperties;
  data: StreamingServicePlaylistRowData<T>;
}

export default function StreamingServicePlaylistRowRenderer({
  index,
  style,
  data,
}: Props<StreamingServicePlaylist>) {

  const {
    curUserPl,
    playlists,
    dragType,
    getPlaylistItems,
    addPlaylistToPlaylist,
    titleAccessor,
    additionalContent,
    portalTarget,
  } = data;

  const playlist = playlists[index];

  return (
    <div style={style}>
      <StreamingServicePlaylistRow
        key={playlist.id!}
        id={playlist.id!}
        dragType={dragType}

        isSelected={curUserPl === playlist}
        title={titleAccessor(playlist)}
        playlist={playlist}

        getPlaylistItems={getPlaylistItems}
        addPlaylistToPlaylist={addPlaylistToPlaylist}

        additionalContent={additionalContent}

        portalTarget={portalTarget}
      />
    </div>
  );
}
