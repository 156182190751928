import Playlist from "../../models/playlist/playlist";
import PlaylistItem from "../../models/playlistItem/playlistItem";
import Source from "../../models/source";
import Track from "../../models/track";
import NullTrack from "../../models/track/nullTrack";
import Constants from "../../settings";
import TrackLibrary from "./trackLibrary";

export default class MusicLibrary {
  static fromJson(lsLib: MusicLibrary) {
    return new MusicLibrary(lsLib);
  }

  // Take care to NEVER CREATE PROPERTIES in this class

  get size() {
    let size = 0;

    for (let type in this) {
      const targetLib = this[type as unknown as Source];
      size += Object.keys(targetLib).length;
    }

    return size;
  }
  createPlaylist() {
    const tracks: Track[] = [];

    for (let type in this) {
      const targetLib = this[type as unknown as Source];
      for (let trackId in targetLib) {
        tracks.push(targetLib[trackId]);
      }
    }

    return new Playlist(Constants.LibraryName, tracks.map(PlaylistItem.from), Constants.LibraryId);
  }
  clone() {
    return new MusicLibrary(this, true);
  }
  private NullProxy(lib: TrackLibrary) {
    var handler = {
      get: function (obj: TrackLibrary, prop: string) {
        return obj[prop] || new NullTrack();
      }
    };

    return new Proxy(lib, handler);
  }

  [Source.Null]: TrackLibrary = this.NullProxy({});
  [Source.File]: TrackLibrary = this.NullProxy({});
  [Source.YouTube]: TrackLibrary = this.NullProxy({});
  [Source.OneDrive]: TrackLibrary = this.NullProxy({});
  [Source.GoogleDrive]: TrackLibrary = this.NullProxy({});
  [Source.Dropbox]: TrackLibrary = this.NullProxy({});
  [Source.Spotify]: TrackLibrary = this.NullProxy({});
  [Source.SoundCloud]: TrackLibrary = this.NullProxy({});
  [Source.Podcast]: TrackLibrary = this.NullProxy({});

  constructor();
  /** Construct from JSON */
  constructor(lsLibraryJSON: MusicLibrary);
  /** Clone */
  constructor(lsLibrary: MusicLibrary, skipProxy: true);
  constructor(lsLibraryJSON?: MusicLibrary, skipProxy?: true) {
    if (lsLibraryJSON) {
      for (const source in Source) {
        const sourceVal = +source as Source;

        if (!isNaN(sourceVal)) {
          if (skipProxy) {
            // || {} Handles library section additions, f.x. Source.XXX but missing in json
            this[sourceVal] = lsLibraryJSON[sourceVal] || {};
          }
          else {
            this[sourceVal] = this.NullProxy(lsLibraryJSON[sourceVal] || {});
          }
        }
      }
    }
  }
}
