enum SyncType {
  /** Signifies absence of a track */
  Podcast,
  YouTubePlaylist,
  OneDriveFolder,
  GoogleDriveFolder,
  DropboxFolder,
  SpotifyPlaylist,
  SoundCloudPlaylist,
}

export default SyncType;
