import * as Msal from '@azure/msal';
import { Client, PageCollection, PageIterator, PageIteratorCallback } from "@microsoft/microsoft-graph-client";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { curry } from 'fp-ts/es6/function';
import { oneDriveEncodeUri } from '../../utilities/preEncodeUri';
import { Flate } from '../_globalContext/context';
import { MSALAuthenticationProvider } from "./MSALAuthenticationProvider";
import { getDataFile, getOrCreateDataFile, putFile } from './oneDriveApi.appData';

/** https://docs.microsoft.com/en-us/graph/api/driveitem-list-children?view=graph-rest-1.0&tabs=http#response */
export const defaultPageSize = 200;

/**
 * We still sometimes see the constant reauth problem
 * Troubleshooting entails putting breakpoints in 
 * MSALAuthenticationProvider getAuthToken and checking the returned error msg
 * Seems to be related to multiple access tokens
 */
const MsalConfiguration: Msal.Configuration = {
  auth: {
    clientId: process.env.NODE_ENV === 'production'
      ? '5257fb08-186b-4f0b-b538-8498d8cf4d32'
      : '7a2381a9-5a1e-447c-a5fc-dcf0725c6fcc',
    /** This does mean that users current onedrive path gets ignored on reauth */
    redirectUri: window.location.origin + '/app/onedrive',
    authority: `https://login.microsoftonline.com/common`,
    // postLogoutRedirectUri: '/',
  },
  system: {

  },
  framework: {

  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};
const AuthenticationParameters: Msal.AuthenticationParameters = {
  scopes: [
    'User.Read',
    'Files.ReadWrite',
  ],
};

export const OneDriveDownloadUrlTimeoutMs = 2700000;

/**
 * Msal.UserAgentApplication w/ config
 * Take care to never create new instances of the UA
 * That seems to be a cause of authority null localStorage items and other issues.
 * Probably creates multiple url hash 'listeners' ?
 */
export const getUA = (function () {
  let UA: Msal.UserAgentApplication;
  return function () {
    if (!UA) {
      UA = new Msal.UserAgentApplication(MsalConfiguration);

      if (process.env.NODE_ENV === 'development') {
        (window as any).UA = UA;
      }
    }
    return UA;
  };
})();
export function getClient() {
  const userAgentApplication = getUA();

  const authProvider
    = new MSALAuthenticationProvider(
      userAgentApplication,
      AuthenticationParameters.scopes!,
    );

  const client = Client.initWithMiddleware({
    authProvider,
  });

  if (process.env.NODE_ENV === 'development') {
    (window as any).msgraphClient = client;
  }

  return client;
}

export async function login() {
  const userAgentApplication = getUA();
  return await userAgentApplication.acquireTokenSilent(AuthenticationParameters);
}

/**
  * Unused
  */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const msgraphGetStream = curry(async function (
  graphClient: Client,
  _errorHandler: ((message: any) => void) | undefined,
  endpoint: string,
) {
  const drive = await graphClient.api(endpoint).getStream();
  console.debug(drive);
  return drive;
});

export const msgraphGet = function (
  graphClient: Client,
  _errorHandler: ((message: any) => void) | undefined,
) {
  return async function (
    endpoint: string,
  ) {

    const data = await graphClient.api(endpoint).get();

    console.debug(data);
    return data;
  }
};


export interface PagedResponse {
  iterator: PageIterator;
  response: PageCollection;
  totalItemCount: number;
}
// The paged requests when run to completion have no maximum item count
// unlike the simple get functions below
export const msgraphGetPaged = curry(async function (
  graphClient: Client,
  _errorHandler: ((message: any) => void) | undefined,
  endpoint: string,
  callback: PageIteratorCallback,
): Promise<PagedResponse> {
  const response: PageCollection
    = await graphClient.api(endpoint).get();
  console.debug(response);

  // Ignore personal vault in count
  if (endpoint === '/me/drive/root/children') {
    var totalItemCount = (response as any)['@odata.count'] - 1;
  }
  else {
    var totalItemCount: number = (response as any)['@odata.count'];
  }

  return {
    iterator: new PageIterator(graphClient, response, callback),
    response,
    totalItemCount,
  };
});

export const msgraphGetPagedById = curry(async function (
  graphClient: Client,
  errorHandler: ((message: any) => void) | undefined,
  driveItemId: string,
  callback: PageIteratorCallback,
): Promise<PagedResponse> {
  const response: PageCollection
    = await msgraphGet(graphClient, errorHandler)
      (`/me/drive/items/${driveItemId}/children`);

  return {
    iterator: new PageIterator(graphClient, response, callback),
    response,
    totalItemCount: (response as any)['@odata.count'],
  };
});

/**
 * Returns all files in a folder and it's sub-folders, filters out folders afterwards.
 */
export const msgraphGetByIdRecursive = curry(async function (
  graphClient: Client,
  errorHandler: ((message: any) => void) | undefined,
  driveItemId: string,
): Promise<MicrosoftGraph.DriveItem[]> {

  const driveItems: MicrosoftGraph.DriveItem[] = [];
  function callback(driveItem: MicrosoftGraph.DriveItem) {
    driveItems.push(driveItem);

    return true;
  }

  const response: PageCollection
    = await msgraphGet(graphClient, errorHandler)
      (`/me/drive/items/${driveItemId}/children`);

  const iter = new PageIterator(graphClient, response, callback);

  await iter.iterate();

  const items = driveItems.filter(x => !x.folder);

  for (let folder of driveItems.filter(x => x.folder)) {
    const subItems = await msgraphGetByIdRecursive
      (graphClient)
      (errorHandler)
      (folder.id!);

    items.push(...subItems);
  }

  return items;
});

export const getChildrenById = curry(async function (
  graphClient: Client,
  errorHandler: ((message: any) => void) | undefined,
  driveItemId: string,
): Promise<MicrosoftGraph.DriveItem[]> {

  const res = await msgraphGet(graphClient, errorHandler)
    (`/me/drive/items/${driveItemId}/children`);
  return res.value;
});

export const getChildrenByPath = curry(async function (
  graphClient: Client,
  errorHandler: ((message: any) => void) | undefined,
  path: string,
): Promise<MicrosoftGraph.DriveItem[]> {

  const res = await msgraphGet(graphClient, errorHandler)
    (`/me/drive/root:/${path}:/children`);
  return res.value;
});
export const getItem = curry(async (
  graphClient: Client,
  errorHandler: ((message: any) => void) | undefined,
  driveItemId: string,
): Promise<MicrosoftGraph.DriveItem> =>
  await msgraphGet(graphClient, errorHandler)
    (`/me/drive/items/${driveItemId}`));

export const endpointFromPath = function (path: string, explicitPath?: boolean) {
  if (explicitPath) {
    return oneDriveEncodeUri(path);
  }
  else {
    return oneDriveEncodeUri(`/me/drive/root:/${path}:/children`);
  }
};

export function configureOneDriveApi(
  graphClient: Client,
  errorHandler: ((message: any) => void) | undefined,
  flate: Flate | undefined,
) {
  return {
    getDataFile: getDataFile(graphClient)(errorHandler)(flate),
    getOrCreateDataFile: getOrCreateDataFile(graphClient)(errorHandler)(flate),
    putFile: putFile(graphClient)(errorHandler)(flate),

    msgraphGetPaged: msgraphGetPaged(graphClient)(errorHandler),
    msgraphGetPagedById: msgraphGetPagedById(graphClient)(errorHandler),
    msgraphGet: msgraphGet(graphClient, errorHandler),
    msgraphGetByIdRecursive: msgraphGetByIdRecursive(graphClient)(errorHandler),

    getChildrenById: getChildrenById(graphClient)(errorHandler),
    getChildrenByPath: getChildrenByPath(graphClient)(errorHandler),
    getItem: getItem(graphClient)(errorHandler),
  }
}
