/**
 * https://github.com/captbaritone/webamp/blob/master/js/fileUtils.ts
 * @param url 
 */
export function genMediaDuration(url: string): Promise<number> {
  if(typeof url !== "string") {
    throw new Error("Attempted to get the duration of media file without passing a url");
  }
  
  return new Promise((resolve, reject) => {
    const audio = document.createElement("audio");
    audio.crossOrigin = "anonymous";
    // TODO: Does this actually stop downloading the file once it's
    // got the duration?
    audio.preload = 'metadata';
    const durationChange = () => {
      const duration = audio.duration;
      audio.removeEventListener("durationchange", durationChange);
      audio.src = "";
      resolve(duration);
    };
    audio.addEventListener("durationchange", durationChange);
    audio.addEventListener("error", e => {
      reject(e);
    });
    audio.src = url;
  });
}
