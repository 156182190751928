import React, { useContext, useRef, useState } from 'react';
import { DragType } from '../../../models/dragTypes';
import Playlist, { moveItems } from '../../../models/playlist/playlist';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import Source from '../../../models/source';
import Track from '../../../models/track';
import { LibraryContext } from '../../../services/library/libraryService';
import { Context } from '../../../services/_globalContext/context';
import Constants from '../../../settings';
import { preventDefaultStopPropogation } from '../../../utilities/preventDefault';
import AddToPlaylistSubMenu from '../../common/addToPlaylist/addToPlaylistSubMenu';
import FadilaMenuItem from '../../common/contextMenu/fadilaMenuItem';
import FadilaSubMenu from '../../common/contextMenu/fadilaSubMenu';
import s from '../../common/itemRow/itemRow.module.scss';
import ItemRowInner from '../../common/itemRow/itemRowInner';
import { ItemRowProps } from '../../common/itemRow/itemRowPropsAndTypes';
import useParentPlaylists from './useParentPlaylistsHook';

export interface PlaylistItemRowProps extends ItemRowProps<PlaylistItem> {
  playlist: Playlist;

  playlistItem: PlaylistItem;

  savePodcastItem(plItm: PlaylistItem, track: Track): void;
  ignorePodcastItemInPlaylist(track: Track): void;
}

export default React.memo(function PlaylistItemRow(props: PlaylistItemRowProps) {

  const {
    index,
    playlist,
    columnScope,
    moveable,
    moveDescending,
  
    playlistItem,
    handlePlayTrack,
    removeItems,
    isSelected,
    selectedItemsRef: selectedItems,
    handleToggleItem,
    handleShiftSelect,
    changeItemSelectionState,
  
    clearSelected,
  
    savePodcastItem,
    // ignorePodcastItemInPlaylist,
  
    portalTarget,
  } = props;

  const innerRowRef = useRef<HTMLElement>(null);
  const titleElRef = useRef<HTMLTableDataCellElement>(null);

  const ctx = useContext(Context);
  const libCtx = useContext(LibraryContext);
  const track = libCtx.getLibraryTrack(playlistItem);

  const [isDragging, setIsDragging] = useState(false);
  const [isHoverTop, setIsHoverTop] = useState(false);
  const [isHoverBottom, setIsHoverBottom] = useState(false);

  const [parentPlaylists, computeParentPlaylists, clearParents]
    = useParentPlaylists(playlistItem, playlist, libCtx.playlists);

  // function handleDelete(remItm?: typeof removeItems) {
  //   return remItm
  //     ? function () {

  //       remItm(playlistItem);
  //       changeItemSelectionState(playlistItem, false);

  //       if (track.type === Source.Podcast) {
  //         // Maybe playlists set up to sync with a podcast should be
  //         // static in this regard
  //         // I do think if they user wants the podcast item gone, it should be gone
  //         // You can always re-add the podcast otherwise
  //         ignorePodcastItemInPlaylist(track);
  //       }
  //     }
  //     : undefined
  //     ;
  // }
  /** This one deletes all selected items */
  function menuOptionDelete() {
    if (removeItems) {
      removeItems(...selectedItems.current);
      selectedItems.current.clear();
    }
  }
  function menuOptionDeleteFromLibrary() {
    libCtx.removeFromLibrary(selectedItems.current);
  }

  const onDragStart = (
    ev: React.DragEvent,
  ) => {
    console.debug('dragstart on playlist items');

    const key = Date.now().toString();
    // We set loads of data here since you can't call getData in onDragOver
    ev.dataTransfer.setData('dragType', DragType.PlaylistItems);
    // types.includes checks for matching drag type in onDragOver
    ev.dataTransfer.setData(DragType.PlaylistItems, DragType.PlaylistItems);
    ev.dataTransfer.setData('text/plain', key);
    ev.dataTransfer.setData('name', track.album || track.artist || track.title);

    ev.dataTransfer.effectAllowed = 'copyMove';

    if (titleElRef.current) {
      ev.dataTransfer.setDragImage(titleElRef.current, 0, 0);
    }

    setIsDragging(true);
    if (!isSelected) {
      clearSelected();
      changeItemSelectionState(playlistItem, true, true);
    }
    ctx.dndData[key] = selectedItems;
  };
  const onDragOver = (ev: React.DragEvent) => {

    if (ev.dataTransfer.types.includes(DragType.PlaylistItems)) {

      // Can't drop on self and onDragStart ensures item is selected on drag
      if (!isSelected) {

        ev.preventDefault();

        if (innerRowRef.current) {
          var pos = innerRowRef.current.getBoundingClientRect();

          if (ev.clientY <= pos.top + (pos.height / 2)) {
            setIsHoverTop(true);
            setIsHoverBottom(false);
          }
          else /*if (ev.clientY > pos.top + (pos.height / 3) * 2)*/ {
            setIsHoverTop(false);
            setIsHoverBottom(true);
          }

          ev.dataTransfer.dropEffect = 'move';
        }
      } else {
        ev.dataTransfer.dropEffect = 'none';
      }
    }
  };
  const onDragLeave = (_ev: React.DragEvent) => {
    console.debug('onDragLeave');
    setIsHoverTop(false);
    setIsHoverBottom(false);
  };
  const onDrop = (
    ev: React.DragEvent,
  ) => {
    console.debug('onDrop');

    if (innerRowRef.current &&
      ev.dataTransfer.types.includes(DragType.PlaylistItems) &&
      !selectedItems.current.has(playlistItem)) {

      ev.preventDefault();

      var pos = innerRowRef.current.getBoundingClientRect();

      if (ev.clientY <= pos.top + (pos.height / 2)) {
        var isAfter = moveDescending
          ? true
          : false;
      }
      else /*if (ev.clientY > pos.top + (pos.height / 3) * 2)*/ {
        var isAfter = moveDescending
          ? false
          : true;
      }

      moveItems(playlist, libCtx.updatePlaylist)(selectedItems.current)(playlistItem)(isAfter);
    }

    setIsHoverTop(false);
    setIsHoverBottom(false);
  };

  function onDragEnd(ev: React.DragEvent) {
    setIsDragging(false);

    const key = ev.dataTransfer.getData('text/plain');

    delete ctx.dndData[key];
  }

  const moveableHandlers = moveable
    ? {
      onDragEnter: (ev: React.DragEvent) => ev.preventDefault(),
      onDragOver,
      onDragLeave,
      onDrop,
    }
    : {}
    ;

  function handleGetTrackTags() {
    selectedItems.current.forEach(x => {
      const track = libCtx.getLibraryTrack(x);
      libCtx.getTrackTags(track);
    });
  }
  function handleSavePodcastItem() {
    selectedItems.current.forEach(x => {
      const track = libCtx.getLibraryTrack(x);
      savePodcastItem(playlistItem, track);
    });
  }
  function handleAddToPlaylist(addItems: (i: PlaylistItem[]) => void) {
    addItems([...selectedItems.current]);
  }

  return (
    <ItemRowInner
      index={index}
      track={track}
      innerRowRef={innerRowRef}
      titleElRef={titleElRef}

      onDragStart={onDragStart}
      onDragEnd={onDragEnd}

      isHoverTop={isHoverTop}
      isHoverBottom={isHoverBottom}
      moveableHandlers={moveableHandlers}

      isSelected={isSelected}
      isDragging={isDragging}

      handleShiftSelect={() => handleShiftSelect(playlistItem)}
      handleToggleItem={() => handleToggleItem(playlistItem)}
      selectOnlyItem={() => changeItemSelectionState(playlistItem, true, true)}

      handlePlay={() => handlePlayTrack(playlistItem)}
      // handleDelete={handleDelete(removeItems)}

      onShowContextMenu={computeParentPlaylists}
      onHideContextMenu={clearParents}
      menuItems={
        <>
          <FadilaMenuItem
            onClick={preventDefaultStopPropogation(() => handlePlayTrack(playlistItem))}
            attributes={{
              className: 'secondary',
            }}
          >
            Play
          </FadilaMenuItem>
          <AddToPlaylistSubMenu
            newPlaylistName={track.album || track.artist || track.title}
            playlistItemOrItems={addItems => handleAddToPlaylist(addItems)}
          />
          <FadilaMenuItem
            onClick={preventDefaultStopPropogation(
              handleGetTrackTags
            )}
          >
            Refetch Tags
          </FadilaMenuItem>
          {track.type === Source.Podcast &&
            <FadilaMenuItem
              onClick={preventDefaultStopPropogation(
                handleSavePodcastItem
              )}
            >
              Save to library
            </FadilaMenuItem>
          }
          <FadilaSubMenu
            title="Parent playlists"
          >
            {parentPlaylists.length > 0
              ?
              parentPlaylists.map(pl =>
                <FadilaMenuItem key={pl.id}>{pl.name}</FadilaMenuItem>
              )
              :
              <FadilaMenuItem>
                No other parents
              </FadilaMenuItem>
            }
          </FadilaSubMenu>
          {removeItems &&
            <FadilaMenuItem
              onClick={preventDefaultStopPropogation(
                menuOptionDelete
              )}
              attributes={{
                className: s.menuOptionDelete,
              }}
            >
              Delete Selected
            </FadilaMenuItem>
          }
          {removeItems && playlist.id !== Constants.LibraryId &&
            <FadilaMenuItem
              onClick={preventDefaultStopPropogation(
                menuOptionDeleteFromLibrary
              )}
              attributes={{
                className: s.menuOptionDelete,
              }}
            >
              Delete Selected From Library
            </FadilaMenuItem>
          }
        </>
      }
      columnScope={columnScope}
      portalTarget={portalTarget}
    />
  );
});
