import React from 'react';
import MobileMenu from '../mobileMenu/mobileMenu';
import Player from '../player/player';
import s from './footer.module.css';

interface Props {

}

export default function Footer({

}: Props) {

  return (
    <footer className={s.footer}>
      <Player />
      <MobileMenu />
    </footer>
  );
};
