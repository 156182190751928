import React, { useContext, useRef, useState } from 'react';
import { DragType } from '../../../models/dragTypes';
import Source from '../../../models/source';
import Track from '../../../models/track';
import createAddTracksToPlaylistThenLibrary from '../../../services/library/addTracksToPlaylistThenLibrary';
import { LibraryContext } from '../../../services/library/libraryService';
import { Context } from '../../../services/_globalContext/context';
import { preventDefaultStopPropogation } from '../../../utilities/preventDefault';
import AddToPlaylistSubMenu from '../../common/addToPlaylist/addToPlaylistSubMenu';
import FadilaMenuItem from '../../common/contextMenu/fadilaMenuItem';
import ItemRowInner from '../../common/itemRow/itemRowInner';
import { ItemRowProps } from '../../common/itemRow/itemRowPropsAndTypes';

export interface TrackItemRowProps extends ItemRowProps<Track> {

  dragType: DragType;

  track: Track;
}

export default React.memo(function TrackItemRow({
  index,
  extraColumns,
  columnScope,
  isSelected,
  moveDescending,
  portalTarget,

  moveable,

  track,
  handlePlayTrack,
  removeItems,

  selectedItemsRef: selectedItems,
  handleToggleItem,
  changeItemSelectionState,
  handleShiftSelect,
  clearSelected,
  dragType,

}: TrackItemRowProps) {

  const innerRowRef = useRef<HTMLElement>(null);
  const titleElRef = useRef<HTMLTableDataCellElement>(null);

  const ctx = useContext(Context);
  const libCtx = useContext(LibraryContext);

  const [isDragging, setIsDragging] = useState(false);

  function handleDelete(remItm?: typeof removeItems) {
    return remItm
      ? function () {

        remItm(track);
        changeItemSelectionState(track, false);

        if (track.type === Source.Podcast) {
          // Maybe playlists set up to sync with a podcast should be
          // static in this regard
          // ignorePodcastItemInPlaylist(track);
        }
      }
      : undefined
      ;
  }
  /** This one deletes all selected items */
  function menuOptionDelete() {
    if (removeItems) {
      removeItems(...selectedItems.current);
    }
  }

  const addTracksToPlaylistThenLibrary = createAddTracksToPlaylistThenLibrary(ctx, libCtx);

  const onDragStart = (
    ev: React.DragEvent,
  ) => {
    console.debug('dragstart on track item');

    // We set loads of data here since you can't call getData in onDragOver
    ev.dataTransfer.setData('dragType', dragType);
    // types.includes checks for matching drag type in onDragOver
    ev.dataTransfer.setData(dragType, dragType);
    ev.dataTransfer.setData('text/plain', track.id);
    ev.dataTransfer.setData('name', track.album || track.artist || track.title);

    ev.dataTransfer.effectAllowed = 'copy';

    if (titleElRef.current) {
      ev.dataTransfer.setDragImage(titleElRef.current, 0, 0);
    }

    setIsDragging(true);
    if (!isSelected) {
      clearSelected();
      changeItemSelectionState(track, true, true);
    }
    ctx.dndData[track.id] = selectedItems;
  };
  function onDragEnd(ev: React.DragEvent) {
    setIsDragging(false);

    const key = ev.dataTransfer.getData('text/plain');

    delete ctx.dndData[key];
  }

  return (
    <ItemRowInner
      index={index}
      track={track}
      innerRowRef={innerRowRef}
      titleElRef={titleElRef}

      onDragStart={onDragStart}
      onDragEnd={onDragEnd}

      isSelected={isSelected}
      isDragging={isDragging}

      handleShiftSelect={() => handleShiftSelect(track)}
      handleToggleItem={() => handleToggleItem(track)}
      selectOnlyItem={() => changeItemSelectionState(track, true, true)}

      handlePlay={() => handlePlayTrack(track)}
      handleDelete={handleDelete(removeItems)}

      menuItems={
        <>
          <FadilaMenuItem
            onClick={preventDefaultStopPropogation(() => handlePlayTrack(track))}
            attributes={{
              className: 'secondary',
            }}
          >
            Play
          </FadilaMenuItem>
          <AddToPlaylistSubMenu
            newPlaylistName={track.album || track.artist || track.title}
            playlistItemOrItems={
              addItems => addTracksToPlaylistThenLibrary(addItems)(...selectedItems.current)}
          />
          {removeItems &&
            <FadilaMenuItem
              onClick={preventDefaultStopPropogation(
                menuOptionDelete
              )}
              attributes={{
                className: 'alert',
              }}
            >
              Delete Selected
            </FadilaMenuItem>
          }
        </>
      }
      extraColumns={extraColumns}
      columnScope={columnScope}
      portalTarget={portalTarget}
    />
  );
});
