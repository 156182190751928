import { useCallback, useRef, useState } from "react";
import QueryIterator from "../../../models/queryIterator";

interface LoadMoreProps<T1, T2> extends LoadMoreBaseProps<T1, T2> {
  itemMapper: (itm: T2) => T1
}

interface LoadMorePropsNoMap<T1, T2 = T1> extends LoadMoreBaseProps<T1, T2> {
  itemMapper?: undefined;
}
interface LoadMoreBaseProps<T1, T2> {
  items: T1[],
  setItems: (items: T1[]) => void,
  queryIterator: QueryIterator<T2> | undefined,
  setIterator: (it: QueryIterator<T2> | undefined) => void,
  itemMapper?: (itm: T2) => T1,
  errorHandler?: (err: any) => Promise<void> | void,
}
export default function useLoadMore<T1, T2>({
  items,
  setItems,
  queryIterator,
  setIterator,
  itemMapper,
  errorHandler,
}: LoadMoreProps<T1, T2> | LoadMorePropsNoMap<T1, T2>): [
  boolean,
  (startIdx: number, stopIndex: number) => Promise<void>,
] {

  const [loadingMore, setLoadingMore] = useState(false);

  // Async state
  /** 
   * We can't use a state based variable in case multiple requests attempt to fire 
   * in quick succession. 
   * This happens when we have total item count and user scrolls to end f.x.
   * In that case all loadMore methods called would have the old state variable.
   * We also need the ref since updating the ref will not trigger a re-render
   * so the TrackPlaylist can 
   */
  const isLoadingMore = useRef(false);
  const stopIdxRef = useRef<number>();

  const loadMore = useCallback(async function loadMore(startIdx: number, stopIndex: number) {
    console.info('LoadMore', startIdx, stopIndex);

    // All executions update stop index
    // JS is not multi-threaded, so we can assume that a second loadMore won't get called
    // until iterate below gets called
    // This allows us to update let stopIndex at end of while loop
    // allowing further data loading if warranted
    stopIdxRef.current = stopIndex;

    if (isLoadingMore.current) {
      return;
    }

    isLoadingMore.current = true;

    if (queryIterator?.next) {

      setLoadingMore(true);
      let iterator = queryIterator;
      let playlistItems = items;

      try {
        while (stopIndex >= playlistItems.length && iterator.next !== undefined) {

          iterator = await iterator.next();

          if (iterator.results) {
            if (itemMapper) {
              const ytTracks = iterator.results.map(itemMapper);
              playlistItems = playlistItems.concat(ytTracks);
            }
            else {
              playlistItems = playlistItems.concat(
                iterator.results as unknown as T1[]
              );
            }

            setItems(playlistItems);
          }

          // Update after async method returns
          stopIndex = stopIdxRef.current;
        }
      }
      catch (err) {
        if (errorHandler) {
          const promise = errorHandler(err);
  
          if (promise) await promise;
        }
      }
      finally {
        isLoadingMore.current = false;
        setIterator(iterator);
        setLoadingMore(false);
      }
    }
    else {
      isLoadingMore.current = false;
    }
  }, [itemMapper, items, queryIterator, setItems, setIterator, errorHandler]);

  return [
    loadingMore,
    loadMore,
  ];
}
