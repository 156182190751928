import Track from '.';
import Source from '../source';

export default class DropboxTrack extends Track {
  static from(file: DropboxTypes.files.FileMetadataReference) {
    return new DropboxTrack(file);
  }
  constructor(
    file: DropboxTypes.files.FileMetadataReference,
  ) {

    super(
      file.id, // we throw on null later in the constructor
      Source.Dropbox,
      // Remove filename extensions
      file.name.replace(/\.(?:.(?!\.))+$/, ''),
    );

    if (!file.id) throw new Error('Missing id for DropboxTrack');
  }
}
