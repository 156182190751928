import React, { useContext, useState } from 'react';
import { GoogleApiContext } from '../../services/gapi/gapiService';
import { LibraryContext } from '../../services/library/libraryService';
import { Context } from '../../services/_globalContext/context';
import Spinner from '../common/spinner/spinner';
import l from './loading.module.css';
import ProviderList from './providerList';
import s from './settingsComponent.module.scss';

interface Props {

}

// eslint-disable-next-line no-empty-pattern
export default function LoadingComponent({
}: Props) {

  const ctx = useContext(Context);
  const libCtx = useContext(LibraryContext);
  const gapiCtx = useContext(GoogleApiContext);

  const [showAdvanced, setShowAdvanced] = useState(false);

  return (
    <article className={l.component}>
      <div className="grid-container">
        <Spinner noMaxHeight />
        <ul>
          {ctx.flate
            ? <li>WASM Flate Loaded</li>
            : <li className="warning-color">WASM Flate Loading...</li>
          }
          {gapiCtx.dataApiLoaded
            ? <li>Google Data Api Loaded</li>
            : <li className="warning-color">Google Data Api Loading...</li>
          }
          {gapiCtx.iframeApiLoaded
            ? <li>Google iFrame Api Loaded</li>
            : <li className="warning-color">Google iFrame Api Loading...</li>
          }
          {gapiCtx.gapiClientLoaded
            ? <li>Google Client Api Loaded</li>
            : <li className="warning-color">Google Client Api Loading...</li>
          }
          {ctx.settingsLoaded
            ? <li>Settings Loaded</li>
            : <li className="warning-color">Settings Loading...</li>
          }
          {libCtx.libraryLoaded
            ? <li>Library Loaded</li>
            : <li className="warning-color">Library Loading...</li>
          }
          {libCtx.playlistsLoaded
            ? <li>Playlists Loaded</li>
            : <li className="warning-color">Playlists Loading...</li>
          }
        </ul>
        <div className={s.advancedBtn}>
          <button className="button" onClick={() => setShowAdvanced(!showAdvanced)}>
            {showAdvanced ? 'Hide' : 'Show'} Advanced
          </button>
        </div>
        {showAdvanced &&
          <div>
            <ProviderList />
            {gapiCtx.gapiAuthorized &&
              <button
                className="button"
                onClick={gapiCtx.resetAppDataFolder}
              >
                Reset GoogleDrive AppData (Only applicable if GoogleDrive is your storage provider)
              </button>
            }
          </div>
        }
      </div>
    </article>
  );
};
