import classNames from 'classnames';
import React from 'react';
import { ContextMenuTrigger, ContextMenuTriggerProps } from 'react-contextmenu';
import PlaylistColumns, { ColumnSettings, TrackProperty } from '../../../services/columnSettings/playlistColumns';
import Constants from '../../../settings';
import s from '../../playlist/playlist.module.scss';

interface Props {
  customName?: string;
  columnKey: TrackProperty | 'size' | 'image';
  columnSettings: Partial<ColumnSettings>;
  contextMenuTriggerRef: 
    (instance: React.Component<ContextMenuTriggerProps, any, any> | null) => void |
    React.RefObject<React.Component<ContextMenuTriggerProps, any, any>> |
    null | 
    undefined;
  onResizeStart: (ev: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => void;
  columnAutoResize(ev: React.MouseEvent<HTMLDivElement>): void;
  toggleMenu: (e: React.MouseEvent) => void;
}

export default function ItemListColumn({
  customName,
  columnKey,
  columnSettings,
  contextMenuTriggerRef,
  onResizeStart,
  columnAutoResize,
  toggleMenu,
}: Props) {

  return (
    <div
      className={classNames(s.headerColumn, {
        [s.hideOnHover]: columnSettings[columnKey]!.width < Constants.HeaderHideBreakpoint
      })}
      style={{ width: columnSettings[columnKey]?.width }}
      data-column-name={columnKey}
    >
      <ContextMenuTrigger
        ref={contextMenuTriggerRef}
        id="cloudprovider-context-menu"
        holdToDisplay={-1}
        disableIfShiftIsPressed
      >
        <span className="header-column-content">
          {customName || PlaylistColumns[columnKey].name}
        </span>
      </ContextMenuTrigger>
      <button
        className={classNames(s.menuBtn, 'invisible-control')}
        onClick={toggleMenu}
      >
        ☰
      </button>
      <div
        className={s.resizeColumn}
        onMouseDown={onResizeStart}
        onDoubleClick={columnAutoResize}
        onTouchStart={onResizeStart}
      />
    </div>
  );
}
