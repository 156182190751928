import PlaylistItem from '../../models/playlistItem/playlistItem';
import { Sources } from '../../models/source';
import Track from '../../models/track/track';
import { TrackProperty } from '../../services/columnSettings/playlistColumns';
import { ILibraryContext } from '../../services/library/libraryService';

export interface ISorter<T extends PlaylistItem | Track> {
  propName?: string;
  (a: T, b: T): number;
}

export const sortByIndex = function <T extends PlaylistItem | Track>(): ISorter<T> {
  const sorter = (_a: T, _b: T) => 0;
  sorter.propName = 'index';
  return sorter;
};
export const sortByIndexDescending
  = function <T extends PlaylistItem | Track>(): ISorter<T> {
    const sorter = (_a: T, _b: T) => -1;
    sorter.propName = 'index-descending';
    return sorter;
  };

export function createSortByProp<T extends PlaylistItem | Track>(libCtx: ILibraryContext) {
  return function sortByProp(prop: TrackProperty) {
    const sorter = function (a: T, b: T): number {

      if ((a as Track).type) {
        var aTrack = a as Track;
        var bTrack = b as Track;
      } else {
        var aTrack = libCtx.getLibraryTrack(a as PlaylistItem);
        var bTrack = libCtx.getLibraryTrack(b as PlaylistItem);
      }

      if (typeof aTrack[prop] === 'string') {
        var aProp: string | number = (aTrack[prop] as string).toLowerCase();
        var bProp: string | number = (bTrack[prop] as string).toLowerCase();
      }
      else {
        var aProp = aTrack[prop];
        var bProp = bTrack[prop];
      }

      return aProp > bProp
        ? 1
        : aProp < bProp
          ? -1
          : 0
        ;
    };

    sorter.propName = prop;

    return () => sorter;
  };
}

export function createSortByPropDescending(libCtx: ILibraryContext) {
  const sortByProp = createSortByProp(libCtx);
  return function sortByPropDescending<T extends PlaylistItem | Track>(prop: TrackProperty) {
    const sorter = (a: T, b: T) => -(sortByProp(prop)()(a, b));
    sorter.propName = `${prop}-descending`;
    return () => sorter;
  };
}

export function createFilterBy<T extends PlaylistItem | Track>(libCtx: ILibraryContext) {
  return function filterBy(filter: string | undefined) {
    return filter
      ?
      function (plItm: T) {

        if ((plItm as Track).type) {
          var track = plItm as Track;
        } else {
          var track = libCtx.getLibraryTrack(plItm as PlaylistItem);
        }

        return (
          track.artist.toLowerCase().indexOf(filter) !== -1 ||
          track.album.toLowerCase().indexOf(filter) !== -1 ||
          track.title.toLowerCase().indexOf(filter) !== -1 ||
          track.genre.toLowerCase().indexOf(filter) !== -1 ||
          Sources[track.type].name.toLowerCase().indexOf(filter) !== -1
        );
      }
      : (x: T) => x
      ;
  };
}
