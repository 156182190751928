import React from 'react';
import { PlayerSize } from '../../components/player/player';
import SyncType from '../../models/playlist/syncType';
import RepeatState from '../../models/repeatState';
import { StorageProviderMap } from '../../models/storageProviderKeys';
import SyncProvider from '../../models/synchronization/syncProvider';
import SyncProviders from '../../models/synchronization/syncProviders';
import { reqQueueCreator } from '../queues/reqQueue';
import IStorageProvider from '../storage/istorageProvider';

export type Flate = typeof import('wasm-flate');

export class SettingsState {
  soundCloudUser: string | undefined;
  repeatState = RepeatState.All;
  libraryPath?: string;
  showPlaylistStatusBar = false;
  confirmDelete = true;
  enableGoogleDriveIdNavigation = false;
  /**
   * Allows the user to lock player size as large despite switching
   * to track types with smaller default player sizes
   */
  lockPlayerSizeMedium = false;

  /**
   * Using this constructor helps upgrading older settings.js when we make additions
   * and sets sensible defaults when applicable.
   * 
   * @param state 
   */
  constructor(state?: SettingsState) {
    if (state) {
      this.soundCloudUser = state.soundCloudUser;
      this.repeatState = state.repeatState || this.repeatState;
      this.libraryPath = state.libraryPath;
      this.showPlaylistStatusBar = state.showPlaylistStatusBar || this.showPlaylistStatusBar;
      this.confirmDelete = state.confirmDelete || this.confirmDelete;
    }
  }
}

export class DeviceCapabilities {
  detectionComplete = false

  hasServiceWorkers = false;
  hasIndexedDB = false;
  hasLocalStorage = false;

  hasFetch = false;
  hasPromise = false;
  hasTextEncoder = false;
}
export interface GlobalState {

  deviceCapabilities: DeviceCapabilities;

  settingsLoaded: boolean;

  playerSize: PlayerSize,
  setPlayerSize: React.Dispatch<React.SetStateAction<PlayerSize>>;

  toggleRepeat(): void;

  callSnackbar(message: any, error?: boolean): void;

  syncProviders: SyncProviders;
  addSyncProvider(syncType: SyncType, syncProvider: SyncProvider): void;

  flate: Flate | undefined;

  storageProvider: React.RefObject<IStorageProvider | undefined>;
  /**
   * Configures the chosen storage provider
   * @param key storageProviderMap key
   */
  configureStorageProvider(key?: string): void;
  /**
   * Providers register with a specific key,
   * key matches saved storage provider choice in localStorage allowing fast app load.
   */
  storageProviderMap: StorageProviderMap;
  autoConfigureStorageProvider(): void;

  isProcessing: boolean;
  setRunningTask(task: string): void;
  removeRunningTask(task: string | number): void;

  soundCloudUser?: string;

  /** 
   * Music library path in cloud storage 
   * Base path used when f.x. saving podcasts, a path of /Music would then save to
   * /Music/Podcasts/[name of podcast]/
   */
  libraryPath?: string;
  setLibraryPath(path: string): void;

  unifiedReqQueue: ReturnType<typeof reqQueueCreator>;

  /** 
   * Object store for dnd operations, key/value store 
   * Dnd should cleanup but to ensure correct data retrieval
   * we access dnd data by timestamp key
   */
  dndData: any;

  /**
   * Service provider logout handler
   */
  spLogoutHandling: () => Promise<void>;
  /**
   * Allows library to configure it's logout cleanup methods
   * Those are used in conjunction with context cleanup methods when spLogoutHandling 
   * is called by cloud providers
   * @param f 
   */
  setLibrarySpLogoutHandling(f: () => Promise<void>): void;

  scrollbarWidth: number | undefined;

  toggleShowPlaylistStatusBar(): void;

  toggleConfirmDelete(): void;
  toggleGoogleDriveIdNavigation(): void;
  toggleLockPlayerSizeMedium(): void;

  settings: SettingsState;
}

export const Context = React.createContext({} as GlobalState);
