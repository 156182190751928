import { GlobalState } from "../_globalContext/context";

export default function handleDropboxError(
  ctxRef: React.MutableRefObject<GlobalState>,
  logout: () => void,
) {
  return async function (err: any, handle409?: () => Promise<any>) {
    if (err.status) {
      if (err.status === 401) {
        ctxRef.current.callSnackbar('Token revoked, please login again');
        logout();
      } else if (err.status === 404) {
        ctxRef.current.callSnackbar('Unable to find requested resource!');
      } else if (err.status === 409) {
        if (handle409) {
          return await handle409();
        } else {
          ctxRef.current.callSnackbar(err.error_summary);
        }
      } else if (err.status === 429) {
        ctxRef.current.callSnackbar('Dropbox requests rate limited!');
      } else if (err.status >= 500) {
        ctxRef.current.callSnackbar('Dropbox server error! Please try again later.');
      } else {
        ctxRef.current.callSnackbar(err.error_summary);
      }
    }
    else {
      ctxRef.current.callSnackbar(err);
    }
  };
}
