import classNames from 'classnames';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, ContextMenuTriggerProps } from 'react-contextmenu';
import AccessibleElement from '../../../gUtilities/components/accessibleElement';
import Portal from '../../../gUtilities/portal';
import { DragType } from '../../../models/dragTypes';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import { SpotifyPlaylistSimplified } from '../../../models/spotify/spotifyPlaylist';
import { SmallDeviceContext, SmallDeviceShow } from '../../../services/smallDevices/smallDeviceService';
import s from '../../library/playlists/playlistRow.module.scss';
import AddToPlaylistSubMenu from '../addToPlaylist/addToPlaylistSubMenu';
import ssStyles from './streamSvcPlaylist.module.scss';

export interface AdditionalContentProps<StreamingServicePlaylist> {
  playlist: StreamingServicePlaylist | undefined;
}
export type StreamingServicePlaylist = gapi.client.youtube.Playlist | SpotifyPlaylistSimplified;
interface Props<StreamingServicePlaylist> {
  isSelected: boolean;
  playlist: StreamingServicePlaylist;
  getPlaylistItems(playlist: StreamingServicePlaylist): Promise<void>;
  addPlaylistToPlaylist(addItems: (i: PlaylistItem[]) => void): (playlistId: string) => Promise<void>;

  id: string;
  title: string;
  dragType: DragType;

  additionalContent?: React.FC<AdditionalContentProps<StreamingServicePlaylist>>;

  portalTarget?: HTMLElement;
}
export type IStreamingServicePlaylistRow<T = StreamingServicePlaylist> = React.FC<Props<T>>

const StreamingServicePlaylistRow: IStreamingServicePlaylistRow
  = React.memo(function ({
    isSelected,
    playlist,
    getPlaylistItems,
    addPlaylistToPlaylist,
    id,
    title,
    dragType,
    additionalContent: Content,
    portalTarget,
  }: Props<StreamingServicePlaylist>) {

    const smallDevSvc = useContext(SmallDeviceContext);

    const [isDragging, setIsDragging] = useState(false);

    const onDragStart = (
      ev: React.DragEvent<HTMLLIElement>,
    ) => {
      console.debug('dragstart on playlist: ', id);

      // We set loads of data here since you can't call getData in onDragOver
      ev.dataTransfer.setData('dragType', dragType);
      // types.includes checks for matching drag type in onDragOver
      ev.dataTransfer.setData(dragType, dragType);
      ev.dataTransfer.setData('text/plain', id);
      ev.dataTransfer.setData('name', title);

      ev.dataTransfer.effectAllowed = 'copy';

      setIsDragging(true);
    };
    function onDragEnd(_ev: React.DragEvent<HTMLLIElement>) {
      setIsDragging(false);
    }

    const contextTrigger = useRef<React.Component<ContextMenuTriggerProps, any, any>>(null);
    const toggleMenu = useCallback((e: React.MouseEvent) => {
      if (contextTrigger.current) {
        (contextTrigger.current as any).handleContextClick(e);
      }
    }, []);

    const onActivate = useCallback(function () {
      smallDevSvc.setState(SmallDeviceShow.Main);
      getPlaylistItems(playlist);

    }, [getPlaylistItems, smallDevSvc, playlist]);

    return (
      <AccessibleElement
        title={title}
        onActivate={onActivate}

        draggable
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}

        className={classNames(s.row, ssStyles.playlistRow, s.spacedFlexContainer, {
          [s.isDragging]: isDragging,
          [ssStyles.selected]: isSelected,
        })}
      >
        <ContextMenuTrigger
          ref={contextTrigger}
          id={id}
          holdToDisplay={-1}
          disableIfShiftIsPressed={true}
          attributes={{
            className: s.rowBody
          }}
        >
          {Content && playlist &&
            <Content playlist={playlist} />
          }
          <span>{title}</span>
          {/* <td>
            {playlist.contentDetails!.itemCount}
          </td> */}
        </ContextMenuTrigger>
        <button
          className={classNames(s.menuBtn, 'hidden-control')}
          onClick={toggleMenu}
        >
          ☰
        </button>

        <Portal portalTarget={portalTarget}>
          <ContextMenu id={id}>
            <AddToPlaylistSubMenu
              addToLibrary
              newPlaylistName={title}
              playlistItemOrItems={addItems => {
                addPlaylistToPlaylist(addItems)(id)
              }}
            />
          </ContextMenu>
        </Portal>
      </AccessibleElement>
    );
  });

export default StreamingServicePlaylistRow;
