/**
 * https://stackoverflow.com/a/13500078/5663961
 */

/** */
const percentNotEscaping = /(?!%[0-9A-F]{2})%/g;

/**
 * When passing uris to react-router-dom <Link/> we expect sane characters to be encoded
 * However cloud folder names can include unsane chars, # and % f.x.
 * 
 * Here we pre-encode those characters
 * 
 * @param uri 
 */
export default function linkEncodeUri(uri?: string) {
  // History package code:
  //     location.pathname = decodeURI(location.pathname);
  // This nukes our % encoding, we get around it this way
  return uri?.replace(percentNotEscaping, '%2525')
    .replace(/#/g, '%23');
}

/**
 * Dropbox want unencoded paths in requests
 * @param uri 
 */
export function dbxDriveEncodeUri(uri: string) {
  return decodeURIComponent(
    // decodeURI doesn't want any stale % chars around
    // this regex finds all instances of % that are not html character escapings
    uri.replace(percentNotEscaping, '%25')
  );
}
/**
 * Dropbox want unencoded paths in requests
 * @param uri 
 */
export function oneDriveEncodeUri(uri: string) {
  // decodeURI doesn't want any stale % chars around
  // this regex finds all instances of % that are not html character escapings
  return uri.replace(percentNotEscaping, '%25');
}
