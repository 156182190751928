import classNames from 'classnames';
import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import AccessibleElement from '../../../gUtilities/components/accessibleElement';
import { ActivePlaylistContext } from '../../../services/activePlaylist/activePlaylist';
import { LibraryContext } from '../../../services/library/libraryService';
import { SmallDeviceContext, SmallDeviceShow } from '../../../services/smallDevices/smallDeviceService';
import Constants from '../../../settings';
import LibraryPlaylistRow from '../playlists/libraryPlaylistRow';
import PlaylistRow from '../playlists/playlistRow';
import PlaylistEnd from '../playlists/playlistRowEnd';
import plStyles from './playlistRow.module.scss';

interface Props {
  index: number;
  style: React.CSSProperties;
  data: {
    inLibraryComponent: boolean;
    curPlaylist: string;
    setCurPlaylist(val: string): void;
    portalTarget: HTMLElement | undefined;
  }
}

export default function PlaylistRowRenderer({
  index,
  style,
  data,
}: Props) {

  const {
    inLibraryComponent,
    curPlaylist,
    setCurPlaylist,
    portalTarget,
  } = data;

  const activePlaylist = useContext(ActivePlaylistContext);
  const smallDevSvc = useContext(SmallDeviceContext);
  const libCtx = useContext(LibraryContext);

  const onActivePlaylistActivate = useCallback(function () {
    if (inLibraryComponent) {
      setCurPlaylist!(activePlaylist.value.id)
      smallDevSvc.setState(SmallDeviceShow.Main);
    }
  }, [activePlaylist, smallDevSvc, inLibraryComponent, setCurPlaylist]);

  switch (index) {
    case 0:
      return (
        <div style={style}>
          <AccessibleElement
            className={classNames(
              plStyles.row,
              plStyles.rowBody,
              plStyles.noMenu,
              plStyles.spacedFlexContainer, {
              [plStyles.selected]: activePlaylist.value.id === curPlaylist
            })}
            onActivate={onActivePlaylistActivate}
            draggable={false}
          >
            {inLibraryComponent
              ?
              <div>{Constants.ActivePlaylistName}</div>
              :
              <Link
                to={`/app/library/${Constants.ActivePlaylistId}`}
                className={classNames(
                  'no-styles-link')}
              >
                {Constants.ActivePlaylistName}
                {/* <td>{activePlaylist.value.tracks.length}</td> */}
              </Link>
            }
          </AccessibleElement>
        </div>
      );

    case 1:
      if (libCtx.libraryPlaylist) {
        return (
          <div style={style}>
            <LibraryPlaylistRow
              playlist={libCtx.libraryPlaylist} // we never get here if library is not ready
              selected={curPlaylist === Constants.LibraryId}
              setCurrentPlaylist={setCurPlaylist}
              portalTarget={portalTarget}
            />
          </div>
        );
      }
      else {
        return <div style={style} />
      }

    case libCtx.playlists.length + 3 - 1:
      return (
        <div style={style}>
          <PlaylistEnd portalTarget={portalTarget} />
        </div>
      );

    default:
      const pl = libCtx.playlists[index - 2];

      return (
        <div style={style}>
          <PlaylistRow
            key={pl.id}
            playlist={pl}
            selected={curPlaylist === pl.id}
            draggable={pl.id !== Constants.LibraryId}
            setCurPlaylist={setCurPlaylist}
            portalTarget={portalTarget}
          />
        </div>
      );
  }
}
