/* global gapi, YT */
/**
 * Google Api max results per page
 * YT (pl, pl items, search results) - 50
 * Google Drive - 1000
 */
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import appendScriptTag from '../../gUtilities/appendScriptTag';
import YouTubePlaylistItemWithDuration from '../../models/gapi/gapiPlaylistItemExtensions';
import UploadResponse from '../../models/gapi/uploadResponse';
import mimeTypes from '../../models/mimeTypes';
import QueryIterator from '../../models/queryIterator';
import { StorageProviderKey } from '../../models/storageProviderKeys';
import { Keys, messages, Tasks } from '../../settings';
import compress, { decompress } from '../../utilities/flateCompressDecompress';
import { MediaUploader } from '../../utilities/gapiUpload';
import IStorageProvider from '../storage/istorageProvider';
import { Context } from '../_globalContext/context';
import GapiTokenService from './gapiTokenService';
import handleGapiError from './handleGapiError';

const apiKey = 'AIzaSyCmeiWGkrGYxDvHIYeHsU6okT59QnBc3O4';
const clientId = '755034602295-fcqcgdol81clacgt1giu25j295n7hkah.apps.googleusercontent.com';

export enum GoogleScope {
  YouTube = 'https://www.googleapis.com/auth/youtube.readonly',
  Drive = 'https://www.googleapis.com/auth/drive',
  DriveReadonly = 'https://www.googleapis.com/auth/drive.readonly',
  AppData = 'https://www.googleapis.com/auth/drive.appdata',
}
export enum GoogleMimeTypes {
  File = 'application/vnd.google-apps.file',
  Folder = 'application/vnd.google-apps.folder',
}
export interface IGoogleApiContext {
  iframeApiLoaded: boolean;
  dataApiLoaded: boolean;
  gapiClientLoaded: boolean;
  gapiAuthorized: boolean;
  authorizedScopes: string;

  playerEl: React.RefObject<HTMLIFrameElement>;
  ytPlayer: React.MutableRefObject<YT.Player | undefined>;

  searchPaged(q: any, maxResults?: number):
    Promise<QueryIterator<gapi.client.youtube.SearchResult>>;
  getUserPlaylistsPaged(maxResults?: number):
    Promise<QueryIterator<gapi.client.youtube.Playlist>>;
  getPlaylistItemsPaged(playlistId: string, maxResults?: number):
    Promise<QueryIterator<YouTubePlaylistItemWithDuration>>;
  getUserDrive(folder: string, resultsPerPage: number):
    Promise<QueryIterator<gapi.client.drive.File>>;
  getDriveFolder(fileId: string):
    gapi.client.Request<gapi.client.drive.File>;
  getFilesInFolderRecursive(folder: string, maxResults?: number):
    Promise<gapi.client.drive.File[]>;
  getDriveFileUrl(fileId: string, alt?: string, overrideChecks?: boolean):
    Promise<string>;
  getPlaylist(playlistId: string):
    Promise<gapi.client.youtube.PlaylistItemListResponse>;
  getDuration(...videoIds: string[]):
    Promise<gapi.client.Response<gapi.client.youtube.VideoListResponse>>;

  resetAppDataFolder(): Promise<void>;
  downloadAppDataFolder(): Promise<void>;

  playVideo(
    callback?: () => void,
    onStateChange?: (ev: YT.OnStateChangeEvent) => void,
    onError?: (ev: YT.OnErrorEvent) => void,
  ): void;

  authorize(options?: gapi.auth2.SigninOptions): Promise<void>;
  authorizeAdditionalScope(scope: string): Promise<unknown>;
  logout(): void;
  disconnect(): void;
  getAccount(): gapi.auth2.GoogleUser;
}
export const GoogleApiContext = React.createContext({} as IGoogleApiContext);

interface Props {
  children: React.ReactNode;
}

export default function GapiService({
  children,
}: Props) {

  const ctx = useContext(Context);
  const ctxRef = useRef(ctx);
  ctxRef.current = ctx;

  /**
   * Caches the drive ids of app data files
   * Behaves like a ref
   * 
   * useState is quicker over useRef, erronously all methods below request this obj
   * as dependency which is unneeded.
   */
  const [appDataIdsCache] = useState({} as any);
  const sp = useRef<IStorageProvider>();
  const getAppDataIdsPromise = useRef<Promise<gapi.client.drive.File[]>>();
  const [gapiTokenService] = useState(() => new GapiTokenService());

  /**
   * Root element for the youtube iframe player
   */
  const playerEl = useRef<HTMLIFrameElement>(null);
  const ytPlayer = useRef<YT.Player>();

  const [iframeApiLoaded, setIframeApiLoaded] = useState(false);
  const [dataApiLoaded, setDataApiLoaded] = useState(false);
  const [gapiClientLoaded, setGapiClientLoaded] = useState(false);
  /**
   * Currently this flag doesn't say much
   * Since it's possible to use Google Drive w/o using it as the AppData storage provider
   * 
   * It is set upon successful authorization, 
   * verified by testing for the profile scope
   */
  const [gapiAuthorized, setGapiAuthorized] = useState(false);

  // Never includes base appdata scope, we track gapiAuthorized for that
  const [authorizedScopes, setAuthorizedScopes] = useState<string>('');


  const authorizeAdditionalScope = useCallback(async function (scope: GoogleScope) {
    var options = new gapi.auth2.SigninOptionsBuilder();
    options.setScope(scope);

    const authInstance = gapi.auth2.getAuthInstance();

    var googleUser = authInstance.currentUser.get();

    if (!googleUser) {
      return authInstance.signIn(options);
    }
    else {
      return new Promise((resolve, reject) => {
        googleUser.grant(options).then(
          () => {
            const authInstance = gapi.auth2.getAuthInstance();
            const currUser = authInstance.currentUser.get();
            const scopes = currUser.getGrantedScopes();

            if (currUser.hasGrantedScopes('profile') && !gapiAuthorized) {
              setGapiAuthorized(true);
            }

            setAuthorizedScopes(scopes);
            resolve(undefined);
          },
          (err: any) => {
            ctxRef.current.callSnackbar(`Requested scope ${scope} not granted, error: ${err?.error}`);
            reject(err);
          },
        );
      });
    }
  }, [gapiAuthorized]);

  const authorize = useCallback(async function (options?: gapi.auth2.SigninOptions) {
    const authInstance = gapi.auth2.getAuthInstance();
    await authInstance.signIn(options);

    if (authInstance.isSignedIn.get()) {
      let authorizedScopes = false;
      setGapiAuthorized(true);

      const u = authInstance.currentUser.get();
      if (options?.scope !== undefined) {

        if (!u.hasGrantedScopes(options.scope)) {
          await authorizeAdditionalScope(options.scope as any);
          authorizedScopes = true;
        }
      }

      // When logging out and in again, the scopes are not updated
      if (!authorizedScopes) {
        setAuthorizedScopes(u.getGrantedScopes());
      }
    }
  }, [authorizeAdditionalScope]);

  const logout = useCallback(function logout() {

    function completeLogout() {
      gapi.auth2.getAuthInstance()
        ?.signOut()
        .then(() => {
          setGapiAuthorized(false);
          setAuthorizedScopes('');
          // if (ctxRef.current.storageProvider === sp.current) {
          //   ctxRef.current.configureStorageProvider();
          // }
        });
    }

    if (ctxRef.current.storageProvider.current === sp.current) {
      ctxRef.current.configureStorageProvider();
      ctxRef.current.spLogoutHandling().then(completeLogout);
    }
    else {
      completeLogout();
    }
  }, []);

  /**
   * This revokes the apps permissions,
   * similarly to manually removing access from 
   * https://myaccount.google.com/u/2/permissions
   */
  const disconnect = useCallback(function disconnect() {

    gapi.auth2.getAuthInstance().disconnect();
    logout();

  }, [logout]);

  const getAccount = useCallback(function () {
    return gapi.auth2.getAuthInstance()?.currentUser?.get();
  }, []);

  const errorHandler = useCallback(
    handleGapiError(ctxRef.current.callSnackbar, logout),
    [logout],
  );

  useEffect(function registerAsStorageProvider() {
    ctxRef.current.storageProviderMap[StorageProviderKey.GoogleDrive] = sp;
  }, []);

  useEffect(function loadScripts() {
    appendScriptTag(
      "https://www.youtube.com/iframe_api",
      undefined,
      () => setIframeApiLoaded(true),
      (event: Event | string,
        _source?: string,
        _lineno?: number,
        _colno?: number,
        error?: Error,
      ) => ctxRef.current.callSnackbar(error || event),
    );
    appendScriptTag(
      "https://apis.google.com/js/api.js",
      undefined,
      () => setDataApiLoaded(true),
      (event: Event | string,
        _source?: string,
        _lineno?: number,
        _colno?: number,
        error?: Error,
      ) => ctxRef.current.callSnackbar(error || event),
    );
  }, []);

  /**
   * Determines if the external google libraries have been loaded.
   * When loaded we can proceed to initializing the google api client.
   */
  useEffect(function shouldInitClient() {
    /**
     * Gotcha: This method is persistently attached as a listener to google api isSignedIn
     * That means its value for gapiAuthorized will be stale if user does not auth at startup
     * We don't care currently..
     * 
     * @param isSignedIn 
     */
    function handleSignedIn(isSignedIn: boolean) {
      if (isSignedIn && !gapiAuthorized) {
        // We don't attach then to getAuthInstance here, we already did inside init
        // and if we do here we break storage provider autoconfig
        // It causes the callback to complete after we attempt to autoconfig the sp
        const authInstance = gapi.auth2.getAuthInstance();
        const currUser = authInstance.currentUser.get();
        const scopes = currUser.getGrantedScopes();
        const authResponse = currUser.getAuthResponse(true);

        gapiTokenService.configureToken(authResponse);

        if (currUser.hasGrantedScopes('profile')) {
          setGapiAuthorized(true);
        }

        setAuthorizedScopes(scopes);
      }
    }

    async function initClient() {
      /**
       * Initialize the google api client with correct api key and YouTube data api v3
       */
      gapi.load('client', async () => {
        gapi.client.setApiKey(apiKey);
        await gapi.client.load('youtube', 'v3');
        await gapi.client.load('drive', 'v3');

        const authInstance = gapi.auth2.init({
          client_id: clientId,
        });
        authInstance.then(
          authInstance => {
            authInstance.isSignedIn.listen(handleSignedIn);
            handleSignedIn(authInstance.isSignedIn.get());

            // We wait for the authInstance to be ready
            // This does not take long and only seems to entail checking
            // if there already exist cached credentials.
            // This is done so that storage provider autoconfig can continue with
            // google creds ready if possible
            setGapiClientLoaded(true);
          },
          errorHandler);
      });
    }

    if (dataApiLoaded) {
      try {
        initClient();
      } catch (err) {
        errorHandler(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataApiLoaded]);

  const searchPaged = useCallback(function (q: any, maxResults?: number) {
    if (!gapiClientLoaded) {
      ctxRef.current.callSnackbar('Unable to access google api, client not ready');
      return Promise.reject();
    }

    let pageToken: string | undefined;

    function doSearch(q: string, _maxResults?: number, pageToken?: string,) {
      return gapi.client.youtube.search.list({
        q,
        part: 'snippet',
        fields: 'items(id,snippet(thumbnails/default,title,channelTitle)),nextPageToken,pageInfo/totalResults',
        maxResults: 50,
        pageToken,
      });
    }

    return (function nextPagedResultsClosure() {

      return (async function nextPagedResults(
        maxResults?: number,
      ): Promise<QueryIterator<gapi.client.youtube.SearchResult>> {
        const res = await doSearch(q, maxResults, pageToken);

        pageToken = res.result.nextPageToken;

        // The returned object holds the result of the first execution and
        // allows for further execution
        return {
          results: res.result.items || [],
          totalResults: res.result.pageInfo!.totalResults || 0,
          next: res.result.nextPageToken ? nextPagedResults : undefined,
        };
      })(maxResults);
    })();
  }, [gapiClientLoaded]);

  /**
   * 
   * @param maxResults 
   * Update: Fetching less than the displayed per page creates
   * a silly UX where you press next page but it just lenghtens the list
   * so disregard the following..
   * 
   * We fetch greedily despite only displaying a portion of returned results
   * Therefore we usually leave this option undefined and leave the api to decide the result count
   */
  const getUserPlaylistsPaged = useCallback(function (maxResults?: number) {

    if (!gapiClientLoaded) {
      ctxRef.current.callSnackbar('Unable to access google api, client not ready');
      return Promise.reject();
    }
    if (!gapiAuthorized) {
      ctxRef.current.callSnackbar('User not authorized for request');
      return Promise.reject();
    }

    let pageToken: string | undefined;

    function gapiGetPlaylists(_maxResults?: number, pageToken?: string) {
      return gapi.client.youtube.playlists.list({
        part: 'snippet,contentDetails',
        maxResults: 50,
        fields: 'items(id,snippet(thumbnails/default,title),contentDetails/itemCount),nextPageToken,pageInfo/totalResults',
        mine: true,
        pageToken,
      });
    }

    return (function nextPagedResultsClosure() {

      return (async function nextPagedResults(
        maxResults?: number,
      ): Promise<QueryIterator<gapi.client.youtube.Playlist>> {

        const res = await gapiGetPlaylists(maxResults, pageToken);

        pageToken = res.result.nextPageToken;

        // The returned object holds the result of the first execution and
        // allows for further execution
        return {
          results: res.result.items || [],
          totalResults: res.result.pageInfo!.totalResults || 0,
          next: res.result.nextPageToken ? nextPagedResults : undefined,
        };
      })(maxResults);
    })();
  }, [gapiClientLoaded, gapiAuthorized]);

  const getPlaylist = useCallback(async function (playlistId: string, _maxResults?: number, pageToken?: string) {
    if (!gapiClientLoaded) {
      ctxRef.current.callSnackbar('Unable to access google api, client not ready');
      return Promise.reject();
    }

    const res = await gapi.client.youtube.playlistItems.list({
      part: 'snippet,contentDetails',
      maxResults: 50,
      playlistId,
      fields: 'items(contentDetails/videoId,snippet(description,thumbnails/default,title,publishedAt)),nextPageToken,pageInfo/totalResults',
      pageToken,
    });

    return res.result;

  }, [gapiClientLoaded]);
  const getDuration = useCallback(async function (...videoIds: string[]) {
    return await gapi.client.youtube.videos.list({
      part: 'contentDetails',
      fields: 'items(id,contentDetails/duration)',
      id: videoIds.join(','),
      maxResults: 50,
    });
  }, []);

  /**
   * 
   * @param maxResults 
   */
  const getPlaylistItemsPaged = useCallback(function (playlistId: string, maxResults?: number) {

    if (!gapiClientLoaded) {
      ctxRef.current.callSnackbar('Unable to access google api, client not ready');
      return Promise.reject();
    }

    let pageToken: string | undefined;

    return (function nextPagedResultsClosure() {

      return (async function nextPagedResults(
        maxResults?: number,
      ): Promise<QueryIterator<YouTubePlaylistItemWithDuration>> {

        const res = await getPlaylist(playlistId, maxResults, pageToken);

        // update items with duration
        if (res.items) {
          const durations = await getDuration(
            ...(res.items.map(itm => itm.contentDetails!.videoId!))
          );

          if (durations.result.nextPageToken) {
            Sentry.captureMessage(
              'found nextPageToken in durations.result. Indicates that we have to refactor getDuration in getPlaylistItemsPaged. We were assuming getDuration had same max results.',
              Severity.Error);
          }

          res.items = res.items.map(itm => {
            const matchingDuration
              = durations.result.items?.find(
                durItm => durItm.id === itm.contentDetails!.videoId!);

            if (matchingDuration?.contentDetails?.duration) {
              (itm as YouTubePlaylistItemWithDuration).duration
                = matchingDuration?.contentDetails?.duration;

            }

            return itm;
          });
        }

        pageToken = res.nextPageToken;

        // The returned object holds the result of the first execution and
        // allows for further execution
        return {
          results: res.items || [],
          totalResults: res.pageInfo!.totalResults || 0,
          next: res.nextPageToken ? nextPagedResults : undefined,
        };
      })(maxResults);
    })();
  }, [gapiClientLoaded, getPlaylist, getDuration]);

  const gapiGetFiles = useCallback(function (folder: string, maxResults?: number, pageToken?: string) {
    return gapi.client.drive.files.list({
      q: `'${folder}' in parents and trashed = false`,
      pageSize: Math.min(1000, Math.max(100, maxResults || 1)),
      fields: "files(iconLink,id,mimeType,name,size),nextPageToken",
      pageToken,
    });
  }, []);

  /**
   * Get GoogleDrive folder files
   * @param folder 
   * @param maxResults 
   */
  const getUserDrive = useCallback(async function (folder: string, maxResults?: number) {
    if (!gapiClientLoaded) {
      ctxRef.current.callSnackbar('Unable to access google api, client not ready');
      return Promise.reject();
    }
    if (!gapiAuthorized) {
      ctxRef.current.callSnackbar('User not authorized for request');
      return Promise.reject();
    }

    let pageToken: string | undefined;

    return (function nextPagedResultsClosure() {

      return (async function nextPagedResults(
        maxResults?: number,
      ): Promise<QueryIterator<gapi.client.drive.File>> {
        const res = await gapiGetFiles(folder, maxResults, pageToken);

        pageToken = res.result.nextPageToken;

        // The returned object holds the result of the first execution and
        // allows for further execution
        return {
          results: res.result.files || [],
          next: res.result.nextPageToken ? nextPagedResults : undefined,
        };
      })(maxResults);
    })();
  }, [gapiClientLoaded, gapiAuthorized, gapiGetFiles]);

  const getFilesInFolderRecursive = useCallback(async function (folder: string) {

    const maxResults = 1000;
    const files: gapi.client.drive.File[] = [];
    const allFolders: gapi.client.drive.File[] = [];
    let pageToken: string | undefined;

    do {
      var iter = await gapiGetFiles(folder, maxResults, pageToken);

      pageToken = iter.result.nextPageToken;
      if (iter.result.files) {
        const folders = iter.result.files.filter(x => x.mimeType === 'application/vnd.google-apps.folder');
        allFolders.push(...folders);
        const audioItems = iter.result.files.filter(x => mimeTypes.has(x.mimeType!))
        files.push(...audioItems);
      }
    } while (iter.result.nextPageToken);

    for (let folder of allFolders) {
      const fil = await getFilesInFolderRecursive(folder.id!);
      files.push(...fil);
    }

    return files;
  }, [gapiGetFiles]);

  const getDriveFolder = useCallback(function (fileId: string) {
    if (!gapiClientLoaded) {
      ctxRef.current.callSnackbar('Unable to access google api, client not ready');
      throw new Error('Unable to access google api, client not ready');
    }
    if (!gapiAuthorized) {
      ctxRef.current.callSnackbar('User not authorized for request');
      throw new Error('User not authorized for request');
    }

    return gapi.client.drive.files.get({
      fileId,
      fields: 'parents,name',
    });
  }, [gapiClientLoaded, gapiAuthorized]);

  /**
   * Gets the drive file url set Html Audio element src to
   * 
   * @param fileId Google Drive unique file id
   * @param alt Used to control download, see Google APi
   * @param overrideChecks When player triggers authorization we don't want to wait for state to update before calling getDriveFileUrl
   */
  const getDriveFileUrl = useCallback(async function (fileId: string, alt = 'media', overrideChecks = false) {

    if (!overrideChecks) {
      if (!gapiClientLoaded) {
        ctxRef.current.callSnackbar('Unable to access google api, client not ready');
        return Promise.reject();
      }
      if (authorizedScopes.indexOf(GoogleScope.AppData) === -1 && authorizedScopes.indexOf(GoogleScope.DriveReadonly) === -1) {
        ctxRef.current.callSnackbar('User not authorized for request');
        return Promise.reject();
      }
    }

    const authResponse
      = await gapi.auth2.getAuthInstance()
        .then(x => x.currentUser.get().getAuthResponse(true));

    gapiTokenService.configureToken(authResponse);

    return `https://www.googleapis.com/drive/v3/files/${fileId}?alt=${alt}`;

  }, [gapiClientLoaded, gapiTokenService, authorizedScopes]);

  const putAppDataFile = useCallback(async function doPutAppDataFile(
    name: string,
    file: any,
  ) {
    if (!ctxRef.current.flate) {
      throw new Error('Ensure wasm-flate is loaded before completing startup');
    }

    const token = gapi.auth.getToken();

    var prevFileId = appDataIdsCache[name];

    if (!(file instanceof Blob)) {

      const compressed = compress(file, ctxRef.current.flate);
      file = new Blob(
        [compressed],
        { type: 'application/x-gzip' }
      );
    }

    const res: any = await new Promise(function (resolve, reject) {
      var uploader = new MediaUploader({
        file,
        token: token.access_token,
        metadata: {
          name,
          parents: prevFileId ? undefined : ['appDataFolder'],
        },
        fileId: prevFileId,
        onComplete: resolve,
        onError: reject,
      });
      uploader.upload();
    });

    const jsonResponse: UploadResponse = JSON.parse(res);
    appDataIdsCache[jsonResponse.name] = jsonResponse.id;

  }, [appDataIdsCache]);

  const getAppDataIds = useCallback(async function (resetAppData?: boolean) {

    async function doGetAppDataIds() {
      const files = new Array<gapi.client.drive.File>();
      let pageToken: string | undefined;

      do {
        var res = await gapi.client.drive.files.list({
          spaces: 'appDataFolder',
          fields: 'nextPageToken, files(id, name)',
          pageSize: 1000,
          pageToken,
        });

        pageToken = res.result.nextPageToken;
        if (res.result.files) {
          files.push(...res.result.files);
        }
      } while (res.result.nextPageToken);

      // Sort files by name
      files.sort((a, b) => a.name! < b.name! ? -1 : a.name === b.name ? 0 : 1);

      // If resetting, don't warn
      if (!resetAppData) {
        let prev;

        // We used to have alot of issues with duplicate app data files in GDrive
        for (let file of files) {
          if (prev && file && prev.name === file.name) {
            ctxRef.current.callSnackbar(messages(Keys.googleAppDataDuplicates));
            break;
          }

          prev = file;
        }
      }

      files.forEach(
        file => appDataIdsCache[file.name!] = file.id
      );

      return files;
    }

    // One request is enough
    if (!resetAppData && getAppDataIdsPromise.current) {
      try {
        await getAppDataIdsPromise.current;
        return;
      } catch (err) { } // Don't handle, just try again
    }

    getAppDataIdsPromise.current = doGetAppDataIds();
    return await getAppDataIdsPromise.current;

  }, [appDataIdsCache]);

  const getOrCreateDataFile = useCallback(async function (
    fileName: string,
    defaultData: any,
  ) {
    if (!ctxRef.current.flate) {
      throw new Error('Ensure wasm-flate is loaded before completing startup');
    }

    try {
      if (!appDataIdsCache[fileName]) {
        await getAppDataIds();
      }

      if (appDataIdsCache[fileName]) {
        const fileUrl = await getDriveFileUrl(appDataIdsCache[fileName]);

        const resp = await fetch(fileUrl);

        if (resp.ok) {
          const buffer = await resp.arrayBuffer();
          return decompress(buffer, ctxRef.current.flate);
        }
        else {
          throw resp;
        }
      }
      else {
        await putAppDataFile(fileName, defaultData);
        return defaultData;
      }
    }
    catch (err) {
      errorHandler(err);
      return Promise.reject();
    }
  }, [errorHandler, putAppDataFile, appDataIdsCache, getAppDataIds, getDriveFileUrl]);

  const resetAppDataFolder = useCallback(async function () {
    ctxRef.current.setRunningTask(Tasks.resetAppDataFolder);

    try {
      const files = await getAppDataIds(true);

      if (files) {
        for (let file of files) {
          await gapi.client.drive.files.delete({
            fileId: file.id!
          });
        }
      }

      try {
        localStorage.clear();
      } catch { }
    } finally {
      ctxRef.current.removeRunningTask(Tasks.resetAppDataFolder);
    }

    window.location.reload();

  }, [getAppDataIds]);

  const downloadAppDataFolder = useCallback(async function () {
    ctxRef.current.setRunningTask(Tasks.downloadAppDataFolder);

    try {
      const files = await getAppDataIds(true);

      if (files) {

        var temporaryDownloadLink = document.createElement("a");
        temporaryDownloadLink.style.display = 'none';

        document.body.appendChild(temporaryDownloadLink);

        for (const file of files) {

          const accessToken
            = await gapi.auth2.getAuthInstance()
              .then(x => x.currentUser.get().getAuthResponse(true).access_token);

          const res = await fetch(
            `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`, {
            headers: new Headers({
              'Authorization': `Bearer ${accessToken}`
            })
          });

          const blob = await res.blob();
          var objUrl = window.URL.createObjectURL(blob);


          temporaryDownloadLink.setAttribute('href', objUrl);
          temporaryDownloadLink.setAttribute('download', file.name!);

          temporaryDownloadLink.click();
        }

        document.body.removeChild(temporaryDownloadLink);
      }
    }
    finally {
      ctxRef.current.removeRunningTask(Tasks.downloadAppDataFolder);
    }
  }, [getAppDataIds]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const saveFile = useCallback(async function (fileName: string, path: string, fileData: any) {
    if (authorizedScopes.indexOf(GoogleScope.Drive) === -1) {
      await authorizeAdditionalScope(GoogleScope.Drive);
    }

    var res = await gapi.client.drive.files.create({
      resource: {
        name: 'Music',
        mimeType: 'application/vnd.google-apps.folder',
      },
      fields: 'id',
    });

    if (res.result.id === undefined) {
      throw new Error('Upload failed, unable to create folder.');
    }

    res = await gapi.client.drive.files.create({
      resource: {
        name: 'Podcasts',
        mimeType: 'application/vnd.google-apps.folder',
        parents: [res.result.id],
      },
      fields: 'id',
    });

    if (res.result.id === undefined) {
      throw new Error('Upload failed, unable to create folder.');
    }

    const token = gapi.auth.getToken();

    if (!(fileData instanceof Blob)) {
      fileData = new Blob(
        [JSON.stringify(fileData)],
        { type: 'application/json' }
      );
    }

    res = await new Promise(function (resolve, reject) {
      var uploader = new MediaUploader({
        file: fileData,
        token: token.access_token,
        metadata: {
          fileName,
          parents: res.result.id,
        },
        // fileId: prevFileId,
        onComplete: resolve,
        onError: reject,
      });
      uploader.upload();
    });
  }, [authorizeAdditionalScope, authorizedScopes]);

  useEffect(function updateStorageProvider() {
    sp.current = {
      name: StorageProviderKey.GoogleDrive,
      load: (fileName: string, defaultData: any) => {
        if (gapiAuthorized && authorizedScopes.indexOf(GoogleScope.AppData) !== -1) {
          return getOrCreateDataFile(fileName, defaultData);
        }
        else {
          ctxRef.current.configureStorageProvider();
          return Promise.reject(messages(Keys.storageProviderNotLoggedIn));
        }
      },
      // Not supported
      // save: (fileName: string, path: string, fileData: any) => {
      //   if (gapiAuthorized) {
      //     return saveFile(fileName, path, fileData);
      //   }
      //   else {
      //     ctxRef.current.configureStorageProvider();
      //     return Promise.reject(messages(Keys.storageProviderNotLoggedIn));
      //   }
      // },
      saveAppData: (fileName: string, fileData: any) => {
        if (gapiAuthorized && authorizedScopes.indexOf(GoogleScope.AppData) !== -1) {
          return putAppDataFile(fileName, fileData)
        }
        else {
          ctxRef.current.configureStorageProvider();
          return Promise.reject(messages(Keys.storageProviderNotLoggedIn));
        }
      },
    };
  }, [gapiAuthorized, authorizedScopes, getOrCreateDataFile, putAppDataFile]);

  const playVideo = useCallback(function (
    callback?: () => void,
    onStateChange?: (ev: YT.OnStateChangeEvent) => void,
    onError?: (ev: YT.OnErrorEvent) => void,
  ) {

    if (!iframeApiLoaded) {
      ctxRef.current.callSnackbar('Unable to play video, YouTube iFrame Api is not ready');
      throw new Error('Unable to play video, YouTube iFrame Api is not ready');
    }
    if (!playerEl.current) {
      ctxRef.current.callSnackbar('Unable to play video, iframe not found');
      throw new Error('Unable to play video, iframe not found');
    }

    // I don't know what it is that this constructor creates 
    // but it bears no resemblance to playerEvt.target
    new YT.Player(playerEl.current, {
      events: {
        onReady: playerEvt => {
          ytPlayer.current = playerEvt.target;
          playerEvt.target.playVideo();

          if (callback) callback();
        },
        onStateChange,
        onError,
      }
    });
  }, [iframeApiLoaded]);

  const ctxValue = useMemo(() => ({
    iframeApiLoaded,
    dataApiLoaded,
    gapiClientLoaded,
    gapiAuthorized,
    authorizedScopes,

    playerEl,
    ytPlayer,

    searchPaged,
    getUserPlaylistsPaged,
    getPlaylistItemsPaged,
    getUserDrive,
    getDriveFolder,
    getFilesInFolderRecursive,
    getDriveFileUrl,
    getPlaylist,
    getDuration,

    resetAppDataFolder,
    downloadAppDataFolder,

    playVideo,

    authorize,
    authorizeAdditionalScope,
    logout,
    disconnect,
    getAccount,
  }), [
    iframeApiLoaded,
    dataApiLoaded,
    gapiClientLoaded,
    gapiAuthorized,
    authorizedScopes,
    playerEl,
    ytPlayer,
    searchPaged,
    getUserPlaylistsPaged,
    getPlaylistItemsPaged,
    getUserDrive,
    getDriveFolder,
    getFilesInFolderRecursive,
    getDriveFileUrl,
    getPlaylist,
    getDuration,

    resetAppDataFolder,
    downloadAppDataFolder,
    playVideo,
    authorize,
    authorizeAdditionalScope,
    logout,
    disconnect,
    getAccount,
  ]);

  return (
    <GoogleApiContext.Provider value={ctxValue}>
      {children}
    </GoogleApiContext.Provider>
  );
};
