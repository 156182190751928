import classNames from 'classnames';
import React, { useContext, useMemo, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';
import { LibraryContext } from '../../services/library/libraryService';
import { SmallDeviceContext, SmallDeviceShow } from '../../services/smallDevices/smallDeviceService';
import s from './libraryPlaylists.module.scss';
import PlaylistRowRenderer from './playlists/playlistRowRenderer';

interface Props {
  curPlaylist?: string;
  setCurPlaylist?(val: string): void;
}

export default function LibraryPlaylists({
  setCurPlaylist,
  curPlaylist,
}: Props) {

  const [playlistsElement, setPlaylistsElement] = useState<HTMLElement | null>(null);

  const inLibraryComponent = !!setCurPlaylist;

  const smallDevSvc = useContext(SmallDeviceContext);
  const libCtx = useContext(LibraryContext);

  const itemData = useMemo(() => ({
    inLibraryComponent,
    curPlaylist,
    setCurPlaylist,
    portalTarget: playlistsElement || undefined,

  }), [inLibraryComponent, curPlaylist, setCurPlaylist, playlistsElement]);

  return (
    <article 
      ref={el => setPlaylistsElement(el)} 
      className={classNames(s.playlistList, {
        [s.open]: smallDevSvc.state === SmallDeviceShow.Playlists,
      })}
    >
      {playlistsElement !== null &&
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              itemCount={libCtx.playlists.length + 3}
              itemSize={29}
              itemData={itemData}
            >
              {PlaylistRowRenderer}
            </List>
          )}
        </AutoSizer>
      }
    </article>
  );
}
