import { useCallback, useState } from "react";
import Playlist from "../../../models/playlist/playlist";
import PlaylistItem from "../../../models/playlistItem/playlistItem";

/**
 * Determine what parent playlists a given playlist item belongs to
 * @param playlistItem 
 * @param thisPlaylist 
 * @param playlists 
 */
export default function useParentPlaylists(
  playlistItem: PlaylistItem,
  thisPlaylist: Playlist,
  playlists: Playlist[],
): [Playlist[], () => void, () => void] {

  const [parentPlaylists, setParentPlaylists] = useState<Playlist[]>([]);
  
  const computeParentPlaylists = useCallback(function () {

    setParentPlaylists(playlists.filter(
      pl => pl !== thisPlaylist && pl.tracks.some(x => x.id === playlistItem.id)
    ));
  }, [playlistItem, thisPlaylist, playlists]);

  const clearParents = useCallback(() => setParentPlaylists([]), []);

  return [parentPlaylists, computeParentPlaylists, clearParents];
}
