import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, ContextMenuTriggerProps } from 'react-contextmenu';
import Portal from '../../../gUtilities/portal';
import { isMac } from '../../../settings';
import s from '../../common/itemRow/itemRow.module.scss';

/**
 * Used in 
 * inner playlist item rows
 * inner track item rows
 * YT/Spotify track search result rows
 */
export interface ItemRowWrapperProps<T> {
  /** Item array index */
  index: number;
  item: T;
  itemId: string;
  className?: string;

  isSelected: boolean;
  isDragging: boolean;

  moveableHandlers?: any;
  notDraggable?: boolean;
  onDragStart: (ev: React.DragEvent) => void;
  onDragEnd: (ev: React.DragEvent) => void;

  /**
   * Removes all previous selections
   */
  selectOnlyItem(): void;
  handleToggleItem(): void;
  handleShiftSelect(): void;

  /** Usually play */
  handlePlay: (item: T) => void;

  menuItems: React.ReactNode;
  onShowContextMenu?: Function;
  onHideContextMenu?: Function;

  portalTarget?: HTMLElement;
  portalChildren?: React.ReactNode;
  children: React.ReactNode;
}

function ItemRowWrapper<T>(
  props: ItemRowWrapperProps<T>,
) {
  const {
    index,
    item,
    itemId,
    isSelected,
    portalTarget,
    notDraggable,
    isDragging,
    moveableHandlers,
    onDragStart,
    onDragEnd,
    handlePlay,
    selectOnlyItem,
    handleToggleItem,
    handleShiftSelect,
    menuItems,
    onShowContextMenu,
    onHideContextMenu,
    className,
    portalChildren,
    children,
  } = props;

  const [showMenuItems, setShowMenuItems] = useState(false);

  const cntxMenuBtn = useRef<HTMLButtonElement>(null);
  const contextTrigger = useRef<React.Component<ContextMenuTriggerProps, any, any>>(null);

  const toggleMenu = useCallback((e: React.MouseEvent) => {
    if (contextTrigger.current) {
      (contextTrigger.current as any).handleContextClick(e);
    }
  }, []);

  const onClick = useCallback(function (ev: React.MouseEvent) {

    if (ev.shiftKey) {
      handleShiftSelect();
    }
    else if ((isMac && ev.metaKey) || (!isMac && ev.ctrlKey)) {
      handleToggleItem();
    }
    else {
      selectOnlyItem();
    }
  }, [handleShiftSelect, handleToggleItem, selectOnlyItem]);

  const onDoubleClick = useCallback(function (ev: React.MouseEvent) {
    if (!ev.shiftKey && !ev.ctrlKey && !ev.metaKey) {
      handlePlay(item);
      selectOnlyItem();
    }
  }, [handlePlay, selectOnlyItem, item]);

  const onContextMenuShow = useCallback(function (ev: CustomEvent) {

    setShowMenuItems(true);

    if (onShowContextMenu) {
      onShowContextMenu();
    }

    if (!isSelected/* && ev.detail.data.target !== cntxMenuBtn.current*/) {
      selectOnlyItem();
    }
  }, [onShowContextMenu, isSelected, selectOnlyItem]);

  const onContextMenuHide = useCallback(function () {
    if (onHideContextMenu) {
      onHideContextMenu();
    }
  }, [onHideContextMenu]);

  return (
    <>
      <Portal portalTarget={portalTarget}>
        <ContextMenu
          id={itemId}
          onShow={onContextMenuShow}
          onHide={onContextMenuHide}
        >
          {showMenuItems && menuItems}
        </ContextMenu>
        {portalChildren}
      </Portal>
      <ContextMenuTrigger
        ref={contextTrigger}
        id={itemId}
        holdToDisplay={-1}
        disableIfShiftIsPressed
        attributes={{
          className: classNames(s.row, className, {
            [s.evenRow]: index % 2 === 0,
            [s.oddRow]: index % 2 !== 0,
            [s.selected]: isSelected,
            [s.isDragging]: isDragging,
          }),

          onClick,
          onDoubleClick,

          draggable: notDraggable !== true,
          onDragStart,
          onDragEnd,

          ...moveableHandlers
        }}
      >
        {children}
        <div>
          <button
            ref={cntxMenuBtn}
            className={classNames(s.menuBtn, 'invisible-control')}
            onClick={toggleMenu}
          >
            ☰
          </button>
        </div>
      </ContextMenuTrigger>
    </>
  );
}

// const ItemRowWrapper = React.forwardRef<HTMLDivElement, ItemRowWrapperProps<any>>(
//   ItemRowWrapperComponent
// ) as typeof ItemRowWrapperComponent;

export default ItemRowWrapper;
