import { useContext, useMemo } from "react";
import { ColumnSettingsContext } from "../../../services/columnSettings/columnSettings";
import { ColumnKey, ColumnScope, TrackProperty } from "../../../services/columnSettings/playlistColumns";
import { ScreenDetectionContext } from "../../../services/screenDetection/screenDetection";

export default function useColumnSettings(columnScope: ColumnScope) {
  const colCtx = useContext(ColumnSettingsContext);
  const screenSize = useContext(ScreenDetectionContext);

  const columnSettings = colCtx.columnSettingsStore[columnScope][screenSize];

  const columns = useMemo(() => columnSettings === undefined ? [] :
    (Object.keys(columnSettings) as ColumnKey[])
      .filter(colKey => columnSettings[colKey as TrackProperty]?.width! > 0)
      .sort((a, b) => {
        const colKeyA = a as TrackProperty;
        const colKeyB = b as TrackProperty;

        return columnSettings[colKeyA]!.sortOrder - columnSettings[colKeyB]!.sortOrder;
      }), [columnSettings]);

  return {
    columns,
    columnSettings,
  };
}
