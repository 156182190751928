import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { StorageProviderKey } from '../../models/storageProviderKeys';
import { GoogleApiContext, GoogleScope } from '../../services/gapi/gapiService';
import { Context } from '../../services/_globalContext/context';
import ProviderList from './providerList';
import s from './settingsComponent.module.scss';

interface Props {
}

export default function SetupComponent({
}: Props) {

  const ctx = useContext(Context);
  const gapiCtx = useContext(GoogleApiContext);

  const [showAdvanced, setShowAdvanced] = useState(false);

  async function gapiEasySetup() {
    await gapiCtx.authorize({
      scope: GoogleScope.AppData,
    });

    ctx.configureStorageProvider(StorageProviderKey.GoogleDrive);
  }

  return (
    <article className={classNames('mainViewport', ctx.playerSize, s.settings)}>
      <article>
        <section>
          <h5>Fadila music player setup</h5>
          <p>To use Fadila you must first sign in with Google and allow it to store your music library in your account.</p>
        </section>
        {(!gapiCtx.gapiAuthorized || !ctx.storageProvider.current) &&
          <>
            <div className={s.easySignin}>
              <button
                className="googleSignin"
                onClick={gapiEasySetup}
              >
              </button>
            </div>
            <div className={s.advancedBtn}>
              <button className="button" onClick={() => setShowAdvanced(!showAdvanced)}>
                {showAdvanced ? 'Hide' : 'Show'} Advanced
              </button>
            </div>
          </>
        }
        {showAdvanced &&
          <div>
            <ProviderList setup />
            {gapiCtx.gapiAuthorized &&
              <button
                className="button"
                onClick={gapiCtx.resetAppDataFolder}
              >
                Reset GoogleDrive AppData
              </button>
            }
          </div>
        }
      </article>
      <br />
    </article>
  );
}
