import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, ContextMenuTriggerProps } from 'react-contextmenu';
import Portal from '../../../gUtilities/portal';
import { DragType } from '../../../models/dragTypes';
import { addItems } from '../../../models/playlist/playlist';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import { DropboxContext } from '../../../services/dropbox/dropboxService';
import { GoogleApiContext } from '../../../services/gapi/gapiService';
import createAddTracksToPlaylistThenLibrary from '../../../services/library/addTracksToPlaylistThenLibrary';
import { LibraryContext } from '../../../services/library/libraryService';
import { OneDriveContext } from '../../../services/oneDrive/oneDriveService';
import { SpotifyContext } from '../../../services/spotify/spotifyService';
import { Context } from '../../../services/_globalContext/context';
import Constants from '../../../settings';
import { preventDefaultStopPropogation } from '../../../utilities/preventDefault';
import FadilaMenuItem from '../../common/contextMenu/fadilaMenuItem';
import s from './playlistRow.module.scss';
import { baseSupportedDragTypes, handleDropCommon } from './playlistRowCommon';
import PlaylistMoveTarget from './playlistRowMoveTarget';

interface Props {
  portalTarget?: HTMLElement;
}

export default function PlaylistEnd({
  portalTarget,
}: Props) {

  const [supportedDropTypes] = useState<string[]>([
    DragType.Playlist,
    DragType.PlaylistItems,
  ].concat(baseSupportedDragTypes));

  const ctx = useContext(Context);
  const libCtx = useContext(LibraryContext);
  const libCtxRef = useRef(libCtx);
  libCtxRef.current = libCtx;
  const spotifyCtx = useContext(SpotifyContext);
  const gapiCtx = useContext(GoogleApiContext);
  const oneDriveCtx = useContext(OneDriveContext);
  const dbxCtx = useContext(DropboxContext);

  const [isHoverDrop, setIsHoverDrop] = useState(false);

  const el = useRef<HTMLDivElement>(null);
  const pos = el.current?.getBoundingClientRect();

  const addTracksToPlaylistThenLibrary = createAddTracksToPlaylistThenLibrary(ctx, libCtx);

  /** Ensure no duplicate names when user spam creates lists */
  function createDefaultName() {
    const newPlaylists
      = libCtx.playlists.filter(x => x.name.startsWith(Constants.NewPlaylistName))
        .sort(function (a, b) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }

          // names must be equal
          return 0;
        });

    const latest = newPlaylists[0];

    if (latest) {
      const nameSplit = latest.name.split(' ');
      const number = nameSplit[nameSplit.length - 1];

      if (!isNaN(+number)) {
        return `New Playlist ${+number + 1}`;
      } else {
        return `New Playlist 1`;
      }
    } else {
      return Constants.NewPlaylistName;
    }
  }

  const [isCreating, setIsCreating] = useState(false);
  const [, setIsHoverTop] = useState(false);

  const onDragOver = (ev: React.DragEvent) => {

    if (ev.dataTransfer.types.some(x => supportedDropTypes.includes(x))) {

      if (ev.dataTransfer.types.includes(DragType.Playlist)) {
        setIsHoverTop(true);
        ev.dataTransfer.dropEffect = 'move';
      } else {
        setIsHoverDrop(true);
        ev.dataTransfer.dropEffect = 'copy';
      }

      ev.preventDefault();
    }
  };
  const onDragLeave = (_ev: React.DragEvent) => {
    setIsHoverTop(false);
    setIsHoverDrop(false);
  };
  const onDrop = (
    ev: React.DragEvent,
  ) => {
    console.debug('onDrop');

    const id = ev.dataTransfer.getData('text/plain');
    const dragType = ev.dataTransfer.getData('dragType');

    if (ev.dataTransfer.types.some(x => supportedDropTypes.includes(x))) {
      ev.preventDefault();

      if (dragType === DragType.Playlist) {
        const pl = libCtx.playlists.find(pl => pl.id === id);
        if (pl) {
          libCtx.movePlaylist(pl, undefined);
        }
      }
      else {
        const name = ev.dataTransfer.getData('name')
          || createDefaultName();

        libCtx.createPlaylist(name)
          // eslint-disable-next-line array-callback-return
          .map(pl => {
            const add = addItems(pl)(libCtx.updatePlaylist);

            if (dragType === DragType.PlaylistItems) {
              const key = ev.dataTransfer.getData('text/plain');
              const items = ctx.dndData[key] as { current: Iterable<PlaylistItem> };
              if (items) {
                add([...items.current].map(PlaylistItem.from));
              }
            }
            else {
              handleDropCommon(
                ctx,
                libCtx,
                gapiCtx,
                spotifyCtx,
                oneDriveCtx,
                dbxCtx,
                add,
                addTracksToPlaylistThenLibrary(add),
                ev,
              );
            }
          }).mapLeft(ctx.callSnackbar);
      }

      setIsHoverTop(false);
      setIsHoverDrop(false);
    }
  };

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    const form = ev.currentTarget;
    const inputEl: HTMLInputElement = form['playlist-name'];

    createPlaylist(inputEl);
  }

  function handleBlur(ev: React.FocusEvent<HTMLInputElement>) {
    createPlaylist(ev.currentTarget);
  }

  function createPlaylist(inputEl: HTMLInputElement) {
    if (inputEl.value.length) {
      libCtx.createPlaylist(inputEl.value)
        .map(() => inputEl.value = '')
        .mapLeft(ctx.callSnackbar);

    } else {
      ctx.callSnackbar('Playlist name missing');
    }

    setIsCreating(false);
  }

  const inputEl = useRef<HTMLInputElement>(null);
  useEffect(function focusInput() {
    if (isCreating) {
      // Select all text in input
      inputEl.current?.select();
    }
  }, [isCreating]);

  const contextTrigger = useRef<React.Component<ContextMenuTriggerProps, any, any>>(null);
  const toggleMenu = useCallback((e: React.MouseEvent) => {
    if (contextTrigger.current) {
      (contextTrigger.current as any).handleContextClick(e);
    }
  }, []);

  return (
    <div
      ref={el}
      className={classNames(
        s.row,
        s.rowEnd,
        s.spacedFlexContainer, {
        [s.hoverDrop]: isHoverDrop,
      })}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <ContextMenuTrigger
        ref={contextTrigger}
        id="playlist-end"
        holdToDisplay={-1}
        disableIfShiftIsPressed={true}
        attributes={{
          className: s.rowBody
        }}
      >
        {isCreating
          ?
          <form onSubmit={handleSubmit}>
            <input
              ref={inputEl}
              id={`playlist-name`}
              name='playlist-name'
              type='text'
              defaultValue={createDefaultName()}
              className={s.playlistNameInput}
              onBlur={handleBlur}
            />
          </form>
          :
          <div className="center-content">
            +
          </div>
        }
      </ContextMenuTrigger>
      <button
        className={classNames(s.menuBtn, 'hidden-control')}
        onClick={toggleMenu}
      >
        ☰
      </button>

      <Portal portalTarget={portalTarget}>
        <PlaylistMoveTarget
          hide={!isHoverDrop}
          posY={pos?.top}
          width={pos?.width}
        />
        <ContextMenu id="playlist-end">
          {!isCreating &&
            <FadilaMenuItem onClick={preventDefaultStopPropogation(() => setIsCreating(true))}>
              New Playlist
            </FadilaMenuItem>
          }
        </ContextMenu>
      </Portal>
    </div>
  );
}
