import { Tags } from 'jsmediatags/types';
import formatDuration from '../../gUtilities/formatDuration';
import { TrackProperty } from '../../services/columnSettings/playlistColumns';
import Source, { Sources } from "../source";
import TrackLength from "./trackLength";

export default class Track {

  /**
   * We don't use class instance methods so we can 
   * skip recreating all library json as track objects on load
   * @param val 
   * @param trackProp 
   */
  static format(val: any, trackProp: TrackProperty) {

    switch (trackProp) {
      /**
       * Get Date obj from a Track date property (releaseDate, dateAdded)
       * Handles values as string and numeric
       * 
       * @param date Set to any to remind of the shift in date storage
       * Dates where being JSON.stringified to string values but then changed to numeric valueOf
       */
      case TrackProperty.releaseDate:
      case TrackProperty.dateAdded:
        if (val) {
          return new Date(val).toLocaleDateString();
        }
        break;

      case TrackProperty.length:
        switch (val) {
          case TrackLength.NOT_READY:
            return 'Not Ready';
          case TrackLength.UNAVAILABLE:
            return '';

          default:
            return formatDuration(val, true);
        }

      case TrackProperty.type:
        return Sources[val as Source].name;
        
      default:
        return val;
    }
  }

  missing = false;

  /**
   * 
   * @param id Library id / OneDrive id / YouTube Video id
   * @param type @type Source
   * @param title 
   * @param artist 
   * @param album 
   * @param length Length in ms
   * @param genre 
   * @param releaseDate 
   * @param dateAdded Date added to library
   * @param tags F.x. ID3
   */
  constructor(
    public id: string,
    public type: Source,
    public title = 'Title Missing',
    public artist = '',
    public album = '',
    public length = TrackLength.UNAVAILABLE as number,
    public genre = '',
    public releaseDate = 0,
    public dateAdded = new Date().valueOf(),
    public tags: Tags = {},
  ) { }

  /**
   * Implemented by sub-classes
   */
  dispose() {

  }

  // toJSON: () => any = () => ({
  //   type: this.type,
  //   id: this.id,
  // });
}
