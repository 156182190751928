import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, ContextMenuTriggerProps } from 'react-contextmenu';
import { Link } from 'react-router-dom';
import Portal from '../../../gUtilities/portal';
import { DragType } from '../../../models/dragTypes';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import PlaylistColumns, { ColumnKey, ColumnScope, TrackProperty } from '../../../services/columnSettings/playlistColumns';
import AddToPlaylistSubMenu from '../addToPlaylist/addToPlaylistSubMenu';
import useColumnSettings from '../hooks/useColumnSettings';
import s from '../itemRow/itemRow.module.scss';

interface Props {
  /** Item array index */
  index: number;
  isFolder: boolean;

  name: string;
  itemId: string;
  size?: string;
  path?: string;

  dragType: DragType;

  addFolderToPlaylist(addItems: (i: PlaylistItem[]) => void):
    (playlistId: string) => void;

  columnScope: ColumnScope;

  portalTarget?: HTMLElement;
}

/**
 * Non-audio files and folders.
 */
export default function CloudProviderFileFolderRow({
  index,
  name,
  size,
  path,
  isFolder,
  dragType,
  addFolderToPlaylist,
  itemId,
  portalTarget,
  columnScope,
}: Props) {

  const { columns, columnSettings, } = useColumnSettings(columnScope);

  const contextTrigger = useRef<React.Component<ContextMenuTriggerProps, any, any>>(null);
  const titleElRef = useRef<HTMLTableDataCellElement>(null);

  // const [isSelected, setIsSelected] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [showMenuItems, setShowMenuItems] = useState(false);

  const toggleMenu = (e: React.MouseEvent) => {
    if (contextTrigger.current) {
      (contextTrigger.current as any).handleContextClick(e);
    }
  };

  const onDragStart = (
    ev: React.DragEvent,
  ) => {
    console.debug('dragstart on track item');

    setIsDragging(true);
    // setIsSelected(true);

    // We set loads of data here since you can't call getData in onDragOver
    ev.dataTransfer.setData('dragType', dragType);
    // types.includes checks for matching drag type in onDragOver
    ev.dataTransfer.setData(dragType, dragType);
    ev.dataTransfer.setData('text/plain', itemId);
    ev.dataTransfer.setData('name', name);

    ev.dataTransfer.effectAllowed = 'copy';

    if (titleElRef.current) {
      ev.dataTransfer.setDragImage(titleElRef.current, 0, 0);
    }
  };
  function onDragEnd(_ev: React.DragEvent) {
    setIsDragging(false);
    // setIsSelected(false);
  }

  return (
    <>
      <Portal portalTarget={portalTarget}>
        <ContextMenu id={itemId} onShow={() => setShowMenuItems(true)}>
          {showMenuItems &&
            <AddToPlaylistSubMenu
              newPlaylistName={name}
              playlistItemOrItems={
                addItems => addFolderToPlaylist(addItems)(itemId)}
            />
          }
        </ContextMenu>
      </Portal>
      <div
        draggable
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        {columnSettings &&
          <ContextMenuTrigger
            ref={contextTrigger}
            disable={!isFolder}
            id={itemId}
            holdToDisplay={-1}
            disableIfShiftIsPressed
            attributes={{
              className: classNames(s.row, {
                [s.evenRow]: index % 2 === 0,
                [s.oddRow]: index % 2 !== 0,
                // [s.selected]: isSelected,
                [s.isDragging]: isDragging // && isSelected,
              }),
            }}
          >
            <div className={s.rowCell}>
            </div>
            {columns.map(col => {
              const colKey = col as ColumnKey;

              if (colKey === TrackProperty.title) {
                return (
                  <div
                    key={colKey}
                    ref={titleElRef}
                    style={{ width: columnSettings[TrackProperty.title]?.width }}
                    className={classNames(s.rowCell, {
                      [s.rightAlignCell]: PlaylistColumns[TrackProperty.title].alignRight
                    })}
                    title={name}
                  >
                    {isFolder && path
                      ? <Link to={path}>{name}</Link>
                      : <p>{name}</p>
                    }
                  </div>

                );
              }
              else /*if (colKey === 'size')*/ {
                return (
                  <div
                    key={colKey}
                    style={{ width: columnSettings['size']?.width }}
                    className={classNames(s.rowCell, {
                      [s.rightAlignCell]: PlaylistColumns.size.alignRight
                    })}
                    title={size}
                  >
                    {size}
                  </div>
                );
              }
            })}
            <div className={s.rowCell}>
              {isFolder &&
                <button className={`${s.menuBtn} invisible-control`} onClick={toggleMenu}>
                  ☰
                </button>
              }
            </div>
          </ContextMenuTrigger>
        }
      </div>
    </>
  );
}
