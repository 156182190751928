import { TrackProperty } from "../services/columnSettings/playlistColumns";

enum Source {
  /** Signifies absence of a track */
  Null,
  File,
  YouTube,
  OneDrive,
  GoogleDrive,
  Dropbox,
  Spotify,
  SoundCloud,
  Podcast,
}
export const Sources: {
  [P in Source]: {
    name: string;
    /**
     * Columns not applicable to Source. 
     * Width from these columns will be added to previous column.
     */
    skipColumns?: TrackProperty[];
  };
} = {
  [Source.Null]: {
    name: 'Null',
  },
  [Source.File]: {
    name: 'File',
  },
  [Source.YouTube]: {
    name: 'YouTube',
    skipColumns: [
      TrackProperty.album,
      TrackProperty.artist,
      TrackProperty.genre,
    ],
  },
  [Source.OneDrive]: {
    name: 'OneDrive',
  },
  [Source.GoogleDrive]: {
    name: 'GoogleDrive',
  },
  [Source.Dropbox]: {
    name: 'Dropbox',
  },
  [Source.Spotify]: {
    name: 'Spotify',
  },
  [Source.SoundCloud]: {
    name: 'SoundCloud',
  },
  [Source.Podcast]: {
    name: 'Podcast',
  },
};

export default Source;
