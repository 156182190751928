import classNames from 'classnames';
import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { IRoute } from '../../App';
import { SmallDeviceContext, SmallDeviceShow } from '../../services/smallDevices/smallDeviceService';
import { Context } from '../../services/_globalContext/context';
import s from './header.module.scss';

interface Props {
  routes: IRoute[];
  setup?: boolean;
}

export default function Header({
  routes,
  setup,
}: Props) {

  const ctx = useContext(Context);
  const smallDevSvc = useContext(SmallDeviceContext);
  const location = useLocation();

  return (
    <header>
      {ctx.isProcessing &&
        <div className={s.processingNotify}>
          Processing
        </div>
      }
      <nav className={classNames(s.navigation, {
        [s.open]: smallDevSvc.state === SmallDeviceShow.Navigation,
      })}>
        <div className={s.logoContainer}>
          <Link to="/">
            <img
              className={s.vinylImg}
              alt="Vinyl Record"
              src="/icons/vinyl.png"
            />
            Fadila
          </Link>
        </div>
        {/* <div className={classNames('callout', 'alert', s.notReadyAlert)}>
          Many of the following pages are not currently ready for smaller devices
        </div> */}
        {!setup &&
          <ul className="menu align-center">
            {routes.map(route =>
              <li
                key={route.path}
                className={classNames(s.navListItem, {
                  [s.outline]: location.pathname.indexOf(route.link || route.path) !== -1
                })}
              >
                <Link
                  to={route.link || route.path}
                  className={route.icon ? s.iconLink : ''}
                >
                  {route.icon
                    ? <img src={route.icon} className={s.routeIcon} alt={route.name} />
                    : route.name}
                </Link>
              </li>
            )}
          </ul>
        }
      </nav>
      {/* <div className="authorization">
        <h4>Login to access your private music</h4>
        <div className="button-group align-center">
          {gapiCtx.gapiAuthorized ?
            <button
              className="button"
              onClick={gapiCtx.logout}
            >
              Google Logout
            </button>
            : gapiCtx.gapiClientLoaded &&
            <button
              className={s.googleSignin}
              onClick={gapiCtx.authorize}
            >
            </button>
          }
          {oneDriveCtx.isAuthenticated ?
            <button
              className="button"
              onClick={oneDriveCtx.logout}
            >
              OneDrive Logout
            </button>
            :
            <button
              className="button"
              onClick={oneDriveCtx.authorize}
            >
              OneDrive Authorize
            </button>
          }
          {dbxCtx.account ?
            <button
              className="button"
              onClick={dbxCtx.logout}
            >
              Dropbox Logout
            </button>
            :
            <button
              className="button"
              onClick={dbxCtx.authorize}
            >
              Dropbox Authorize
            </button>
          }
        </div>
      </div> */}
    </header>
  );
}
