export default function preventDefault<
  T extends (ev: React.FormEvent) => any,
  >(
    f: T
  ) {

  return function (ev: React.FormEvent) {
    ev.preventDefault();

    return f(ev);
  };
}

export function preventDefaultStopPropogation<T extends (ev: any /** i give up */) => any>(f: T) {
  return function (ev: React.BaseSyntheticEvent) {
    ev.preventDefault();
    ev.stopPropagation();

    return f(ev);
  };
}
