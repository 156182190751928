import { Flate } from "../services/_globalContext/context";

/**
 * Converts a javascript object to ArrayBuffer using TextEncoder
 * 
 * @param obj 
 */
export default function compress(obj: any, flate: Flate) {
  const json = JSON.stringify(obj);

  const enc = new TextEncoder();
  const jsonBytes = enc.encode(json);

  return flate.gzip_encode_raw(jsonBytes);
}

export function decompress(arrayBuffer: ArrayBuffer, flate: Flate) {
  const decompressed = flate.gzip_decode_raw(
    new Uint8Array(arrayBuffer)
  );

  const dec = new TextDecoder();
  const json = dec.decode(decompressed);

  return JSON.parse(json);
}
