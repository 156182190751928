import { SubMenu } from "react-contextmenu";

export default class FadilaSubMenu extends SubMenu {

  menu: HTMLDivElement | undefined;
  subMenu: HTMLDivElement | undefined;

  /**
   * Fix ctx menu positioning so it doesn't end up off screen
   */
  getMenuPosition = () => {
    const { innerWidth, innerHeight } = window;
    const position: any = {};

    if (this.menu && this.subMenu) {
      const parentRect = this.menu.getBoundingClientRect();
      const rect = this.subMenu.getBoundingClientRect();

      // Does the sub menu grow below the bottom of viewport
      if (2 + parentRect.top + rect.height > innerHeight) {
        const amountSubMenuGrowsBelowViewport
          = rect.height - (innerHeight - (parentRect.top + 2));
        position.top = `-${amountSubMenuGrowsBelowViewport}px`;
      }
      else {
        position.top = 0;
      }
      // // Does the sub menu grow beyond the top of viewport
      // else if (parentRect.top - rect.height < 0) {
      //   position.bottom = `-${parentRect.top}px`;
      // }
  
      if (rect.right < innerWidth) {
        position.left = '100%';
      } else {
        position.right = '100%';
      }
    }

    return position;
  }
}
