/**
 * lowercase because HTML DnD API clobbers casing
 */
export enum DragType {
  None = 'none',
  PlaylistItems = 'playlistitems',
  Playlist = 'playlist',
  /**
   * All cloud tracks/videos are expected to create an array of Track[] 
   * and present their data in a similar way, unifying drop handling for those items
   */
  TrackItems = 'trackitems',

  YouTubePlaylist = 'youtube#playlist',
  YouTubeChannel = 'youtube#channel',
  YouTubeAny = 'youtubeany',

  SoundCloudPlaylist = 'soundcloudplaylist',

  SpotifyPlaylist = 'spotifyplaylist',
  SpotifyAlbum = 'spotifyalbum',

  GoogleDriveFolder = 'googledrivefolder',
  DropBoxFolder = 'dropboxfolder',
  OneDriveFolder = 'onedrivefolder',
}
