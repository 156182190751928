import { IRoute } from './App';
import dropbox from './assets/dropbox.svg';
import gdrive from './assets/googledrive.svg';
import onedrive from './assets/onedrive.svg';
import DropboxComponent from './components/dropbox/dropboxComponent';
import GoogleDriveComponent from './components/googleDrive/googleDriveComponent';
import Library from './components/library/libraryComponent';
import OneDriveComponent from './components/oneDrive/oneDriveComponent';
import PodcastComponent from './components/podcasts/podcastComponent';
import SettingsComponent from './components/settingsAndStartup/settingsComponent';
import SpotifyComponent from './components/spotify/spotifyComponent';
import YouTubeComponent from './components/youTube/youTubeComponent';

const routes: IRoute[] = [
  {
    name: 'Library',
    link: '/app/library',
    path: '/app/library/:playlist*',
    component: Library,
  },
  {
    name: 'YouTube',
    path: '/app/youtube',
    component: YouTubeComponent,
    notExact: true,
    icon: '/icons/yt_logo_rgb_light.png'
  },
  {
    name: 'Spotify',
    path: '/app/spotify',
    component: SpotifyComponent,
    icon: '/icons/Spotify_Logo_RGB_Black.png'
  },
  {
    name: 'OneDrive',
    link: '/app/onedrive',
    path: '/app/onedrive/:path*',
    component: OneDriveComponent,
    icon: onedrive,
  },
  {
    name: 'Google Drive',
    link: '/app/googleDrive',
    path: '/app/googleDrive/:path*',
    component: GoogleDriveComponent,
    icon: gdrive,
  },
  {
    name: 'Dropbox',
    link: '/app/dropbox',
    path: '/app/dropbox/:path*',
    component: DropboxComponent,
    icon: dropbox,
  },
  {
    name: 'Podcasts',
    path: '/app/podcasts',
    component: PodcastComponent,
    // icon: '/icons/podcast-icon-1322239_1280.png'
  },
  // {
  //   name: 'SoundCloud',
  //   path: '/app/soundCloud',
  //   component: SoundCloudComponent,
  // },
  {
    name: 'Settings',
    path: '/app/settings',
    component: SettingsComponent,
  },
];

export default routes;
