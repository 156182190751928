import classNames from 'classnames';
import React from 'react';
import s from './itemMoveTarget.module.scss';

interface Props {
  hide: boolean;
  posY?: number;
  width?: number;
}

export default function ItemRowMoveTarget({
  hide,
  posY,
  width,
}: Props) {

  return (
    <div
      style={{
        top: posY,
        width,
      }}
      className={classNames(s.moveTarget, {
        hide,
      })}
    >
    </div>
  );
}
