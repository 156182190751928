import classNames from 'classnames';
import React, { useContext, useRef, useState } from 'react';
import { DragType } from '../../models/dragTypes';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import YouTubeKind from '../../models/youTube/kind';
import { ActivePlaylistContext } from '../../services/activePlaylist/activePlaylist';
import PlaylistColumns, { ColumnKey, ColumnScope, TrackProperty } from '../../services/columnSettings/playlistColumns';
import { Context } from '../../services/_globalContext/context';
import decodeHtml from '../../utilities/decodeHtml';
import { preventDefaultStopPropogation } from '../../utilities/preventDefault';
import AddToPlaylistSubMenu from '../common/addToPlaylist/addToPlaylistSubMenu';
import FadilaMenuItem from '../common/contextMenu/fadilaMenuItem';
import useColumnSettings from '../common/hooks/useColumnSettings';
import s from '../common/itemRow/itemRow.module.scss';
import ItemRowWrapper from '../common/itemRow/ItemRowWrapper';
import sS from '../common/streamingServiceCommon/streamSvcSearchResultRow.module.scss';

export interface YouTubeSearchResultProps {
  /** Item array index */
  index: number;
  item: gapi.client.youtube.SearchResult;

  isSelected: boolean;
  selectedItemsRef: React.MutableRefObject<Set<gapi.client.youtube.SearchResult>>;

  handleToggleItem(plItm: gapi.client.youtube.SearchResult): void;
  changeItemSelectionState(
    plItm: gapi.client.youtube.SearchResult,
    selectState: boolean,
    removePrevious?: boolean): void;
  handleShiftSelect(plItm: gapi.client.youtube.SearchResult): void;
  clearSelected(): void;

  handlePlay(
    item: gapi.client.youtube.SearchResult,
  ): void;
  handleNavigation: Function;
  addAnyToPlaylist(
    addItems: (i: PlaylistItem[]) => void,
  ): (...searchResult: gapi.client.youtube.SearchResult[]) => void;

  portalTarget?: HTMLElement;
}

export default function YouTubeSearchResultRow({
  index,
  item,
  isSelected,
  selectedItemsRef,
  portalTarget,
  handlePlay,
  handleNavigation,
  changeItemSelectionState,
  handleShiftSelect,
  handleToggleItem,
  clearSelected,
  addAnyToPlaylist,
}: YouTubeSearchResultProps) {

  const selectedItems = selectedItemsRef.current;

  const titleElRef = useRef<HTMLTableDataCellElement>(null);

  const ctx = useContext(Context);
  const apCtx = useContext(ActivePlaylistContext);

  const [isDragging, setIsDragging] = useState(false);

  const { columns, columnSettings } = useColumnSettings(ColumnScope.YouTubeSearch);

  switch (item.id!.kind) {
    case YouTubeKind.YouTubeVideos:
    default:
      var youTubeKind = YouTubeKind.YouTubeVideos;
      var draggable = true;
      break;
    case YouTubeKind.YouTubePlaylist:
      var youTubeKind = YouTubeKind.YouTubePlaylist;
      var draggable = true;
      break;
    case YouTubeKind.YouTubeChannel:
      var youTubeKind = YouTubeKind.YouTubeChannel;
      var draggable = false;
      break;
  }

  const itemId = item.id!.channelId || item.id!.playlistId || item.id!.videoId!;

  const onDragStart = (
    ev: React.DragEvent,
  ) => {
    console.debug('dragstart on track item');

    if (youTubeKind !== YouTubeKind.YouTubeChannel) {
      setIsDragging(true);

      // if (youTubeKind === YouTubeKind.YouTubeVideos) {
      //   // unify accepted types 
      //   var dragType = DragType.TrackItems;
      // } else { // if (youTubeKind === YouTubeKind.YouTubePlaylist) {
      //   var dragType = DragType.YouTubePlaylist;
      // }

      var dragType = DragType.YouTubeAny;

      // We set loads of data here since you can't call getData in onDragOver
      ev.dataTransfer.setData('dragType', dragType);
      // types.includes checks for matching drag type in onDragOver
      ev.dataTransfer.setData(dragType, dragType);
      ev.dataTransfer.setData('text/plain', itemId);
      ev.dataTransfer.setData('name', item.snippet?.title!);

      ev.dataTransfer.effectAllowed = 'copy';

      if (titleElRef.current) {
        ev.dataTransfer.setDragImage(titleElRef.current, 0, 0);
      }
      if (!isSelected) {
        clearSelected();
        changeItemSelectionState(item, true, true);
      }
      // if (youTubeKind === YouTubeKind.YouTubeVideos) {
      ctx.dndData[itemId] = selectedItemsRef;
      // }
    }
  };
  function onDragEnd(_ev: React.DragEvent) {
    setIsDragging(false);

    delete ctx.dndData[itemId];
  }

  return (
    <ItemRowWrapper<gapi.client.youtube.SearchResult>
      index={index}
      item={item}
      itemId={itemId}
      className={sS.row}

      notDraggable={!draggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}

      isSelected={isSelected}
      isDragging={isDragging}

      handleShiftSelect={() => handleShiftSelect(item)}
      handleToggleItem={() => handleToggleItem(item)}
      selectOnlyItem={() => changeItemSelectionState(item, true, true)}

      handlePlay={youTubeKind === YouTubeKind.YouTubeVideos
        ? () => handlePlay(item)
        : () => handleNavigation()}

      menuItems={<>
        {youTubeKind === YouTubeKind.YouTubeVideos &&
          <FadilaMenuItem
            onClick={preventDefaultStopPropogation(() => handlePlay(item))}
            attributes={{
              className: 'secondary',
            }}
          >
            Play
          </FadilaMenuItem>
        }
        {youTubeKind === YouTubeKind.YouTubeVideos &&
          <AddToPlaylistSubMenu
            newPlaylistName={item.snippet!.title}
            playlistItemOrItems={
              addItems => addAnyToPlaylist(addItems)(...selectedItems as any)}
          />
        }
        {youTubeKind === YouTubeKind.YouTubePlaylist &&
          <AddToPlaylistSubMenu
            newPlaylistName={item.snippet!.title}
            playlistItemOrItems={addItems => addAnyToPlaylist(addItems)(...selectedItems as any)}
          />
        }
      </>}
      portalTarget={portalTarget}
    >
      {columnSettings &&
        <>
          <div className={classNames(s.rowCell, s.playBtn)}>
            {apCtx.currentTrack.id === itemId
              ?
              '♫'
              :
              <>
                <button
                  className="play-button"
                  onClick={youTubeKind === YouTubeKind.YouTubeVideos
                    ? preventDefaultStopPropogation(() => handlePlay(item))
                    : () => handleNavigation()}
                >
                  {youTubeKind === YouTubeKind.YouTubeVideos &&
                    '►'
                  }
                </button>
              </>
            }
          </div>
          {columns.map(col => {
            const colKey = col as ColumnKey;

            switch (colKey) {
              case TrackProperty.title:
                return (
                  <div
                    key={colKey}
                    ref={titleElRef}
                    className={classNames(s.rowCell, {
                      [s.rightAlignCell]: PlaylistColumns[colKey].alignRight
                    })}
                    style={{
                      width: columnSettings[colKey]?.width
                    }}
                    title={decodeHtml(item.snippet!.title!)}
                  >
                    {decodeHtml(item.snippet!.title!)}
                  </div>
                );

              case TrackProperty.album:
                return (
                  <div
                    key={colKey}
                    className={classNames(s.rowCell, {
                      [s.rightAlignCell]: PlaylistColumns[colKey].alignRight
                    })}
                    style={{
                      width: columnSettings[colKey]?.width
                    }}
                    title={decodeHtml(item.snippet!.channelTitle!)}
                  >
                    {decodeHtml(item.snippet!.channelTitle!)}
                  </div>
                );

              case 'image':
                return (
                  <div
                    key={colKey}
                    className={classNames(s.rowCell, {
                      [s.rightAlignCell]: PlaylistColumns[colKey].alignRight
                    })}
                    style={{
                      width: columnSettings[colKey]?.width
                    }}
                  >
                    <img
                      src={item.snippet!.thumbnails && item.snippet!.thumbnails.default!.url}
                      alt={item.snippet!.title}
                    />
                  </div>
                );

              case TrackProperty.type:
                return (
                  <div
                    key={colKey}
                    className={classNames(s.rowCell, {
                      [s.rightAlignCell]: PlaylistColumns[colKey].alignRight
                    })}
                    style={{
                      width: columnSettings[colKey]?.width
                    }}
                  >
                    {youTubeKind === YouTubeKind.YouTubeChannel
                      ? 'Channel'
                      : youTubeKind === YouTubeKind.YouTubePlaylist
                        ? 'Playlist'
                        : youTubeKind === YouTubeKind.YouTubeVideos
                          ? 'Video'
                          : 'Type Unknown'
                    }
                  </div>
                );

              default: throw new Error('Unexpected column mismatch!')
            }
          })}
        </>
      }
    </ItemRowWrapper>
  );
};
