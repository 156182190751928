import classNames from 'classnames';
import React from 'react';
import { DragType } from '../../models/dragTypes';
import { DropboxFile } from '../../models/dropboxFile';
import DropboxTrack from '../../models/track/dropboxTrack';
import PlaylistColumns from '../../services/columnSettings/playlistColumns';
import linkEncodeUri from '../../utilities/preEncodeUri';
import CloudProviderFileFolderRow from '../common/cloudProviderCommon/cloudProviderFileFolderRow';
import useColumnSettings from '../common/hooks/useColumnSettings';
import s from '../common/itemRow/itemRow.module.scss';
import { CloudItemRendererData } from '../common/itemRow/itemRowPropsAndTypes';
import TrackItemRow from '../playlist/playlistItems/trackItemRow';

export default React.memo(function DropboxItemRenderer({
  index,
  style,
  data,
}: CloudItemRendererData<DropboxFile, DropboxTypes.files.FileMetadataReference, DropboxTrack>) {

  const {
    items,
    audioItems,
    parentFolderPath,
  } = data;

  const {
    columnScope,

    dragType,

    handlePlayTrack,

    selectedItems,
    selectedItemsRef,
    handleToggleItem,
    changeItemSelectionState,
    handleShiftSelect,
    clearSelected,

    addFolderToPlaylist,

    portalTarget,
  } = data;

  const { columnSettings, } = useColumnSettings(columnScope);

  const indexBumpAmount = 1 + (parentFolderPath ? 1 : 0);

  const driveItem = items[index - indexBumpAmount]!; // We check undefined in base

  if (driveItem[".tag"] !== 'deleted') {
    const isAudio = audioItems.includes(driveItem as any);
    // const splitName = file.name.split('.');
    // splitName.pop();
    // const nameWithoutExtension = splitName.join('.');

    if (isAudio) {
      const audioFile = driveItem as DropboxTypes.files.FileMetadataReference;
      const track = DropboxTrack.from(audioFile);

      return (
        <div style={style}>
          <TrackItemRow
            key={audioFile.id}
            index={index}

            columnScope={columnScope}
            extraColumns={
              <>
                {columnSettings && columnSettings['size'] &&
                  <div
                    style={{ width: columnSettings['size'].width }}
                    className={classNames(s.rowCell, {
                      [s.rightAlignCell]: PlaylistColumns.size.alignRight
                    })}
                  >
                    {driveItem[".tag"] === 'file' ? audioFile.size : ''}
                  </div>
                }
              </>
            }
            isSelected={selectedItems.current.has(audioFile)}

            track={track}

            handlePlayTrack={() => handlePlayTrack(audioFile)}

            dragType={dragType}

            selectedItemsRef={selectedItemsRef}
            handleToggleItem={() => handleToggleItem(audioFile)}
            changeItemSelectionState={
              (_, selectState, removePrevious) => changeItemSelectionState(audioFile, selectState, removePrevious)}
            handleShiftSelect={() => handleShiftSelect(audioFile)}
            clearSelected={clearSelected}

            portalTarget={portalTarget}
          />
        </div>
      );
    } else {
      return (
        <div style={style}>
          <CloudProviderFileFolderRow
            index={index}
            key={driveItem.id}
            isFolder={driveItem[".tag"] === 'folder'}

            name={driveItem.name}
            itemId={driveItem.id}
            size={driveItem[".tag"] === 'folder'
              ? undefined
              : driveItem.size.toString()}
            path={driveItem[".tag"] === 'folder'
              ? `/app/dropbox${linkEncodeUri(driveItem.path_lower)}`
              : undefined}

            dragType={DragType.DropBoxFolder}
            columnScope={columnScope}

            addFolderToPlaylist={addItems => _ => addFolderToPlaylist(driveItem)(addItems)}
            portalTarget={portalTarget}
          />
        </div>
      );
    }
  }

  throw new Error('Deleted dropbox items not currently supported');
});
