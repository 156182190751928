import classNames from 'classnames';
import React, { useContext, useRef, useState } from 'react';
import Portal from '../../../gUtilities/portal';
import { DragType } from '../../../models/dragTypes';
import Playlist, { moveItems } from '../../../models/playlist/playlist';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import { LibraryContext } from '../../../services/library/libraryService';
import s from '../../common/itemRow/itemRow.module.scss';
import ItemRowMoveTarget from '../../common/itemRow/itemRowMoveTarget';

interface Props {
  portalTarget?: HTMLElement;
  playlist: Playlist;
  selectedItems: Set<PlaylistItem>;
}

export default function ItemRowEnd({
  playlist,
  selectedItems,
  portalTarget,
}: Props) {

  const [supportedDropTypes] = useState<string[]>([
    DragType.PlaylistItems,
  ]);

  const libCtx = useContext(LibraryContext);

  const elRef = useRef<HTMLTableRowElement>(null);

  const [isHoverTop, setIsHoverTop] = useState(false);

  const onDragOver = (ev: React.DragEvent<HTMLTableRowElement>) => {

    console.log(
      'onDragOver',
      'ev.dataTransfer.types.some(x => supportedDropTypes.includes(x)) == ' + (ev.dataTransfer.types.some(x => supportedDropTypes.includes(x))),
    );
    if (ev.dataTransfer.types.some(x => supportedDropTypes.includes(x))) {

      if (elRef.current) {

        setIsHoverTop(true);
        ev.dataTransfer.dropEffect = 'move';
      }

      ev.preventDefault();
    }
  };
  const onDragLeave = (_ev: React.DragEvent<HTMLTableRowElement>) => {
    setIsHoverTop(false);
  };
  const onDrop = (
    event: React.DragEvent<HTMLTableRowElement>,
  ) => {
    console.debug('onDrop');
    event.preventDefault();

    moveItems(playlist, libCtx.updatePlaylist)(selectedItems)(undefined)(false);

    setIsHoverTop(false);
  };

  const pos = elRef.current?.getBoundingClientRect();

  return (
    <>
      <div
        ref={elRef}
        className={classNames({
          [s.row]: true,
        })}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div></div>
      </div>
      <Portal portalTarget={portalTarget}>
        <ItemRowMoveTarget
          hide={!isHoverTop}
          posY={pos?.top}
          width={pos?.width}
        />
      </Portal>
    </>
  );
}
