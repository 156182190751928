import Track from '.';
import Source from '../source';

export default class NullTrack extends Track {
  constructor(
    name?: string,
    artist?: string,
  ) {
    super(
      'Null',
      Source.Null,
      name || 'Track not found in library',
      artist,
    );
  }

  toJSON = () => ({
    trackType: Source.Null,
  });
}
