import React from 'react';
import parseQueryString from '../../utilities/parseQueryString';
import { appKey } from './dropboxService';

interface Props {

}

/**
 * After user completes authentication with dropbox he is redirected to this component.
 * 
 * This component (and page), living in a child window of the main app, 
 * then uses HTML5 postMessage to allow the main app to complete authorization.
 * 
 */
export default function DropboxRedirect({

}: Props) {

  var hashObj = parseQueryString(window.location.hash);
  hashObj[appKey] = true;

  if (window.opener) {
    window.opener.postMessage(hashObj, window.location.origin);
  
    // const qs = queryString();
  
    
    // window.opener.postMessage({
    //   code: qs.code,
    //   nonce: qs.state,
    // }, 
    // window.location.origin);
  
    window.close();
  } else {
    console.debug('window.opener undefined')
  }

  return (
    <></>
  );
};
