import classNames from 'classnames';
import React from 'react';
import { ColumnScope, TrackProperty } from '../../../services/columnSettings/playlistColumns';
import useColumnSettings from '../hooks/useColumnSettings';
import s from '../itemRow/itemRow.module.scss';
import sNavigation from './cloudProviderNavigation.module.scss';

interface Props {
  /** Item array index */
  index: number;
  style: React.CSSProperties;
  columnScope: ColumnScope;
  children: React.ReactNode;
}

export default function CloudProviderNavigation({
  index,
  style,
  columnScope,
  children,
}: Props) {

  const { columnSettings, } = useColumnSettings(columnScope);

  return (
    <div style={style}>
      {columnSettings &&
        <div
          className={classNames({
            [s.row]: true,
            [s.evenRow]: index % 2 === 0,
            [s.oddRow]: index % 2 !== 0,
          })}
        >
          <div
            className={classNames(sNavigation.navigation, s.rowCell)}
            style={{ width: columnSettings[TrackProperty.title]?.width }}
          >
            {children}
          </div>
          <div></div>
        </div>
      }
    </div>
  );
}
