import IStorageProvider from "../storage/istorageProvider";

export const fileQueueCreator = function (
  fileName: string,
  setActive: React.MutableRefObject<(task: string) => void>,
  disableActive: React.MutableRefObject<(task: string) => void>,
  debounceTimer = 300,
) {

  let interval: NodeJS.Timeout | undefined;

  const state = {
    /** Is a request currently executing */
    isActive: false,
    /** Next in line */
    queuedReq: undefined as (() => Promise<void>) | undefined,
  };

  function finishReq() {
    if (state.queuedReq) {
      debounce();
    }

    disableActive.current(fileName);
    state.isActive = false;
  }

  async function dequeue() {
    if (!state.isActive && state.queuedReq) {
      setActive.current(fileName);
      state.isActive = true;

      // If we would execute a resolved promise before cleaning up
      // we might get an infinite self invoking loop
      const toExec = state.queuedReq;
      state.queuedReq = undefined;
      return await toExec();
    }
  }

  function debounce() {
    if (interval) {
      clearTimeout(interval);
    }

    interval = setTimeout(function () {
      dequeue();
      interval = undefined;
    }, 
    debounceTimer);
  }

  const q = {
    queue: function (data: any, provider: IStorageProvider) {

      state.queuedReq = () => provider.saveAppData(fileName, data)
        .finally(finishReq);

      debounce();
    },
    async executeSingle() {

      await dequeue();
    },
    get queuedReq() { return state.queuedReq; },
    get fileName() { return fileName; },
  };

  /**
   * Here we only handle a paused queue.
   * Context registers a callback to run if there are runningTasks
   */
  window.addEventListener('beforeunload', (ev: BeforeUnloadEvent) => {
    if (state.queuedReq) {
      const prompt = `${fileName} currently has unsaved changes, would you like to save?`;
      // We fire off the request, if user confirms reloading this evaporates anyway
      q.executeSingle().then(() => window.location.reload());
      ev.preventDefault();

      ev.returnValue = prompt;
      return prompt;
    }
  });

  return q;
};
