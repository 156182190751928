import Dropbox from 'dropbox';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import randomString from '../../gUtilities/randomString';
import { DropboxFile } from '../../models/dropboxFile';
import { isAudioFile } from '../../models/fileExtensions';
import { StorageProviderKey } from '../../models/storageProviderKeys';
import Constants, { Keys, messages } from '../../settings';
import compress, { decompress } from '../../utilities/flateCompressDecompress';
import IStorageProvider from '../storage/istorageProvider';
import { Context } from '../_globalContext/context';
import handleDropboxError from './handleDropboxError';

export const appKey = '2sblr267pe2r3uv';
/**
 * Seems insane to store this here despite
 * https://www.dropboxforum.com/t5/API-Support-Feedback/How-sensitive-are-the-app-key-and-app-secret/td-p/289907
 */
// const appSecret = 'no oauth code flow until we research better';

export interface IDropboxContext {
  dbx: Dropbox.Dropbox;
  authorize(): Promise<void>;
  logout(): void;
  account: Dropbox.users.FullAccount | undefined;
  nonce: React.MutableRefObject<string | undefined>;

  getUserDrive(path: string | undefined, resultsPerPage: number): Promise<DropboxIterator>;
  getAllTracksRecursive(path: string | undefined, ):
    Promise<DropboxTypes.files.FileMetadataReference[]>;

  errorHandler(err: any): void;
}
export const DropboxContext = React.createContext({} as IDropboxContext);

interface Props {
  children: React.ReactNode;
}

export interface DropboxIterator {
  files: Dropbox.files.ListFolderResult;
  next(prev: Dropbox.files.ListFolderResult): Promise<DropboxIterator>;
}
export default function DropboxService({
  children,
}: Props) {

  const [dbx] = useState(() => new Dropbox.Dropbox({
    clientId: appKey,
    fetch,
  }));

  const nonce = useRef<string>();
  const sp = useRef<IStorageProvider>();
  const messageHandlerCallback = useRef<Function>();

  const ctx = useContext(Context);
  const ctxRef = useRef(ctx);
  ctxRef.current = ctx;
  const [account, setAccount] = useState<Dropbox.users.FullAccount>();

  useEffect(function debug() {
    if (process.env.NODE_ENV === 'development') {
      (window as any)['dbx'] = dbx;
    }
  }, [dbx]);
  useEffect(function registerAsStorageProvider() {
    ctxRef.current.storageProviderMap[StorageProviderKey.Dropbox] = sp;
  }, []);
  useEffect(function loadTokenFromStorage() {
    (async function () {
      try {
        const storedToken = localStorage.getItem('dbxToken');
        if (storedToken) {
          dbx.setAccessToken(storedToken);
          const account = await dbx.usersGetCurrentAccount();
          setAccount(account);
        }
      } catch { }
    })();
  }, [dbx]);

  useEffect(function setupDropboxAuthenticationFlow() {

    function persistToken(token: string) {
      try {
        localStorage.setItem('dbxToken', token);
      } catch { }
    }
    // dbx.setClientSecret(appSecret);

    async function messageHandler(e: MessageEvent) {

      if (window.location.origin === e.origin
        && e.data[appKey] === true
        // && nonce.current
        // && e.data.nonce === nonce.current
      ) {

        // try {
        //   const res = await fetch(
        //     'https://api.dropboxapi.com/oauth2/token?' +
        //     `code=${e.data.code}&grant_type=authorization_code&redirect_uri=${document.location.origin + '/dropbox-redirect'}&client_id=${appKey}&client_secret=${appSecret}`, {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/x-www-form-urlencoded",
        //     },
        //   });

        //   if (res.ok) {
        //     const token = await res.json();

        // const code = await dbx.getAccessTokenFromCode(document.location.origin + '/dropbox', e.data.code); // invocation on window TypeError use manual fetch

        dbx.setAccessToken(e.data.access_token);
        // dbx.setAccessToken(token.access_token);
        const account = await dbx.usersGetCurrentAccount();
        setAccount(account);
        persistToken(e.data.access_token);
        //   }
        //   else {
        //     throw res;
        //   }
        // } catch {
        //   ctx.callSnackbar('Error completing dropbox authorization, please try again');
        // }

        if (messageHandlerCallback.current) {
          messageHandlerCallback.current();
        }
      }
    }

    window.addEventListener('message', messageHandler, false);

    return function () {
      window.removeEventListener('message', messageHandler, false);
    };
  }, [dbx]);

  const authorize = useCallback(function (): Promise<void> {
    const rndStr = randomString();
    nonce.current = rndStr;

    // const authUrl = dbxSvc.dbx.getAuthenticationUrl(
    //   document.location.origin + '/dropbox-redirect', 
    //   nonce, 
    //   'code',
    // );
    const authUrl = dbx.getAuthenticationUrl(document.location.origin + '/dropbox-redirect');
    window.open(authUrl, 'DropboxAuthPopup', 'width=670,height=800,dialog=yes,dependent=yes,scrollbars=yes,location=yes');

    return new Promise((resolve, reject) => {
      const timeout = setInterval(function checkIfClosed() {
        if (window.closed) {
          reject();
        }
      }, 300);

      messageHandlerCallback.current = () => {
        clearInterval(timeout);
        resolve();
      }
    });
  }, [dbx]);

  const logout = useCallback(function () {

    function completeLogout() {

      setAccount(undefined);
      dbx.authTokenRevoke();

      try {
        localStorage.removeItem('dbxToken');
      } catch (err) {
        ctxRef.current.callSnackbar(err);
      }
    }

    if (ctxRef.current.storageProvider.current === sp.current) {
      ctxRef.current.configureStorageProvider();
      ctxRef.current.spLogoutHandling().then(completeLogout);
    }
    else {
      completeLogout();
    }
  }, [dbx]);

  const getUserDrive = useCallback(async function (
    path: string | undefined = '',
    resultsPerPage: number,
    options?: any,
  ): Promise<DropboxIterator> {

    let files = await dbx.filesListFolder({
      path,
      limit: Math.max(100, resultsPerPage),
      ...(options || {}),
    });

    async function next(
      prevResults: Dropbox.files.ListFolderResult,
    ): Promise<DropboxIterator> {
      return {
        files: await dbx.filesListFolderContinue({
          cursor: prevResults.cursor,
        }),
        next,
      };
    }

    // Dropbox doesn't seem to return results = rpp
    while (files.has_more && files.entries.length < resultsPerPage) {
      const iter = await next(files);
      const entries = files.entries.concat(iter.files.entries);
      files = iter.files;
      files.entries = entries;
    }

    return {
      files,
      next,
    };
  }, [dbx]);

  const getAllTracksRecursive = useCallback(async function (
    path: string | undefined = '',
  ) {
    const resultsPerPage = 2000;

    ctxRef.current.setRunningTask('getDropboxTracksRecursive');
    const results: DropboxFile[] = [];

    try {
      let iter = await getUserDrive(
        path,
        resultsPerPage,
        {
          recursive: true,
          include_non_downloadable_files: false,
        }
      );

      let tracks = iter.files.entries.filter(x => isAudioFile(x.name));

      results.push(...tracks);

      while (iter.files.has_more) {
        iter = await iter.next(iter.files);
        tracks = iter.files.entries.filter(x => isAudioFile(x.name));

        results.push(...tracks);
      }
    } finally {
      ctxRef.current.removeRunningTask('getDropboxTracksRecursive');
    }

    return results as Dropbox.files.FileMetadataReference[];
  }, [getUserDrive]);

  const handleError = useMemo(
    () => handleDropboxError(ctxRef, logout),
    [logout],
  );

  const putFile = useCallback(async function (
    path: string,
    fileData: any,
    mute = false,
  ) {
    if (!ctxRef.current.flate) {
      throw new Error('Ensure wasm-flate is loaded before completing startup');
    }

    try {
      const compressed = compress(fileData, ctxRef.current.flate);

      await dbx.filesUpload({
        path,
        mode: {
          '.tag': 'overwrite'
        },
        mute,
        contents: compressed,
      });
    } catch (err) {
      handleError(err);
    }
  }, [dbx, handleError]);

  const getOrCreateDataFile = useCallback(async function (
    path: string,
    defaultData: any,
  ) {
    if (!ctxRef.current.flate) {
      throw new Error('Ensure wasm-flate is loaded before completing startup');
    }

    try {
      const res = await dbx.filesDownload({
        path
      });

      const buffer = await (res as any).fileBlob.arrayBuffer();

      return decompress(buffer, ctxRef.current.flate);
    }
    catch (err) {
      try {
        await handleError(err, async () => {
          // No need for user to wait on this, 
          // we do want to handle and/or display message on error though
          putFile(path, defaultData);
        });

        return defaultData;
      }
      catch (innerErr) {
        await handleError(err);
        return Promise.reject();
      }
    }
  }, [dbx, handleError, putFile]);

  useEffect(function updateStorageProvider() {
    sp.current = {
      name: StorageProviderKey.Dropbox,
      load: (fileName: string, defaultData: any) => {
        if (account) {
          return getOrCreateDataFile(`/${Constants.DataFolder}/${fileName}`, defaultData);
        }
        else {
          ctxRef.current.configureStorageProvider();
          return Promise.reject(messages(Keys.storageProviderNotLoggedIn));
        }
      },
      saveAppData: (fileName: string, fileData: any) => {
        if (account) {
          return putFile(`/${Constants.DataFolder}/${fileName}`, fileData);
        }
        else {
          ctxRef.current.configureStorageProvider();
          return Promise.reject(messages(Keys.storageProviderNotLoggedIn));
        }
      },
    };
  }, [account, getOrCreateDataFile, putFile]);

  /**
   * Here we proxy all method invocations on the dropbox object when used elsewhere in the app
   * This way we can ensure an access token is configured and display the appropriate message
   * if it is not.
   */
  const methodProxy = useMemo(() => ({
    apply: function (target: any, thisArg: any, argumentsList: any) {
      if (dbx.getAccessToken()) {
        return target.apply(dbx, argumentsList);
      } else {
        ctxRef.current.callSnackbar(messages(Keys.dropboxNotLoggedIn), false);

        return Promise.reject();
      }
    }
  }), [dbx]);
  const handler = useMemo(() => ({
    get: function (target: Dropbox.Dropbox, prop: string, _receiver: any) {

      const targetProp = (target as any)[prop];

      return typeof targetProp === 'object' 
        ? new Proxy(targetProp, methodProxy)
        : targetProp
        ;

      // return prop === 'filesGetTemporaryLink'
      //   ? new Proxy(targetProp, getTemporaryLinkProxy)
      //   : targetProp;
    },
  }), [methodProxy]);

  const dbxProxy = useMemo(() => new Proxy(dbx, handler), [dbx, handler]);

  const ctxValue = useMemo(() => ({
    dbx: dbxProxy,
    authorize,
    logout,
    account,
    nonce,

    getUserDrive,
    getAllTracksRecursive,

    errorHandler: handleError,
  }), [
    dbxProxy,
    authorize,
    logout,
    account,
    getUserDrive,
    getAllTracksRecursive,
    handleError,
  ]);

  return (
    <DropboxContext.Provider value={ctxValue}>
      {children}
    </DropboxContext.Provider>
  );
}
