import { ScreenSize } from '../screenDetection/screenDetection';
import calcWidthFromCharCount from './calcWidthFromCharCount';
import PlaylistColumns, { ColumnScope, ColumnSettings, SmallScreenColumns, TrackProperty } from './playlistColumns';

/**
 * When creating default settings for smaller screen sizes we grow all applicable columns
 * proportionately by the missing width from hidden columns.
 * 
 * @param columns 
 */
export default function playlistColumnSubset(
  viewportWidth: number,
  scope: ColumnScope,
  screenSize: ScreenSize.Small | ScreenSize.Medium,
  displayedColumnKeys: string[],
) {
  displayedColumnKeys = displayedColumnKeys.filter(
    key => SmallScreenColumns[screenSize].includes(key as TrackProperty))

  const displayedColWidthPercSum = displayedColumnKeys.reduce(
    (prev, cur) => {
      if (PlaylistColumns[cur as TrackProperty].calcFromCharCount !== true) {
        return prev + PlaylistColumns[cur as TrackProperty]!.defaultWidthPerc[scope];
      }
      else {
        return prev;
      }
    }, 0);

  // Available width in percentages
  const availableWidthPerc = 100 - displayedColWidthPercSum;

  const colSettings = displayedColumnKeys
    .filter(colKey => PlaylistColumns[colKey as TrackProperty].calcFromCharCount)
    .reduce((prev, cur) => {
      const curKey = cur as TrackProperty;
      const charCount = PlaylistColumns[curKey].defaultWidthPerc[scope];

      const colWidth = calcWidthFromCharCount(charCount);
      prev[curKey] = {
        width: colWidth,
        sortOrder: PlaylistColumns[curKey].sortOrder,
      };

      viewportWidth -= colWidth;
      return prev;

    }, {} as Partial<ColumnSettings>);

  return displayedColumnKeys
    .filter(colKey => PlaylistColumns[colKey as TrackProperty].calcFromCharCount !== true)
    .reduce((prev, cur) => {
      const curKey = cur as TrackProperty;
      const defaultWidthPerc = PlaylistColumns[curKey].defaultWidthPerc[scope];

      // Columns portion of the available width perc
      const columnPortion = availableWidthPerc * defaultWidthPerc / displayedColWidthPercSum;
      prev[curKey] = {
        width: viewportWidth
          * (defaultWidthPerc + columnPortion)
          / 100,
        sortOrder: PlaylistColumns[curKey].sortOrder,
      };

      return prev;

    }, colSettings);
}
