import React, { useContext } from 'react';
import { StorageProviderKey } from '../../models/storageProviderKeys';
import { DropboxContext } from '../../services/dropbox/dropboxService';
import { GoogleApiContext, GoogleScope } from '../../services/gapi/gapiService';
import { OneDriveContext } from '../../services/oneDrive/oneDriveService';
import { Context } from '../../services/_globalContext/context';
interface Props {
  setup?: boolean;
}

export default function ProviderList({
  setup,
}: Props) {

  const ctx = useContext(Context);
  const gapiCtx = useContext(GoogleApiContext);
  const oneDriveCtx = useContext(OneDriveContext);
  const dbxCtx = useContext(DropboxContext);

  const googleAccount = gapiCtx.getAccount();
  const googleProfile = googleAccount && googleAccount.getBasicProfile();
  const oneDriveAccount = oneDriveCtx.isAuthenticated && oneDriveCtx.getAccount();

  return (
    <ul>
      <li>
        {gapiCtx.authorizedScopes.indexOf(GoogleScope.AppData) !== -1 ?
          <>
            <p>Google Account {googleProfile.getEmail()}</p>
            <div className="button-group align-center">
              <button
                className="button"
                onClick={gapiCtx.logout}
              >
                Google Logout
              </button>
              <button
                className="button"
                onClick={() => ctx.configureStorageProvider(StorageProviderKey.GoogleDrive)}
              >
                Set as storage provider
              </button>
            </div>
          </>
          :
          <button
            className="googleSignin"
            onClick={setup
              ? () =>
                gapiCtx.authorize({
                  scope: GoogleScope.AppData,
                }).then(() =>
                  ctx.configureStorageProvider(StorageProviderKey.GoogleDrive)
                )
              : () => gapiCtx.authorize({
                scope: GoogleScope.AppData,
              })
            }
          >
          </button>
        }
      </li>
      <br />
      <li>
        {oneDriveAccount ?
          <>
            <p>OneDrive Account {oneDriveAccount.userName}</p>
            <div className="button-group align-center">
              <button
                className="button"
                onClick={oneDriveCtx.logout}
              >
                OneDrive Logout
              </button>

              <button
                className="button"
                onClick={() => ctx.configureStorageProvider(StorageProviderKey.OneDrive)}
              >
                Set as storage provider
              </button>
            </div>
          </>
          :
          <button
            className="button"
            onClick={setup
              ? () =>
                oneDriveCtx.authorize().then(
                  () => ctx.configureStorageProvider(StorageProviderKey.OneDrive)
                )
              : oneDriveCtx.authorize
            }
          >
            OneDrive Authorize
          </button>
        }
      </li>
      <br />
      <li>
        {dbxCtx.account ?
          <>
            <p>Dropbox Account {dbxCtx.account.email}</p>
            <div className="button-group align-center">
              <button
                className="button"
                onClick={dbxCtx.logout}
              >
                Dropbox Logout
              </button>
              <button
                className="button"
                onClick={() => ctx.configureStorageProvider(StorageProviderKey.Dropbox)}
              >
                Set as storage provider
              </button>
            </div>
          </>
          :
          <button
            className="button"
            onClick={setup
              ? () =>
                dbxCtx.authorize().then(() =>
                  ctx.configureStorageProvider(StorageProviderKey.Dropbox)
                )
              : dbxCtx.authorize
            }
          >
            Dropbox Authorize
          </button>
        }
      </li>
    </ul>
  );
}
