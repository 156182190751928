import { Either } from 'fp-ts/es6/Either';
import React, { Component, useContext } from 'react';
import { addItem, addItems } from '../../../models/playlist/playlist';
import { ActivePlaylistContext } from '../../../services/activePlaylist/activePlaylist';
import { LibraryContext } from '../../../services/library/libraryService';
import { Context } from '../../../services/_globalContext/context';
import Constants from '../../../settings';
import { preventDefaultStopPropogation } from '../../../utilities/preventDefault';
import FadilaMenuItem from '../contextMenu/fadilaMenuItem';
import FadilaSubMenu from '../contextMenu/fadilaSubMenu';
import { AddToPlaylistProps } from './addToPlaylistCommon';

export default React.forwardRef(function AddToPlaylistSubMenu({
  addToLibrary,
  newPlaylistName,
  playlistItemOrItems,
}: AddToPlaylistProps,
  ref: React.Ref<Component<any, any>>) {

  const ctx = useContext(Context);
  const library = useContext(LibraryContext);
  const activePlaylist = useContext(ActivePlaylistContext);

  function addToNewPlaylist() {
    const name = window.prompt(
      'Please enter or confirm new playlist name',
      newPlaylistName,
    );

    if (name) {
      if (typeof playlistItemOrItems === 'function') {
        var create = library.createPlaylist(name)
          .map(pl => addItems(pl)(library.updatePlaylist))
          // Call PlItm creator with add fn
          .map(add => playlistItemOrItems(add))
          ;
      } else {
        var create: Either<string, any> = library.createPlaylist(name)
          .map(pl => addItem(pl)(library.updatePlaylist))
          .map(add => add(playlistItemOrItems))
          ;
      }

      create.mapLeft(ctx.callSnackbar);
    }
  }

  return (
    <FadilaSubMenu
      title="Add to Playlist"
      preventCloseOnClick
      onClick={(ev: React.MouseEvent) => ev.stopPropagation()}
    >
      <FadilaMenuItem
        onClick={typeof playlistItemOrItems === 'function'
          ? preventDefaultStopPropogation(() => playlistItemOrItems(activePlaylist.addItems))
          : preventDefaultStopPropogation(() => activePlaylist.addItem(playlistItemOrItems))
        }
      >
        {Constants.ActivePlaylistName}
      </FadilaMenuItem>
      {addToLibrary &&
        <FadilaMenuItem
          onClick={typeof playlistItemOrItems === 'function'
            // This option applies to f.x. youtube component
            // Example: you want to add a video straight to library no playlist
            // In these cases playlistItemOrItems will be a function param
            // and that function will take care of adding to library which in turn
            // updates the library playlist
            ? preventDefaultStopPropogation(() => playlistItemOrItems(() => null))
            : preventDefaultStopPropogation(() => null)
          }>
          {Constants.LibraryName}
        </FadilaMenuItem>
      }
      {library.playlists.map(pl =>
        <FadilaMenuItem
          key={pl.id}
          onClick={typeof playlistItemOrItems === 'function'
            ? preventDefaultStopPropogation(() => playlistItemOrItems(addItems(pl)(library.updatePlaylist)))
            : preventDefaultStopPropogation(() => addItem(pl)(library.updatePlaylist)(playlistItemOrItems))
          }>
          {pl.name}
        </FadilaMenuItem>
      )}
      <FadilaMenuItem onClick={preventDefaultStopPropogation(addToNewPlaylist)}>
        {Constants.NewPlaylistName}
      </FadilaMenuItem>
    </FadilaSubMenu>
  );
});
