import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMac } from '../../../settings';
import { createChangeItemSelectionState, createHandleToggleItem, createShiftSelectItem } from '../../playlist/playlistCommon';

interface Params<T> {
  /**
   * Omit if useMoreSharedPlaylistMethods  
   * In that case the select all keyboard shortcut will be configured there instead
   * using filtered tracks
   */
  tracks?: T[];
  playlistElement: HTMLElement | undefined,
}
export default function useSharedPlaylistMethods<T>({
  tracks,
  playlistElement,
}: Params<T>) {

  // Current recommendation - 2020-04-27
  // Stable, mutable Set instance wrapped in a mutable wrapper.
  // Take care not to recreate Set when updating to ensure all handlers point to the same Set.
  //
  // Original: setState with the same set would not re-render due to reference equality
  // creating a new set on each update could be expensive
  // Thus we do like this, creating a wrapper object on each update
  //
  // Edit: Is not expensive, should refactor, we still need both of these however
  // Reasoning for refobject below.
  // To ensure non-equality of references all setSelectedItems must use a fresh { current: }
  // wrapper.
  // Components that depend on selectedItems for displaying selected state must of course
  // ensure to have this object assigned as prop since they would not re-render when 
  // solely depending on the refobject below.
  //
  // Edit2: Immutability for the set causes it's own problems.
  // If we recreate the set on each change that means we can not create handleToggleItem below
  // and expect that a call to ensureIsSelected that calls setSelectedItems will mean
  // that handleToggleItem's React.MutableRefObject points to the newly created Set
  // since a ensureIsSelected(handleToggleItem) call will invoke toggle before react
  // updates the ref..
  const [selectedItems, setSelectedItems] = useState({
    current: new Set<T>(),
  });
  // Yep this all seems really redundant but in fact it isn't
  // More importantly, this ref object is assigned to ctx.DnD data which definately needs
  // a stable refobject to dereference when it comes to handling onDrop events
  const selectedItemsRef = useRef(selectedItems.current);
  selectedItemsRef.current = selectedItems.current;

  const lastSelectedItem = useRef<T>();

  const handleToggleItem = useMemo(() => createHandleToggleItem(setSelectedItems, lastSelectedItem), []);
  const changeItemSelectionState = useMemo(() => createChangeItemSelectionState(setSelectedItems, lastSelectedItem), []);
  const shiftSelectItem = useMemo(() => createShiftSelectItem(setSelectedItems, lastSelectedItem), []);

  const clearSelected = useCallback(function () {
    setSelectedItems({
      current: new Set(),
    });
  }, []);

  useEffect(function configureKeyboardShortcuts() {
    function listener(ev: KeyboardEvent) {
      if (document.activeElement?.tagName !== 'INPUT'
        && ev.key === 'a'
        && ((isMac && ev.metaKey) || (!isMac && ev.ctrlKey))) {

        setSelectedItems({
          current: new Set(tracks),
        });

        ev.stopPropagation();
        ev.preventDefault();
      }
    }

    if (tracks) {
      document.addEventListener('keydown', listener);
    }

    return () => document.removeEventListener('keydown', listener);

  }, [playlistElement, tracks]);

  return {
    lastSelectedItem,
    selectedItems,
    selectedItemsRef,

    handleToggleItem,
    shiftSelectItem,
    changeItemSelectionState,
    clearSelected,

    setSelectedItems,
  };
}
