const fileExtensions = [
  '.ogg',
  '.oga',
  '.mogg',
  '.3gp',
  '.aa',
  '.aac',
  '.aax',
  '.act',
  '.aiff',
  '.amr',
  '.ape',
  '.au',
  '.awb',
  '.dct',
  '.dss',
  '.dvf',
  '.flac',
  '.gsm',
  '.iklax',
  '.ivs',
  '.m4a',
  '.m4b',
  '.m4p',
  '.mmf',
  '.mp3',
  '.mpc',
  '.msv',
  '.nmf',
  '.nsf',
  '.opus',
  '.ra',
  '.rm',
  '.raw',
  '.sln',
  '.tta',
  '.voc',
  '.vox',
  '.wav',
  '.wma',
  '.wv',
  '.webm',
  '.8svx',
];
export default fileExtensions;

export function isAudioFile(fileName: string) {
  return fileExtensions.some(x => fileName.toLowerCase().endsWith(x));
}
