import React from 'react';
import { SpotifyPlaylistSimplified } from '../../models/spotify/spotifyPlaylist';
import { AdditionalContentProps } from '../common/streamingServiceCommon/streamSvcPlaylistPlaylistRow';
import s from './spotify.module.scss';
import getImageBySize from './spotifyCommon';

export default function SpotifyPlaylistRowContent({
  playlist
}: AdditionalContentProps<SpotifyPlaylistSimplified>) {

  return (
    <>
      {playlist &&
        <img
          className={s.playlistImg}
          src={getImageBySize(playlist.images).url}
          alt={playlist.name}
        />
      }
    </>
  );
}
