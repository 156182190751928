import { array } from 'fp-ts/es6';
import { concat, curry, pipe } from 'fp-ts/es6/function';
import { none, some } from 'fp-ts/es6/Option';
import move from '../../gUtilities/fp/move';
import uuidv4 from '../../gUtilities/uuidv4';
import RepeatState from '../../models/repeatState';
import Track from '../../models/track';
import { ILibraryContext } from '../../services/library/libraryService';
import Constants from '../../settings';
import PlaylistItem from "../playlistItem/playlistItem";
import SyncSource from './syncSource';

export default class Playlist {
  static from(tracks: PlaylistItem[]) {
    const id = uuidv4();
    return new Playlist(
      id,
      tracks,
      id,
    );
  }
  static activeFrom(tracks: PlaylistItem[]) {
    return new Playlist(
      Constants.ActivePlaylistName,
      tracks,
      Constants.ActivePlaylistId,
    );
  }

  syncSources: SyncSource[] = [];
  /** Could be a set but that complicates toJson/fromJson */
  syncIgnore: {
    [index: string]: boolean;
  } = {};

  constructor(
    public name: string,
    public tracks: PlaylistItem[] = [],
    public id: string = '',
  ) {
    if (id === '') {
      this.id = uuidv4();
    }
  }
}

/** Handles upgrading older playlist files */
export function playlistUpgrader(pl: Playlist) {
  pl.syncSources = pl.syncSources || [];
  pl.syncIgnore = pl.syncIgnore || {};

  return pl;
}
export const addItem
  = curry((playlist: Playlist,
    setPlaylist: (pl: Playlist) => void,
  ) =>
    pipe(
      (plItm: PlaylistItem) => Array.of(plItm),
      curry(concat)(playlist.tracks),
      tracks => ({ ...playlist, tracks }),
      setPlaylist,
    )
  );
export const addItems
  = curry((playlist: Playlist,
    setPlaylist: (pl: Playlist) => void,
  ) =>
    pipe(
      (plItms: PlaylistItem[]) => plItms,
      curry(concat)(playlist.tracks),
      tracks => ({ ...playlist, tracks }),
      setPlaylist,
    )
  );

interface Params {
  tasks: Promise<any>[],
  items: PlaylistItem[],
  addItems: (plItms: PlaylistItem[]) => void,
}
export function addMultipleItemsAsync({
  tasks,
  items,
  addItems,
}: Params) {
  // Try to add all successful in browser agents supporting allSettled
  if ('allSettled' in Promise) {
    return Promise.allSettled(tasks)
      .then(() => addItems(items));
  }
  else {
    return Promise!.all(tasks)
      .then(() => addItems(items));
  }
}

export class PlaylistNotFoundError extends Error { }
/**
 * Neibb thetta virkar ekki, sja libCtxRef.updatePlaylist, 
 * thad hefur verid timing vesen med svona ref notkun adur
 * 
 * @param libCtxRef 
 * @param playlist 
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function addItemsUsingRef(
  libCtxRef: React.MutableRefObject<ILibraryContext>,
  playlist: Playlist,
) {
  return function doAddItemsUsingRef(plItms: PlaylistItem[]) {

    // This ensures we always have the freshest version of this playlist
    // When we fail to find it means this is a newly created playlist
    playlist = libCtxRef.current.playlists.find(pl => pl.id === playlist.id) || playlist;




    return pipe(
      (plItms: PlaylistItem[]) => plItms,
      curry(concat)(playlist.tracks),
      tracks => ({ ...playlist, tracks }),
      libCtxRef.current.updatePlaylist,
    )(plItms);
  }
}

export const insertPlaylistItem
  = (pl: Playlist,
    setPlaylist: (pl: Playlist) => void,
  ) =>
    curry((index: number, playlistItem: PlaylistItem) =>
      array.insertAt(index, playlistItem, pl.tracks)
        .map(tracks => ({ ...pl, tracks }))
        .map(setPlaylist)
    );

export const insertTrack
  = (pl: Playlist,
    setPlaylist: (pl: Playlist) => void,
  ) =>
    curry((index: number, track: Track) =>
      insertPlaylistItem(pl, setPlaylist)(index)
        (PlaylistItem.from(track)));

/** Helper */
export const removeItemAtIndex = curry((
  pl: Playlist,
  idx: number,
) => array.deleteAt(idx, pl.tracks)
  .map(tracks => ({ ...pl, tracks }))
);
/** Currently does not care if we are deleting currentPlaylistItem */
export const removeItem = (
  playlist: Playlist,
  setPlaylist: (pl: Playlist) => void,
) => (
  itemToRemove: PlaylistItem,
  ) => {
    setPlaylist({
      ...playlist,
      tracks: playlist.tracks.filter(x => x !== itemToRemove)
    });
    return some(undefined as void);
  };
/** Currently does not care if we are deleting currentPlaylistItem */
export const removeItems = (
  playlist: Playlist,
  setPlaylist: (pl: Playlist) => void,
) => (
  ...itemsToRemove: PlaylistItem[]
) => {
    setPlaylist({
      ...playlist,
      tracks: playlist.tracks.filter(x => !itemsToRemove.includes(x))
    });
    return some(undefined as void);
  };
export const removeTrackNoPersist = (
  playlist: Playlist,
) => (track: Track): Playlist => ({
  ...playlist,
  tracks: playlist.tracks.filter(x => x.id !== track.id),
});

export const moveItem = (
  playlist: Playlist,
  setPlaylist: (pl: Playlist) => void,
) =>
  curry((
    from: number,
    to: number,
  ) =>
    pipe(
      move<PlaylistItem>(from)(to),
      tracks => ({ ...playlist, tracks }),
      setPlaylist,
    )(playlist.tracks)
  );
export const moveItems = (
  playlist: Playlist,
  setPlaylist: (pl: Playlist) => void,
) =>
  curry(function (
    itemsToMove: Set<PlaylistItem>,
    moveTarget: PlaylistItem | undefined,
    after: boolean,
  ) {
    if (moveTarget && itemsToMove.has(moveTarget)) {
      // Won't happen unless i code drunk
      throw new Error('Move target cannot be subject to a move.');
    }

    const itemsNotBeingMoved = playlist.tracks.filter(x => !itemsToMove.has(x));

    if (moveTarget) {
      const targetIdx = after
        ? itemsNotBeingMoved.indexOf(moveTarget) + 1
        : itemsNotBeingMoved.indexOf(moveTarget);

      setPlaylist({
        ...playlist,
        tracks: [
          ...itemsNotBeingMoved.slice(0, targetIdx),
          ...itemsToMove,
          ...itemsNotBeingMoved.slice(targetIdx),
        ],
      });
    }
    else {
      setPlaylist({
        ...playlist,
        tracks: itemsNotBeingMoved.concat(...itemsToMove),
      });
    }
  });

export function prevItemIndex(
  playlist: Playlist,
  curPlaylistItem: number,
) {
  return playlist.tracks.length === 0
    ? none
    : curPlaylistItem === -1 || curPlaylistItem === 0
      ? some(0)
      : some(curPlaylistItem - 1);
}
export function nextItemIndex(
  playlist: Playlist,
  curPlaylistItem: number,
  repeat: RepeatState,
) {
  if (playlist.tracks.length === 0) {
    return none;
  }

  if (repeat === RepeatState.One) {
    return some(curPlaylistItem);
  }

  const lastIndex = playlist.tracks.length - 1;

  if (repeat === RepeatState.None && curPlaylistItem === lastIndex) {
    return none;
  }

  // RepeatState.All or None when playlist not finished
  return curPlaylistItem === -1 || curPlaylistItem === lastIndex
    ? some(0)
    : some(curPlaylistItem + 1);
}
