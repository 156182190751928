import { Either } from "fp-ts/es6/Either";
import YouTubePlaylistItemWithDuration from "../../models/gapi/gapiPlaylistItemExtensions";
import PlaylistItem from "../../models/playlistItem/playlistItem";
import QueryIterator from "../../models/queryIterator";
import SpotifyApiPlaylistTrack from "../../models/spotify/spotifyApiTrack";
import { GlobalState } from "../../services/_globalContext/context";
import { Keys, messages } from "../../settings";

export default function createAddPlaylistToPlaylist<T = YouTubePlaylistItemWithDuration | SpotifyApiPlaylistTrack>(
  ctx: GlobalState,
  getPlaylistItemsPaged: (playlistId: string) => Promise<QueryIterator<T>>,
  addItemsThenToLibrary: (addItems: (i: PlaylistItem[]) => void) => (...items: T[]) => Either<void, void>,
  addItems: (i: PlaylistItem[]) => void,
) {
  return async function (playlistId: string) {

    ctx.setRunningTask(`addPlaylistToPlaylist ${playlistId}`);

    try {
      const items: T[] = [];
      let res = await getPlaylistItemsPaged(playlistId);

      items.push(...(res.results || []));

      while (res.next) {
        res = await res.next();

        items.push(...(res.results || []));
      }

      addItemsThenToLibrary(addItems)(...items);
    } 
    catch (err) {
      ctx.callSnackbar(messages(Keys.errorAddingPlaylistToLibrary));
      console.error(err);
    } 
    finally {
      ctx.removeRunningTask(`addPlaylistToPlaylist ${playlistId}`);
    }
  }
}
