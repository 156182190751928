import cx from 'classnames';
import React from 'react';
import { MenuItem } from "react-contextmenu";
import { cssClasses } from 'react-contextmenu/es6/helpers';

/**
 * Remove onTouchEnd event handler
 */
export default class FadilaMenuItem extends MenuItem {
  render() {
    const {
      attributes,
      children,
      className,
      disabled,
      divider,
      selected
    } = this.props;

    const menuItemClassNames = cx(
      className,
      cssClasses.menuItem,
      attributes.className,
      {
        [cx(cssClasses.menuItemDisabled, attributes.disabledClassName)]: disabled,
        [cx(cssClasses.menuItemDivider, attributes.dividerClassName)]: divider,
        [cx(cssClasses.menuItemSelected, attributes.selectedClassName)]: selected
      }
    );

    return (
      // eslint-disable-next-line jsx-a11y/role-supports-aria-props
      <div
        {...attributes} 
        className={menuItemClassNames}
        role='menuitem' 
        tabIndex='-1' 
        aria-disabled={disabled ? 'true' : 'false'}
        aria-orientation={divider ? 'horizontal' : null}
        ref={(ref) => { this.ref = ref; }}
        onMouseMove={this.props.onMouseMove} 
        onMouseLeave={this.props.onMouseLeave}
        onClick={this.handleClick}
      >
        {divider ? null : children}
      </div>
    );
  }
}
