import React, { useContext } from 'react';
import AccessibleListElement from '../../gUtilities/components/accessibleListElement';
import SyncType from '../../models/playlist/syncType';
import { LibraryContext } from '../../services/library/libraryService';
import { PodcastContext } from '../../services/podcasts/podcastService';
import { Context } from '../../services/_globalContext/context';
import ErrorBoundary from '../common/errorBoundary/errorBoundary';
import s from './podcast.module.css';

interface Props {

}

export default function PodcastComponent({

}: Props) {

  const ctx = useContext(Context);
  const libCtx = useContext(LibraryContext);
  const podcastCtx = useContext(PodcastContext);

  // const [podcastLoading, setPodcastLoading] = useState(false);
  // const [curPodcast, setCurPodcast] = useState<Podcast>();

  // const [podcastPageNumber, setPodcastPageNumber] = useState(1);

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    const form = ev.currentTarget;
    const inputEl: HTMLInputElement = form['add-podcast'];

    if (inputEl.value.length) {
      podcastCtx.addPodcast(inputEl.value);

      inputEl.value = '';
    } else {
      ctx.callSnackbar('Podcast feed missing');
    }
  }

  return (
    <ErrorBoundary>
    <article className="grid-container fluid">
      <div className="grid-x grid-margin-x">
        <div className="cell shrink">
          <div className={`mainViewport ${s.podcasts} ${ctx.playerSize}`}>
            <form onSubmit={handleSubmit}>
              <label htmlFor="add-podcast">
                <div className="grid-x ">
                  <input
                    className="cell large-9"
                    id="add-podcast"
                    name="add-podcast"
                    type="text"
                    placeholder="Podcast Feed"
                  />
                  <button className="cell large-3 button">
                    Add
                  </button>
                </div>
              </label>
            </form>
            <article>
              <h3>Podcast Playlists</h3>
              <ul className={s.podcastList}>
                {libCtx.playlists.filter(pl => pl.syncSources.some(ss => ss.type === SyncType.Podcast))
                  .map((pl) =>
                  <AccessibleListElement key={pl.id} onActivate={() => console.debug('activated podcast list element')}>
                    {pl.name}
                  </AccessibleListElement>
                )}
              </ul>
            </article>
          </div>
        </div>
        <div className="cell auto">
        <p>This page currently simply adds podcast playlists to your library.</p>
        <p>Podcast playlists are synced automatically and all other management tasks are handled in the library.</p>
          {/* {(podcastLoading || curPodcast) && (() => {

            // add podcasts adds to library?
            // A podcast is a playlist that syncs from an rss url
            // the playlist can have multiple tracks excluded from syncing to list
            // that way when you save a track from the feed you add it to exclusion
            // also this way you can add older tracks from the podcast to the list

            const externalTrackItems = curPodcast?.items.map(x => new WebTrack(x, curPodcast));

            return (
              <PlaylistComponent
                draggable={false}
                playlist={new Playlist(
                  curPodcast?.title || '',
                  externalTrackItems?.map(PlaylistItem.from),
                  curPodcast?.feed,
                )}
                trackItems={externalTrackItems || []}
                loading={podcastLoading}
                controlledPlaylist

                display={true}
                totalItemCount={() => curPodcast?.items.length || 0}

                // pageNumber={podcastPageNumber}
                // setPageNumber={setPodcastPageNumber}
                // handleNextPage={() => Promise.resolve(
                //   setPodcastPageNumber(podcastPageNumber + 1))}
                // handleChangePage={ev => Promise.resolve(
                //   setPodcastPageNumber(+ev.currentTarget.value))}
              />
            );
          })} */}
        </div>
      </div>
    </article>
    </ErrorBoundary>
  );
}
