import React, { useCallback, useEffect, useState } from 'react';

export enum ScreenSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'X-Large',
}
const s = '(max-width: 768px)';
const m = '(max-width: 1024px)';
const l = '(max-width: 1440px)';

export const ScreenDetectionContext = React.createContext(ScreenSize.Large);

interface Props {
  children: React.ReactNode;
}

export default function ScreenDetection({
  children
}: Props) {

  const [mediaQueries] = useState({
    [ScreenSize.Small]: window.matchMedia(s),
    [ScreenSize.Medium]: window.matchMedia(m),
    [ScreenSize.Large]: window.matchMedia(l),
  });

  const mediaQueryListener = useCallback(function (ev?: MediaQueryListEvent) {
    console.debug('match media listener called', ev?.matches);

    if (mediaQueries[ScreenSize.Small].matches) {
      if (ev) {
        setScreenSize(ScreenSize.Small);
      }
      else {
        return ScreenSize.Small;
      }
    }
    else if (mediaQueries[ScreenSize.Medium].matches) {
      if (ev) {
        setScreenSize(ScreenSize.Medium);
      }
      else {
        return ScreenSize.Medium;
      }
    }
    else if (mediaQueries[ScreenSize.Large].matches) {
      if (ev) {
        setScreenSize(ScreenSize.Large);
      }
      else {
        return ScreenSize.Large;
      }
    }
    else {
      if (ev) {
        setScreenSize(ScreenSize.XLarge);
      }
      else {
        return ScreenSize.XLarge;
      }
    }
  }, [mediaQueries]);

  const [screenSize, setScreenSize] = useState(() => mediaQueryListener()!);

  useEffect(function configureScreenDetection() {

    mediaQueries[ScreenSize.Small].addListener(mediaQueryListener);
    mediaQueries[ScreenSize.Medium].addListener(mediaQueryListener);
    mediaQueries[ScreenSize.Large].addListener(mediaQueryListener);

    return () => {
      mediaQueries[ScreenSize.Small].removeListener(mediaQueryListener);
      mediaQueries[ScreenSize.Medium].removeListener(mediaQueryListener);
      mediaQueries[ScreenSize.Large].removeListener(mediaQueryListener);
    };
  }, [mediaQueries, mediaQueryListener]);

  return (
    <ScreenDetectionContext.Provider value={screenSize}>
      {children}
    </ScreenDetectionContext.Provider>
  );
}
