import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import mimeTypes from '../../models/mimeTypes';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import QueryIterator from '../../models/queryIterator';
import Path from '../../models/routing';
import GoogleDriveTrack from '../../models/track/googleDriveTrack';
import { ActivePlaylistContext } from '../../services/activePlaylist/activePlaylist';
import { GoogleApiContext, GoogleScope } from '../../services/gapi/gapiService';
import handleGapiError from '../../services/gapi/handleGapiError';
import { createAddItemsToPlaylistThenLibrary } from '../../services/library/addTracksToPlaylistThenLibrary';
import { LibraryContext } from '../../services/library/libraryService';
import { Context } from '../../services/_globalContext/context';
import Constants, { Tasks } from '../../settings';
import CloudProviderFilesWithMemo from '../common/cloudProviderCommon/cloudProviderFiles';
import ErrorBoundary from '../common/errorBoundary/errorBoundary';
import PlaylistControls from '../common/playlistControls/playlistControls';
import Spinner from '../common/spinner/spinner';
import LibraryPlaylists from '../library/libraryPlaylists';
import googleDriveItemRenderer from './googleDriveItemRenderer';

interface Props {
}

export default function GoogleDriveComponent({
}: Props) {

  const history = useHistory();
  const match = useRouteMatch<Path>();

  const ctx = useContext(Context);
  const libCtx = useContext(LibraryContext);
  const gapiCtx = useContext(GoogleApiContext);
  const activePlaylist = useContext(ActivePlaylistContext);

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [folder, setFolder] = useState<gapi.client.drive.File>();
  const [driveData, setDriveData]
    = useState<gapi.client.drive.File[]>([]);
  const [driveIterator, setDriveIterator]
    = useState<QueryIterator<gapi.client.drive.File>>();

  const audioFiles = useMemo(
    () => driveData.filter(x => mimeTypes.has(x.mimeType!)),
    [driveData]);

  const errorHandler = useMemo(
    () => handleGapiError(ctx.callSnackbar, gapiCtx.logout),
    [ctx.callSnackbar, gapiCtx.logout]);

  useEffect(function loadDriveData() {

    let halt = false;

    async function getSetUserDrive(path: string) {
      try {
        setLoading(true);
        const iterator = await gapiCtx.getUserDrive(path, Constants.ResultCount);
        // Get current folder entry, used for Up navigation
        gapiCtx.getDriveFolder(path).then(res => setFolder(res.result));

        console.debug(iterator);

        if (halt === false) {
          setDriveData(iterator.results || []);
          setDriveIterator(iterator);
        }
      }
      catch (error) {
        errorHandler(error);
      }
      finally {
        setLoading(false);
      }
    }

    if (gapiCtx.gapiAuthorized
      && gapiCtx.authorizedScopes.indexOf(GoogleScope.DriveReadonly) !== -1) {
      if (match.params.path) {
        getSetUserDrive(match.params.path);
      } else {
        // Load drive root
        getSetUserDrive('root');
      }
    }

    return () => { halt = true };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gapiCtx.gapiAuthorized, gapiCtx.authorizedScopes, match.params.path]);

  const addItemsToPlaylistThenLibrary = useMemo(() => createAddItemsToPlaylistThenLibrary<gapi.client.drive.File>(
    ctx,
    libCtx,
    GoogleDriveTrack.from,
  ), [ctx, libCtx]);

  const handlePlayTrack = useCallback((driveItem: gapi.client.drive.File) => {

    const audioItems = driveData.filter(x => x.mimeType && mimeTypes.has(x.mimeType));
    const idx = audioItems.indexOf(driveItem);

    const tracks
      = audioItems.slice(idx, audioItems.length)
        .concat(audioItems.slice(0, idx));

    const curPlItm = 0;
    const doPlay = true;
    addItemsToPlaylistThenLibrary(x =>
      activePlaylist.setActivePlaylist(
        x,
        curPlItm,
        doPlay,
      )
    )(...tracks);
  }, [addItemsToPlaylistThenLibrary, activePlaylist, driveData]);

  // Async state
  const isLoadingMore = useRef(false);
  const stopIdxRef = useRef<number>();

  const loadMore = useCallback(async function (startIdx: number, stopIdx: number) {

    console.info('LoadMore', startIdx, stopIdx);

    // accounts for index padding in react-window due to navigation elements
    const virtualItemCount
      = 1 // Go to root / home button
      + (match.params.path ? 1 : 0) // Go Up
      ;

    let stopIndex = stopIdx - virtualItemCount;

    // All executions update stop index
    // JS is not multi-threaded, so we can assume that a second loadMore won't get called
    // until iterate below gets called
    // This allows us to update let stopIndex at end of while loop
    // allowing further data loading if warranted
    stopIdxRef.current = stopIndex;

    if (isLoadingMore.current) {
      return;
    }

    isLoadingMore.current = true;

    if (driveIterator && driveIterator.next) {

      setLoadingMore(true);
      let iterator = driveIterator;
      let googleDriveData = driveData;

      try {
        while (stopIndex >= googleDriveData.length && iterator.next !== undefined) {

          iterator = await iterator.next();

          if (iterator.results) {
            googleDriveData = googleDriveData.concat(iterator.results);
          }

          setDriveData(googleDriveData);

          // Update after async method returns
          stopIndex = stopIdxRef.current;
        }
      }
      catch (err) {
        errorHandler(err);
      }
      finally {
        isLoadingMore.current = false;
        setDriveIterator(iterator);
        setLoadingMore(false);
      }
    }
  }, [driveData, match.params.path, errorHandler, driveIterator]);

  const addFolderToPlaylist = useCallback(function (
    driveItem: gapi.client.drive.File,
  ) {
    return async function (
      addItemsToPlaylist: (i: PlaylistItem[]) => void,
    ) {

      ctx.setRunningTask(Tasks.addFolderToPlaylist);
      try {
        const driveItems = await gapiCtx.getFilesInFolderRecursive(driveItem.id!);
        const audioItems = driveItems.filter(x => mimeTypes.has(x.mimeType!));

        addItemsToPlaylistThenLibrary(addItemsToPlaylist)(...audioItems);
      }
      catch (err) {
        errorHandler(err);
      }
      finally {
        ctx.removeRunningTask(Tasks.addFolderToPlaylist);
      }
    };
  }, [ctx, gapiCtx, errorHandler, addItemsToPlaylistThenLibrary]);

  /**
   * Google Drive Api does not show total item count on partial iteration
   * This in turns disables page selection before complete iteration.
   * This is a workaround
   * @param ev 
   */
  const completeFolderIterate = useCallback(async function (_ev: React.MouseEvent<HTMLButtonElement>) {
    if (driveIterator?.next) {
      setLoading(true);

      let iter = driveIterator;

      let googleDriveData = driveData;

      try {
        do {
          iter = await iter.next!(1000);

          if (iter.results) {
            googleDriveData = googleDriveData.concat(iter.results);
          }
        } while (iter.next);

        setDriveData(googleDriveData);
        setDriveIterator(iter);

      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    }
  }, [driveIterator, errorHandler, driveData]);

  return (
    <ErrorBoundary>
      {loading ?
        <Spinner />
        : gapiCtx.authorizedScopes.indexOf(GoogleScope.DriveReadonly) === -1 ?
          <div>
            <button
              className="googleSignin"
              onClick={() => gapiCtx.authorizeAdditionalScope(GoogleScope.DriveReadonly)}
            >
            </button>
          </div>
          :
          <article className="grid-container full fluid">
            <div className="grid-x">
              <div className="cell shrink">
                <article className={`mainViewport ${ctx.playerSize}`}>
                  <LibraryPlaylists />
                </article>
              </div>

              <div className="cell auto">
                <article className={`mainViewport ${ctx.playerSize}`}>
                  <PlaylistControls
                    name={folder?.name || '..'} // Placeholder while we get folder
                    display={driveIterator !== undefined}
                    isComplete={() => !driveIterator!.next}

                    filteredItemCount={driveData.length}
                    totalItemCount={() => driveIterator!.next
                      ? 0
                      : driveData.length!}

                    displayGetRemaining={true}
                    getRemaining={completeFolderIterate}

                    totalItemCountString="files"
                  >
                    {ctx.settings.enableGoogleDriveIdNavigation &&
                      <form onSubmit={ev => {
                        ev.preventDefault();
                        const form = ev.currentTarget;
                        const inputEl = form.elements.namedItem('folder-id') as HTMLInputElement;

                        history.push(`/app/googleDrive/${inputEl.value}`);
                      }}>
                        <label htmlFor="folder-id">
                          <input 
                            id="folder-id" 
                            name="folder-id" 
                            placeholder="Navigate to specific gapi folder" 
                          />
                          <button>Navigate</button>
                        </label>
                      </form>
                    }
                    <CloudProviderFilesWithMemo
                      itemRenderer={googleDriveItemRenderer}
                      trackCreator={GoogleDriveTrack.from}
                      items={driveData}
                      audioItems={audioFiles}

                      handlePlayTrack={handlePlayTrack}
                      addFolderToPlaylist={addFolderToPlaylist}

                      rootPath="/app/googleDrive"
                      parentFolderPath={
                        folder?.parents &&
                        folder?.parents[0] &&
                        `/app/googleDrive/${folder.parents[0]}`
                      }

                      loadMore={loadMore}
                      isNextPageLoading={loadingMore}
                      hasMore={driveIterator?.next !== undefined}
                    />
                  </PlaylistControls>
                </article>
              </div>
            </div>
          </article>
      }
    </ErrorBoundary>
  );
}
