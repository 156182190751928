import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import Source, { Sources } from '../../models/source';
import { SmallDeviceContext, SmallDeviceShow } from '../../services/smallDevices/smallDeviceService';
import s from './mobileMenu.module.scss';

interface Props {

}

export default function MobileMenu({

}: Props) {

  const smallDevSvc = useContext(SmallDeviceContext);
  const location = useLocation();

  if (location.pathname.search(/youtube/i) !== -1) {
    var streamingProviderName = Sources[Source.YouTube].name;
  }
  else if (location.pathname.search(/spotify/i) !== -1) {
    var streamingProviderName = Sources[Source.Spotify].name;
  }
  else if (location.pathname.search(/soundcloud/i) !== -1) {
    var streamingProviderName = Sources[Source.SoundCloud].name;
  }
  
  return (
    <article className={s.mobileMenu}>
      <button
        className="button"
        onClick={() => smallDevSvc.setState(smallDevSvc.state === SmallDeviceShow.Playlists
          ? SmallDeviceShow.Main
          : SmallDeviceShow.Playlists)}
      >
        Playlists
      </button>
      {streamingProviderName! &&
        <button
          className="button"
          onClick={() => smallDevSvc.setState(smallDevSvc.state === SmallDeviceShow.StreamingPlaylists
            ? SmallDeviceShow.Main
            : SmallDeviceShow.StreamingPlaylists)}
        >
          {streamingProviderName!} Playlists
        </button>
      }
      <button
        className="button"
        onClick={() => smallDevSvc.setState(smallDevSvc.state === SmallDeviceShow.Player
          ? SmallDeviceShow.Main
          : SmallDeviceShow.Player)}
      >
        Now Playing
      </button>
      <button
        className="button"
        onClick={() => smallDevSvc.setState(smallDevSvc.state === SmallDeviceShow.Navigation
          ? SmallDeviceShow.Main
          : SmallDeviceShow.Navigation)}
      >
        Menu
      </button>
    </article>
  );
}
