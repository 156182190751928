import classNames from 'classnames';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, ContextMenuTriggerProps } from 'react-contextmenu';
import { ReactComponent as SortAscending } from '../../assets/icomoon/sort-amount-asc.svg';
import { ReactComponent as SortDescending } from '../../assets/icomoon/sort-amount-desc.svg';
import Playlist from '../../models/playlist/playlist';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import Source from '../../models/source';
import PodcastTrack from '../../models/track/podcastTrack';
import Track from '../../models/track/track';
import { ActivePlaylistContext } from '../../services/activePlaylist/activePlaylist';
import PlaylistColumns, { ColumnScope, TrackProperty } from '../../services/columnSettings/playlistColumns';
import { LibraryContext } from '../../services/library/libraryService';
import { PodcastContext } from '../../services/podcasts/podcastService';
import { Context } from '../../services/_globalContext/context';
import Constants, { Keys, messages } from '../../settings';
import { preventDefaultStopPropogation } from '../../utilities/preventDefault';
import shuffle from '../../utilities/shuffle';
import FadilaMenuItem from '../common/contextMenu/fadilaMenuItem';
import HideColumnsMenu from '../common/hideColumnsMenu/hideColumnsMenu';
import useColumnResizing from '../common/hooks/useColumnResizing';
import useColumnSettings from '../common/hooks/useColumnSettings';
import useDeductedContentWidth from '../common/hooks/useDeductedContentWidth';
import useMoreSharedPlaylistMethods from '../common/hooks/useMoreSharedPlaylistMethods';
import useSharedPlaylistMethods from '../common/hooks/useSharedPlaylistMethods';
import { ItemRendererProps } from '../common/itemRow/itemRowPropsAndTypes';
import PlaylistControls from '../common/playlistControls/playlistControls';
import Spinner from '../common/spinner/spinner';
import ListWithAutosizer from '../common/virtualized/listWithAutosizer';
import s from './playlist.module.scss';
import PlaylistItemRow from './playlistItems/playlistItemRow';
import PlaylistItemRowEnd from './playlistItems/playlistItemRowEnd';
import WindowedPlaylistItemRenderer from './playlistItems/windowedPlaylistItemRenderer';

interface Props {
  className?: string;
  /**
   * True indicates this playlist shows items not currently in library,
   * f.x. YT playlist
   * Hides delete and clear buttons.
   * Shows add button.
   */
  controlledPlaylist?: boolean;
  moveable?: boolean;
  /** 
   * We don't allow active playlist ordering since it's likely to be confusing
   * Ordering an ap is only a visual action. That is it does not change the play order of tracks
   */
  notOrderable?: boolean;
  loading?: boolean;

  playlist: Playlist;

  /** Applicable for library or active playlist */
  // updatePlaylist?(pl: Playlist): void;
  removeItem?(...playlistItems: PlaylistItem[]): void;
  clearPlaylist?(): void;
  updatePlaylist?(pl: Playlist): void;

  columnScope?: ColumnScope;
}

export default React.memo(function PlaylistComponent({
  className,
  loading,

  moveable: moveableProp,
  notOrderable,

  playlist,

  removeItem: removeItems,
  clearPlaylist,
  updatePlaylist,

  columnScope: columnScopeProp,
}: Props) {

  const playlistItems = playlist.tracks;

  const ctx = useContext(Context);
  const library = useContext(LibraryContext);
  const activePlaylist = useContext(ActivePlaylistContext);
  const podcastCtx = useContext(PodcastContext);

  const columnScope = columnScopeProp || ColumnScope.LibraryPlaylists;
  const { columns, columnSettings, } = useColumnSettings(columnScope);

  const {
    totalContentWidth,
    mainViewport,
    setMainViewport,
    resetColumnWidths,
    columnAutoResize,
    hideColumn,
    unhideColumn,
  } = useDeductedContentWidth(columnScope);
  const [onResizeStart] = useColumnResizing(columnScope, mainViewport);

  const {
    lastSelectedItem,
    selectedItems,
    selectedItemsRef,

    handleToggleItem,
    shiftSelectItem,
    changeItemSelectionState,
    clearSelected,
    setSelectedItems,
  } = useSharedPlaylistMethods<PlaylistItem>({
    playlistElement: mainViewport,
  });
  const {
    sortBy,
    sortByProp,
    setSortBy,
    sortOnClickCreator,
    sortByPropDescending,

    updateFilter,

    filteredAndSortedTracks,
    handlePlayTrack,
    moveable,
  } = useMoreSharedPlaylistMethods({
    playlist,
    items: playlistItems,
    lastSelectedItem,
    selectedItemsRef,
    setSelectedItems,
    moveableProp,
    columnScope,
  });

  const doSortPlaylistsByProp = useCallback(function (
    sorter: ReturnType<ReturnType<typeof sortByPropDescending>>
  ) {
    const sortedItems = playlistItems.sort(sorter);
    updatePlaylist!({ ...playlist, tracks: sortedItems, });

    setSortBy('index');
  }, [playlistItems, playlist, setSortBy, updatePlaylist, sortByPropDescending]);
  /**
   * Permanently orders playlist tracks by a given track property
   * @param propName 
   */
  const sortPlaylistsByProp = useCallback(function (propName: TrackProperty) {
    return function (ev: React.MouseEvent) {
      ev.stopPropagation();

      const sorter = sortByProp(propName!)();

      doSortPlaylistsByProp(sorter);
    };
  }, [sortByProp, doSortPlaylistsByProp]);
  const sortPlaylistsByPropDescending = useCallback(function (propName: TrackProperty) {
    return function (ev: React.MouseEvent) {
      ev.stopPropagation();

      const sorter = sortByPropDescending(propName!)();
      doSortPlaylistsByProp(sorter);
    };
  }, [sortByPropDescending, doSortPlaylistsByProp]);

  const shufflePlaylist = useCallback(function () {
    const shuffledItems = shuffle(filteredAndSortedTracks.slice());

    if (shuffledItems[0]) {
      const doPlay = true;
      activePlaylist.setActivePlaylist(
        shuffledItems,
        0,
        doPlay,
      );
    }
  }, [filteredAndSortedTracks, activePlaylist]);

  const savePodcastItem = useCallback(async function (playlistItem: PlaylistItem, podTrack: Track) {

    if (removeItems) {
      podcastCtx.savePodcastItem(
        playlistItem,
        podTrack,
        playlist,
      )
    }
  }, [podcastCtx, playlist, removeItems]);

  const ignorePodcastItemInPlaylist = useCallback(function (track: Track) {

    if (track.type === Source.Podcast) {

      const podcastTrack = track as PodcastTrack;

      const filteredSources = playlist.syncSources.filter(x => x.sourceId === podcastTrack.podcastFeed);

      // Is playlist set up to synchronize from the given podcast ?
      if (filteredSources.length) {
        playlist.syncIgnore[track.id] = true;

        library.updatePlaylist(playlist);
      }
    } else {
      ctx.callSnackbar(messages(Keys.notPodcastItem));
    }
  }, [playlist, library, ctx]);

  const itemRendererProps: ItemRendererProps<PlaylistItem> = useMemo(() => ({
    filteredAndSortedItems: filteredAndSortedTracks,

    ItemRow: PlaylistItemRow,
    ItemRowEnd: PlaylistItemRowEnd,

    playlist,

    columnScope,
    moveable,
    moveDescending: sortBy.propName === 'index-descending',

    handlePlayTrack,
    removeItems,

    selectedItems,
    selectedItemsRef,
    handleToggleItem: plItm => handleToggleItem(plItm, selectedItemsRef),
    handleShiftSelect: plItm => shiftSelectItem(plItm, selectedItemsRef, filteredAndSortedTracks),
    changeItemSelectionState: (plItm, selectState, remPrev) =>
      changeItemSelectionState(plItm, selectedItemsRef, selectState, remPrev),

    clearSelected,

    savePodcastItem,
    ignorePodcastItemInPlaylist,

    portalTarget: mainViewport || undefined,
  }), [
    selectedItems,
    selectedItemsRef,
    filteredAndSortedTracks,
    mainViewport,
    columnScope,
    moveable,
    sortBy,
    handlePlayTrack,
    handleToggleItem,
    shiftSelectItem,
    changeItemSelectionState,
    clearSelected,
    ignorePodcastItemInPlaylist,
    playlist,
    removeItems,
    savePodcastItem,
  ]);

  const [contextTriggers] = useState<{
    [P in TrackProperty]?: React.Component<ContextMenuTriggerProps, any, any>;
  } & {
    contextCol?: React.Component<ContextMenuTriggerProps, any, any>;
  }>({});

  const toggleMenu = useCallback((e: React.MouseEvent) => {
    const parent = e.currentTarget.parentElement;

    if (parent) {
      const columnName = parent.attributes.getNamedItem('data-column-name')?.value as TrackProperty;

      const ctxTrigger = contextTriggers[columnName];
      if (ctxTrigger) {
        (ctxTrigger as any).handleContextClick(e);
      }
    }
  }, [contextTriggers]);

  console.debug('rendering playlist component');

  return (
    <article
      ref={c => setMainViewport(c || undefined)}
      className={classNames('mainViewport', s.playlist, ctx.playerSize, className)}
    >
      {loading || columnSettings === undefined || mainViewport === undefined ?
        <Spinner />
        :
        <>
          <PlaylistControls
            name={playlist.name}
            display={true}
            isComplete={() => true}

            filteredItemCount={filteredAndSortedTracks.length}
            totalItemCount={() => playlist.tracks.length}

            displayGetRemaining={false}

            handleFilter={updateFilter}
            clearPlaylist={clearPlaylist}
            shufflePlaylist={shufflePlaylist}

            displayBottom={ctx.settings.showPlaylistStatusBar}
          >
            <div className={classNames(s.contentWrapper, s.parentContentWrapper)}>
              <div className={s.thead} style={{ width: totalContentWidth }}>
                <div
                  className={classNames(
                    s.headerColumn,
                    s.underscoreColumn, {
                    [s.activeFilter]: sortBy.propName === 'index',
                    [s.activeFilterDesc]: sortBy.propName === 'index-descending',
                  })}
                >
                  <div
                    onClick={ev => {
                      if (!notOrderable) {
                        ev.stopPropagation();
                        return sortBy.propName === 'index'
                          ? setSortBy('index', true)
                          : setSortBy('index');
                      }
                    }}
                  >
                    _
                  </div>
                </div>
                {/* We never have size and image in playlist components */}
                {(columns as TrackProperty[]).map(key =>
                    <div
                      key={key}
                      data-column-name={key}
                      style={{ width: columnSettings[key]?.width }}
                      className={classNames(
                        s.headerColumn, {
                        [s.hideOnHover]: columnSettings[key]!.width < Constants.HeaderHideBreakpoint,
                        [s.activeFilter]: sortBy.propName === key,
                        [s.activeFilterDesc]: sortBy.propName === `${key}-descending`,
                      })}
                    >
                      <ContextMenuTrigger
                        ref={c => contextTriggers[key] = c || undefined}
                        id={`context-menu-${key}`}
                        holdToDisplay={-1}
                        disableIfShiftIsPressed
                      >
                        <div
                          onClick={!notOrderable
                            ? sortOnClickCreator(PlaylistColumns[key].trackProperty)
                            : undefined}
                        >
                          <span className="header-column-content">
                            {PlaylistColumns[key].name}
                          </span>
                        </div>
                      </ContextMenuTrigger>
                      {!notOrderable &&
                        <button
                          className={classNames(s.menuBtn, s.hiddenColControls)}
                          onClick={sortOnClickCreator(PlaylistColumns[key].trackProperty)}>
                          {sortBy.propName === key
                            ?
                            <SortDescending fill="white" />
                            :
                            <SortAscending fill="white" />
                          }
                        </button>
                      }
                      <button
                        className={classNames(s.menuBtn, 'invisible-control')}
                        onClick={toggleMenu}
                      >
                        ☰
                      </button>
                      <ContextMenu id={`context-menu-${key}`}>
                        {moveable &&
                          <>
                            <FadilaMenuItem
                              onClick={
                                (preventDefaultStopPropogation(
                                  sortPlaylistsByProp(
                                    PlaylistColumns[key].trackProperty)))}
                            >
                              Permanently Sort by {PlaylistColumns[key].name} Ascending
                            </FadilaMenuItem>
                            <FadilaMenuItem
                              onClick={preventDefaultStopPropogation(
                                sortPlaylistsByPropDescending(
                                  PlaylistColumns[key].trackProperty))}
                            >
                              Permanently Sort by {PlaylistColumns[key].name} Descending
                            </FadilaMenuItem>
                          </>
                        }
                        <FadilaMenuItem onClick={() => hideColumn(key)}>
                          Hide Column
                        </FadilaMenuItem>
                        <FadilaMenuItem
                          onClick={preventDefaultStopPropogation(resetColumnWidths)}
                        >
                          Reset columns
                        </FadilaMenuItem>
                      </ContextMenu>
                      <div
                        className={s.resizeColumn}
                        onMouseDown={onResizeStart}
                        onDoubleClick={columnAutoResize}
                        onTouchStart={onResizeStart}
                      />
                    </div>
                  )}
                <div
                  data-column-name={'contextCol'}
                  className={classNames(
                    s.headerColumn,
                    s.hideOnHover,
                    s.contextMenuBtnColumn,
                  )}
                  style={{ width: (ctx.scrollbarWidth || 0) + 16 }}
                >
                  <ContextMenuTrigger
                    ref={c => contextTriggers['contextCol'] = c || undefined}
                    id="context-menu-contextCol"
                    holdToDisplay={-1}
                    disableIfShiftIsPressed={true}
                  >
                    <></>
                  </ContextMenuTrigger>
                  <button
                    className={classNames(s.menuBtn, 'invisible-control')}
                    onClick={toggleMenu}
                  >
                    ☰
                  </button>
                  <ContextMenu id="context-menu-contextCol">
                    <HideColumnsMenu
                      scope={columnScope}
                      hideColumn={hideColumn}
                      unhideColumn={unhideColumn}
                    />
                    <FadilaMenuItem
                      onClick={preventDefaultStopPropogation(resetColumnWidths)}
                    >
                      Reset columns
                    </FadilaMenuItem>
                  </ContextMenu>
                </div>
              </div>
              <div
                className={classNames(s.contentWrapper, {
                  [s.oddRows]: filteredAndSortedTracks.length % 2 !== 0
                })}
                style={{
                  width: totalContentWidth > mainViewport.offsetWidth
                    ? totalContentWidth
                    : undefined
                }}
              >
                <ListWithAutosizer
                  itemCount={filteredAndSortedTracks.length + (moveable ? 1 : 0)}
                  itemData={itemRendererProps}
                // innerElementType={innerElementType}
                >
                  {WindowedPlaylistItemRenderer}
                </ListWithAutosizer>
              </div>
            </div>
          </PlaylistControls>
        </>
      }
    </article >
  );
});
