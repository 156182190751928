import { DriveItem } from '@microsoft/microsoft-graph-types';
import classNames from 'classnames';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, ContextMenuTriggerProps } from 'react-contextmenu';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { promiseNoop } from '../../../gUtilities/noop';
import { DragType } from '../../../models/dragTypes';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import Track from '../../../models/track';
import { ColumnKey, ColumnScope, TrackProperty } from '../../../services/columnSettings/playlistColumns';
import { Context } from '../../../services/_globalContext/context';
import Constants from '../../../settings';
import { preventDefaultStopPropogation } from '../../../utilities/preventDefault';
import s from '../../playlist/playlist.module.scss';
import FadilaMenuItem from '../contextMenu/fadilaMenuItem';
import HideColumnsMenu from '../hideColumnsMenu/hideColumnsMenu';
import useColumnResizing from '../hooks/useColumnResizing';
import useColumnSettings from '../hooks/useColumnSettings';
import useDeductedContentWidth from '../hooks/useDeductedContentWidth';
import useSelectedItemsRef from '../hooks/useSelectedItemsRef';
import useSharedPlaylistMethods from '../hooks/useSharedPlaylistMethods';
import { CloudItemRendererData, CloudItemRendererProps } from '../itemRow/itemRowPropsAndTypes';
import Spinner from '../spinner/spinner';
import CloudItemRendererBase from './cloudItemRendererBase';
import cloudProviderStyles from './cloudProvider.module.scss';

interface Props<T, T2, TrackT> {
  itemRenderer: React.NamedExoticComponent<CloudItemRendererData<T, T2, TrackT>>;
  trackCreator: (item: T2) => TrackT;
  items: (T | undefined)[];
  audioItems: T2[];

  handlePlayTrack(item: T): void;
  addFolderToPlaylist(
    item: T,
  ): (
      addItemsToPlaylist: (i: PlaylistItem[]) => void
    ) => Promise<void>;

  rootPath: string;
  parentFolderPath?: string;

  isNextPageLoading: boolean;
  loadMore(startIndex: number, stopIndex: number): Promise<any>;
  /**
   * When provided, we expect items to be a collection of loaded and not loaded items
   * when missing we show a loading row at the bottom more akin to an infinite loader
   * 
   * Either totalItemCount or hasMore should be provided, not both
   */
  totalItemCount?: number;
  /**
   * Is there more data left to be loaded?
   */
  hasMore?: boolean;
}

type CloudFile = DriveItem | DropboxTypes.files.FileMetadataReference | gapi.client.drive.File;
const CloudProviderFiles = function <T extends CloudFile, CloudAudioFileT extends T, TrackT extends Track>({
  itemRenderer,
  trackCreator,
  items,
  audioItems,
  handlePlayTrack,
  addFolderToPlaylist,
  rootPath,
  parentFolderPath,
  isNextPageLoading,
  loadMore,
  totalItemCount,
  hasMore,
}: Props<T, CloudAudioFileT, TrackT>) {

  if ((totalItemCount === undefined && hasMore === undefined)
    || (totalItemCount !== undefined && hasMore !== undefined)) {
    throw new Error('Either totalItemCount or hasMore should be provided');
  }

  const ctx = useContext(Context);
  const columnScope = ColumnScope.CloudProviders;
  const { columns, columnSettings, } = useColumnSettings(columnScope);

  const {
    totalContentWidth,
    mainViewport,
    setMainViewport,
    resetColumnWidths,
    columnAutoResize,
    hideColumn,
    unhideColumn,
  } = useDeductedContentWidth(columnScope);
  const [onResizeStart] = useColumnResizing(columnScope, mainViewport);

  const {
    selectedItems,
    selectedItemsRef: selItmsRefOriginal,
    setSelectedItems,

    handleToggleItem,
    shiftSelectItem,
    changeItemSelectionState,
    clearSelected,

  } = useSharedPlaylistMethods<CloudAudioFileT>({
    tracks: audioItems,
    playlistElement: mainViewport,
  });
  const selectedItemsRef = useSelectedItemsRef(trackCreator, selItmsRefOriginal);

  const itemRendererProps: CloudItemRendererProps<T, CloudAudioFileT, TrackT> = useMemo(() => ({
    items,
    audioItems,
    rootPath,
    parentFolderPath,

    dragType: DragType.TrackItems,
    columnScope,
    portalTarget: mainViewport,

    handlePlayTrack,

    selectedItems,
    selectedItemsRef,
    handleToggleItem: plItm => handleToggleItem(plItm, selectedItems),
    handleShiftSelect: plItm => shiftSelectItem(plItm, selectedItems, audioItems),
    changeItemSelectionState: (plItm, selectState, remPrev) =>
      changeItemSelectionState(plItm, selectedItems, selectState, remPrev),

    clearSelected,

    addFolderToPlaylist,
  }), [
    rootPath,
    parentFolderPath,
    selectedItems,
    selectedItemsRef,
    items,
    audioItems,
    mainViewport,
    columnScope,
    handlePlayTrack,
    handleToggleItem,
    shiftSelectItem,
    changeItemSelectionState,
    clearSelected,
    addFolderToPlaylist,
  ]);

  const selectAll = useCallback(function () {
    setSelectedItems({
      current: new Set(audioItems),
    });
  }, [audioItems, setSelectedItems]);

  // adds space for preceding navigation elements, go up or root
  const precedingVirtualItemCount
    = 1 // Go to root / home button
    + (parentFolderPath ? 1 : 0) // Go Up

  // Adds for the conditional loading element that follows if we do not have a total item count
  const totalVirtualItemCount
    = precedingVirtualItemCount
    + (totalItemCount === undefined && hasMore ? 1 : 0) // Loading element at end
    ;

  const isItemLoaded = useCallback(
    (index: number) => totalItemCount
      ? items[index - precedingVirtualItemCount] !== undefined
      // Don't include loading element padding
      : index < items.length + precedingVirtualItemCount
    , [items, totalItemCount, precedingVirtualItemCount]);

  const [contextTriggers] = useState<{
    [P in TrackProperty]?: React.Component<ContextMenuTriggerProps, any, any>;
  } & {
    size?: React.Component<ContextMenuTriggerProps, any, any>;
    contextCol?: React.Component<ContextMenuTriggerProps, any, any>;
  }>({});

  const toggleMenu = useCallback((e: React.MouseEvent) => {
    const parent = e.currentTarget.parentElement;

    if (parent) {
      const columnName = parent.attributes.getNamedItem('data-column-name')?.value as TrackProperty;

      const ctxTrigger = contextTriggers[columnName];
      if (ctxTrigger) {
        (ctxTrigger as any).handleContextClick(e);
      }
    }
  }, [contextTriggers]);

  return (
    <article
      ref={c => setMainViewport(c || undefined)}
      className={cloudProviderStyles.cloudProviderFiles}
    >
      {columnSettings === undefined || mainViewport === undefined ?
        <Spinner />
        :
        <div className={classNames(s.contentWrapper, s.parentContentWrapper)}>
          <div className={s.thead} style={{ width: totalContentWidth }}>
            <div className={s.headerColumn}>
              <button
                className="button no-margin"
                onClick={selectedItemsRef.current.size
                  ? clearSelected
                  : selectAll
                }
              >
                x
              </button>
            </div>
            {columns.map(col => {
              const colKey = col as ColumnKey;

              if (colKey === TrackProperty.title) {
                return (
                  <div
                    key={colKey}
                    className={classNames(s.headerColumn, {
                      [s.hideOnHover]: columnSettings[TrackProperty.title]?.width! < Constants.HeaderHideBreakpoint
                    })}
                    style={{ width: columnSettings[TrackProperty.title]?.width }}
                    data-column-name={TrackProperty.title}
                  >
                    <ContextMenuTrigger
                      ref={c => contextTriggers[TrackProperty.title] = c || undefined}
                      id="cloudprovider-context-menu"
                      holdToDisplay={-1}
                      disableIfShiftIsPressed={true}
                    >
                      <span className="header-column-content">
                        Name
                      </span>
                    </ContextMenuTrigger>
                    <button
                      className={`${s.menuBtn} invisible-control`}
                      onClick={toggleMenu}
                    >
                      ☰
                    </button>
                    <div
                      className={s.resizeColumn}
                      onMouseDown={onResizeStart}
                      onDoubleClick={columnAutoResize}
                      onTouchStart={onResizeStart}
                    />
                  </div>
                );
              }
              else /*if (colKey === 'size')*/ {
                return (
                  <div
                    key={colKey}
                    className={classNames(s.headerColumn, {
                      [s.hideOnHover]: columnSettings['size']?.width! < Constants.HeaderHideBreakpoint
                    })}
                    style={{ width: columnSettings['size']?.width }}
                    data-column-name="size"
                  >
                    <ContextMenuTrigger
                      ref={c => contextTriggers['size'] = c || undefined}
                      id="cloudprovider-context-menu"
                      holdToDisplay={-1}
                      disableIfShiftIsPressed={true}
                    >
                      <span className="header-column-content">
                        Size
                    </span>
                    </ContextMenuTrigger>
                    <button
                      className={`${s.menuBtn} invisible-control`}
                      onClick={toggleMenu}
                    >
                      ☰
                  </button>
                    <div
                      className={s.resizeColumn}
                      onMouseDown={onResizeStart}
                      onDoubleClick={columnAutoResize}
                      onTouchStart={onResizeStart}
                    />
                  </div>
                );
              }
            })}
            <div
              data-column-name={'contextCol'}
              className={classNames(
                s.headerColumn,
                s.hideOnHover,
                s.contextMenuBtnColumn,
              )}
              style={{ width: (ctx.scrollbarWidth || 0) + 16 }}
            >
              <ContextMenuTrigger
                ref={c => contextTriggers['contextCol'] = c || undefined}
                id="cloudprovider-context-menu"
                holdToDisplay={-1}
                disableIfShiftIsPressed={true}
              >
                <></>
              </ContextMenuTrigger>
              <button
                className={classNames(s.menuBtn, 'invisible-control')}
                onClick={toggleMenu}
              >
                ☰
              </button>
            </div>
            <ContextMenu id="cloudprovider-context-menu">
              <HideColumnsMenu
                scope={columnScope}
                hideColumn={hideColumn}
                unhideColumn={unhideColumn}
              />
              <FadilaMenuItem
                onClick={preventDefaultStopPropogation(resetColumnWidths)}
              >
                Reset columns
              </FadilaMenuItem>
            </ContextMenu>
          </div>
          <div
            className={classNames(s.contentWrapper, {
              [s.oddRows]: items.length % 2 !== 0
            })}
            style={{ width: totalContentWidth }}
          >
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={totalVirtualItemCount + (totalItemCount || items.length)}
              loadMoreItems={totalItemCount
                ? loadMore
                : isNextPageLoading
                  ? promiseNoop
                  : loadMore}
            >
              {({ onItemsRendered, ref }) => (
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      height={height}
                      width={width}
                      itemSize={26}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      itemCount={totalVirtualItemCount + (totalItemCount || items.length)}
                      itemData={itemRendererProps}
                    >
                      {CloudItemRendererBase(itemRenderer)}
                    </List>
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </div>
        </div>
      }
    </article>
  );
};

const typedMemo: <T>(c: T) => T = React.memo;
const CloudProviderFilesWithMemo = typedMemo(CloudProviderFiles);

export default CloudProviderFilesWithMemo;
