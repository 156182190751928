export const reqQueueCreator = (function (
  setActive: () => void, 
  disableActive: () => void,
  maxConcurrent = 3,
) {

  let reqCount = 0;
  const queueArray = new Array<Function>();

  /**
   * Queue next item if applicable, otherwise lower running request counter
   */
  function onComplete() {
    if (queueArray.length) {
      const queuedItm = queueArray.pop()!;

      setTimeout(queuedItm, 0);
    } else {
      reqCount--;
    }

    if (reqCount === 0) {
      disableActive();
    }
  }

  return {
    queue(req: () => Promise<any>) {
      return new Promise(function (resolve, reject) {
        try {
          if (reqCount < maxConcurrent) {
    
            // Transition from inactive to active
            if (reqCount === 0) {
              setActive();
            }
    
            reqCount++;
            req().finally(onComplete).then(resolve, reject);
          } 
          else {
            queueArray.push(() => req().finally(onComplete).then(resolve, reject));
          }
        }
        catch(err) {
          reject(err);
        }
      });
    },
  };
});
