import classNames from 'classnames';
import React, { useContext } from 'react';
import { StorageProviderKey, StorageProviderKeyToReadableName } from '../../models/storageProviderKeys';
import { GoogleApiContext } from '../../services/gapi/gapiService';
import { LibraryContext } from '../../services/library/libraryService';
import { SpotifyContext } from '../../services/spotify/spotifyService';
import { Context } from '../../services/_globalContext/context';
import { Keys, messages } from '../../settings';
import ErrorBoundary from '../common/errorBoundary/errorBoundary';
import ProviderList from './providerList';
import s from './settingsComponent.module.scss';

interface Props {
}

export default function SettingsComponent({
}: Props) {

  const ctx = useContext(Context);
  const libCtx = useContext(LibraryContext);
  const spotifyCtx = useContext(SpotifyContext);
  const gapiCtx = useContext(GoogleApiContext);

  function handleLibraryPathSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    const form = ev.currentTarget;
    const inputEl = form.elements.namedItem('library-path') as HTMLInputElement;

    if (inputEl.value.startsWith('/')) {
      ctx.setLibraryPath(inputEl.value);
      ctx.callSnackbar(messages(Keys.saved), false);
    } else {
      ctx.callSnackbar(messages(Keys.ensurePathStringConformity));
    }
  }

  return (
    <ErrorBoundary>
      <article className={classNames('mainViewport', ctx.playerSize, s.settings)}>
        <article className={s.storageProviders}>
          {ctx.storageProvider.current
            ?
            <h3>Current storage provider: {StorageProviderKeyToReadableName[ctx.storageProvider.current.name]}</h3>
            :
            <h3>Choose storage provider</h3>
          }
          <hr />
          <ProviderList />
        </article>
        <div>
          {spotifyCtx.accessToken
            ?
            <>
              <p>Spotify Account {spotifyCtx.userProfile?.email}</p>
              <button
                className="button"
                onClick={spotifyCtx.logout}
              >
                Spotify Logout
            </button>
            </>
            :
            <button className="button" onClick={spotifyCtx.authorize}>
              Spotify Login
            </button>
          }
        </div>
        <div><hr /></div>
        <div>
          <br />
          <button className="button" onClick={libCtx.saveLibrary}>
            Save Library
          </button>
        </div>
        <div>
          <br />
          <button className="button" onClick={ctx.toggleConfirmDelete}>
            {ctx.settings.confirmDelete ? 'Never' : 'Always'} confirm before deleting tracks from library
          </button>
        </div>
        <div>
          <br />
          <button className="button" onClick={ctx.toggleShowPlaylistStatusBar}>
            {ctx.settings.showPlaylistStatusBar ? 'Disable' : 'Enable'} playlist status bar
          </button>
        </div>
        <div><hr /></div>
        <h3>Advanced</h3>
        {gapiCtx.gapiAuthorized &&
          ctx.storageProvider.current?.name === StorageProviderKey.GoogleDrive &&
          <div className={s.bigBtnGroup}>
            <button
              className="button"
              onClick={gapiCtx.resetAppDataFolder}
            >
              Reset GoogleDrive AppData
            </button>
            <button
              className="button"
              onClick={gapiCtx.downloadAppDataFolder}
            >
              Download GoogleDrive AppData
            </button>
            {/* 
              Especially useful for changing YouTube channels since the 
              Google Api is extremely limited in this regard. 
              See Asana tickets on Binni's issue 
            */}
            <button
              className="button"
              onClick={gapiCtx.disconnect}
            >
              Revoke app permissions
            </button>
          </div>
        }
        <form onSubmit={handleLibraryPathSubmit}>
          <div className="button-group align-center">
            <label htmlFor="library-path">
              {/* Configure your music library location in cloud storage. This path is used to determine where to save files for example. */}
              The podcast save feature currently only supports onedrive and does not ensure folders exist beforehand.
              <input
                id="library-path"
                name="library-path"
                type="text"
                defaultValue={ctx.libraryPath?.toString()}
                placeholder="/onedrive/podcast/save/path"
              />
            </label>
          </div>
          <button className="button secondary">Submit</button>
        </form>
        <h5>See <a href="https://developers.google.com/drive/api/v3/about-files">Google Drive Api docs</a> for info on File/Folder id. Viewable from google drive.</h5>
        <button
          className="button"
          onClick={ctx.toggleGoogleDriveIdNavigation}
        >
          {ctx.settings.enableGoogleDriveIdNavigation ? 'Disable' : 'Enable'} google drive id navigation
        </button>
        <h5>Locks the player on size medium. YouTube track playback size is controlled by Google, to lock on player size small, don't play YouTube tracks :)</h5>
        <button
          className="button"
          onClick={ctx.toggleLockPlayerSizeMedium}
        >
          {ctx.settings.lockPlayerSizeMedium ? 'Unlock' : 'Lock'} player size medium
        </button>
      </article>
    </ErrorBoundary>
  );
}
