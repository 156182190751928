import React from 'react';
import PlaylistItem from '../../../models/playlistItem/playlistItem';
import { ItemRendererData } from '../../common/itemRow/itemRowPropsAndTypes';

const WindowedPlaylistItemRenderer = React.memo(function (props: ItemRendererData<PlaylistItem>) {

  const {
    index,
    style,
    data,
  } = props;
  
  const {
    filteredAndSortedItems,

    ItemRow,
    ItemRowEnd,

  } = data;

  const {
    playlist,

    columnScope,
    portalTarget,

    moveable,
    moveDescending,

    handlePlayTrack,
    removeItems,

    selectedItemsRef,
    handleToggleItem,
    handleShiftSelect,
    changeItemSelectionState,

    clearSelected,

    savePodcastItem,
    ignorePodcastItemInPlaylist,

  } = data;

  const item = filteredAndSortedItems[index];

  // We add 1 to the itemCount when moveable, causing the index to grow past array length
  return moveable && index === filteredAndSortedItems.length
    ?
    <div style={style}>
      <ItemRowEnd
        portalTarget={portalTarget}
        playlist={playlist}
        selectedItems={selectedItemsRef.current}
      />
    </div>
    :
    <div style={style}>
      <ItemRow
        index={index}
        playlist={playlist}
        playlistItem={item}

        isSelected={selectedItemsRef.current.has(item)}

        columnScope={columnScope}
        portalTarget={portalTarget}

        moveable={moveable}
        moveDescending={moveDescending}

        handlePlayTrack={handlePlayTrack}
        removeItems={removeItems}

        selectedItemsRef={selectedItemsRef}
        handleToggleItem={handleToggleItem}
        changeItemSelectionState={changeItemSelectionState}
        handleShiftSelect={handleShiftSelect}
        clearSelected={clearSelected}

        savePodcastItem={savePodcastItem}
        ignorePodcastItemInPlaylist={ignorePodcastItemInPlaylist}
      />
    </div>
});

export default WindowedPlaylistItemRenderer;
