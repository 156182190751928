import { traverse } from "fp-ts/es6/Array";
import { either, tryCatch2v } from "fp-ts/es6/Either";
import { curry } from "fp-ts/es6/function";
import { IO } from "fp-ts/es6/IO";
import { fromNullable } from "fp-ts/es6/Option";
import Playlist from "../../models/playlist/playlist";

export const getLsItem = (key: string) =>
  new IO(() => fromNullable(localStorage.getItem(key)));
const createPlaylist = (clearKey: string) =>
  function (pl: Playlist) {
    return tryCatch2v(
      () => pl,
        // new Playlist(
        //   pl.name,
        //   pl.tracks.map((plItm: PlaylistItem) =>
        //     new PlaylistItem(plItm.id, plItm.trackType)
        //   ),
        //   pl.id
        // ),
      /** Clear LS on mismatched shape */
      function onShapeMismatch() {
        localStorage.setItem(clearKey, JSON.stringify({}))
        return undefined;
      }
    );
  };
  // AP
export const loadPlaylist = (key: string) => () =>
  getLsItem(key).run()
    .map(JSON.parse)
    .map(createPlaylist(key))
    .chain(x => fromNullable(x.value))
  ;
  /** Unused */
export const loadPlaylists = () =>
  getLsItem('playlists').run()
    .map(JSON.parse)
    .map((pls: Playlist[]) => traverse(either)(pls, createPlaylist('state')))
    .chain(x => fromNullable(x.value))
  ;

export const setLsItem = curry((key: string, value: unknown) =>
  localStorage.setItem(key, JSON.stringify(value))
);
