import { Either } from "fp-ts/es6/Either";
import { GlobalState } from "../../services/_globalContext/context";
import { Keys, messages, Tasks } from "../../settings";

export default function createAddFolderToPlaylist<T>(
  ctx: GlobalState,
  getItemsRecursive: (folderId: string) => Promise<T[]>,
  addItemsThenToLibrary: (...items: T[]) => Either<void, void>,
  audioItemFilter: (item: T) => boolean,
  errorHandler?: (err: any) => void,
) {

  return async function addFolderToPlaylist(
    folderId: string,
  ) {
    ctx.setRunningTask(`${Tasks.addFolderToPlaylist} ${folderId}`);
    try {
      const items = await getItemsRecursive(folderId);
      const audioItems = items.filter(audioItemFilter);

      return addItemsThenToLibrary(...audioItems);
    } 
    catch (err) {
      if (errorHandler) {
        errorHandler(err);
      } else {
        ctx.callSnackbar(messages(Keys.errorAddingFolderToLibrary));
        console.error(err);
      }
    }
    finally {
      ctx.removeRunningTask(`${Tasks.addFolderToPlaylist} ${folderId}`);
    }
  }
}
