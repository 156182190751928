// import { Rollbar } from './services'
// Rollbar.debug('Application starting');

import * as Sentry from '@sentry/browser';
import jsmediatags from 'jsmediatags';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import firebaseInit from './services/firebase';
import './styles/index.scss';

if (process.env.NODE_ENV === 'development') {
  (global as any)['jsmediatags'] = jsmediatags;
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://7209373ec3954030b4d29b570a9679d9@sentry.io/2303514",
  });

  firebaseInit();
}

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>,
  document.getElementById('root')
);
