import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { DropboxFile } from '../../models/dropboxFile';
import { isAudioFile } from '../../models/fileExtensions';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import Path from '../../models/routing';
import DropboxTrack from '../../models/track/dropboxTrack';
import { ActivePlaylistContext } from '../../services/activePlaylist/activePlaylist';
import { DropboxContext, DropboxIterator } from '../../services/dropbox/dropboxService';
import handleDropboxError from '../../services/dropbox/handleDropboxError';
import { createAddItemsToPlaylistThenLibrary } from '../../services/library/addTracksToPlaylistThenLibrary';
import { LibraryContext } from '../../services/library/libraryService';
import { Context } from '../../services/_globalContext/context';
import Constants, { Tasks } from '../../settings';
import { dbxDriveEncodeUri } from '../../utilities/preEncodeUri';
import CloudProviderFilesWithMemo from '../common/cloudProviderCommon/cloudProviderFiles';
import ErrorBoundary from '../common/errorBoundary/errorBoundary';
import PlaylistControls from '../common/playlistControls/playlistControls';
import Spinner from '../common/spinner/spinner';
import LibraryPlaylists from '../library/libraryPlaylists';
import dropboxItemRenderer from './dropboxItemRenderer';
import dropboxSorter from './dropboxSorter';

interface Props {
}
export default function DropboxComponent({
}: Props) {

  const match = useRouteMatch<Path>();

  const ctx = useContext(Context);
  const ctxRef = useRef(ctx);
  ctxRef.current = ctx;
  const libCtx = useContext(LibraryContext);
  const activePlaylist = useContext(ActivePlaylistContext);
  const dbxSvc = useContext(DropboxContext);

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [files, setFiles] = useState<DropboxFile[]>([]);
  const [dropboxIterator, setDropboxIterator] = useState<DropboxIterator>();

  const driveData = useMemo(() => files.sort(dropboxSorter), [files]);
  const audioFiles = useMemo(
    () => driveData.filter(
      x => isAudioFile(x.name) && x[".tag"] === 'file') as DropboxTypes.files.FileMetadataReference[],
    [driveData]);

  const errorHandler = useMemo(
    () => handleDropboxError(ctxRef, dbxSvc.logout),
    [dbxSvc.logout],
  );

  useEffect(function loadDriveData() {

    let halt = false;

    async function getSetUserDrive(path: string | undefined) {
      try {
        setLoading(true);
        const res = await dbxSvc.getUserDrive(path, Constants.ResultCount);
        // Get current folder entry, used for Up navigation
        console.debug(res);

        if (halt === false) {
          setFiles(res.files.entries);
          setDropboxIterator(res);
        }
      } catch (error) {
        errorHandler(error, () => Promise.resolve(ctx.callSnackbar('Error, path not found!')));
      }
      finally {
        setLoading(false);
      }
    }

    if (dbxSvc.account) {
      getSetUserDrive(match.params.path
        ? `/${dbxDriveEncodeUri(match.params.path)}`
        : undefined);
    }

    return () => { halt = true };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbxSvc.account, match.params.path]);

  const addItemsToPlaylistThenLibrary = useMemo(() => createAddItemsToPlaylistThenLibrary<DropboxTypes.files.FileMetadataReference>(
    ctx,
    libCtx,
    DropboxTrack.from,
  ), [ctx, libCtx]);

  const handlePlayTrack = useCallback((file: DropboxTypes.files.FileMetadataReference) => {

    const idx = audioFiles.indexOf(file);

    const tracks
      = audioFiles.slice(idx, audioFiles.length)
        .concat(audioFiles.slice(0, idx));

    const curPlItm = 0;
    const doPlay = true;
    addItemsToPlaylistThenLibrary(x =>
      activePlaylist.setActivePlaylist(
        x,
        curPlItm,
        doPlay,
      )
    )(...tracks);
  }, [addItemsToPlaylistThenLibrary, activePlaylist, audioFiles]);

  // Async state
  const isLoadingMore = useRef(false);
  const stopIdxRef = useRef<number>();

  const loadMore = useCallback(async function (startIdx: number, stopIdx: number) {

    console.log('LoadMore', startIdx, stopIdx);

    // accounts for index padding in react-window due to navigation elements
    const virtualItemCount
      = 1 // Go to root / home button
      + (match.params.path ? 1 : 0) // Go Up
      ;

    let stopIndex = stopIdx - virtualItemCount;

    // All executions update stop index
    // JS is not multi-threaded, so we can assume that a second loadMore won't get called
    // until iterate below gets called
    // This allows us to update let stopIndex at end of while loop
    // allowing further data loading if warranted
    stopIdxRef.current = stopIndex;

    if (isLoadingMore.current) {
      return;
    }

    isLoadingMore.current = true;

    if (dropboxIterator) {

      setLoadingMore(true);
      let iterator = dropboxIterator;
      let dropboxFiles = files;

      try {
        while (stopIndex >= dropboxFiles.length) {

          iterator = await dropboxIterator.next(dropboxIterator.files);

          dropboxFiles = dropboxFiles.concat(iterator.files.entries);

          setFiles(dropboxFiles);

          // Update after async method returns
          stopIndex = stopIdxRef.current;
        }
      }
      catch (err) {
        errorHandler(err);
      }
      finally {
        isLoadingMore.current = false;
        setDropboxIterator(iterator);
        setLoadingMore(false);
      }
    }
  }, [files, match.params.path, errorHandler, dropboxIterator]);

  const addFolderToPlaylist = useCallback(function (
    folder: DropboxTypes.files.FolderMetadataReference,
  ) {
    return async function (
      addItemsToPlaylist: (i: PlaylistItem[]) => void,
    ) {
      // errors handled in dbxSvc

      ctx.setRunningTask(Tasks.addFolderToPlaylist);
      try {
        const files = await dbxSvc.getAllTracksRecursive(folder.path_lower);
        const audioFiles = files.filter(x => isAudioFile(x.name) && x[".tag"] === 'file');

        addItemsToPlaylistThenLibrary(addItemsToPlaylist)(...audioFiles);
      } 
      catch (err) {
        errorHandler(err);
      }
      finally {
        ctx.removeRunningTask(Tasks.addFolderToPlaylist);
      }
    }
  }, [ctx, dbxSvc, errorHandler, addItemsToPlaylistThenLibrary]);

  /**
   * Dropbox Api does not show total item count on partial iteration
   * This in turns disables page selection before complete iteration.
   * This is a workaround
   * @param ev 
   */
  const completeFolderIterate = useCallback(async function (ev: React.MouseEvent<HTMLButtonElement>) {
    if (dropboxIterator) {
      setLoading(true);

      let iter = dropboxIterator;
      let dropboxFiles = files;

      try {
        do {
          iter = await iter.next(iter.files);

          if (iter.files.entries) {
            dropboxFiles = dropboxFiles.concat(iter.files.entries);
          }
        } while (iter.files.has_more);

        setFiles(dropboxFiles);
        setDropboxIterator(iter);
      } 
      catch (err) {
        errorHandler(err);
      } 
      finally {
        setLoading(false);
      }
    }
  }, [dropboxIterator, errorHandler, files]);

  return (
    <ErrorBoundary>
      {loading ?
        <Spinner />
        : dbxSvc.account === undefined ?
          <div>
            <button
              className="button"
              onClick={dbxSvc.authorize}
            >
              Dropbox Authorize
            </button>
          </div>
          :
          <article className="grid-container full fluid">
            <div className="grid-x">
              <div className="cell shrink">
                <article className={`mainViewport ${ctx.playerSize}`}>
                  <LibraryPlaylists />
                </article>
              </div>

              <div className="cell auto">
                <article className={`mainViewport ${ctx.playerSize}`}>
                  <PlaylistControls
                    name={match.params.path
                      ? match.params.path.replace(/.*\//, '')
                      : 'Dropbox'}
                    display={dropboxIterator !== undefined}
                    isComplete={() => !dropboxIterator!.files.has_more}

                    filteredItemCount={files.length}

                    totalItemCount={() => !dropboxIterator!.files.has_more
                      ? files.length
                      : 0}

                    displayGetRemaining={true}
                    getRemaining={completeFolderIterate}

                    totalItemCountString="files"
                  >
                    <CloudProviderFilesWithMemo
                      itemRenderer={dropboxItemRenderer}
                      trackCreator={DropboxTrack.from}
                      items={driveData}
                      audioItems={audioFiles}

                      handlePlayTrack={handlePlayTrack}
                      addFolderToPlaylist={addFolderToPlaylist}

                      rootPath="/app/dropbox"
                      parentFolderPath={match.params.path &&
                        `/app/dropbox/${(() => {
                          const paths = match.params.path.split('/');

                          return paths.slice(0, paths.length - 1)
                            .join('/')
                        })()}`}

                      loadMore={loadMore}
                      isNextPageLoading={loadingMore}
                      hasMore={dropboxIterator?.files.has_more}
                    />
                  </PlaylistControls>
                </article>
              </div>
            </div>
          </article>
      }
    </ErrorBoundary>
  );
}
