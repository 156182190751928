import { DriveItem } from '@microsoft/microsoft-graph-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { isAudioFile } from '../../models/fileExtensions';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import Path from '../../models/routing';
import OneDriveTrack from '../../models/track/oneDriveTrack';
import { ActivePlaylistContext } from '../../services/activePlaylist/activePlaylist';
import { createAddItemsToPlaylistThenLibrary } from '../../services/library/addTracksToPlaylistThenLibrary';
import { LibraryContext } from '../../services/library/libraryService';
import * as OneDriveApi from '../../services/oneDrive/oneDriveApi';
import { OneDriveContext } from '../../services/oneDrive/oneDriveService';
import { Context } from '../../services/_globalContext/context';
import { Tasks } from '../../settings';
import { oneDriveEncodeUri } from '../../utilities/preEncodeUri';
import CloudProviderFiles from '../common/cloudProviderCommon/cloudProviderFiles';
import ErrorBoundary from '../common/errorBoundary/errorBoundary';
import PlaylistControls from '../common/playlistControls/playlistControls';
import Spinner from '../common/spinner/spinner';
import LibraryPlaylists from '../library/libraryPlaylists';
import oneDriveItemRenderer from './oneDriveItemRenderer';

interface Props {
}

export default function OneDriveComponent(props: Props) {

  const match = useRouteMatch<Path>();
  const location = useLocation<void>();

  const ctx = useContext(Context);
  const libCtx = useContext(LibraryContext);
  const activePlaylist = useContext(ActivePlaylistContext);
  const oneDriveCtx = useContext(OneDriveContext);

  const [loading, setLoading] = useState(false);

  const [pageIterator, setPageIterator] = useState<OneDriveApi.PagedResponse>();
  // This 'ref' is used so we can keep the same array but refresh state
  // by recreating it's wrapper
  const [driveData, setDriveData]
    = useState<Array<DriveItem | undefined>>([]);

  const audioFiles = useMemo(
    () => driveData.filter(x => x?.audio),
    [driveData]) as DriveItem[];

  const configuredApi = useMemo(() => OneDriveApi.configureOneDriveApi(
    oneDriveCtx.graphClient,
    ctx.callSnackbar,
    ctx.flate,
  ), [oneDriveCtx.graphClient, ctx.callSnackbar, ctx.flate]);

  const iteratorCallback = useCallback(function (
    stopIndex?: number
  ): [(di: DriveItem) => boolean, Array<DriveItem | undefined>] {

    const items = new Array<DriveItem | undefined>();
    const callback = function (driveItem: DriveItem) {
      items.push(driveItem);

      return driveData.length > 0 && stopIndex
        ? items.length + driveData.length <= stopIndex
        // On initial get, stop after we hit default page size
        : items.length < OneDriveApi.defaultPageSize
        ;
    };

    return [callback, items];

    // Since the current value of driveData is always mutated
    // We can use an older version of the wrapper to reference the always current
    // driveData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGraphPath = useCallback(function () {
    if (match.params.path) {
      /**
       * Route to one drive path based on url
       * /onedrive/MyFolder/OneDrivePath
       * will f.x. navigate to /me/drive/root/MyFolder/OneDrivePath 
       * on the users OneDrive
       */
      return OneDriveApi.endpointFromPath(match.params.path);
    }
    else {
      // Load drive root
      return OneDriveApi.endpointFromPath('/me/drive/root/children', true);
    }
  }, [match.params.path]);

  /**
   * Load drive data when onedrive becomes ready
   */
  useEffect(function loadDriveData() {
    let halt = false;
    async function getSetPagedChildrenByPath(path: string) {
      try {
        const [callback, driveItems] = iteratorCallback();

        setLoading(true);
        const {
          iterator,
          response,
          totalItemCount,
        } = await configuredApi.msgraphGetPaged
          (path)
          (callback);

        if (halt === false) {
          await iterator.iterate();

          setDriveData(driveItems);
          setPageIterator({
            iterator,
            response,
            totalItemCount,
          });
        }
      } catch (error) {
        oneDriveCtx.handleGraphErrors(error);
      } finally {
        setLoading(false);
      }
    }

    if (oneDriveCtx.isAuthenticated) {
      const endpoint = getGraphPath();

      getSetPagedChildrenByPath(endpoint);
    }

    /** 
     * Don't try to update state if navigated away
     */
    return () => { halt = true };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneDriveCtx.isAuthenticated, match.params.path]);

  const addItemsToPlaylistThenLibrary = useMemo(() => createAddItemsToPlaylistThenLibrary<DriveItem>(
    ctx,
    libCtx,
    OneDriveTrack.from,

  ), [ctx, libCtx]);

  const addFolderToPlaylist = useCallback(function (
    driveItem: DriveItem,
  ) {
    return async function (
      addItemsToPlaylist: (i: PlaylistItem[]) => void
    ) {
      if (!driveItem.id) {
        ctx.callSnackbar('Erroneous drive item, please try again');
        return;
      }

      if (driveItem.size! > 10000000000) {
        if (!window.confirm(
          'You are attempting to add a folder larger than 10Gb to your library, are you sure?'
        )) {
          return;
        }
      }

      ctx.setRunningTask(Tasks.addFolderToPlaylist);

      try {
        const driveItems = await configuredApi.msgraphGetByIdRecursive(driveItem.id);
        const audioItems = driveItems.filter(x => x.audio)

        addItemsToPlaylistThenLibrary(addItemsToPlaylist)(...audioItems);
      }
      catch (err) {
        oneDriveCtx.handleGraphErrors(err);
      }
      finally {
        ctx.removeRunningTask(Tasks.addFolderToPlaylist);
      }
    };
  }, [ctx, oneDriveCtx, configuredApi, addItemsToPlaylistThenLibrary]);

  const handlePlayTrack = useCallback((driveItem: DriveItem) => {

    const audioItems = driveData.filter(x => x?.audio || isAudioFile(x?.name || '')) as DriveItem[];
    const idx = audioItems.indexOf(driveItem);

    const driveItems
      // Ordered to start at selected track
      = audioItems.slice(idx, audioItems.length)
        .concat(audioItems.slice(0, idx));

    const curPlItm = 0;
    const doPlay = true;
    addItemsToPlaylistThenLibrary(x =>
      activePlaylist.setActivePlaylist(
        x,
        curPlItm,
        doPlay,
      )
    )(...driveItems);
  }, [driveData, activePlaylist, addItemsToPlaylistThenLibrary]);

  // Async state
  /** 
   * We can't use a state based variable in case multiple requests attempt to fire 
   * in quick succession. 
   * This happens when we have total item count and user scrolls to end f.x.
   * In that case all loadMore methods called would have the old state variable.
   * We also need the ref since updating the ref will not trigger a re-render
   * so the TrackPlaylist can 
   */
  const isLoadingMore = useRef(false);
  const stopIdxRef = useRef<number>();

  const loadMore = useCallback(async function (startIdx: number, stopIdx: number) {

    console.log('LoadMore', startIdx, stopIdx);

    // accounts for index padding in react-window due to navigation elements
    const virtualItemCount
      = 1 // Go to root / home button
      + (match.params.path ? 1 : 0) // Go Up
      ;

    let stopIndex = stopIdx - virtualItemCount;

    // All executions update stop index
    // JS is not multi-threaded, so we can assume that a second loadMore won't get called
    // until iterate below gets called
    // This allows us to update let stopIndex at end of while loop
    // allowing further data loading if warranted
    stopIdxRef.current = stopIndex;

    if (isLoadingMore.current) {
      return;
    }

    isLoadingMore.current = true;
    let oneDriveData = driveData;

    try {
      if (pageIterator) {
        while (stopIndex > oneDriveData.length) {

          const [callback, driveItems] = iteratorCallback(stopIndex);
          pageIterator.iterator['callback'] = callback;

          await pageIterator.iterator.iterate();

          oneDriveData = oneDriveData.concat(driveItems);

          setDriveData(oneDriveData);

          // Update after async method returns
          stopIndex = stopIdxRef.current;
        }
      }
    }
    catch (err) {
      oneDriveCtx.handleGraphErrors(err);
    }
    finally {
      isLoadingMore.current = false;
    }
  }, [driveData, iteratorCallback, match.params.path, oneDriveCtx, pageIterator]);

  console.debug('OneDriveComponent rendering');

  return (
    <ErrorBoundary>
      {loading ?
        <Spinner />
        : !oneDriveCtx.isAuthenticated ?
          <div>
            <button
              className="button"
              onClick={oneDriveCtx.authorize}
            >
              OneDrive Authorize
            </button>
          </div>
          :
          <article className="grid-container full fluid">
            <div className="grid-x">
              <div className="cell shrink">
                <article className={`mainViewport ${ctx.playerSize}`}>
                  <LibraryPlaylists />
                </article>
              </div>

              <div className="cell auto">
                <article className={`mainViewport ${ctx.playerSize}`}>

                  <PlaylistControls
                    name={location.pathname.replace(/.*\//, '')}
                    display={pageIterator !== undefined}
                    isComplete={() => pageIterator!.iterator.isComplete()}

                    filteredItemCount={driveData.length}
                    totalItemCount={() => pageIterator!.response['@odata.count']}

                    displayGetRemaining={false}

                    totalItemCountString="files"
                    additionalControls={
                      <Link className="button" to={oneDriveEncodeUri(location.pathname)}>Refresh</Link>
                    }
                  >
                    <CloudProviderFiles
                      itemRenderer={oneDriveItemRenderer}
                      trackCreator={OneDriveTrack.from}
                      items={driveData}
                      audioItems={audioFiles}

                      handlePlayTrack={handlePlayTrack}
                      addFolderToPlaylist={addFolderToPlaylist}

                      rootPath="/app/onedrive"
                      parentFolderPath={match.params.path &&
                        oneDriveEncodeUri(
                          location.pathname.replace(/(\/[^/]+)$/, '')
                        )
                      }

                      totalItemCount={pageIterator?.totalItemCount}
                      loadMore={loadMore}
                      isNextPageLoading={false}
                    />
                  </PlaylistControls>
                </article>
              </div>
            </div>
          </article>
      }
    </ErrorBoundary>
  );
}
