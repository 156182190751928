import classNames from 'classnames';
import React from 'react';
import { isTouchDevice } from '../../../settings';
import s from './tips.module.scss';

interface Props {
  className?: string;
}

export default function KeyboardShortcuts({
  className
}: Props) {

  return (
    <section className={classNames(s.tips, className)}>
      <h4>
        Tips
      </h4>
      {!isTouchDevice &&
        <>
          <p>
            <b>Shift</b> clicking on a track will
            select all tracks from your last selected track
            up to and including your target
          </p>
          <p>
            <b>Ctrl/⌘</b> clicking on a track will
            add that track to your currently selected tracks
          </p>
        </>
      }
      <section>
        <h5>Context menu (☰)</h5>
        <p>
          Most interactive elements in Fadila have
          additional options available through the contextual menu.
          F.x. if you use any of the contextual menu options with multiple selected tracks
          the chosen action will be performed for all items.
        </p>
      </section>
    </section>
  );
}
