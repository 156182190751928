import { Account } from '@azure/msal';
import { Client, GraphError } from "@microsoft/microsoft-graph-client";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { StorageProviderKey } from '../../models/storageProviderKeys';
import { Keys, messages } from '../../settings';
import IStorageProvider from '../storage/istorageProvider';
import { Context } from '../_globalContext/context';
import * as OneDriveApi from './oneDriveApi';

export interface IOneDriveContext {
  isAuthenticated: boolean;
  graphClient: Client;

  authorize(): Promise<void>;
  /**
   * Assumes you have previously completed auth flow.
   * Immediately sets OneDrive state as authenticated, skips api call and auth prompt.
   */
  setIsAuthenticated(): void;
  logout(): void;
  getAccount(): Account;
  handleGraphErrors(err: GraphError): void;

  configuredApi: ReturnType<typeof OneDriveApi.configureOneDriveApi>;
}
export const OneDriveContext = React.createContext({} as IOneDriveContext);

interface Props {
  children: React.ReactNode;
}

export default function OneDriveService({
  children,
}: Props) {

  const ctx = useContext(Context);
  const ctxRef = useRef(ctx);
  ctxRef.current = ctx;

  const [isAuthenticated, setAuthenticated] = useState(false);
  const [graphClient] = useState(OneDriveApi.getClient);

  const sp = useRef<IStorageProvider>();

  const getAccount = useCallback(function () {
    const userAgentApplication = OneDriveApi.getUA();
    return userAgentApplication.getAccount();
  }, []);
  useEffect(function autologin() {

    if (getAccount()) {
      setAuthenticated(true);
    }
  }, [getAccount]);

  const logout = useCallback(function () {

    function completeLogout() {
      const userAgentApplication = OneDriveApi.getUA();
      // userAgentApplication.logout();
      (userAgentApplication as any).clearCache();
      (userAgentApplication as any).account = null;

      setAuthenticated(false);
    }

    if (ctxRef.current.storageProvider.current === sp.current) {
      ctxRef.current.configureStorageProvider();
      ctxRef.current.spLogoutHandling().then(completeLogout);
    }
    else {
      completeLogout();
    }
  }, []);

  const errorHandler = useCallback(async function (err: GraphError) {
    if (err instanceof GraphError) {
      if (err.statusCode === 401) {
        ctxRef.current.callSnackbar('Not currently logged in, please login.');
        logout();
      } else if (err.statusCode === 404) {
        ctxRef.current.callSnackbar('Unable to find requested resource!');
      } else if (err.statusCode === 413) {
        ctxRef.current.callSnackbar('The request size exceeds the maximum limit.');
      } else if (err.statusCode === 429) {
        ctxRef.current.callSnackbar('Too many OneDrive requests, please try again later.');
      } else if (err.statusCode === 507) {
        ctxRef.current.callSnackbar('The maximum storage quota has been reached.');
      } else if (err.statusCode === 509) {
        ctxRef.current.callSnackbar('App has been throttled for exceeding the maximum bandwidth cap. Please contact fadila@starcraft.is');
      } else if (err.statusCode < 500) {
        ctxRef.current.callSnackbar('There was a problem with your request, please try again later.')
      } else if (err.statusCode >= 500) {
        ctxRef.current.callSnackbar('OneDrive server error! Please try again later.');
      } else {
        ctxRef.current.callSnackbar(err.message);
      }
    }
    else {
      ctxRef.current.callSnackbar((err as any).message || err);
    }
  }, [logout]);

  const configuredApi = useMemo(
    () => OneDriveApi.configureOneDriveApi(graphClient, errorHandler, ctx.flate),
    [graphClient, errorHandler, ctx.flate]
  );
  const configuredApiRef = useRef(configuredApi);
  configuredApiRef.current = configuredApi;

  useEffect(function debug() {
    (global as any)['od'] = graphClient;
  }, [graphClient]);
  useEffect(function registerAsStorageProvider() {
    ctxRef.current.storageProviderMap[StorageProviderKey.OneDrive] = sp;
  }, []);
  useEffect(function updateStorageProvider() {
    sp.current = {
      name: StorageProviderKey.OneDrive,

      // decurry
      load: function load<T>(fileName: string, defaultData: T) {
        if (isAuthenticated) {
          return configuredApiRef.current.getOrCreateDataFile(fileName)(defaultData);
        }
        else {
          ctxRef.current.configureStorageProvider();
          return Promise.reject(messages(Keys.storageProviderNotLoggedIn));
        }
      },
      saveAppData: (fileName: string, fileData: any) => {
        if (isAuthenticated) {
          return configuredApiRef.current.putFile(fileName)(fileData);
        }
        else {
          ctxRef.current.configureStorageProvider();
          return Promise.reject(messages(Keys.storageProviderNotLoggedIn));
        }
      },
    };
  }, [isAuthenticated]);

  const authorize = useCallback(function () {
    // @ts-ignore TS2341
    return graphClient.config.authProvider.getAccessToken().then(
      () => setAuthenticated(true),
      ctxRef.current.callSnackbar,
    );
  }, [graphClient]);

  const setIsAuthenticated = useCallback(() => setAuthenticated(true), []);
  const ctxValue = useMemo(() => ({

    isAuthenticated,
    graphClient,

    authorize,
    setIsAuthenticated,
    logout,
    getAccount,
    handleGraphErrors: errorHandler,

    configuredApi,
  }), [
    isAuthenticated,
    graphClient,
    authorize,
    setIsAuthenticated,
    logout,
    getAccount,
    errorHandler,
    configuredApi,
  ]);

  return (
    <OneDriveContext.Provider value={ctxValue}>
      {children}
    </OneDriveContext.Provider >
  );
}
