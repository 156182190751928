import React from 'react';
import { Link } from 'react-router-dom';
import { CloudItemRendererData } from '../itemRow/itemRowPropsAndTypes';
import InfiniteLoadingRow from '../virtualized/infiniteLoadingRow';
import CloudProviderNavigation from './cloudProviderNavigation';

export default function CloudItemRendererBase(ItemRenderer: any) {

  return React.memo(function <FileT, AudioFileT, TrackT>({
    index,
    style,
    data,
  }: CloudItemRendererData<FileT, AudioFileT, TrackT>) {

    const {
      items,
      rootPath,
      parentFolderPath,
    } = data;

    const {
      columnScope: columnSettingsScope,
    } = data;

    const indexBumpAmount = 1 + (parentFolderPath ? 1 : 0);

    if (index === 0) {
      return (
        <CloudProviderNavigation 
          index={index}
          style={style} 
          columnScope={columnSettingsScope}
        >
          <Link to={rootPath}>Drive Root</Link>
        </CloudProviderNavigation>
      );
    }
    else if (parentFolderPath && index === 1) {
      return (
        <CloudProviderNavigation 
          index={index}
          style={style} 
          columnScope={columnSettingsScope}
        >
          <Link to={parentFolderPath}>Go Up</Link>
        </CloudProviderNavigation>
      );
    }
    else {
      const driveItem = items[index - indexBumpAmount];
      
      if (driveItem === undefined) {
        return <InfiniteLoadingRow style={style} />;
      }
      else {
        return (
          <div style={style}>
            <ItemRenderer index={index} data={data} />
          </div>
        );
      }
    }
  });
}
