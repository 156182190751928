import Podcast, { PodcastItem } from '../podcast';
import Source from '../source';
import Track from './track';

export default class PodcastTrack extends Track {

  podcastFeed: string;
  /** 
   * Podcast item constructor 
   * We still fetch tags and generate media duration in libraryService
  */
  constructor(
    podcastItem: PodcastItem,
    podcast: Podcast,
  ) {
    super(
      // we throw on null later in the constructor
      podcastItem.url,
      Source.Podcast,
      // Our youTube 'part' parameter ensures we receive the title
      podcastItem.title,
      podcastItem.author,
      podcast.title,
      podcastItem.duration * 1000,
      '',
      podcastItem.date.valueOf(),
    );

    this.podcastFeed = podcast.feed;
  }
}
