import { DriveItem } from '@microsoft/microsoft-graph-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import Playlist from '../../models/playlist/playlist';
import SyncSource from '../../models/playlist/syncSource';
import SyncType from '../../models/playlist/syncType';
import PlaylistItem from '../../models/playlistItem/playlistItem';
import Podcast from '../../models/podcast';
import Source from '../../models/source';
import Track from '../../models/track';
import OneDriveTrack from '../../models/track/oneDriveTrack';
import PodcastTrack from '../../models/track/podcastTrack';
import Constants, { backendHost, Keys, messages } from '../../settings';
import { LibraryContext } from '../library/libraryService';
import { getItem } from '../oneDrive/oneDriveApi';
import { OneDriveContext } from '../oneDrive/oneDriveService';
import { Context } from '../_globalContext/context';

async function getPodcastFromBackend(
  podcastFeed: string
): Promise<Podcast> {
  if (process.env.NODE_ENV === 'production') {
    var res = await fetch(
      `${backendHost()}/ParsePodcast?url=${podcastFeed}`);
  } else {
    var res = await fetch(
      `${backendHost()}/ParsePodcast?url=${podcastFeed}`);
  }

  return await res.json();
}

async function backendSavePodcast(
  accessToken: string,
  errorHandler: ((message: any) => void) | undefined,
  mediaUrl: string,
  savePath: string,
): Promise<DriveItem | undefined> {

  // ToDo: Ensure folders exist

  try {
    const res = await fetch(`${backendHost()}/SavePodcast`, {
      method: 'POST',
      body: JSON.stringify({
        savePath,
        accessToken,
        mediaUrl,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    if (res.ok) {
      return await res.json();
    }
    else {
      const msg = await res.text();
      throw new Error(`Save podcast error: '${msg}'`);
    }
  }
  catch (err) {
    if (errorHandler) {
      errorHandler(err);
    } else {
      throw err;
    }
  }
}

export interface IPodcastContext {
  addPodcast(podcastFeed: string): void;
  savePodcast(podcastItemUrl: string, podcastName: string): void;
  savePodcastItem(
    playlistItem: PlaylistItem,
    podTrack: Track,
    playlist: Playlist,
  ): void;
}
export const PodcastContext = React.createContext({} as IPodcastContext);

interface Props {
  children: React.ReactNode;
}

export default function PodcastService({
  children
}: Props) {

  const ctx = useContext(Context);
  const ctxRef = useRef(ctx);
  ctxRef.current = ctx;
  const library = useContext(LibraryContext);
  const libraryRef = useRef(library);
  libraryRef.current = library;

  // Initially only support onedrive podcast saving
  const oneDriveCtx = useContext(OneDriveContext);
  const oneDriveCtxRef = useRef(oneDriveCtx);
  oneDriveCtxRef.current = oneDriveCtx;

  /**
   * Previously we had an infinite loop when defining this function with useCallback
   * ctx is a dependency, registerProvid would then re-register, this would update the ctx....
   */
  const podcastSyncProvider = useCallback(async function (
    syncSource: SyncSource,
    playlist: Playlist
  ) {

    let pl = playlist;

    try {
      const podcast = await getPodcastFromBackend(syncSource.sourceId);
      const podcastInfo = { ...podcast, items: [] };

      const newItems = podcast.items.filter(
        item => !pl.syncIgnore[item.url]
          && !pl.tracks.some(track => track.id === item.url)
      )
        .map(x => new PodcastTrack(x, podcastInfo));

      if (newItems.length) {
        const res = libraryRef.current.addToLibrary(...newItems)
          .map(x => x.map(PlaylistItem.from))
          .mapLeft(ctxRef.current.callSnackbar);

        if (res.isRight()) {
          pl = {
            ...pl,
            tracks: pl.tracks.concat(res.getOrElse([])),
          };
        }
      }
    } catch (err) {
      ctxRef.current.callSnackbar(err);
    } finally {
      // We always end by updating playlist since it's last updated value will have changed
      libraryRef.current.updatePlaylist(pl);
    }
  }, []);
  useEffect(function registerSyncProvider() {
    ctx.addSyncProvider(SyncType.Podcast, podcastSyncProvider);
    // We can't listen to addSyncProvider changes since it changes each time we add
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcastSyncProvider]);

  const addPodcast = useCallback(async function (podcastFeed: string) {
    try {
      const podcast = await getPodcastFromBackend(podcastFeed);

      library.createPlaylist(podcast.title)
        .map(x => {
          x.syncSources.push({
            type: SyncType.Podcast,
            sourceId: podcast.feed,
            lastUpdated: -1,
          });

          return x;
        })
        .map(library.updatePlaylist)
        .mapLeft(ctxRef.current.callSnackbar);
    } catch (err) {
      ctxRef.current.callSnackbar(err);
    }
  }, [library]);

  const savePodcast = useCallback(async function (podcastItemUrl: string, podcastName: string) {
    if (!oneDriveCtxRef.current.isAuthenticated) {
      ctxRef.current.callSnackbar('Podcast saving is initially only supported by OneDrive')
      return;
    }

    if (!ctxRef.current.libraryPath) {
      ctxRef.current.callSnackbar(messages(Keys.libraryPathNotConfigured));
      return;
    }

    try {
      ctxRef.current.setRunningTask('Saving podcast');
      const accessToken
        = await (oneDriveCtxRef.current.graphClient as any).config.authProvider.getAccessToken();

      const savePath = `${ctxRef.current.libraryPath}/${Constants.PodcastFolderName}/${podcastName}/`;

      return await backendSavePodcast(
        accessToken,
        ctxRef.current.callSnackbar,
        podcastItemUrl,
        savePath,
      );
    }
    catch (err) {
      ctxRef.current.callSnackbar(err);
    }
    finally {
      ctxRef.current.removeRunningTask('Saving podcast');
    }
  }, []);

  /**
   * Uppercase Use 'cause React
   * 
   * Removes matching PodcastTrack from playlist and inserts the newly saved track instead
   * 
   * @param podcastTrack 
   * @param savedPlaylistItem 
   * @param playlist 
   */
  const UseSavedPodcastTrackInPlaylist = useCallback(function (
    podcastTrack: PodcastTrack,
    savedPlaylistItem: PlaylistItem,
    playlist: Playlist,
  ) {
    const newPlaylist: Playlist = {
      ...playlist,
      tracks: playlist.tracks.map(x => x.id === podcastTrack.id
        ? savedPlaylistItem
        : x),
    };

    const filteredSources = playlist.syncSources.filter(x => x.sourceId === podcastTrack.podcastFeed);

    // Is playlist set up to synchronize from the given podcast ?
    if (filteredSources.length) {
      playlist.syncIgnore[podcastTrack.id] = true;
    }

    return newPlaylist;
  }, []);

  const savePodcastItem = useCallback(async function (
    playlistItem: PlaylistItem,
    podTrack: Track,
    playlist: Playlist,
  ) {

    let playl: Playlist | undefined = playlist;

    if (podTrack.type === Source.Podcast) {

      const podcastTrack = podTrack as PodcastTrack;

      const driveItemBase = await savePodcast(podcastTrack.id, podcastTrack.album);
      // ensure we work with the most recent playlist after async work

      if (driveItemBase) {
        const driveItem = await getItem(oneDriveCtxRef.current.graphClient)
          (ctxRef.current.callSnackbar)
          (driveItemBase.id!);

        playl = libraryRef.current.playlists.find(x => x.id === playl!.id);
        const track = new OneDriveTrack(driveItem);

        libraryRef.current.addToLibrary(track)
          // All other playlists containing this podcast item
          .map(x => libraryRef.current.playlists.map(
            pl => pl.id !== Constants.LibraryId
              && pl.tracks.some(t => t.id === playlistItem.id)

              ? UseSavedPodcastTrackInPlaylist(podcastTrack, PlaylistItem.from(x[0]), pl)
              : pl)
          )
          // Batch update playlists
          .map(libraryRef.current.setPersistPlaylists)
          .mapLeft(ctxRef.current.callSnackbar);

        libraryRef.current.removeFromLibrary([playlistItem], true);
        ctxRef.current.callSnackbar(messages(Keys.savedPodcastItem), false);
      }
    } else {
      ctxRef.current.callSnackbar(messages(Keys.notPodcastItem));
    }
  }, [UseSavedPodcastTrackInPlaylist, savePodcast]);

  /**
   * The problem with the following is that if one of the base methods changes,
   * all of the queue wrapped methods belows signature will change.
   */
  const ctxValue = useMemo(() => ({
    addPodcast: (podcastFeed: string) => ctx.unifiedReqQueue.queue(
      () => addPodcast(podcastFeed)),
    savePodcast: (podcastItemUrl: string, podcastName: string) =>
      ctx.unifiedReqQueue.queue(() => savePodcast(podcastItemUrl, podcastName)),
    savePodcastItem: (
      playlistItem: PlaylistItem,
      podTrack: Track,
      playlist: Playlist,
    ) => ctx.unifiedReqQueue.queue(() => savePodcastItem(playlistItem, podTrack, playlist)),

  }), [ctx.unifiedReqQueue, savePodcast, savePodcastItem, addPodcast]);

  return (
    <PodcastContext.Provider value={ctxValue}>
      {children}
    </PodcastContext.Provider>
  );
}
