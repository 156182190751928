import React, { useContext, useEffect, useRef } from 'react';
import Playlist from '../../models/playlist/playlist';
import SyncSource from '../../models/playlist/syncSource';
import { ILibraryContext, LibraryContext } from '../library/libraryService';
import { Context, GlobalState } from '../_globalContext/context';

// const interval = 1000 * 60 * 5;

function synchronizePlaylist(ctx: GlobalState) {
  return function (source: SyncSource, pl: Playlist) {

    console.debug('synchronizing playlist ', pl, source);
    
    const syncProvider = ctx.syncProviders[source.type];
    if (syncProvider) {
      // This ensures we don't requeue the same synchronization if the sync effect
      // re-triggers multiple times before the queued sync below is executed.
      source.lastUpdated = Date.now();
      ctx.unifiedReqQueue.queue(
        () => syncProvider(source, pl)
      );
    }
  };
}
function processPlaylists(
  libCtx: ILibraryContext,
  syncPlaylist: (source: SyncSource, pl: Playlist) => void,
) {
  return function (playlists?: Playlist[]) {
    console.debug('Processing playlists', playlists || libCtx.playlists);
    
    for (let pl of playlists || libCtx.playlists) {
      for (let source of pl.syncSources) {
        const lastUpdateDate = new Date(source.lastUpdated);
        const now = new Date();

        if (source.lastUpdated <= 0) {
          syncPlaylist(source, pl);
          continue;
        }

        // This should update each playlist, set up for synchronization, once daily
        if (lastUpdateDate < now) {
          if (lastUpdateDate.getFullYear() < now.getFullYear()) {
            syncPlaylist(source, pl);
            continue;
          }
          if (lastUpdateDate.getMonth() < now.getMonth()) {
            syncPlaylist(source, pl);
            continue;
          }
          if (lastUpdateDate.getDate() < now.getDate()) {
            syncPlaylist(source, pl);
            continue;
          }
        }
      }
    }
  };
}

interface Props {
  children: React.ReactNode;
}

export default function SyncService({
  children,
}: Props) {

  const ctx = useContext(Context);
  const libCtx = useContext(LibraryContext);

  const syncPl = synchronizePlaylist(ctx);
  const processPls = processPlaylists(libCtx, syncPl);
  const processPlsRef = useRef(processPls);
  processPlsRef.current = processPls;

  const previousPlaylists = useRef<Playlist[]>();

  // const timeout = useRef<NodeJS.Timeout>();

  // Currently we only update playlists daily
  // useEffect(function syncPeriodically() {
  //   timeout.current = setInterval(function () {
  //     processPlsRef.current();
  //   }, interval);

  //   return () => timeout.current && clearInterval(timeout.current);
  // }, []);
  useEffect(function watchForNewPlaylists() {
    const newPlaylists = libCtx.playlists.filter(x => !previousPlaylists.current?.includes(x));

    processPlsRef.current(newPlaylists);
    
  }, [libCtx.playlists]);
  useEffect(function updatePreviousPlaylistsRef() {
    previousPlaylists.current = libCtx.playlists;
  }, [libCtx.playlists]);

  return (
    <>
      {children}
    </>
  );
}
