import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import appendScriptTag from '../../gUtilities/appendScriptTag';
import randomString from '../../gUtilities/randomString';
import Source from '../../models/source';
import SpotifyAuthenticationErrorObject from '../../models/spotify/authenticationErrorObject';
import SpotifyErrorObject from '../../models/spotify/errorObject';
import UserProfile from '../../models/spotify/userProfile';
import { ActivePlaylistContext } from '../activePlaylist/activePlaylist';
import { Context } from '../_globalContext/context';
import configureSpotifyApi, { clientID, getUserProfile, scopes } from './spotifyApi';

// export interface SpotifyIterator {
//   files: Dropbox.files.ListFolderResult;
//   next: (prevResults: Dropbox.files.ListFolderResult) => Promise<DropboxListIterator>;
// }

function getAuthUrl(nonce: string) {
  return 'https://accounts.spotify.com/authorize'
    + `?client_id=${clientID}`
    + `&redirect_uri=${(window.location.origin + '/spotify-redirect').replace(/\//g, '%2F')}`
    // https://developer.spotify.com/documentation/general/guides/authorization-guide/#list-of-scopes
    + `&scope=${scopes.join('%20')}`
    + `&response_type=token&state=${nonce}`;
}
/**
 * https://developer.spotify.com/documentation/general/guides/authorization-guide/#implicit-grant-flow
 */
function authorize(
  nonce: React.MutableRefObject<string>, 
  messageHandlerCallback: React.MutableRefObject<Function | undefined>,
): Promise<void> {

  window.open(getAuthUrl(nonce.current), 'SpotifyAuthPopup', 'width=670,height=800,dialog=yes,dependent=yes,scrollbars=yes,location=yes');

  return new Promise((resolve, reject) => {
    const nodeTimeout = setInterval(function checkIfClosed() {
      if (window.closed) {
        reject();
      }
    }, 300);

    messageHandlerCallback.current = () => {
      clearInterval(nodeTimeout);
      resolve();
    }
  });
}

export interface ISpotifyContext {
  authorize(): Promise<void>;
  logout(): void;
  accessToken?: string;
  userProfile?: UserProfile;
  isAttemptingLogin: boolean;

  player?: Spotify.SpotifyPlayer;
  playerId?: string;
  playbackState?: Spotify.PlaybackState;

  spotifyApi?: ReturnType<typeof configureSpotifyApi>;
}
export const SpotifyContext = React.createContext({} as ISpotifyContext);

interface Props {
  children: React.ReactNode;
}

export default function SpotifyService({
  children,
}: Props) {

  const {
    callSnackbar,
  } = useContext(Context);
  const ap = useContext(ActivePlaylistContext);

  const didAppendScriptTag = useRef(false);
  const didAttemptSilentLogin = useRef(false);

  const silentLoginIframe = useRef<HTMLIFrameElement>(null);
  const nonce = useRef<string>(randomString());
  const messageHandlerCallback = useRef<Function>();

  const [isAttemptingLogin, setIsAttemptingLogin] = useState(false);
  const [accessToken, setAccessToken] = useState<string>();
  // For player get token callback
  const accessTokenRef = useRef<string>();
  accessTokenRef.current = accessToken;
  const [userProfile, setUserProfile] = useState<UserProfile>();

  const [player, setPlayer] = useState<Spotify.SpotifyPlayer>();
  const [playerId, setPlayerId] = useState<string>();

  const [playbackState, setPlaybackState] = useState<Spotify.PlaybackState>();

  const logout = useRef(function () {
    setUserProfile(undefined);
    setAccessToken(undefined);
  });
  const silentLogin = useRef(function () {
    if (silentLoginIframe.current) {
      // Ensure only a single attempt is running at a time
      if (!isAttemptingLogin) {
        setIsAttemptingLogin(true);

        silentLoginIframe.current.addEventListener('load', iframeCleanup);
        silentLoginIframe.current.src = getAuthUrl(nonce.current);
      }
    }
  });
  const spotifyErrorHandler = useCallback(function (err: any) {
    if (err.error) {
      if (err.error.message) {
        const errObj = err.error as SpotifyErrorObject;

        // https://developer.spotify.com/documentation/web-api/#response-schema
        switch (errObj.status) {
          case 401:
            logout.current();
            callSnackbar('Authorization failed, please re-authenticate and try again.');
            break;

          case 400:
          case 403:
            callSnackbar(errObj.message);
            break;

          case 404:
          case 429:
            callSnackbar('There was a problem with your Spotify request. Please try again later.');
            break;

          case 500:
          case 502:
          case 503:
            callSnackbar('Spotify server error! Please try again later.');
            break;

          default:
            callSnackbar(errObj.message);
            break;
        }
      } else if (err.error.error_description) {
        const errObj = err.error as SpotifyAuthenticationErrorObject;

        logout.current();
        callSnackbar('Authorization failed, please re-authenticate and try again.');
        console.error(errObj);
      }
    } else {
      callSnackbar(err);
    }
  }, [callSnackbar]);

  const errorHandlerWrapper: <T>(cb: () => Promise<T>) => Promise<T>
    = useCallback(async function <T>(cb: () => Promise<T>) {

      try {
        return await cb();
      }
      catch (err) {
        spotifyErrorHandler(err);
        throw err;
      }
    }, [spotifyErrorHandler]);

  const spotifyApi = useMemo(() =>
    accessToken ? configureSpotifyApi(accessToken, errorHandlerWrapper) : undefined,
    [accessToken, errorHandlerWrapper]);

  /**
    * Previously we registered a listener on player play cleanup
    * This listener listened for state changes and paused spotify when applicable
    * 
    * This was needed because the cleanup spotifyPlayer.pause was sometimes resolving
    * before the spotifyPlayer PUT play track request finished.
    * 
    * The aforementioned listener was however not reliable, maybe it was pausing before
    * the play and we weren't getting a correct state update after the play finished.
    * Maybe it was the state_conflict calls in the network log....
    * 
    * @param state 
    */
  useEffect(function doMonitorPlayerState() {

    let interval: NodeJS.Timeout | undefined;

    async function monitorPlayerState() {
      if (player) {
        const state = await player.getCurrentState();
        if (state) {
          setPlaybackState(state);

          if (!state.paused && ap.currentTrack.trackType !== Source.Spotify) {
            player.pause().catch(console.error);
          }
        }
      }
    }

    if (player) {
      monitorPlayerState();
      interval = setInterval(monitorPlayerState, 1000);
    }

    return function () {
      if (interval !== undefined) {
        clearInterval(interval);
      }
    };
  }, [player, ap.currentTrack]);

  useEffect(function setupSpotifyAuthenticationFlow() {

    async function messageHandler(e: MessageEvent) {
      if (window.location.origin === e.origin
        && e.data[clientID] === true
        && nonce.current
        && e.data.state === nonce.current
      ) {
        if (accessToken === undefined) {
          console.debug(e.data);

          setAccessToken(e.data.access_token);
          // If token expires, ensure app knows the situation
          const expireTimeout = setTimeout(async function handleExpires() {
            try {
              await authorize(nonce, messageHandlerCallback);
            } catch {
              logout.current();
            }
          }, e.data.expires_in * 1000);

          setTimeout(function refreshToken() {
            function refreshMessageHandler() {
              if (window.location.origin === e.origin
                && e.data[clientID] === true
                && nonce.current
                && e.data.state === nonce.current
              ) {
                clearTimeout(expireTimeout);
              }
            }
            function removeListeners() {
              if (silentLoginIframe.current) {
                silentLoginIframe.current.removeEventListener('load', removeListeners);
              }
              window.removeEventListener('message', refreshMessageHandler);
            }

            if (silentLoginIframe.current) {
              silentLoginIframe.current.addEventListener('load', removeListeners);
              window.addEventListener('message', refreshMessageHandler);
              silentLogin.current();
            }
          },
            e.data.expires_in * 1000 - (5 * 60 * 1000));

          // We can't wait for SetToken to rerender this component and create a configured spotify api
          // we therefore call getUserProfile directly with the newly received access token
          const userProfile = await getUserProfile(e.data.access_token);
          setUserProfile(userProfile);

          if (messageHandlerCallback.current) {
            messageHandlerCallback.current();
          }
        }
      }
    }

    window.addEventListener('message', messageHandler, false);

    return () => window.removeEventListener('message', messageHandler, false);

  }, [accessToken]);

  function iframeCleanup() {
    setIsAttemptingLogin(false);
    if (silentLoginIframe.current) {
      silentLoginIframe.current.removeEventListener('load', iframeCleanup);
      silentLoginIframe.current.src = 'about:blank';
    }
  }

  useEffect(function attemptSilentLoginOnLoad() {
    // didAttempt is needed since the iFrame is recreated during startup for some reason
    // The setIsAttemptingLogin inside silentLogin however does likely not work
    // for this startup issue since state does not finish updating before the
    // second attempt fires.
    if (silentLoginIframe.current && !didAttemptSilentLogin.current) {
      console.debug('Attempting silent spotify login');
      didAttemptSilentLogin.current = true;
      silentLogin.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [silentLoginIframe.current]);

  useEffect(function loadSpotifyWebPlaybackSdk() {

    function initPlayer() {
      const player = new Spotify.Player({
        name: 'Fadila Web Player',
        getOAuthToken: cb => accessTokenRef.current && cb(accessTokenRef.current)
      });

      // Error handling
      player.addListener('initialization_error', ({ message }) => { spotifyErrorHandler(message); });
      player.addListener('authentication_error', ({ message }) => { spotifyErrorHandler(message); });
      player.addListener('account_error', ({ message }) => { spotifyErrorHandler(message); });
      player.addListener('playback_error', ({ message }) => {
        // Skip errors on pause failures
        if (message !== 'Cannot perform operation; no list was loaded.') {
          spotifyErrorHandler(message);
        }
      });

      // Playback status updates
      player.addListener('player_state_changed', function debugLogPlayerStateChanged(state) {
        console.debug(state);
      });

      // Ready
      player.addListener('ready', ({ device_id }) => {
        setPlayerId(device_id);
      });

      // Not Ready
      player.addListener('not_ready', ({ device_id }) => {
        console.log('Device ID has gone offline', device_id);
        setPlayerId(undefined);
        player.connect();
      });

      // Connect to the player!
      player.connect().then(success => {
        if (success) {
          console.debug('The Web Playback SDK successfully connected to Spotify!');
          setPlayer(player);

          if (process.env.NODE_ENV === 'development') {
            (window as any)['spotifyPlayer'] = player;
          }
        }
      });
    }

    if (accessToken) {
      window.onSpotifyWebPlaybackSDKReady = initPlayer;

      if (!didAppendScriptTag.current) {
        appendScriptTag("https://sdk.scdn.co/spotify-player.js");
        didAppendScriptTag.current = true;
      }
    }
  }, [accessToken, spotifyErrorHandler]);

  const ctxValue = useMemo(() => ({
    authorize: () => authorize(nonce, messageHandlerCallback),
    logout: logout.current,
    accessToken,
    userProfile,
    isAttemptingLogin,

    player,
    playerId,
    playbackState,

    spotifyApi,
  }), [
    accessToken,
    userProfile,
    isAttemptingLogin,
    player,
    playerId,
    playbackState,
    spotifyApi,
  ]);

  return (
    <SpotifyContext.Provider value={ctxValue}>
      {children}
      <iframe
        ref={silentLoginIframe}
        title="Silent login iFrame"
        className="hide"
      />
    </SpotifyContext.Provider>
  );
}
