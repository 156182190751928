import { ScreenSize } from "../screenDetection/screenDetection";

export enum TrackProperty {
  title = 'title',
  length = 'length',
  artist = 'artist',
  album = 'album',
  dateAdded = 'dateAdded',
  releaseDate = 'releaseDate',
  type = 'type',
  genre = 'genre',
}
export type ColumnKey = TrackProperty | 'size' | 'image';

export enum ColumnScope {
  /**
   * Layouts with the library playlists on either side and content using the remaining space
   */
  LibraryPlaylists = 'LibraryPlaylists',
  /**
   * Layouts where the main content is sandwiched between two lists
   * Usually the library playlists on left and cloud provider playlists on right side
   */
  YouTube = 'YouTube',
  YouTubeSearch = 'YouTubeSearch',
  Spotify = 'Spotify',
  SpotifyAlbumSearch = 'SpotifyAlbumSearch',
  SpotifyPlaylistSearch = 'SpotifyPlaylistSearch',
  SpotifyTrackSearch = 'SpotifyTrackSearch',

  CloudProviders = 'CloudProviders',
}

interface ColumnDefaults {
  name: string;
  trackProperty: TrackProperty;
  /**
   * Width in percentages of main viewport width
   */
  defaultWidthPerc: {
    [S in ColumnScope]: number;
  };
  sortOrder: number;
  /** Align content right */
  alignRight?: boolean;
  /**
   * Calculate this columns default width by rendering an element with x * '0' as content
   * X = the width number.
   * The remaining columns then calculate width using viewPort - these columns and percentage
   * off the remainder.
   */
  calcFromCharCount?: boolean;
}

/**
 * Default properties for all standard playlist columns.
 * defaultWidth is for Large screens, 
 * defaults for smaller ones is to grow all columns proportionately by the missing width.
 */
const PlaylistColumns: {
  [P in TrackProperty]: ColumnDefaults;
} & {
  size: Omit<ColumnDefaults, 'trackProperty'>;
  image: Omit<ColumnDefaults, 'trackProperty'>;
} = {
  [TrackProperty.length]: {
    name: 'Time',
    trackProperty: TrackProperty.length,
    defaultWidthPerc: {
      [ColumnScope.LibraryPlaylists]: 7,
      [ColumnScope.YouTube]: 7,
      [ColumnScope.Spotify]: 7,
      [ColumnScope.CloudProviders]: 0,
      [ColumnScope.YouTubeSearch]: 0,
      [ColumnScope.SpotifyAlbumSearch]: 0,
      [ColumnScope.SpotifyPlaylistSearch]: 0,
      [ColumnScope.SpotifyTrackSearch]: 7,
    },
    sortOrder: 0,
    alignRight: true,
    calcFromCharCount: true,
  },
  [TrackProperty.title]: {
    name: 'Name',
    trackProperty: TrackProperty.title,
    defaultWidthPerc: {
      [ColumnScope.LibraryPlaylists]: 40,
      [ColumnScope.YouTube]: 100,
      [ColumnScope.Spotify]: 40,
      [ColumnScope.CloudProviders]: 80,
      [ColumnScope.YouTubeSearch]: 55,
      [ColumnScope.SpotifyAlbumSearch]: 60,
      [ColumnScope.SpotifyPlaylistSearch]: 90,
      [ColumnScope.SpotifyTrackSearch]: 40,
    },
    sortOrder: 1,
  },
  [TrackProperty.artist]: {
    name: 'Artist',
    trackProperty: TrackProperty.artist,
    defaultWidthPerc: {
      [ColumnScope.LibraryPlaylists]: 25,
      [ColumnScope.YouTube]: 0,
      [ColumnScope.Spotify]: 25,
      [ColumnScope.CloudProviders]: 0,
      [ColumnScope.YouTubeSearch]: 0,
      [ColumnScope.SpotifyAlbumSearch]: 30,
      [ColumnScope.SpotifyPlaylistSearch]: 0,
      [ColumnScope.SpotifyTrackSearch]: 30,
    },
    sortOrder: 2,
  },
  [TrackProperty.album]: {
    name: 'Album',
    trackProperty: TrackProperty.album,
    defaultWidthPerc: {
      [ColumnScope.LibraryPlaylists]: 25,
      [ColumnScope.YouTube]: 0,
      [ColumnScope.Spotify]: 25,
      [ColumnScope.CloudProviders]: 0,
      [ColumnScope.YouTubeSearch]: 35,
      [ColumnScope.SpotifyAlbumSearch]: 0,
      [ColumnScope.SpotifyPlaylistSearch]: 0,
      [ColumnScope.SpotifyTrackSearch]: 30,
    },
    sortOrder: 3,
  },
  [TrackProperty.genre]: {
    name: 'Genre',
    trackProperty: TrackProperty.genre,
    defaultWidthPerc: {
      [ColumnScope.LibraryPlaylists]: 10,
      [ColumnScope.YouTube]: 0,
      [ColumnScope.Spotify]: 10,
      [ColumnScope.CloudProviders]: 0,
      [ColumnScope.YouTubeSearch]: 0,
      [ColumnScope.SpotifyAlbumSearch]: 0,
      [ColumnScope.SpotifyPlaylistSearch]: 0,
      [ColumnScope.SpotifyTrackSearch]: 0,
    },
    sortOrder: 4,
  },
  [TrackProperty.type]: {
    name: 'Type',
    trackProperty: TrackProperty.type,
    defaultWidthPerc: {
      [ColumnScope.LibraryPlaylists]: 12,
      [ColumnScope.YouTube]: 0,
      [ColumnScope.Spotify]: 0,
      [ColumnScope.CloudProviders]: 0,
      [ColumnScope.YouTubeSearch]: 12,
      [ColumnScope.SpotifyAlbumSearch]: 0,
      [ColumnScope.SpotifyPlaylistSearch]: 0,
      [ColumnScope.SpotifyTrackSearch]: 0,
    },
    sortOrder: 5,
    calcFromCharCount: true,
  },
  [TrackProperty.releaseDate]: {
    name: 'Year',
    trackProperty: TrackProperty.releaseDate,
    defaultWidthPerc: {
      [ColumnScope.LibraryPlaylists]: 10,
      [ColumnScope.YouTube]: 10,
      [ColumnScope.Spotify]: 10,
      [ColumnScope.CloudProviders]: 0,
      [ColumnScope.YouTubeSearch]: 0,
      [ColumnScope.SpotifyAlbumSearch]: 0,
      [ColumnScope.SpotifyPlaylistSearch]: 0,
      [ColumnScope.SpotifyTrackSearch]: 10,
    },
    sortOrder: 6,
    alignRight: true,
    calcFromCharCount: true,
  },
  [TrackProperty.dateAdded]: {
    name: 'Added',
    trackProperty: TrackProperty.dateAdded,
    defaultWidthPerc: {
      [ColumnScope.LibraryPlaylists]: 10,
      [ColumnScope.YouTube]: 0,
      [ColumnScope.Spotify]: 0,
      [ColumnScope.CloudProviders]: 0,
      [ColumnScope.YouTubeSearch]: 0,
      [ColumnScope.SpotifyAlbumSearch]: 0,
      [ColumnScope.SpotifyPlaylistSearch]: 0,
      [ColumnScope.SpotifyTrackSearch]: 0,
    },
    sortOrder: 7,
    alignRight: true,
    calcFromCharCount: true,
  },
  /** Cloud providers - file size */
  size: {
    name: 'Size',
    defaultWidthPerc: {
      [ColumnScope.LibraryPlaylists]: 0,
      [ColumnScope.YouTube]: 0,
      [ColumnScope.Spotify]: 0,
      [ColumnScope.CloudProviders]: 20,
      [ColumnScope.YouTubeSearch]: 0,
      [ColumnScope.SpotifyAlbumSearch]: 0,
      [ColumnScope.SpotifyPlaylistSearch]: 0,
      [ColumnScope.SpotifyTrackSearch]: 0,
    },
    sortOrder: 8,
    alignRight: true,
  },
  image: {
    name: 'Image',
    defaultWidthPerc: {
      [ColumnScope.LibraryPlaylists]: 0,
      [ColumnScope.YouTube]: 0,
      [ColumnScope.Spotify]: 0,
      [ColumnScope.CloudProviders]: 0,
      [ColumnScope.YouTubeSearch]: 10,
      [ColumnScope.SpotifyAlbumSearch]: 10,
      [ColumnScope.SpotifyPlaylistSearch]: 10,
      [ColumnScope.SpotifyTrackSearch]: 0,
    },
    sortOrder: 9,
  }
};

/**
 * Columns displayed by default in smaller screen sizes
 */
export const SmallScreenColumns = {
  [ScreenSize.Small]: [
    TrackProperty.title,
  ],
  [ScreenSize.Medium]: [
    TrackProperty.length,
    TrackProperty.title,
    TrackProperty.artist,
    TrackProperty.album,
  ]
};

export interface ColumnSetting {
  width: number;
  sortOrder: number;
  default?: boolean;
}

/**
 * Column settings values for a given scope and screen size.
 * 
 * Width in numeric pixels per TrackProperty
 */
export type ColumnSettings = {
  [P in Partial<TrackProperty>]: ColumnSetting;
} & {
  size?: ColumnSetting;
  image?: ColumnSetting;
};
/**
 * Object with settings for all screen sizes in a given scope.
 */
export type ColumnSettingsScope = {
  [P in ScreenSize]: Partial<ColumnSettings>;
};

export class ColumnSettingsStore {
  [ColumnScope.LibraryPlaylists]: Partial<ColumnSettingsScope> = {};
  [ColumnScope.YouTube]: Partial<ColumnSettingsScope> = {};
  [ColumnScope.YouTubeSearch]: Partial<ColumnSettingsScope> = {};
  [ColumnScope.Spotify]: Partial<ColumnSettingsScope> = {};
  [ColumnScope.SpotifyAlbumSearch]: Partial<ColumnSettingsScope> = {};
  [ColumnScope.SpotifyPlaylistSearch]: Partial<ColumnSettingsScope> = {};
  [ColumnScope.SpotifyTrackSearch]: Partial<ColumnSettingsScope> = {};
  [ColumnScope.CloudProviders]: Partial<ColumnSettingsScope> = {};

  constructor(storeJSON?: ColumnSettingsStore) {
    if (storeJSON) {
      for (const scope in ColumnScope) {
        const columnScope = scope as ColumnScope;

        this[columnScope] = storeJSON[columnScope] || this[columnScope];
      }
    }
  }
}

export default PlaylistColumns;
