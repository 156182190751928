import { MDCSnackbar } from '@material/snackbar';
import "@material/snackbar/dist/mdc.snackbar.css";
import * as Sentry from '@sentry/browser';

/**
* 
* @param message 
* @param buttonText 
* @param unexpectedError
*/
export default function createCallSnackbar(
  snackbarEl: HTMLDivElement | null,
) {

  function doCallSnackbar(
    message: any,
    // buttonText: string = 'OK',
    unexpectedError = true,
  ) {
    if (snackbarEl && message) {
      const snackbar = new MDCSnackbar(snackbarEl);
      snackbar.listen('MDCSnackbar:closed', handleClosed);
      // snackbar.actionButtonText = buttonText;

      if (message instanceof Error) {
        snackbar.labelText = message.message;
      }
      else if (message instanceof Event) {
        // Worst case the following property access will return undefined
        const element = message.target as HTMLScriptElement;
        
        snackbar.labelText = `Error event received from ${element?.localName} tag. ${element?.src}`;
      }
      else if (typeof message !== 'string') {
        if (typeof message.error === 'string') {
          snackbar.labelText = message.error;
        } else {
          snackbar.labelText = JSON.stringify(message);
        }
      }
      else {
        snackbar.labelText = message;
      }

      snackbar.open();
    }

    if (unexpectedError) {
      console.error(message);
      Sentry.captureException(unexpectedError);
    }
  }
  
  const queue = new Array<Function>();

  function handleClosed() {
    queue.shift();
    if (queue.length > 0) {
      queue[0]();
    }
  }
  
  return function callSnackbar(
    message: any,
    unexpectedError = true,
  ) {
    queue.push(() => doCallSnackbar(message, unexpectedError));
    if (queue.length === 1) {
      queue[0]();
    }
  }
}
