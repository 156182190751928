// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export async function register(config?: any) {
  if (/*process.env.NODE_ENV === 'production' && */'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    // const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    const swUrl = `${process.env.PUBLIC_URL}/thesw.js`;

    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      await checkValidServiceWorker(swUrl, config);

      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      await navigator.serviceWorker.ready;

      console.debug('Worker ready');
    }
    else {
      // Is not localhost. Just register service worker
      await registerValidSW(swUrl, config);
    }
  }
}

async function registerValidSW(swUrl: string, config?: any) {
  const registration = await navigator.serviceWorker
    .register(swUrl);

  registration.onupdatefound = () => {
    const installingWorker = registration.installing;
    if (installingWorker == null) {
      return;
    }
    installingWorker.onstatechange = () => {
      if (installingWorker.state === 'installed') {
        if (navigator.serviceWorker.controller) {
          // At this point, the updated precached content has been fetched,
          // but the previous service worker will still serve the older
          // content until all client tabs are closed.
          console.log('New worker is available and will be used when all ' +
            'tabs for this page are closed. See https://bit.ly/CRA-PWA.');
          // Execute callback
          if (config && config.onUpdate) {
            config.onUpdate(registration);
          }
        }
        else {
          // At this point, everything has been precached.
          // It's the perfect time to display a
          // "Content is cached for offline use." message.
          // Execute callback
          if (config && config.onSuccess) {
            config.onSuccess(registration);
          }
        }
      }
    };
  };
}

async function checkValidServiceWorker(swUrl: string, config?: any) {
  // Check if the service worker can be found. If it can't reload the page.
  try {
    const response = await fetch(swUrl);
    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get('content-type');
    if (response.status === 404 ||
      (contentType != null && contentType.indexOf('javascript') === -1)) {
      // No service worker found. Probably a different app. Reload the page.
      const registration = await navigator.serviceWorker.ready;
      await registration.unregister();
      window.location.reload();
    }
    else {
      // Service worker found. Proceed as normal.
      await registerValidSW(swUrl, config);
    }
  }
  catch (err) {
    if (err instanceof TypeError) {
      console.log('No internet connection found. App is running in offline mode.');
    }

    throw err;
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

export function updateGapiToken(token: string) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.controller?.postMessage({
      "command": "update_token",
      "message": token,
    });
  }
}
